import { useContext, useEffect, useState } from "react";
import { TableContainer, Box, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, TextField, InputAdornment, LinearProgress, Modal } from "@mui/material";
import { Search } from "@mui/icons-material";
import { StyledTableHeadCell, StyledTableHeader } from "../../../components/Table";
import { EditItem, ViewItem } from "../../../components/Icons";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { api } from "../../global/apiConfig";
import { AlertToast, noFetch } from "../../../divine-constants";
import ScheduledVisitViewModal from "../../../modals/ScheduledVisitViewModal";

export default function ToursEnquiry() {
	const {setAlert} = useContext(AlertToast);
	const [rows, setRows] = useState([]); 
	const [filteredList, setFilteredList] = useState(rows);
	const [noResults, setNoResults] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [clickedRow, setClickedRow] = useState();
	const [viewModalOpen, setViewModalOpen] = useState(false);
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [progress, setProgress] = useState(true);
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;

	const fetchData = () =>
		api().get("sadmin/get_schedule_visit?type=tour").then(res => {
			if(res.status === 200) {
				setRows(res.data);
				setProgress(false);
			}
		}).catch(() => setAlert(noFetch));

	useEffect(() => {
		fetchData()
	}, []);
	
	useEffect(() => {
		setFilteredList(rows);
		setPage(0);
	}, [rows]);	

	function handlePageChange(event, newPage) {	
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(parseInt(event.target.value), 10);
		setPage(0);
	}
	
	function handleTableSearch(event) {
		const query = event.target.value
		const next = rows.filter(item => {
			return Object
			.values(item)
			.map(v => typeof v === "string" ? v.toString() : "")
			.some(el => el.toLowerCase().includes(query));
		});
		if(next.length <= 0) setPage(0)
		setFilteredList(next);
		setNoResults(next.length <= 0);
	}
	
	<TextField 
		label="Search in Table"
		onChange={handleTableSearch}
		InputProps={{
			endAdornment: (
				<InputAdornment position="end">
					<Search />
				</InputAdornment>
			)
		}}
		InputLabelProps={{shrink: undefined}}
		required={false}
		helperText={noResults && "No results found"}
		FormHelperTextProps={{
			error: noResults
					}}
				/>
	return (
		<Box component="div">
			<StyledTableHeader>
				<TextField 
					label="Search in Table"
					onChange={handleTableSearch}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Search />
							</InputAdornment>
						)
					}}
					InputLabelProps={{shrink: undefined}}
					required={false}
					helperText={noResults && "No results found"}
					FormHelperTextProps={{
						error: noResults
					}}
				/>
			</StyledTableHeader>
			{progress && <LinearProgress />}
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<StyledTableHeadCell>S.No</StyledTableHeadCell>
							<StyledTableHeadCell>Username</StyledTableHeadCell>
							<StyledTableHeadCell>Property name</StyledTableHeadCell>
							<StyledTableHeadCell>Date</StyledTableHeadCell>
							<StyledTableHeadCell>Time</StyledTableHeadCell>
							<StyledTableHeadCell>Action</StyledTableHeadCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0 
							? filteredList.slice(page * rowsPerPage, (page + 1) * rowsPerPage) 
							: filteredList).map((row, i) => (
							<TableRow key={i}>
								<TableCell>{i + 1 + page*rowsPerPage}</TableCell>
								<TableCell>{row.user_name}</TableCell>
								<TableCell>{row.property_id}</TableCell>
								<TableCell>{row.date}</TableCell>
								<TableCell>{row.time}</TableCell>
								<TableCell>
									<ViewItem onClick={() => {setClickedRow(row); setViewModalOpen(true)}} />
									<EditItem />
								</TableCell>
							</TableRow>
						))}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={4} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[5, 10, {label: "All", value: -1}]}
				colSpan={100}
				count={filteredList.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
			/>
			<Modal 
				open={viewModalOpen} 
				onClose={() => setViewModalOpen(false)} 
				aria-labelledby="hostel room list form" 
				aria-describedby="insert new hostel room"
			><ScheduledVisitViewModal data={clickedRow} setModalOpen={setViewModalOpen} /></Modal>
		</Box>
	)
}