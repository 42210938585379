import { InputAdornment, TextField, Box, Button, Table, TableContainer, TableRow, TableCell, Modal, TablePagination, TableHead, TableBody, LinearProgress } from "@mui/material";
import { useState, useEffect, useContext, useReducer } from "react";
import DeleteModal from "../../../modals/DeleteModal";
import { Add, Search } from "@mui/icons-material";
import { EditItem, Redbin } from "../../../components/Icons";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { StyledTableHeadCell, StyledTableHeader } from "../../../components/Table";
import { AlertToast, noFetch } from "../../../divine-constants";
import { api } from "../../global/apiConfig";
import GSTModal from "../../../modals/GSTModal";
import GSTEditModal from "../../../modals/GSTEditModal";
import { modalReducer } from "../../../divine-constants";

export default function PrivacyPolicyTab() {
	const {setAlert} = useContext(AlertToast);
	const [rows, setRows] = useState([]); 
	const [deleteModal, setDeleteModal] = useState(false);
	const [filteredList, setFilteredList] = useState(rows);
	const [noResults, setNoResults] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(2);
	const [modalOpen, setModalOpen] = useReducer(modalReducer, {
		addNew: false,
		edit: false
	});
	const [clickedRow, setClickedRow] = useState();
	const [progress, setProgress] = useState(true);
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;

	const getGST = () => api().get("sadmin/get_gst").then(res => {
		setRows(res.data);
		setProgress(false)
	}).catch(() => setAlert(noFetch));

	useEffect(() => {
		getGST()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	useEffect(() => {
		setFilteredList(rows.filter(row => row.status));
		setPage(0);
	}, [rows]);

	function handlePageChange(_, newPage) {	
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(parseInt(event.target.value), 10);
		setPage(0);
	}
	
	function handleTableSearch(event) {
		const query = event.target.value
		const next = rows.filter(item => {
			return Object
			.values(item)
			.map(v => v.toString())
			.some(el => el.toLowerCase().includes(query.toLowerCase()));
		});
		if(next.length <= 0) setPage(0)
		setFilteredList(next);
		setNoResults(next.length <= 0);
	}
	return (
		<Box component="div">
		<StyledTableHeader>
			<TextField 
				label="Search in Table"
				onChange={handleTableSearch}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<Search />
						</InputAdornment>
					)
				}}
				InputLabelProps={{shrink: undefined}}
				required={false}
				helperText={noResults && "No results found"}
				FormHelperTextProps={{
					error: noResults
				}}
			/>
			<Button onClick={() => setModalOpen({type: "addNew", to: "open"})}><Add /> Add new</Button>
		</StyledTableHeader>
		{progress && <LinearProgress />}
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						<StyledTableHeadCell>S.No</StyledTableHeadCell>
						<StyledTableHeadCell>Type</StyledTableHeadCell>
						<StyledTableHeadCell>Minimum</StyledTableHeadCell>
						<StyledTableHeadCell>Maximum</StyledTableHeadCell>
						<StyledTableHeadCell>Percentage</StyledTableHeadCell>
						<StyledTableHeadCell>Action</StyledTableHeadCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{(rowsPerPage > 0 
						? filteredList.slice(page * rowsPerPage, (page + 1) * rowsPerPage) 
						: filteredList).map((row, i) => (row.status && 
						<TableRow key={row.id}>
							<TableCell>{i + 1 + page*rowsPerPage}</TableCell>
							<TableCell>{row.type}</TableCell>
							<TableCell>{row.type === "hotel" ? row.min_value : "N/A"}</TableCell>
							<TableCell>{row.type === "hotel" ? row.max_value : "N/A"}</TableCell>
							<TableCell>{row.gst_percentage}%</TableCell>
							<TableCell>
								<EditItem onClick={() => {setClickedRow(row); setModalOpen({type: "edit", to: "open"})}} />
								<Redbin onClick={() => {setDeleteModal(true); setClickedRow(row)}} />
							</TableCell>
						</TableRow>
					))}
					{emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
							<TableCell colSpan={4} />
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
		<TablePagination
			rowsPerPageOptions={[2, 4, {label: "All", value: -1}]}
			colSpan={100}
			count={filteredList.length}
			rowsPerPage={rowsPerPage}
			page={page}
			onPageChange={handlePageChange}
			onRowsPerPageChange={handleChangeRowsPerPage}
			ActionsComponent={TablePaginationActions}
		/>
		<Modal 
			open={modalOpen.addNew} 
			onClose={() => setModalOpen({type: "addNew", to: "close"})} 
			aria-labelledby="Gst form" 
			aria-describedby="insert new record"
		><GSTModal fetchData={getGST} setModalOpen={setModalOpen}/></Modal>

		<Modal 
			open={modalOpen.edit} 
			onClose={() => setModalOpen({type: "edit", to: "close"})} 
			aria-labelledby="Gst form" 
			aria-describedby="insert new record"
		><GSTEditModal setModalOpen={setModalOpen} fetchData={getGST} data={clickedRow} /></Modal>

		<DeleteModal dialogDisplay={deleteModal} setDialogDisplay={setDeleteModal} url={"sadmin/delete_gst?gst_id=" + clickedRow?.id} fetchData={getGST} />
		
	</Box>
	)
}