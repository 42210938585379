import { useContext, useEffect, useReducer, useState } from "react";
import { TableContainer, Box, Table, TableHead, TableBody, TableRow, Button, Modal, TableCell, TablePagination, TextField, InputAdornment, LinearProgress, Chip, Switch } from "@mui/material";
import { Add, AddOutlined, Search } from "@mui/icons-material";
import { StyledTableHeadCell, StyledTableHeader } from "../../components/Table";
import { TablePaginationActions } from "../../components/Table-pagination";
import BannersModal from "../../modals/BannersModal";
import { AlertToast, modalReducer, noFetch, tableImageHeight } from "../../divine-constants";
import { EditItem, Redbin } from "../../components/Icons";
import { api } from "../global/apiConfig";
import DeleteModal from "../../modals/DeleteModal";
import BannersEditModal from "../../modals/BannersEditModal";

export default function Banners() {
	const { setAlert } = useContext(AlertToast);
	const [rows, setRows] = useState([]);
	const [filteredList, setFilteredList] = useState(rows);
	const [noResults, setNoResults] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [modalOpen, setModalOpen] = useReducer(modalReducer, {
		addNew: false,
		edit: false
	});
	const [progress, setProgress] = useState(true);
	const [deleteModal, setDeleteModal] = useState(false);
	const [clickedRow, setClickedRow] = useState();
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;

	const fetchData = () =>
		api().get("sadmin/get_banners").then(res => {
			if (res.status !== 200) return;
			setRows(res.data);
			setProgress(false);
		}).catch(() => setAlert(noFetch));

	useEffect(() => {
		fetchData();
	}, [])
	useEffect(() => {
		setFilteredList(rows);
		setPage(0);
	}, [rows])

	function handlePageChange(event, newPage) {
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(parseInt(event.target.value), 10);
		setPage(0);
	}

	function handleTableSearch(event) {
		const query = event.target.value
		const next = rows.filter(item => {
			return Object
				.values(item)
				.map(v => typeof v === "string" ? v.toString() : "")
				.some(el => el.toLowerCase().includes(query));
		});
		if (next.length <= 0) setPage(0)
		setFilteredList(next);
		setNoResults(next.length <= 0);
	}

	const handleChange = async(id) => {
		api().patch('sadmin/display_banners',{banner_id:id}).then(res => {
		  fetchData()
		}).catch(() => setAlert(noFetch));
	  };

	return (
		<Box component="div">
			<StyledTableHeader>
				<Button onClick={() => setModalOpen({ type: "addNew", to: "open" })}><Add /> Add new</Button>
				<TextField
					label="Search in Table"
					onChange={handleTableSearch}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Search />
							</InputAdornment>
						)
					}}
					InputLabelProps={{ shrink: undefined }}
					required={false}
					helperText={noResults && "No results found"}
					FormHelperTextProps={{
						error: noResults
					}}
				/>
			</StyledTableHeader>
			{progress && <LinearProgress />}
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<StyledTableHeadCell>S.No</StyledTableHeadCell>
							<StyledTableHeadCell>Name</StyledTableHeadCell>
							<StyledTableHeadCell>Web Image</StyledTableHeadCell>
							<StyledTableHeadCell>Mobile Image</StyledTableHeadCell>
							<StyledTableHeadCell>Type</StyledTableHeadCell>
							<StyledTableHeadCell>Display Status</StyledTableHeadCell>
							<StyledTableHeadCell>Action</StyledTableHeadCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0
							? filteredList.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
							: filteredList).map((row, i) => (
								<TableRow key={row.id}>
									<TableCell>{i + 1 + page * rowsPerPage}</TableCell>
									<TableCell>{row.banner_name}</TableCell>
									<TableCell><img height={tableImageHeight} src={row.website_image} alt={row.website_image} /></TableCell>
									<TableCell><img height={tableImageHeight} src={row.mobile_image} alt={row.mobile_image} /></TableCell>
									<TableCell>{row.type}</TableCell>
									<TableCell>
										<Switch
											onClick={()=>handleChange(row.id)}
											checked={row.display_status===true}
											inputProps={{ 'aria-label': 'controlled' }}
										/>
									</TableCell>
									<TableCell>
										<EditItem onClick={() => { setClickedRow(row); setModalOpen({ type: "edit", to: "open" }) }} />
										<Redbin onClick={() => { setDeleteModal(true); setClickedRow(row) }} />
									</TableCell>
								</TableRow>
							))}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={4} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[5, 10, { label: "All", value: -1 }]}
				colSpan={100}
				count={filteredList.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
			/>
			<Modal
				open={modalOpen.addNew}
				onClose={() => setModalOpen({ type: "addNew", to: "close" })}
				aria-labelledby="banners-form"
				aria-describedby="insert new record"
			><BannersModal setModalOpen={setModalOpen} fetchData={fetchData} /></Modal>

			<Modal
				open={modalOpen.edit}
				onClose={() => setModalOpen({ type: "edit", to: "close" })}
				aria-labelledby="Offer & banner  form"
				aria-describedby="insert new record"
			><BannersEditModal setModalOpen={setModalOpen} fetchData={fetchData} data={clickedRow} /></Modal>

			<DeleteModal dialogDisplay={deleteModal} setDialogDisplay={setDeleteModal} url={"sadmin/delete_banners?banner_id=" + clickedRow?.id} fetchData={fetchData} />
		</Box>
	)
}