import { TextField } from "@mui/material";
import { useState, useContext } from "react";
import { AlertToast, alertState } from "../../../divine-constants";
import { api } from "../../global/apiConfig";
import SubmitButton from "../../../components/FormButton";

export default function RefundPolicyTab({data, setData}) {
	const {setAlert} = useContext(AlertToast);
	const [progress, setProgress] = useState(false);

	function handleSubmit(event) {
		setProgress(true);
		event.preventDefault();
		api().post("sadmin/setting_refund_policy", {
			refund_policy: data.privacy_policy.trim()
		}).then(res => {
			if(res.data.code === 201) {
				setAlert(alertState({
					title: "",
					message: "Refund policy added successfully",
					severity: "success",
					show: true
				}))
			}
		}).catch(() => {
			setAlert(alertState({	
				title: "",
				message: "Something went wrong",
				severity: "error",
				show: true
			}))
		}).finally(() => setProgress(false));
	}
	function handleChange(event) {
		setData(curr => ({...curr, refund_cancellation: event.target.value}))
	}
	return (
		<form onSubmit={handleSubmit}>
			<div>
				<TextField 
					label="Refund Policy"
					placeholder="Enter your refund policy"
					value={data.refund_cancellation}
					type="text"
					onChange={handleChange}
					fullWidth
					multiline
				/>
			</div>
			<SubmitButton showProgress={progress} type="Update" />
		</form>
	)
}