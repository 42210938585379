import { useContext } from "react";
import { User } from "../../divine-constants";
import { hyphenToNormal } from "../../utils";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

export default function Title() {
	const {user} = useContext(User);
	const location = useLocation();
	return (
		user.loggedIn && <div className="titlebar" style={{
			display: "flex", 
			justifyContent: "space-between",
			marginBottom: "1rem"
		  }}>
		  <Typography variant="h3" fontWeight={"bold"}>
			{hyphenToNormal(location).join(" / ")}
		  </Typography>
		</div>
	)
}