import { forwardRef, useContext, useState } from "react";
import { Box, Divider, FormControlLabel, InputLabel, Typography,FormControl, Switch, TextField } from "@mui/material";
import Fieldset from "../components/Fieldset";
import { StyledModalPaper } from "../styles/modal";
import ModalHeader from "./ModalHeader";
import SubmitButton from "../components/FormButton";
import { api } from "../pages/global/apiConfig"
import { alertState, AlertToast } from "../divine-constants";

function ScheduledVisitViewModal({data, setModalOpen, fetchData, type}) {
	const [progress, setProgress] = useState(false);
	const [formData, setFormData] = useState({
      id:data?data.id:"",
	  admin_comment:"",
	  visit_status:data?data.visit_status:""
	});
	const {setAlert} = useContext(AlertToast);
	function handleText(event) {
		setFormData(current => ({...current, [event.target.name]: event.target.value}));
	}
	function handleFormSubmit(event) {
		event.preventDefault();
		setProgress(true);
		api().patch("sadmin/edit_schedule_visit", 
			formData
		).then(res => {
						if(res.data.code === 201) {
								setModalOpen(false);
				setAlert(alertState({
					title: "",
					message: "SuccessFully Added",
					severity: "success",
					show: true
				}));
				fetchData();
			}
		}).catch(err => {
			if(err.status === 401) setAlert(alertState({
					title: "",
					message: "Unauthorized - Cannot add record",
					severity: "warning",
					show: true
				}))
			else setAlert(alertState({
				title: "",
				message: "Something went wrong. Please try again later",
				severity: "error",
				show: true
			}))
		}).finally(() => setProgress(false));
	}

			return (
		<StyledModalPaper>
			<ModalHeader setModalOpen={setModalOpen} title={data.user_name + "\t-\t Schedule details"}/>
			<Box display="flex" gap="1rem">
				<Box>
					<Typography component="span" color="text.secondary">Date</Typography><br />
					<Typography component="span" color="text.primary">{data.date}</Typography>
				</Box>
				<Divider orientation="vertical" flexItem />
				<Box>
					<Typography component="span" color="text.secondary">Time</Typography><br />
					<Typography component="span" color="text.primary">{data.time}</Typography>
				</Box>
			</Box>
			<br />
			<Fieldset legend="User Comment">{data.user_comment}</Fieldset>
			{!type?<Fieldset legend="Admin Comment">{data.admin_comment}</Fieldset>:
			<>
			<Box>
			<FormControl className="inline-form-control" required={false}>
							<InputLabel shrink>Status</InputLabel>
							<FormControlLabel 
								label={formData.visit_status ?"Visited" : "Not Visited" } 
								value={formData.visit_status}
								onChange={e => setFormData(curr => ({...curr, visit_status: e.target.checked}))} 
								checked={formData.visit_status}
								control={<Switch/>} 
							/>
						</FormControl>
			</Box>
			<Box component="form" onSubmit={handleFormSubmit} noValidate>
				<Box component="div" className="form-grid-container ">
					<TextField
						label="Admin Comment"
						type="text"
						name="admin_comment"
						fullWidth
						placeholder="Enter Your Comment"
						onChange={handleText}
						value={formData.admin_comment}
					/>
					
				</Box>
				<SubmitButton showProgress={progress} />
			</Box>
			</>
			}
		</StyledModalPaper>
	)
}

export default forwardRef((props, _ref) => <ScheduledVisitViewModal {...props} />)