import { useContext, useEffect, useState } from "react";
import HotelBookingsModal from "../../../modals/BookingsModal";
import { TableContainer, Box, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, TextField, InputAdornment, LinearProgress, MenuItem, Modal, Stack, Typography } from "@mui/material";
import { Search, SearchOutlined } from "@mui/icons-material";
import { StyledTableHeadCell, StyledTableHeader } from "../../../components/Table";
import { ViewItem } from "../../../components/Icons";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import AdvancedSearch from "../../../components/AdvancedSearch";
import { api } from "../../global/apiConfig";
import NumberDisplay from "../../../components/NumberDisplay";
import { AlertToast, noFetch } from "../../../divine-constants";
import corp from "../../Logo/corp-logo.png";
import ta from "../../Logo/travel-logo.png";


export default function HostelBookings() {
	const { setAlert } = useContext(AlertToast);
	const [rows, setRows] = useState([]);
	const [filteredList, setFilteredList] = useState(rows);
	const [noResults, setNoResults] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [progress, setProgress] = useState(true);
	const [clickedRow, setClickedRow] = useState();
	const [modal, setModal] = useState(false);
	const [advancedSearch, setAdvancedSearch] = useState({
		start: "", end: "", name: ""
	});
	const [cardData, setCardData] = useState({});
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;

	const fetchData = (startDate, endDate, name) => {
		const url = `sadmin/get_hostel_booking_details?start_date=${startDate}&end_date=${endDate}&property_name=${name}`;
		api().get(url).then(res => {
			if (res.status === 200) {
				setRows(res.data.data);
				setCardData(res.data);
				setProgress(false);
			}
		}).catch(() => setAlert(noFetch));
	}
	useEffect(() => {
		fetchData(advancedSearch.start, advancedSearch.end, advancedSearch.name);
	}, [advancedSearch.start, advancedSearch.end, advancedSearch.name]);

	useEffect(() => {
		setFilteredList(rows);
		setPage(0);
	}, [rows]);

	function handlePageChange(_, newPage) {
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(parseInt(event.target.value), 10);
		setPage(0);
	}

	function handleAdvancedSearch(event) {
		const query = event.target.value.toLowerCase();
		if (query === "select") {
			setAdvancedSearch((prev) => {
				return { ...prev, name: "" }
			})
			fetchData(advancedSearch.start, advancedSearch.end, "");
			return
		}
		const next = rows.filter(item => {
			return Object.values(item)
				.map(v => {
					const typeValue = typeof v
					if (typeValue === 'string') {
						return v.toString()
					}
				})
				.some(el => {
					const typeValue = typeof el
					if (typeValue === 'string') {
						return el.toLowerCase().includes(query)
					}
				});
		});
		if (next.length <= 0) setPage(0)
		setFilteredList(next);
		setNoResults(next.length <= 0);

		setAdvancedSearch(curr => ({ ...curr, [event.target.name]: event.target.value }))
	}


	return (
		<Box component="div">
			<Box className="card-container">
				<NumberDisplay color="text.primary" title="Total Bookings" value={cardData.booking_count} />
				<NumberDisplay color="primary.main" title="Total Amount" value={cardData.total_amounts} />
				<NumberDisplay color="error.main" title="Cancellations" value={cardData.cancellation_count} />
				<NumberDisplay color="success.main" title="Confirmations" value={cardData.confirmation_count} />
			</Box>
			<br />
			<AdvancedSearch>
				<TextField
					required={false}
					label="Start Date"
					type="date"
					name="start"
					value={advancedSearch.start}
					onChange={handleAdvancedSearch}
					helperText=""
				/>
				<TextField
					required={false}
					label="End Date"
					type="date"
					name="end"
					value={advancedSearch.end}
					onChange={handleAdvancedSearch}
					helperText=""
				/>
				<TextField
					select
					required={false}
					name="name"
					value={advancedSearch.name}
					onChange={handleAdvancedSearch}
					label="Hostel Name"
					helperText=""
				>
					<MenuItem value={"select"}>Select</MenuItem>
					{rows.map((item, ind) => <MenuItem key={ind} value={item.property_name}>{item.property_name}</MenuItem>)}
				</TextField>
			</AdvancedSearch>
			{progress && <LinearProgress />}
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<StyledTableHeadCell>S.No</StyledTableHeadCell>
							<StyledTableHeadCell>User Name</StyledTableHeadCell>
							<StyledTableHeadCell>Property Name</StyledTableHeadCell>
							<StyledTableHeadCell>Total Rooms</StyledTableHeadCell>
							<StyledTableHeadCell>Total Amount</StyledTableHeadCell>
							<StyledTableHeadCell>Booking Status</StyledTableHeadCell>
							<StyledTableHeadCell>View</StyledTableHeadCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0
							? filteredList.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
							: filteredList).map((row, i) => (
								<TableRow key={row.order_id}>
									<TableCell>
										<Stack>
											{row.user_type === "super_admin" || row.user_type === "manager" ? (
												<img src={corp} style={{ width: "40px", height: "20px" }} />
											) : row.user_type === 'agent' ? (
												<img src={ta} style={{ width: "40px", height: "20px" }} />
											) : null}
											<Typography>{i + 1 + page * rowsPerPage}</Typography>
										</Stack>
									</TableCell>
									<TableCell>{row.user_name}</TableCell>
									<TableCell>{row.property_name}</TableCell>
									<TableCell>{row.no_of_room}</TableCell>
									<TableCell>{row.total_amount}</TableCell>
									<TableCell>{row.booking_status}</TableCell>
									<TableCell>
										<ViewItem onClick={() => { setClickedRow(row); setModal(true) }} />
									</TableCell>
								</TableRow>
							))}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={4} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 20, { label: "All", value: -1 }]}
				colSpan={100}
				count={filteredList.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
			/>
			<Modal
				open={modal}
				onClose={() => setModal(false)}
				aria-labelledby="hotel bookings view"
			><HotelBookingsModal booking={clickedRow} setModalOpen={setModal} /></Modal>
		</Box>
	)
}