import { Close } from "@mui/icons-material";
import { Typography, IconButton, Divider } from "@mui/material";

export default function ModalHeader({setModalOpen, title = "Add New Record",edit, type}) {
	return (
		<div className="modal-heading">
			<Typography variant="h4" fontWeight="bold" textAlign="left" marginBottom="1rem">
				{edit === "edit"|| type === "edit"?"Edit Record":title}
			</Typography>
			<IconButton style={{
				position: "absolute",
				top: "1rem",
				transform: "translate(0.5rem, -0.5rem)",
				right: "1rem"
			}} onClick={() => setModalOpen(type ? {type: type, to: "close"} : false)}><Close/></IconButton>
			<Divider />
			<br />
		</div>
	)
}