import { Tab, Tabs } from "@mui/material"
import { useState } from "react";
import TabPanel from "../../../../components/TabPanel";
import DistinctChambRec from "./ChambRec";
import HotelRec from "./HotelRec";
import HostelRec from "./HostelRec";
import HallRec from "./HallRec";
import ToursRec from "./ToursRec";

export default function Recommendations() {
	const [page, setPage] = useState(0);
	function handlePageChange(e, next) {
		setPage(next);
	}
	return (
		<>
			<Tabs value={page} onChange={handlePageChange}>
				<Tab label="Hotels"/>
				<Tab label="Hostels"/>
				<Tab label="Halls"/>
				<Tab label="Tours"/>
				{/* <Tab disabled label="Distinct Chamb"/> */}
			</Tabs>
			<TabPanel index={0} value={page} children={<HotelRec />}/>
			<TabPanel index={1} value={page} children={<HostelRec />}/>
			<TabPanel index={2} value={page} children={<HallRec />}/>
			<TabPanel index={3} value={page} children={<ToursRec />}/>
			{/* <TabPanel index={4} value={page} children={<DistinctChambRec />} /> */}
		</>
	)
}