import { forwardRef } from "react";
import { StyledModalPaper } from "../../styles/modal";
import {
  Box,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import ModalHeader from "../ModalHeader";
import Fieldset from "../../components/Fieldset";
import { Clear, Done } from "@mui/icons-material";

function HallViewModal({ setModalOpen, data }) {
    return (
    <StyledModalPaper>
      <ModalHeader
        title="Hall Details"
        setModalOpen={setModalOpen}
        type="view"
      />
      <Box
        sx={{
          display: "grid",
          width: "100%",
          gap: "1rem",
          "& > fieldset": {
            width: "fit-content",
            flexGrow: 1,
            display: "grid",
            gap: "1rem",
            justifyContent: "space-around",
            pb: 1,
          },
          "@media only screen and (max-width: 750px)": {
            gridTemplateColumns: "1fr",
          },
        }}
        className="two-column-layout"
      >
        <Box>
          <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1}>
            <Chip label={data.category} />
            {/* <Chip label={data.room_type} color={data.room_type.toLowerCase() === "ac" ? "success" : "error"} /> */}
            <Chip
              label={
                data.active_status
                  ? "Status - Active"
                  : "Status - Inactive"
              }
              color={data.active_status ? "success" : "error"}
            />
            {/* <Chip icon={data.couple_friendly ? <Done /> : <Clear /> } label={"Couple Friendly"} color={data.couple_friendly ? "success" : "error"} /> */}
            <Chip color="success" label={data.discount_percentage + "% off"} />
          </Stack>
          <br />
          <Box>
          <Typography component="span" fontWeight={900} fontSize={20}>
                Starting Price:{" "}
              </Typography>
            <h2 style={{ display: "inline" }}>&#8377; {data.staring_price}</h2>
            &nbsp;(w/o discount) <br />
          </Box>
          <br />
          <Stack>
            <Box>
              <Typography component="span" fontWeight={600}>
                Hall Name:{" "}
              </Typography>
              {data.name}
            </Box>
            <Box>
              <Typography component="span" fontWeight={600}>
                Address:{" "}
              </Typography>
              {data.address}, {data.location}, {data.state}, {data.pincode}
            </Box>
            <Box>
              <Typography component="span" fontWeight={900}>
                Contact Details
              </Typography>
            </Box>
            <Box>
              <Typography component="span" fontWeight={600}>
                Email:{" "}
              </Typography>
              {data.email}
            </Box>
            <Box>
              <Typography component="span" fontWeight={600}>
                Mobile No:{" "}
              </Typography>
              {data.mobile}
            </Box>
          </Stack>
        </Box>
        <Fieldset
          legend="Amenities"
          style={{
            width: "100%",
            justifyContent: "flex-start",
            display: "block",
          }}
        >
          <List sx={{ maxHeight: 200, overflowY: "auto" }}>
            {data.hall_amentities.map((amenity, ind) => (
              <ListItem key={ind} sx={{ pb: 2 }}>
                <ListItemAvatar>
                  <img src={amenity.image} alt={amenity.image} height={30} />
                </ListItemAvatar>
                &nbsp;
                <ListItemText>{amenity.amenities_name}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Fieldset>
      </Box>
      <Fieldset legend="Description">{data.hall_description}</Fieldset>
      <Fieldset legend="Policy">{data.hall_poilcy}</Fieldset>
      <Fieldset legend="Hall Capacity">
      <Box>
              <Typography component="span" fontWeight={600}>
              Maximum Capacity:{" "}
              </Typography>
              {data.maximum_capacity}
            </Box>
            <Box>
              <Typography component="span" fontWeight={600}>
              Minimum Capacity:{" "}
              </Typography>
              {data.minimum_capacity}
            </Box>
      </Fieldset>
      <Fieldset legend="Main Image" style={{ paddingBottom: 16 }}>
        <img
          src={data.main_image}
          style={{ maxWidth: "35%", width: "45%" }}
          srcSet={data.main_image}
          alt="main_Image"
          loading="lazy"
        />
      </Fieldset>

      <Fieldset
        legend="Images"
        style={{ paddingBottom: 16, display: "flex", flexWrap: "wrap", gap: 4 }}
      >
        {data.other_image.map((image, ind) => (
          <img
            key={ind}
            src={image}
            style={{ maxWidth: "35%", width: "45%" }}
            srcSet={image}
            alt={image}
            loading="lazy"
          />
        ))}
      </Fieldset>
      <Fieldset legend="Location" style={{ paddingBottom: 16 }}>
        <Box component="div" className="form-grid-container two-column-layout">
         
         <Box component="span">
           <iframe src={data.iframe}></iframe>
          </Box>
         
         {/* <Box
            component="span"
            dangerouslySetInnerHTML={{ __html: data.iframe }}
          ></Box> */}
        </Box>
        <Box>
          <Typography component="span" fontWeight={600}>
            Latitude:{" "}
          </Typography>
          {data.latitude}
        </Box>
        <Box>
          <Typography component="span" fontWeight={600}>
            Longitude:{" "}
          </Typography>
          {data.longitude}
        </Box>
      </Fieldset>
    </StyledModalPaper>
  );
}

export default forwardRef((props, _ref) => <HallViewModal {...props} />);
