import { forwardRef, useState, useContext } from "react";
import { StyledModalPaper } from "../../styles/modal";
import { Box, TextField } from "@mui/material";
import ModalHeader from "../ModalHeader";
import SubmitButton from "../../components/FormButton";
import { api } from "../../pages/global/apiConfig";
import { alertState, AlertToast } from "../../divine-constants";

function HallCategoryModal({setModalOpen, fetchData, data,type}) {
	console.log(data,"hall_data")
	const [formData, setFormData] = useState({
		categoryName: data? data.category_name:"",
		image:""
	});
	const [progress, setProgress] = useState(false);
	
	const {setAlert} = useContext(AlertToast);

	function handleCategory(event) {
		setFormData(current => ({...current, categoryName: event.target.value}))
	}

	function handleImage(event) {
		setFormData(current => ({...current, image: event.target.files[0]}))
	}
	
	function handleFormSubmit(event) {
			
		event.preventDefault();
		setProgress(true);
		if(formData.categoryName.trim() === "") return;

		if(type.type === "edit"){
						api().patch("sadmin/edit_category", {
				category_name: formData.categoryName,
				image: formData.image,
				cat_id:type.id,
				type: "hall"
			}).then(res => {
				if(res.data.code === 201) {
					
					setModalOpen(false);
					setAlert(alertState({
						title: "",
						message: "Hall category Updated successfully",
						severity: "success",
						show: true
					}));
					fetchData();
				}
			}).catch(err => {
				if(err.status === 401) setAlert(alertState({
						title: "",
						message: "Unauthorized - Cannot add record",
						severity: "warning",
						show: true
					}))
				else setAlert(alertState({
					title: "",
					message: "Something went wrong. Please try again later",
					severity: "error",
					show: true
				}))
			}).finally(() => setProgress(false));
			type.type = "";
		}else{
			api().post("sadmin/add_category", {
				category_name: formData.categoryName,
				image: formData.image,
				type: "hall"
			}).then(res => {
				if(res.data.code === 201) {
					
					setModalOpen(false);
					setAlert(alertState({
						title: "",
						message: "Hall category added successfully",
						severity: "success",
						show: true
					}));
					fetchData();
				}
			}).catch(err => {
				if(err.status === 401) setAlert(alertState({
						title: "",
						message: "Unauthorized - Cannot add record",
						severity: "warning",
						show: true
					}))
				else setAlert(alertState({
					title: "",
					message: "Something went wrong. Please try again later",
					severity: "error",
					show: true
				}))
			}).finally(() => setProgress(false));
		}
		
	}
	return (
		<StyledModalPaper>
			<ModalHeader setModalOpen={setModalOpen}/>
			<Box component="form" onSubmit={handleFormSubmit} noValidate>
				<Box component="div" className="form-grid-container two-column-layout">
					<TextField
						label="Category Name"
						type="text"
						value={formData.categoryName}
						placeholder="Enter category name"
						onChange={handleCategory}
					/>
					<TextField
						label="Category Image"
						type="file"
						placeholder="Image"
						onChange={handleImage}
					/>
				</Box>
				<SubmitButton showProgress={progress} />
			</Box>
		</StyledModalPaper>
	)
}

export default forwardRef((props, _ref) => <HallCategoryModal {...props} />)
