import { useEffect, useState } from "react";
import { TableContainer, Box, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, TextField, InputAdornment, LinearProgress, MenuItem, Modal } from "@mui/material";
import { Search } from "@mui/icons-material";
import { StyledTableHeadCell, StyledTableHeader } from "../../components/Table";
import { ViewItem } from "../../components/Icons";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import AdvancedSearch from "../../components/AdvancedSearch";
import { Property_URL, api } from "../global/apiConfig";
import NumberDisplay from "../../components/NumberDisplay";
import HotelBookingsModal from "../../modals/BookingsModal";

export default function Bookings() {
    const [formData, setFormData] = useState({});
	const [rows, setRows] = useState([]);
	const [filteredList, setFilteredList] = useState(rows);
	const [noResults, setNoResults] = useState(false);
	const [modal, setModal] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [progress, setProgress] = useState(true);
	const [clickedRow, setClickedRow] = useState();
	const [advancedSearch, setAdvancedSearch] = useState({
		start: "", end: "", hotelName: ""
	});
	const [cardData, setCardData] = useState({});
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;

	const id = localStorage.getItem("UserId");
	const fetchData = () => api().get(`property/get_tour_booking_details?property_id=${id}&&start_date=${advancedSearch.start}&end_date=${advancedSearch.end}` ).then(res => {
		if(res.status === 200) {
            console.log(res)
			setRows(res.data.data);	
            setCardData(res.data);
			setProgress(false);	
		}
	})
	useEffect(() => {
		fetchData(advancedSearch.start);
	}, [advancedSearch.start]);

	useEffect(() => {
		setFilteredList(rows);
		setPage(0);
	}, [rows]);


	function handlePageChange(_, newPage) {
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(parseInt(event.target.value), 10);
		setPage(0);
	}

	function handleAdvancedSearch(event) {
		setAdvancedSearch(curr => ({...curr, [event.target.name]: event.target.value}))
	}
	
	function handleTableSearch(event) {
		const query = event.target.value
		const next = rows.filter(item => {
			return Object
			.values(item)
			.map(v => v.toString())
			.some(el => el.toLowerCase().includes(query));
		});
		if(next.length <= 0) setPage(0)
		setFilteredList(next);
		setNoResults(next.length <= 0);
	}
	return (
		<Box component="div">
			<Box className="card-container">
				<NumberDisplay color="text.primary" title="Total Bookings" value={cardData.booking_count} />
				<NumberDisplay color="primary.main" title="Total Amount" value={cardData.total_amounts} />
				<NumberDisplay color="error.main" title="Cancellations" value={cardData.cancellation_count} />
				<NumberDisplay color="success.main" title="Confirmations" value={cardData.confirmation_count} />
			</Box>
			<br />
			<AdvancedSearch>
				<TextField 
					required={false}
					label="Start Date"
					type="date" 
					name="start"
					value={advancedSearch.start}
					onChange={handleAdvancedSearch}
					helperText=""
				/>
				<TextField 
					required={false}
					label="End Date"
					type="date" 
					name="end"
					value={advancedSearch.end}
					onChange={handleAdvancedSearch}
					helperText=""
				/>
				{/* <TextField 
					select
					required={false}
					name="hotelName"
					value={advancedSearch.hotelName}
					onChange={handleAdvancedSearch}
					label="Hotel Name"
					helperText=""
				>
					{rows.map((item, ind) => <MenuItem key={ind} value={item.property_name}>{item.property_name}</MenuItem>)}
				</TextField> */}
			</AdvancedSearch>
			{progress && <LinearProgress />}
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<StyledTableHeadCell>S.No</StyledTableHeadCell>
							<StyledTableHeadCell>User name</StyledTableHeadCell>
                            <StyledTableHeadCell>Property Name</StyledTableHeadCell>
							<StyledTableHeadCell>Total Amount</StyledTableHeadCell>
							<StyledTableHeadCell>Booking Status</StyledTableHeadCell>
							<StyledTableHeadCell>View</StyledTableHeadCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0 
							? filteredList.slice(page * rowsPerPage, (page + 1) * rowsPerPage) 
							: filteredList).map((row, i) => (
							<TableRow key={row.order_id}>
								<TableCell>{i + 1 + page*rowsPerPage}</TableCell>
								<TableCell>{row.user_name}</TableCell>
								<TableCell>{row.property_name}</TableCell>
								<TableCell>{row.total_amount}</TableCell>
								<TableCell>{row.booking_status}</TableCell>
								<TableCell>
									<ViewItem onClick={() => {setClickedRow(row);setModal(true)}} />
								</TableCell>
							</TableRow>
						))}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={4} />
							</TableRow>
						)}
					</TableBody>
				</Table> 
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[2, 4, {label: "All", value: -1}]}
				colSpan={100}
				count={filteredList.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
			/>
			<Modal 
				open={modal} 
				onClose={() => setModal(false)} 
				aria-labelledby="chamb bookings view" 
			><HotelBookingsModal booking={clickedRow} setModalOpen={setModal}/></Modal>
		</Box>
	)
}