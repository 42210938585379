import { forwardRef, useState, useEffect, useContext } from "react";
import { StyledModalPaper } from "../../styles/modal";
import {
  Box,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  ListItemText,
  Switch,
  InputLabel,
  InputAdornment,
  FormControl,
} from "@mui/material";
import ModalHeader from "../ModalHeader";
import SubmitButton from "../../components/FormButton";
import { noFetch, AlertToast, alertState } from "../../divine-constants";
import { CurrencyRupee, PercentOutlined } from "@mui/icons-material";
import { api } from "../../pages/global/apiConfig";
import Fieldset from "../../components/Fieldset";
import { arrayStringify } from "../../utils";

function AgentModal({ setModalOpen, fetchData }) {
  const { setAlert } = useContext(AlertToast);
  const [areas, setAreas] = useState([]);
  const [progress, setProgress] = useState(false);
  const [formData, setFormData] = useState({
    telNum: "",
    agentname: "",
    gender: "",
    email: "",
    password: "",
    roll: "agent",
    state: "",
    city: "",
    area: "",
    pincode: "",
    address: "",
    profile_image: "",
    idfrontSide: "",
    idbackSide: "",
    status: false,
  });

  const [selectArrays, setSelectArrays] = useState({
    cities: [],
    amenities: [],
    categories: [],
  });

  useEffect(() => {
    Promise.all([api().get("sadmin/get_city_location")])
      .then((results) => {
        setSelectArrays({
          cities: results[0].data,
        });
      })
      .catch(() => setAlert(noFetch));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectArrays.cities.length > 0 && formData.city !== "") {
      api()
        .get(
          "sadmin/get_place_in_city?location_id=" +
            selectArrays.cities.filter(
              (it) => it.location_name === formData.city
            )[0].id
        )
        .then((res) => {
          setAreas(res.data);
        })
        .catch(() => setAlert(noFetch));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.city]);

  function handleText(event) {
    setFormData((current) => ({
      ...current,
      [event.target.name]: event.target.value,
    }));
  }

  function handleMainImg(event) {
    setFormData((current) => ({
      ...current,
      [event.target.name]: event.target.files[0],
    }));
  }

  function handleSingleSelect(event) {
    setFormData((current) => ({
      ...current,
      [event.target.name]: event.target.value,
    }));
  }

  function handleFormSubmit(event) {
        event.preventDefault();

    setProgress(true);
    api()
      .post("sadmin/add_agent", {
        name: formData.agentname.trim(),
        password: formData.password.trim(),
        gender: formData.gender,
        email: formData.email.trim(),
        mobile_no: formData.telNum,
        pincode: formData.pincode,
        country: formData.country,
        area: formData.area,
        address: formData.address,
        profile_image: formData.profile_image,
        state: formData.state,
        location: formData.city,
        active_status: formData.status,
        role: "agent",
        idfrontside: formData.idfrontSide,
        idbackside: formData.idbackSide,
        status: true,
      })
      .then((res) => {
        if (res.data.code === 201) {
                    setAlert(
            alertState({
              message: "Agent added successfully",
              severity: "success",
              show: true,
            })
          );
          setModalOpen({ type: "addNew", to: "close" });
          fetchData();
        }
      })
      .catch((err) => {
        if (err.status === 401) {
          setAlert(
            alertState({
              message: "You are not authorized to add a new Agent.",
              severity: "warning",
              show: true,
            })
          );
        } else if (err.status === 403) {
          setAlert(
            alertState({
              message: "You do not have access to add a new agent.",
              severity: "warning",
              show: true,
            })
          );
        } else
          setAlert(
            alertState({
              message: "Something went wrong. Please try again",
              severity: "error",
              show: true,
            })
          );
      })
      .finally(() => setProgress(false));
  }

  return (
    <StyledModalPaper>
      <ModalHeader setModalOpen={setModalOpen} type="addNew" />
      <Box
        component="form"
        className="list-form"
        onSubmit={handleFormSubmit}
        noValidate
      >
        <Box>
          <Fieldset className="form-grid-container four-column-layout">
            <TextField
              select
              label="Country"
              defaultValue={formData.country}
              value={formData.country}
              name="country"
              onChange={handleSingleSelect}
            >
              {["India"].map((country, i) => (
                <MenuItem value={country} key={i}>
                  {country}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label="State"
              defaultValue={formData.state}
              value={formData.state}
              name="state"
              onChange={handleSingleSelect}
            >
              {["Tamil Nadu"].map((state, i) => (
                <MenuItem value={state} key={i}>
                  {state}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label="City"
              defaultValue={formData.city}
              value={formData.city}
              name="city"
              onChange={handleSingleSelect}
            >
              {selectArrays.cities.map((city) => (
                <MenuItem value={city.location_name} key={city.id}>
                  {city.location_name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              select
              label="Area"
              name="area"
              value={formData.area}
              onChange={handleSingleSelect}
            >
              {areas.map((area, i) => (
                <MenuItem value={area} key={i}>
                  {area}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              label="Pincode"
              type="text"
              name="pincode"
              value={formData.pincode}
              onChange={handleText}
              placeholder="Eg. 600091"
            />

            <TextField
              required={false}
              label="Address"
              type="text"
              name="address"
              onChange={handleText}
              value={formData.address}
              placeholder="Enter address"
            />

            <TextField
              label="Profile Image"
              type="file"
              name="profile_image"
              onChange={handleMainImg}
              placeholder="Profile Image"
            />

            <TextField
              label="Id Proof front Side"
              type="file"
              name="idfrontSide"
              onChange={handleMainImg}
              placeholder="Id Proof front Side"
            />
            <TextField
              label="Id Proof back Side"
              type="file"
              name="idbackSide"
              onChange={handleMainImg}
              placeholder="Id Proof back Side"
            />

            <TextField
              select
              label="Gender"
              defaultValue={formData.gender}
              value={formData.gender}
              name="gender"
              onChange={handleSingleSelect}
            >
              {["Male", "Female"].map((gender, i) => (
                <MenuItem value={gender} key={i}>
                  {gender}
                </MenuItem>
              ))}
            </TextField>

            <FormControl className="inline-form-control" required={false}>
              <InputLabel shrink>Status</InputLabel>
              <FormControlLabel
                label={formData.status ? "Active" : "Inactive"}
                value={formData.status}
                onChange={(e) =>
                  setFormData((curr) => ({ ...curr, status: e.target.checked }))
                }
                checked={formData.status}
                control={<Switch />}
              />{" "}
            </FormControl>
          </Fieldset>
          <Fieldset
            legend="Agent Details"
            className="form-grid-container four-column-layout"
          >
            <TextField
              label="Mobile"
              type="tel"
              inputMode="tel"
              name="telNum"
              value={formData.telNum}
              onChange={handleText}
              placeholder="Enter mobile number"
            />
            <TextField
              label="Agent Name"
              type="text"
              name="agentname"
              value={formData.agentname}
              onChange={handleText}
              placeholder="Enter Agentname"
            />

            <TextField
              label="Email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleText}
              placeholder="Enter email"
            />

            <TextField
              label="Password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleText}
              placeholder="Enter password"
            />
          </Fieldset>
        </Box>
        <SubmitButton showProgress={progress} />
      </Box>
    </StyledModalPaper>
  );
}
export default forwardRef((props, _ref) => <AgentModal {...props} />);
