import { createContext } from "react";
import { AddOutlined, CategoryOutlined, ChairOutlined, EventOutlined, FestivalOutlined, GiteOutlined, HandshakeOutlined, HomeOutlined, HotelOutlined, HouseboatOutlined, InfoOutlined, ListAltOutlined, LocalOfferOutlined, PaymentOutlined, PaymentsOutlined, PeopleOutlined, PhotoOutlined, PlaceOutlined, QuestionAnswerOutlined, RecommendOutlined, ScheduleOutlined, SettingsOutlined, SpaceDashboardOutlined, SpellcheckOutlined, SupportAgentOutlined, TourOutlined, ReceiptOutlined, AdminPanelSettingsOutlined } from '@mui/icons-material';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import RedeemOutlinedIcon from '@mui/icons-material/RedeemOutlined';
export const Drawer = {
  Expanded: 225,
  Collapsed: 56,
  zIndex: 1200,
};
export const userTypes = [
  "SUPER_ADMIN",
  "ADMIN",
  "MANAGER",
  "HOTEL",
  "HOSTEL",
  "HALL",
  "TOUR",
  "CHAMB",
];

export const User = createContext();
export const AlertToast = createContext();
export const uType = createContext();
export function alertState({ title = "", message = "", severity = "", show = false }) {
  return { title, message, severity, show };
}
export const noFetch = alertState({ title: "ERROR", message: "Unable to fetch data. Try reloading the page.", severity: "error", show: true })

/**
 * 
 * @param {{
 * addNew?: boolean, 
 * addNewNested?: boolean,
 * edit?: boolean,
 * editNested?: boolean,
 * view?: boolean,
 * delete?: boolean
 * }} state 
 * @param {{type: "addBank"|"addNew"|"addNewNested"|"edit"|"editNested"|"view", to: "open" | "close"}} action 
 * @returns 
 */
export function modalReducer(state, action) {
  switch (action.type) {
    case "addBank": return { ...state, addBank: action.to === "open" };
    case "addNew": return { ...state, addNew: action.to === "open" };
    case "addNewNested": return { ...state, addNewNested: action.to === "open" };
    case "edit": return { ...state, edit: action.to === "open" };
    case "editNested": return { ...state, editNested: action.to === "open" };
    case "view": return { ...state, view: action.to === "open" };
    case "viewNested": return { ...state, viewNested: action.to === "open" };
    default: return { ...Object.keys(state).map(key => ({ [key]: false, to: "close" })) };
  }
}

export const toolbarHeight = 56;
export const tableImageHeight = 56;

export const links = [
  { name: "Dashboard", path: "/dashboard", icon: <SpaceDashboardOutlined />, children: [] },
  {
    name: "Hotel", path: "/hotels", icon: <HotelOutlined />, parentId: 0, children: [
      { name: "Hotel Category", path: "/category", icon: <CategoryOutlined /> },
      { name: "Hotel List", path: "/list", icon: <ListAltOutlined /> },
      { name: "Bookings", path: "/bookings", icon: <EventOutlined /> },
      { name: "Check Availability", path: "/check-availability", icon: <SpellcheckOutlined /> },
      { name: "Weekly Payments", path: "/weekly-payments", icon: <PaymentsOutlined /> },
    ]
  },
  {
    name: "Hostel", path: "/hostels", icon: <GiteOutlined />, parentId: 1, children: [
      { name: "Hostel Category", path: "/category", icon: <CategoryOutlined /> },
      { name: "Hostel List", path: "/list", icon: <ListAltOutlined /> },
      { name: "Bookings", path: "/bookings", icon: <EventOutlined /> },
      { name: "Scheduled Visit", path: "/scheduled-visit", icon: <ScheduleOutlined /> },
      { name: "Check Availability", path: "/check-availability", icon: <SpellcheckOutlined /> },
      { name: "Weekly Payments", path: "/weekly-payments", icon: <PaymentsOutlined /> },
    ]
  },
  {
    name: "Hall", path: "/halls", icon: <FestivalOutlined />, parentId: 2, children: [
      { name: "Hall Category", path: "/category", icon: <CategoryOutlined /> },
      { name: "Hall List", path: "/list", icon: <ListAltOutlined /> },
      { name: "Bookings", path: "/bookings", icon: <EventOutlined /> },
      { name: "Enquiry", path: "/enquiry", icon: <ScheduleOutlined /> },
      { name: "Weekly Payments", path: "/weekly-payments", icon: <PaymentsOutlined /> },
    ]
  },
  {
    name: "Tours & Travels", path: "/tours-and-travels", icon: <TourOutlined />, parentId: 3, children: [
      { name: "Tours & Travels Category", path: "/category", icon: <CategoryOutlined /> },
      { name: "Partners", path: "/partners", icon: <HandshakeOutlined /> },
      { name: "Bookings", path: "/bookings", icon: <EventOutlined /> },
      { name: "Enquiry", path: "/enquiry", icon: <ScheduleOutlined /> },
      { name: "Weekly Payments", path: "/weekly-payments", icon: <PaymentsOutlined /> },
    ],
  },
  {
    name: "Distinct Chamb", path: "/distinct-chamb", icon: <HouseboatOutlined />, parentId: 4, children: [
      { name: "Category", path: "/category", icon: <CategoryOutlined /> },
      { name: "Partners", path: "/partners", icon: <HandshakeOutlined /> },
      { name: "Bookings", path: "/bookings", icon: <EventOutlined /> },
      { name: "Enquiry", path: "/enquiry", icon: <ScheduleOutlined /> },
      { name: "Weekly Payments", path: "/weekly-payments", icon: <PaymentsOutlined /> },
    ]
  },
  {
    name: "Home Screen", path: "/home-screen", icon: <HomeOutlined />, parentId: 5, children: [
      { name: "Recommendations", path: "/recommendations", icon: <RecommendOutlined /> },
      { name: "Offers", path: "/offers", icon: <LocalOfferOutlined /> }
    ]
  },

  { name: "Amenities", path: "/amenities", icon: <ChairOutlined />, children: [] },
  { name: "Locations", path: "/locations", icon: <PlaceOutlined />, children: [] },
  { name: "Banners & Offers", path: "/banners-and-offers", icon: <PhotoOutlined />, children: [] },
  { name: "Customers", path: "/customers", icon: <PeopleOutlined />, children: [] },
  // { name: "Agent", path: "/agent", icon: <SupportAgentOutlined />, children: [] },
  { name: "Company Users", path: "/company-users", icon: <AdminPanelSettingsOutlined />, children: [] },
  { name: "Payments", path: "/payments", icon: <HomeOutlined />, children: [] },
  { name: "Settings", path: "/settings", icon: <SettingsOutlined />, children: [] }
];

export const propertyLinks = {
  HOTEL: [
    { name: "Dashboard", path: "/hotel/dashboard", icon: <SpaceDashboardOutlined /> },
    { name: "Company Info", path: "/hotel/company-info", icon: <InfoOutlined /> },
    { name: "Bookings", path: "/hotel/bookings", icon: <EventOutlined /> },
    { name: "Availability", path: "/hotel/check-availability", icon: <SpellcheckOutlined /> },
    { name: "User Management", path: "/hotel/user-management", icon: <GroupAddOutlinedIcon /> },

    {
      name: "Payments", path: "/hotel/payments", icon: <PaymentOutlined />, children: [
        { name: "Account Details", path: "/hotel/add-bank-account", icon: <AddOutlined /> },
        { name: "Weekly payments", path: "/hotel/weekly-payments", icon: <PaymentsOutlined /> }
      ]
    }
  ],
  HOSTEL: [
    { name: "Dashboard", path: "/hostel/dashboard", icon: <SpaceDashboardOutlined /> },
    { name: "Company Info", path: "/hostel/company-info", icon: <InfoOutlined /> },
    { name: "Bookings", path: "/hostel/bookings", icon: <EventOutlined /> },
    { name: "User Management", path: "/hostel/user-management", icon: <GroupAddOutlinedIcon /> },
    { name: "Availability", path: "/hostel/check-availability", icon: <SpellcheckOutlined /> },
    {
      name: "Payments", path: "/hostel/payments", icon: <PaymentOutlined />, children: [
        { name: "Account Details", path: "/hostel/add-bank-account", icon: <AddOutlined /> },
        { name: "Weekly payments", path: "/hostel/weekly-payments", icon: <PaymentsOutlined /> }
      ]
    }
  ],
  HALL: [
    { name: "Dashboard", path: "/hall/dashboard", icon: <SpaceDashboardOutlined /> },
    { name: "Company Info", path: "/hall/company-info", icon: <InfoOutlined /> },
    { name: "Bookings", path: "/hall/bookings", icon: <EventOutlined /> },
    { name: "User Management", path: "/hall/user-management", icon: <GroupAddOutlinedIcon /> },
    {
      name: "Payments", path: "/hall", icon: <PaymentOutlined />, children: [
        { name: "Account Details", path: "/hall/add-bank-account", icon: <AddOutlined /> },
        { name: "Weekly payments", path: "/hall/weekly-payments", icon: <PaymentsOutlined /> }
      ]
    }
  ],
  TOUR: [
    { name: "Dashboard", path: "/tours-and-travels/dashboard", icon: <SpaceDashboardOutlined /> },
    { name: "Company Info", path: "/tours-and-travels/company-info", icon: <InfoOutlined /> },
    { name: "Bookings", path: "/tours-and-travels/bookings", icon: <EventOutlined /> },
    { name: "User Management", path: "/tours-and-travels/user-management", icon: <GroupAddOutlinedIcon /> },
    {
      name: "Payments", path: "/tours-and-travels/enquiry", icon: <PaymentOutlined />, children: [
        { name: "Account Details", path: "tours-and-travels/add-bank-account", icon: <AddOutlined /> },
        { name: "Weekly payments", path: "tours-and-travels/weekly-payments", icon: <PaymentsOutlined /> }
      ]
    }
  ],
  CHAMB: [
    { name: "Dashboard", path: "/moving-homes/dashboard", icon: <SpaceDashboardOutlined /> },
    { name: "Caravan", path: "/moving-homes/caravan", icon: <HotelOutlined /> },
    { name: "Bookings", path: "/moving-homes/bookings", icon: <QuestionAnswerOutlined /> },
    { name: "User Management", path: "/moving-homes/user-management", icon: <GroupAddOutlinedIcon /> },
    {
      name: "Payments", path: "/moving-homes/enquiry", icon: <PaymentOutlined />, children: [
        { name: "Account Details", path: "moving-homes/add-bank-account", icon: <AddOutlined /> },
        { name: "Weekly payments", path: "moving-homes/weekly-payments", icon: <PaymentsOutlined /> }
      ]
    }
  ],

  FIELD_WORKER: [
    { name: "Hotel", path: "/field-worker/hotel", icon: <HotelOutlined /> },
    { name: "Hostel", path: "/field-worker/hostel", icon: <RedeemOutlinedIcon /> },
    { name: "Hall", path: "/field-worker/hall", icon: <QuestionAnswerOutlined /> },
    { name: "Tours & Travels", path: "/field-worker/tours-and-travels", icon: <ReceiptOutlined /> },
    { name: "Distinct Chamb", path: "/field-worker/distinct-chamb", icon: <QuestionAnswerOutlined /> },
    {
      name: "Payments", path: "/", icon: <PaymentOutlined />, children: [
        { name: "Account Details", path: "field-worker/add-bank-account", icon: <AddOutlined /> },
        { name: "Weekly payments", path: "field-worker/weekly-payments", icon: <PaymentsOutlined /> }
      ]
    },
  ],
  // MANAGER: [
  //   { name: "Dashboard", path: "/manager/dashboard", icon: <SpaceDashboardOutlined />},
  //   { name: "Hotel", path: "/manager/hotel", icon: <HotelOutlined /> },
  //   { name: "Hostel", path: "/manager/hostel", icon: <RedeemOutlinedIcon/> },
  //   { name: "Hall", path: "/manager/hall", icon: <QuestionAnswerOutlined /> },
  //   { name: "Tours & Travels", path: "/manager/tours-and-travels", icon: <ReceiptOutlined /> },
  //   { name: "Distinct Chamb", path: "/manager/distinct-chamb", icon: <QuestionAnswerOutlined /> },
  //   { name: "Payments", path: "/", icon: <PaymentOutlined />, children: [
  //     { name: "Account Details", path: "manager/add-bank-account", icon: <AddOutlined /> },
  //     { name: "Weekly payments", path: "manager/weekly-payments", icon: <PaymentsOutlined /> }
  //   ] },
  // ],
  TELECOM: [
    { name: "Dashboard", path: "/booking-demand/tele-support/dashboard", icon: <SpaceDashboardOutlined /> ,children: [] },
    {
      name: "Hotel", path: "/", icon: <HotelOutlined />, parentId: 0, children: [
        { name: "Bookings", path: "booking-demand/tele-support/hotel-bookings", icon: <EventOutlined /> },
        { name: "Check Availability", path: "booking-demand/tele-support/hotel-check-availability", icon: <SpellcheckOutlined /> }
      ]
    },
    {
      name: "Hostel", path: "/", icon: <GiteOutlined />, parentId: 1, children: [
        { name: "Bookings", path: "booking-demand/tele-support/hostel-bookings", icon: <EventOutlined /> },
        { name: "Check Availability", path: "booking-demand/tele-support/hostel-check-availability", icon: <SpellcheckOutlined /> }
      ]
    },
    {
      name: "Hall", path: "/", icon: <FestivalOutlined />, parentId: 2, children: [
        { name: "Bookings", path: "booking-demand/tele-support/hall-bookings", icon: <EventOutlined /> },
        { name: "Check Availability", path: "booking-demand/tele-support/hall-check-availability", icon: <SpellcheckOutlined /> }
      ]
    },
    {
      name: "Tours And Travels", path: "/", icon: <TourOutlined />, parentId: 3, children: [
        { name: "Bookings", path: "booking-demand/tele-support/tours-&-travels-bookings", icon: <EventOutlined /> },
        { name: "Check Availability", path: "booking-demand/tele-support/tours-&-travels-check-availability", icon: <SpellcheckOutlined /> }
      ]
    },
    {
      name: "Distinct Chamb", path: "/", icon: <HouseboatOutlined />, parentId: 4, children: [
        { name: "Bookings", path: "booking-demand/tele-support/distinct-chamb-bookings", icon: <EventOutlined /> },
        { name: "Check Availability", path: "booking-demand/tele-support/distinct-chamb-check-availability", icon: <SpellcheckOutlined /> }
      ]
    },
  ],
};
export const propertyStaffLinks = {
  HOTEL: [
    { name: "Dashboard", path: "/hotel/dashboard", icon: <SpaceDashboardOutlined />, children: [] },
    { name: "Company Info", path: "/hotel/company-info", icon: <InfoOutlined /> },
    { name: "Rooms", path: "/hotel/rooms", icon: <HotelOutlined /> },
    { name: "Offline Booking", path: "/hotel/offline-booking", icon: <ReceiptOutlined /> },
    { name: "Bookings", path: "/hotel/bookings", icon: <EventOutlined /> },
    { name: "Availability", path: "/hotel/check-availability", icon: <SpellcheckOutlined /> },

    {
      name: "Payments", path: "/hotel/payments", icon: <PaymentOutlined />, children: [
        { name: "Account Details", path: "/hotel/add-bank-account", icon: <AddOutlined /> },
        { name: "Weekly payments", path: "/hotel/weekly-payments", icon: <PaymentsOutlined /> }
      ]
    }
  ],
  HOSTEL: [
    { name: "Dashboard", path: "/hostel/dashboard", icon: <SpaceDashboardOutlined />, children: [] },
    { name: "Company Info", path: "/hostel/company-info", icon: <InfoOutlined /> },
    { name: "Rooms", path: "/hostel/rooms", icon: <HotelOutlined /> },
    { name: "Offline Booking", path: "/hostel/offline-booking", icon: <ReceiptOutlined /> },
    { name: "Bookings", path: "/hostel/bookings", icon: <EventOutlined /> },
    { name: "Scheduled Visits", path: "/hostel/scheduled-visits", icon: <ScheduleOutlined /> },
    { name: "Availability", path: "/hostel/check-availability", icon: <SpellcheckOutlined /> },
    {
      name: "Payments", path: "/hostel/payments", icon: <PaymentOutlined />, children: [
        { name: "Account Details", path: "/hostel/add-bank-account", icon: <AddOutlined /> },
        { name: "Weekly payments", path: "/hostel/weekly-payments", icon: <PaymentsOutlined /> }
      ]
    }
  ],
  HALL: [
    { name: "Dashboard", path: "/hall/dashboard", icon: <SpaceDashboardOutlined />, children: [] },
    { name: "Company Info", path: "/hall/company-info", icon: <InfoOutlined /> },
    { name: "Bookings", path: "/hall/bookings", icon: <EventOutlined /> },
    { name: "Offline Booking", path: "/hall/offline-booking", icon: <ReceiptOutlined /> },
    { name: "Enquiry", path: "/hall/enquiry", icon: <QuestionAnswerOutlined /> },
    {
      name: "Payments", path: "/hall", icon: <PaymentOutlined />, children: [
        { name: "Account Details", path: "/hall/add-bank-account", icon: <AddOutlined /> },
        { name: "Weekly payments", path: "/hall/weekly-payments", icon: <PaymentsOutlined /> }
      ]
    },

  ],
  TOUR: [
    { name: "Dashboard", path: "/tours-and-travels/dashboard", icon: <SpaceDashboardOutlined />, children: [] },
    { name: "Company Info", path: "/tours-and-travels/company-info", icon: <InfoOutlined /> },
    { name: "Tour Packages", path: "/tours-and-travels/tour-packages", icon: <HotelOutlined /> },
    { name: "Offline Booking", path: "/tours-and-travels/offline-booking", icon: <ReceiptOutlined /> },
    { name: "Bookings", path: "/tours-and-travels/bookings", icon: <EventOutlined /> },
    { name: "Enquiry", path: "/tours-and-travels/enquiry", icon: <QuestionAnswerOutlined /> },
    {
      name: "Payments", path: "/tours-and-travels/enquiry", icon: <PaymentOutlined />, children: [
        { name: "Account Details", path: "tours-and-travels/add-bank-account", icon: <AddOutlined /> },
        { name: "Weekly payments", path: "tours-and-travels/weekly-payments", icon: <PaymentsOutlined /> }
      ]
    }
  ],
  CHAMB: [
    { name: "Dashboard", path: "/moving-homes/dashboard", icon: <SpaceDashboardOutlined />, children: [] },
    { name: "Caravan", path: "/moving-homes/caravan", icon: <HotelOutlined /> },
    { name: "Packages", path: "/moving-homes/packages", icon: <RedeemOutlinedIcon /> },
    { name: "Enquiry", path: "/moving-homes/enquiry", icon: <QuestionAnswerOutlined /> },
    { name: "Offline Booking", path: "/moving-homes/offline-booking", icon: <ReceiptOutlined /> },
    { name: "Bookings", path: "/moving-homes/bookings", icon: <QuestionAnswerOutlined /> },
    {
      name: "Payments", path: "/moving-homes/enquiry", icon: <PaymentOutlined />, children: [
        { name: "Account Details", path: "moving-homes/add-bank-account", icon: <AddOutlined /> },
        { name: "Weekly payments", path: "moving-homes/weekly-payments", icon: <PaymentsOutlined /> }
      ]
    }
  ]
};

export const imageTypes = [
  "image/apng",
  "image/avif",
  "image/gif",
  "image/jpeg",
  "image/png",
  "image/svg+xml",
  "image/webp",
];
