import { forwardRef, useState, useContext } from "react";
import { StyledModalPaper } from "../styles/modal";
import { MenuItem, FormControl, TextField, FormControlLabel, Switch, Box, Button } from "@mui/material";
import { api } from "../pages/global/apiConfig";
import ModalHeader from "./ModalHeader";
import { CloudUploadOutlined } from "@mui/icons-material";
import SubmitButton from "../components/FormButton";
import { imageTypes, AlertToast, alertState } from "../divine-constants";
import { styled } from "@mui/material";

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
});

function AmenitiesModal({setModalOpen, fetchData, data}) {
	const amenitiesOptions = [
		{displayName: "Hotel", value: "hotel"},
		{displayName: "Hostel", value: "hostel"},
		{displayName: "Halls", value: "halls"},
		{displayName: "Tours & Travels", value: "toursAndTravels"},
		{displayName: "Moving Homes", value: "movingHomes"},
	];
	const [formData, setFormData] = useState({
		amenities_name: data.amenities_name,
		type: data.type,
		image: data.image,
		highlight_status: data.highlight_status,
		file: undefined
	});
 console.log(data,"data")
 console.log(fetchData,"fetch_data")
	const [progress, setProgress] = useState(false);
	const {setAlert} = useContext(AlertToast);
	const [validations, setValidations] = useState({
		amenities_name: " ",
		type: " ",
		image: " "
	});

	function handleHighlight() {
		setFormData(current => {
			return {...current, highlight_status: !current.highlight_status}
		})
	}

	function handleAmenities(event) {
		setFormData(current => {
			return {...current, amenities_name: event.target.value}
		});
		setValidations(curr => ({...curr, amenities_name: " "}));
	}

	function handleType(event) {
		setFormData(current => {
			return {...current, type: event.target.value}
		});
		setValidations(curr => ({...curr, type: " "}));
	}

	function handleImage(ev) {
		let reader = new FileReader();
		reader.readAsDataURL(ev.target.files?.item(0));
		reader.onload = e => {
			setFormData(curr => ({...curr, image: e.target?.result, file: ev.target.files?.item(0)}));
			setValidations(curr => ({...curr, image: " "}))
		}
	}

	function validate() {
		setProgress(true);
		let isValid = true;
		if(formData.amenities_name === "") {
			isValid = false;
			setValidations(curr => ({...curr, amenities_name: "Name is required"}))
		} else setValidations(curr => ({...curr, amenities_name: " "}))
		if(formData.type === "") {
			isValid = false;
			setValidations(curr => ({...curr, type: "Type is required"}))
		} else setValidations(curr => ({...curr, type: " "}))
		if((formData.image === undefined || formData.image === null) && (formData.file === undefined || formData.file === null)) {
			isValid = false;
			setValidations(curr => ({...curr, image: "Image is required"}));
		} else if(formData.file !== undefined && !imageTypes.includes(formData.file?.type)) {
			isValid = false;
			setValidations(curr => ({...curr, image: "Invalid image file type"}))
		} else setValidations(curr => ({...curr, image: " "}))
		setProgress(false);
		return isValid;
	}

	function handleFormSubmit(event) {
		event.preventDefault();
		if(!validate()) return;
		setProgress(true);
		api().patch("sadmin/edit_amenities", {
			amenities_id: data.id,
			amenities_name: formData.amenities_name.trim(),
			type: formData.type,
			image: formData.file ?? "null",
			highlight_status: formData.highlight_status
		}).then(res => {
			if(res.data.message === "successfully edited"){
				
				setAlert(alertState({
					title: "",
					message: "Amenity updated successfully",
					severity: "success",
					show: true
				}));
				fetchData(); 
				setModalOpen(false);
			}
		}).catch(err => {
			if(err.status === 401) {
				setAlert(alertState({
					title: "",
					message: "Unauthorized - Cannot update amenity",
					severity: "warning",
					show: true
				}))
			} else setAlert(alertState({
				title: "",
				message: "Something went wrong. Please try again later",
				severity: "error",
				show: true
			}))
		}).finally(() => {setProgress(false)});
	}

	return (
	<>
		<StyledModalPaper>
			<ModalHeader setModalOpen={setModalOpen} type="edit" />
			<Box component="form" noValidate onSubmit={handleFormSubmit}>
				<Box component="div" className="form-grid-container two-column-layout">
					<TextField
						type="text"
						label="Amenities"
						className="amenities"
						placeholder="Enter Amenities"
						value={formData.amenities_name}
						helperText={validations.amenities_name}
						error={Boolean(validations.amenities_name.trim())}
						onChange={handleAmenities}
					/>
					
					<TextField
						select
						label="Type"
						className="type"
						onChange={handleType}
						value={formData.type}
						helperText={validations.type}
						error={Boolean(validations.type.trim())}
					>
						{amenitiesOptions.map((option, index) => (
							<MenuItem key={index} value={option.value}>{option.displayName}</MenuItem>
						))}
					</TextField>
					<TextField
						type="file"
						label="Image"
						onChange={handleImage}
						helperText={validations.image}
						error={Boolean(validations.image.trim())}
					/>

					{/* <FormControl>
						<FormControlLabel label="Highlight" control={
							<Switch color="primary" checked={formData.highlight_status} onChange={handleHighlight}/>}
						/>
					</FormControl> */}
					
				</Box>
				<br />
				<SubmitButton showProgress={progress} />
			</Box>
		</StyledModalPaper>
	</>
	)
}

//Caveat with strict mode
export default forwardRef((props, _ref) => <AmenitiesModal {...props}/>);