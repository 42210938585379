import { useContext, useEffect, useState } from "react";
import { TableContainer, Box, Table, TableHead, TableBody, TableRow, Button, Modal, TableCell, TablePagination, TextField, InputAdornment, LinearProgress } from "@mui/material";
import { Add, Search } from "@mui/icons-material";
import { StyledTableHeadCell, StyledTableHeader } from "../../../components/Table";
import { EditItem, Redbin } from "../../../components/Icons";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";

import { Navigate } from "react-router-dom";
import { api } from "../../global/apiConfig";
import DeleteModal from "../../../modals/DeleteModal";
import { AlertToast, noFetch,tableImageHeight } from "../../../divine-constants";
import ChambCategoryModal from "../../../modals/distinct chamb/ChambModal";
export default function DistinctChambCategory() {
		const {setAlert} = useContext(AlertToast);
	const [rows, setRows] = useState([]); 
	const [filteredList, setFilteredList] = useState(rows);
	const [noResults, setNoResults] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [clickedRow, setClickedRow] = useState();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [modalOpen, setModalOpen] = useState(false);
	const [progress, setProgress] = useState(true);
	const [type, setType] = useState({type:"",id:""})
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;

	const fetchData = () =>
		api().get("sadmin/get_category?type=chamb").then(res => {
			if(res.status === 200) {
				setRows(res.data);
				setProgress(false);
			}
		}).catch(() => {<Navigate to="/login" replace />;setAlert(noFetch)});

	useEffect(() => {
		fetchData()
	}, []);
	
	useEffect(() => {
		setFilteredList(rows);
		setPage(0);
	}, [rows]);
	
	function handleOpenModal() {
		setModalOpen(true);
	}

	function handleCloseModal() {
		setModalOpen(false);
	}

	function handlePageChange(event, newPage) {	
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(parseInt(event.target.value), 10);
		setPage(0);
	}
	
	function handleTableSearch(event) {
		const query = event.target.value
		const next = rows.filter(item => {
			return Object
			.values(item)
			.map(v => typeof v === "string" ? v.toString() : "")
			.some(el => el.toLowerCase().includes(query.toLowerCase()));
		});
		if(next.length <= 0) setPage(0)
		setFilteredList(next);
		setNoResults(next.length <= 0);
	}
	return (
		<Box component="div">
			<StyledTableHeader>
			<Button onClick={handleOpenModal}><Add /> Add new</Button>
				<TextField 
					label="Search in Table"
					onChange={handleTableSearch}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Search />
							</InputAdornment>
						)
					}}
					InputLabelProps={{shrink: undefined}}
					required={false}
					helperText={noResults && "No results found"}
					FormHelperTextProps={{
						error: noResults
					}}
				/>
			</StyledTableHeader>
			{progress && <LinearProgress />}
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<StyledTableHeadCell>S.No</StyledTableHeadCell>
							<StyledTableHeadCell>Category Name</StyledTableHeadCell>
							<StyledTableHeadCell>Image</StyledTableHeadCell>
							<StyledTableHeadCell>Action</StyledTableHeadCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0 
							? filteredList.slice(page * rowsPerPage, (page + 1) * rowsPerPage) 
							: filteredList).map((row, i) => {
							
						return(
							<TableRow key={i}>
							<TableCell>{i + 1 + page*rowsPerPage}</TableCell>
							<TableCell>{row.category_name}</TableCell>	
							<TableCell><img height={tableImageHeight} src={row.image} alt={row.image} /></TableCell>							
							<TableCell>
							    <EditItem onClick={()=>{setClickedRow(row); setModalOpen(true);setType({...type,type:"edit",id:row.id,category_type:"distinct_chamb"})} }/>
								<Redbin onClick={() => {setClickedRow(row); setDeleteModal(true)}} />
							</TableCell>
						</TableRow>
						)
                          })}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={4} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[5, 10, {label: "All", value: -1}]}
				colSpan={100}
				count={filteredList.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
			/>
			<Modal 
				open={modalOpen} 
				onClose={handleCloseModal} 
				aria-labelledby="hall category form" 
				aria-describedby="insert new record"
			><ChambCategoryModal fetchData={fetchData} setModalOpen={setModalOpen} type={type} data={clickedRow} /></Modal>
			<DeleteModal dialogDisplay={deleteModal} setDialogDisplay={setDeleteModal} url={"sadmin/delete_category"} data={{category_id: clickedRow?.id}} fetchData={fetchData} />
		</Box>
	)
}