import { forwardRef, useState, useEffect, useContext } from "react";
import { StyledModalPaper } from "../../styles/modal";
import {
  Box,
  TextField,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControlLabel,
  Switch,
  InputAdornment,
  FormControl,
  InputLabel,
} from "@mui/material";
import { api } from "../../pages/global/apiConfig";
import ModalHeader from "../ModalHeader";
import SubmitButton from "../../components/FormButton";
import { CurrencyRupeeOutlined, PercentOutlined } from "@mui/icons-material";
import { AlertToast, alertState, noFetch } from "../../divine-constants";
import { arrayStringify } from "../../utils";

function ChambPackageModal({ setModalOpen, data, id, fetchData }) {
  console.log(id,"P")
    const [formData, setFormData] = useState({
    chamb_id:id.Chamb_id,
    name: data ? data.name : "",
    subtitle:"null",
    category: data ? data.category : "",
    price: data ? data.price : "",
    discount: data ? data.discount_percentage : "",
    discount_price: data ? data.discount_price : "",   
    description: data ? data.description : "",
    chamb_image: [],
    status: data ? data.active_status : false,   
    policy: data ? data.policy : "",
    amenities: data ? data.amenities.map(a => a.id) : [],
    
  });

  const [progress, setProgress] = useState(false);
  const { setAlert } = useContext(AlertToast);
  const [amenities, setAmenitites] = useState([]);
  const [selectArrays, setselectArrays] = useState({
		cities: [],
		categories: [],
		amenities: []
	})

   useEffect(() => {
		Promise.all([
			api().get('sadmin/get_city_location'),
			api().get('sadmin/get_amenities'),
			api().get('sadmin/get_category?type=chamb')
		]).then(results => {
			setselectArrays({
				...selectArrays,			
				categories: results[2].data,
				amenities: results[1].data,
				cities: results[0].data,
			});		
		}).catch(() => setAlert(noFetch));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

  
  function handleText(event) {
    setFormData((current) => ({
      ...current,
      [event.target.name]: event.target.value,
    }));
  }

  function handleImgs(event) {
    setFormData((current) => ({ ...current, chamb_image: event.target.files }));
  }

  function handleMultiSelect(event) {
    const {
      target: { value },
    } = event;
    setFormData((current) => ({ ...current, amenities: value }));
  }
  
  function handleFormSubmit(event) {
      
    setProgress(true);
    event.preventDefault();
    if(id.type === "edit"){
    	api().patch("sadmin/edit_chamb_property", {
    	     distinct_chamb_id:formData.chamb_id,
           chamb_property_id:data._id,
              name: formData.name ,
              subtitle:"null",
              category: formData.category ,
              price: formData.price ,
              discount_percentage: formData.discount ,
              discount_price: 0 ,   
              description: formData.description ,
              policy:formData.policy,
              chamb_image: Array.from(formData.chamb_image),
              active_status: formData.status,    
              amenities: arrayStringify(formData.amenities),
    	}).then(res => {
    		if(res.data.code === 201) {
    			setAlert(alertState({
    				message: "Chamb Updated successfully",
    				severity: "success",
    				show: true
    			}));
    			fetchData();
    			setModalOpen({type: "editNested" , to: "close"});
    		}
    	}).catch(err => {
    		if(err.status === 401) {
    			setAlert(alertState({
    				message: "You are not authorized to add a new room.",
    				severity: "warning",
    				show: true
    			}))
    		} else if (err.status === 403) {
    			setAlert(alertState({
    				message: "You do not have access to add a new room.",
    				severity: "warning",
    				show: true
    			}))
    		} else setAlert(alertState({
    			message: "Something went wrong. Please try again",
    			severity: "error",
    			show: true
    		}))
    	}).finally(() => setProgress(false));
    }else{
               
    	api().post("sadmin/add_chamb_property", 
            {
              chamb_id:formData.chamb_id,
              name: formData.name ,
              subtitle:"null",
              category: formData.category ,
              price: formData.price ,
              discount_percentage: formData.discount ,
              discount_price: 0 ,   
              description: formData.description ,
              policy:formData.policy,
              chamb_image: Array.from(formData.chamb_image),
              active_status: formData.status,    
              amenities: arrayStringify(formData.amenities),
            }
    	).then(res => {
    		    		if(res.data.code === 201) {
    			setAlert(alertState({
    				message: "Package added successfully",
    				severity: "success",
    				show: true
    			}));
    			fetchData();
    			setModalOpen({type: data ? "edit" : "addNewNested", to: "close"});
    		}
    	}).catch(err => {
    		if(err.status === 401) {
    			setAlert(alertState({
    				message: "You are not authorized to add a new room.",
    				severity: "warning",
    				show: true
    			}))
    		} else if (err.status === 403) {
    			setAlert(alertState({
    				message: "You do not have access to add a new room.",
    				severity: "warning",
    				show: true
    			}))
    		} else setAlert(alertState({
    			message: "Something went wrong. Please try again",
    			severity: "error",
    			show: true
    		}))
    	}).finally(() => setProgress(false));
    }
  }

  function handleSingleSelect(event) {
		setFormData(current => ({...current, [event.target.name]: event.target.value}));
	}

  return (
    <StyledModalPaper>
      <ModalHeader
        setModalOpen={setModalOpen}
        type={data ? "editNested" : "addNewNested"}
      />
      <Box
        component="form"
        className="list-form"
        onSubmit={handleFormSubmit}
        noValidate
      >
        <Box component="div" className="form-grid-container four-column-layout">
          <TextField
            label=" Name"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleText}
            placeholder="Package Name"
          />
       
        
          <TextField 
							select 
							label="Category"
							defaultValue={formData.category}
							value={formData.category}
							name="category"
							onChange={handleSingleSelect}
							>
								{selectArrays.categories.map((category, i) => (
									<MenuItem value={category.category_name} key={category.id}>{category.category_name}</MenuItem>
								))}
						</TextField>

            <TextField
							label="Amenities"
							select
							SelectProps={{multiple: true, renderValue: selected => selected.map(s => selectArrays.amenities.find(v => v.id === s)?.amenities_name) + ', '}}
							value={formData.amenities}
							onChange={handleMultiSelect}
							name="amenities"
							placeholder="Select Amenities"
						>
							{selectArrays.amenities.map(option => (
								<MenuItem									
									key={option.id}
									value={option.id}
								>
									<Checkbox checked={formData.amenities.indexOf(option.id) > -1} />
									<ListItemText>{option.amenities_name}</ListItemText>
								</MenuItem>
							))}
						</TextField>

          <TextField
            required={false}
            label="Price"
            type="number"
            name="price"
            value={formData.price}
            onChange={handleText}
            placeholder="Enter Price"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CurrencyRupeeOutlined />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            required={false}
            label="Discount"
            type="number"
            name="discount"
            value={formData.discount}
            onChange={handleText}
            placeholder="Whole number percent"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PercentOutlined />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            required={false}
            label="Discount Price"
            type="number"
            name="discount_price"
            value={formData.discount_price}
            onChange={handleText}
            placeholder="Enter Discount Price"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CurrencyRupeeOutlined />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            required={false}
            label="Images"
            inputProps={{ multiple: true }}
            type="file"
            onChange={handleImgs}
            name="chamb_image"
          />
         
          {/* <FormControl required={false} className="inline-form-control">
            <InputLabel shrink>Status</InputLabel>
            <FormControlLabel
              label={formData.status ? "Active" : "Inactive"}
              onChange={(e) =>
                setFormData((curr) => ({ ...curr, status: e.target.checked }))
              }
              control={<Switch checked={formData.status} />}
            />
          </FormControl> */}

          <TextField
            label="Description"
            placeholder="Enter Description..."
            className="big-text"
            name="description"
            value={formData.description}
            onChange={handleText}
            multiline
          />
          <TextField
            label="Policy"
            placeholder="Enter highlight..."
            className="big-text"
            name="policy"
            value={formData.policy}
            onChange={handleText}
            multiline
          />
          
        </Box>
        
            

        <SubmitButton showProgress={progress} />
      </Box>
      
    </StyledModalPaper>
  );
}
export default forwardRef((props, _ref) => <ChambPackageModal {...props} />);
