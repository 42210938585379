import { useState, useContext, useEffect, useReducer } from "react";
import {
  TableContainer,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Button,
  Modal,
  TableCell,
  TablePagination,
  LinearProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import { api } from "../global/apiConfig";
import { Add, Search } from "@mui/icons-material";
import {
  AlertToast,
  modalReducer,
  noFetch,
  tableImageHeight,
} from "../../divine-constants";
import { StyledTableHeadCell, StyledTableHeader } from "../../components/Table";
import { AddBank, EditItem, Redbin, ViewItem } from "../../components/Icons";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import AgentModal from "../../modals/AgentModal/AgentModal";
import DeleteModal from "../../modals/DeleteModal";
import AgentEditListModal from "../../modals/AgentModal/AgentEditListModal";
import AgentViewModal from "../../modals/AgentModal/AgentViewModal";
import AgentPaymentModal from "../../modals/AgentModal/AgentPaymentModal";
export default function Agent() {
  const { setAlert } = useContext(AlertToast);
  const [rows, setRows] = useState([]);
  const [filteredList, setFilteredList] = useState(rows);
  const [deleteModal, setDeleteModal] = useState(false);
  const [clickedRow, setClickedRow] = useState();
  const [nestedClickedRow, setNestedClickedRow] = useState();
  const [noResults, setNoResults] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalOpen, setModalOpen] = useReducer(modalReducer, {
    addNew: false,
    addBank: false,
    edit: false,
    view: false,
  });
  const [progress, setProgress] = useState(true);
	const [Id,setId] = useState({});

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;

  const fetchData = () =>
    api()
      .get("sadmin/get_agent")
      .then((res) => {
        if (res.status === 200) {
          setRows(res.data);
          setProgress(false);
        }
      })
      .catch(() => setAlert(noFetch));

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setFilteredList(rows);
  }, [rows]);

  function handlePageChange(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value), 10);
    setPage(0);
  }

  function handleTableSearch(event) {
    const query = event.target.value;
    const next = rows.filter((item) => {
      return Object.values(item)
        .map((v) => v.toString())
        .some((el) => el.toLowerCase().includes(query));
    });
    if (next.length <= 0) setPage(0);
    setFilteredList(next);
    setNoResults(next.length <= 0);
  }
  return (
    <Box component="div">
      <StyledTableHeader>
      <Button onClick={() => setModalOpen({ type: "addNew", to: "open" })}>
          <Add /> Add new
        </Button>
        <TextField
					label="Search in Table"
					onChange={handleTableSearch}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Search/>
							</InputAdornment>
						)
					}}
					InputLabelProps={{shrink: undefined}}
					required={false}
					helperText={noResults && "No results found"}
					FormHelperTextProps={{
						error: noResults
					}}
				/>
      </StyledTableHeader>
      {progress && <LinearProgress />}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableHeadCell>S.No</StyledTableHeadCell>
              <StyledTableHeadCell>Name</StyledTableHeadCell>
              <StyledTableHeadCell>Image</StyledTableHeadCell>
              <StyledTableHeadCell>Mobile</StyledTableHeadCell>
              <StyledTableHeadCell>Email</StyledTableHeadCell>
              <StyledTableHeadCell>Action</StyledTableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredList.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              : filteredList
            ).map((row, i) => (
              <TableRow key={i}>
                <TableCell>{i + 1 + page * rowsPerPage}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>
                  <img
                    height={tableImageHeight}
                    src={row.profile_image}
                    alt={row.profile_image}
                  />
                </TableCell>
                <TableCell>{row.mobile_no}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>
								<AddBank onClick={() => {setNestedClickedRow(row); setModalOpen({type: "addBank", to: "open"});setId({agent_id:row._id})}}/>

                  <ViewItem
                    onClick={() => {
                      setNestedClickedRow(row);
                      setModalOpen({ type: "view", to: "open" });
                    }}
                  />
                  <EditItem
                    onClick={() => {
                      setClickedRow(row);
                      setModalOpen({ type: "edit", to: "open" });
                    }}
                  />
                  <Redbin
                    onClick={() => {
                      setClickedRow(row);
                      setDeleteModal(true);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, { label: "All", value: -1 }]}
        colSpan={100}
        count={filteredList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
      <Modal
        open={modalOpen.addNew}
        onClose={() => setModalOpen({ type: "addNew", to: "close" })}
        aria-labelledby="agent list form"
        aria-describedby="insert new record"
      ><AgentModal fetchData={fetchData} setModalOpen={setModalOpen} />
      </Modal>

      <Modal 
				open={modalOpen.addBank} 
				onClose={() => setModalOpen({type: "addBank", to: "close"})} 
				aria-labelledby="Add Bank Account form" 
				aria-describedby="insert new record"
			><AgentPaymentModal setModalOpen={setModalOpen} fetchData={fetchData} id={Id} /></Modal>

      <Modal 
				open={modalOpen.edit} 
				onClose={() => setModalOpen({type: "edit", to: "close"})} 
				aria-labelledby="edit" 
				aria-describedby="Edit record"
			><AgentEditListModal setModalOpen={setModalOpen} modalOpen={modalOpen} fetchData={fetchData} data={clickedRow} /></Modal>

			<Modal 
				open={modalOpen.view} 
				onClose={() => setModalOpen({type: "view", to: "close"})} 
				aria-labelledby="view" 
				aria-describedby="View record"		
      ><AgentViewModal setModalOpen={setModalOpen} data={nestedClickedRow} /></Modal>

      <DeleteModal
        dialogDisplay={deleteModal}
        setDialogDisplay={setDeleteModal}
        url={"sadmin/delete_agent"}
        data={{ agent_id: clickedRow?._id }}
        fetchData={fetchData}
      />
    </Box>
  );
}
