import { Box, Typography } from "@mui/material";

function Fieldset({legend, className, children, style}) {
	console.log("styledField",style);
	return (<Box component="fieldset" className={className} sx={{px: '1rem', pt: '0.5rem', mb: 2, borderColor: "text.disabled", borderWidth: 1, borderRadius: 1,...style}} >
		<Typography color="primary.main" component="legend" variant="subtitle1">{legend}</Typography>
		{children}
	</Box>)
}


export default Fieldset