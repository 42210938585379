
import { useEffect, useState } from "react";
import { Box, TextField, MenuItem, Checkbox, ListItemText, FormControlLabel, Switch, FormControl, InputLabel, Card, CardActionArea, CardContent, CardHeader, Typography, Grid, List, ListItem, ListItemAvatar } from "@mui/material";
import { Property_URL, api } from "../../pages/global/apiConfig";
import styles from "../hotel/Companyinfo.module.css"

export default function Caravan() {
	
	const [formData, setFormData] = useState({});



 useEffect(() => {    
  let token = localStorage.getItem("token");
  let property_id = localStorage.getItem("UserId");
  async function Callfunction() {
    let response = await fetch(
      // "https://admin.moszadhotels.com/property/main_property_list?property_type=chamb",
      `${Property_URL}property/main_property_list?property_type=chamb`,

      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    let res = await response.json();      
    setFormData(res[0]);
    // localStorage.setItem("UserId", res._id);
  }
  Callfunction();
}, []);

	return (
	<> 
  <Box className={styles.CardStyle} >
  <Card className={styles.CardDataStyle} >
        <CardActionArea>
         <CardHeader   title={ <Typography variant="h3" display="block">Chamb Details</Typography>}></CardHeader>
      <CardContent sx={{ paddingTop:0}} >
      <Typography variant="button" display="block" fontWeight={600} gutterBottom>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 1,
          m: 1,

        }}
      >
       <Box>Chamb Name</Box>       
       <Box>{formData?.name}</Box>
      </Box>
      
      </Typography>
      <Typography variant="button" display="block" fontWeight={600} gutterBottom>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 1,
          m: 1,

        }}
      >
       <Box>Category</Box>       
       <Box>{formData?.category}</Box>
      </Box>
      
      </Typography>
    
      <Typography variant="button" display="block" fontWeight={600} gutterBottom>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 1,
          m: 1,

        }}
      >
       <Box> Email </Box>       
       <Box>{formData?.email}</Box>
      </Box>
      
      </Typography>
      <Typography variant="button" display="block" fontWeight={600} gutterBottom >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 1,
          m: 1,

        }}
      >
       <Box> Mobile No </Box>       
       <Box>{formData?.mobile}</Box>
      </Box>     
      </Typography>    
      </CardContent>
      </CardActionArea>
     
    </Card>    
  
  <Card className={styles.CardDataStyle} >
  <CardActionArea>
         <CardHeader   title={ <Typography variant="h3" display="block">Chamb Address</Typography>}></CardHeader>
      <CardContent sx={{ paddingTop:0}} >
      <Typography variant="button" display="block" fontWeight={600} gutterBottom>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 1,
          m: 1,

        }}
      >
       <Box> Address </Box>       
       <Box>{formData?.address}</Box>
      </Box> 
      
      </Typography>
      <Typography variant="button" display="block" fontWeight={600} gutterBottom>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 1,
          m: 1,

        }}
      >
       <Box>  Area  </Box>       
       <Box>{formData?.area}</Box>
      </Box> 
     
      </Typography>
      <Typography variant="button" display="block" fontWeight={600} gutterBottom>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 1,
          m: 1,

        }}
      >
       <Box>  State  </Box>       
       <Box>{formData?.state} - {formData?.pincode}</Box>
      </Box> 
    
      </Typography>
      <Typography variant="button" display="block" fontWeight={600} gutterBottom >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 1,
          m: 1,

        }}
      >
       <Box>  Country  </Box>       
       <Box>{formData?.country}</Box>
      </Box> 
     
      
      </Typography>    
      </CardContent>
      </CardActionArea>
    </Card>    
  </Box> 

{/* 
  <Box >
  <Card  >
        <CardActionArea>
         <CardHeader  title={<Typography variant="h3" display="block">Chamb Amenities</Typography>}></CardHeader>
      <CardContent sx={{ paddingTop:0}}>
      <List sx={{maxHeight: 200, overflowY: "auto"}}>
						{formData?.Chamb_amentities && formData?.Chamb_amentities.map((amenity, ind) => 
						<ListItem key={ind} sx={{pb: 1}}>
							<ListItemAvatar>
								<img src={amenity.image} alt={amenity.image} height={30} />
							</ListItemAvatar>
							&nbsp;
							<ListItemText>
								{amenity.amenities_name}
							</ListItemText>
						</ListItem>
						)}
					</List>
      </CardContent>
      </CardActionArea>
    </Card>  
    </Box>  */}
  <Box sx={{ paddingTop:1}}>
  <Card  >
        <CardActionArea>
         <CardHeader  title={<Typography variant="h3" display="block">Main Image</Typography>}></CardHeader>
      <CardContent sx={{ paddingTop:0, display:"flex"}}>
      <img
          src={formData?.main_image}
          style={{ maxWidth: "35%", width: "45%" }}
          srcSet={formData?.main_image}
          alt="main_Image"
          loading="lazy"
        />
      </CardContent>
      </CardActionArea>
    </Card>  
    </Box> 
  <Box sx={{ paddingTop:1}}>
  <Card  >
        <CardActionArea>
         <CardHeader  title={<Typography variant="h3" display="block">Other Image</Typography>}></CardHeader>
      <CardContent style={{ paddingTop:0, paddingBottom: 16, display: "flex", flexWrap: "wrap", gap: 4 }}>
      {formData?.other_image && formData?.other_image.map((image, ind) => (
          <img
            key={ind}
            src={image}
            style={{ maxWidth: "25%", width: "35%"  }}
            srcSet={image}
            alt={image}
            loading="lazy"
          />
        ))}
      </CardContent>
      </CardActionArea>
    </Card>  
    </Box> 
  <Box sx={{ paddingTop:1}}>
  <Card  >
        <CardActionArea>
         <CardHeader  title={<Typography variant="h3" display="block">Map View</Typography>}></CardHeader>
      <CardContent sx={{ paddingTop:0}}>
        <Box component="span">
             <iframe src={formData?.iframe}></iframe>
            </Box>
      {/* <Box
            component="div"        
            dangerouslySetInnerHTML={{ __html: formData?.iframe }}
          ></Box> */}
      </CardContent>
      </CardActionArea>
    </Card>  
    </Box> 
  <Box sx={{ paddingTop:1}}>
  <Card  >
        <CardActionArea>
         <CardHeader  title={<Typography variant="h3" display="block">Chamb Description</Typography>}></CardHeader>
      <CardContent variant="body2" sx={{ paddingTop:0}}>
        {formData?.description}
      </CardContent>
      </CardActionArea>
    </Card>  
    </Box> 
  <Box sx={{ paddingTop:1}}>
  <Card  >
        <CardActionArea>
         <CardHeader  title={<Typography variant="h3" display="block">Chamb Policy</Typography>}></CardHeader>
      <CardContent variant="body2" sx={{ paddingTop:0}}>
      {formData?.policy}
      </CardContent>
      </CardActionArea>
    </Card>  
    </Box> 
  </>
	)
}
