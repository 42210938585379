import { createTheme } from "@mui/material"; 

export const moszadTheme = createTheme({
	palette: {
		primary: {
            main: "#3143a2",
            light: "#3456fa",
            dark: "#1a32ab"
        }
	} ,
    breakpoints: {
		values: {
			sm: 750
		}
	},
	typography: {
		fontWeightBold: 600,
		fontFamily: [
			'"Nunito"',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
	  	].join(','),
		body1: {
			fontSize: "0.875rem"
		},
		body2: {
			fontSize: "0.8rem"
		},
		h2: {
			fontSize: "1.5rem"
		},
		h3: {
			fontSize: "1.27rem"
		},
		h4: {
			fontSize: "1rem"
		},
		h5: {
			fontSize: "0.75rem"
		},
		button: {
			textTransform: "capitalize"
		}
	},
    components: {
        MuiFormLabel: {
			styleOverrides: {
				asterisk: {
					color: "red",
					fontSize: "1rem"
				}
			}
		},
		MuiTextField: {
			defaultProps: {
				variant: "outlined",
				color: "primary",
				size: "small",
				required: true,
				margin: "dense",
				helperText: " ",
				autoComplete: "off",
				minRows: 5,
				maxRows: 12,
				multiline: false,
				InputLabelProps: {
					shrink: true
				},
				FormHelperTextProps: {
					error: true
				}
			}
		},
        MuiCheckbox: {
			defaultProps: {
				size: "small",
				checked: false
			}
		},
        MuiButton: {
			defaultProps: {
				size: 'small',
				variant: "contained"
			}
		},
        MuiAlert: {
			defaultProps: {
				style: {
					width: "fit-content",
					marginInline: "auto",
					marginTop: 16
				},
				elevation: 5
			}
		}
    }
})

export const propertyTheme = createTheme({
	palette: {
		primary: {
            main: "#0a5e1b",
            light: "#f4fa7a",
            dark: "#a8b00b"
        }
	},
    breakpoints: {
		values: {
			sm: 750
		}
	},
	typography: {
		fontWeightBold: 600,
		fontFamily: [
			'"Nunito"',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
	  	].join(','),
		body1: {
			fontSize: "0.875rem"
		},
		body2: {
			fontSize: "0.8rem"
		},
		h2: {
			fontSize: "1.5rem"
		},
		h3: {
			fontSize: "1.27rem"
		},
		h4: {
			fontSize: "1rem"
		},
		h5: {
			fontSize: "0.75rem"
		},
		button: {
			textTransform: "capitalize"
		}
	},
    components: {
        MuiFormLabel: {
			styleOverrides: {
				asterisk: {
					color: "red",
					fontSize: "1rem"
				}
			}
		},
		MuiTextField: {
			defaultProps: {
				variant: "outlined",
				color: "success",
				size: "small",
				required: true,
				margin: "dense",
				helperText: " ",
				autoComplete: "off",
				minRows: 5,
				maxRows: 12,
				multiline: false,
				InputLabelProps: {
					shrink: true
				},
				FormHelperTextProps: {
					error: true
				}
			}
		},
        MuiCheckbox: {
			defaultProps: {
				size: "small",
				checked: false
			}
		},
        MuiButton: {
			defaultProps: {
				size: 'small',
				variant: "contained"
			}
		},
        MuiAlert: {
			defaultProps: {
				style: {
					width: "fit-content",
					marginInline: "auto",
					marginTop: 16
				},
				elevation: 5
			}
		}
    } 
})