import { forwardRef } from "react";
import { StyledModalPaper } from "../styles/modal";
import {
  Box,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import ModalHeader from "./ModalHeader";
import Fieldset from "../components/Fieldset";
import { Clear, Done } from "@mui/icons-material";

function CustomerViewModal({ setModalOpen, data }) {
    return (
        <StyledModalPaper>
            <ModalHeader
        title="Customer Details"
        setModalOpen={setModalOpen}
        type="view"
      />
      <Box
        sx={{
          display: "grid",
          width: "100%",
          gap: "1rem",
          "& > fieldset": {
            width: "fit-content",
            flexGrow: 1,
            display: "grid",
            gap: "1rem",
            justifyContent: "space-around",
            pb: 1,
          },
          "@media only screen and (max-width: 750px)": {
            gridTemplateColumns: "1fr",
          },
        }}
        className="two-column-layout"
      >
        <Box>
          <br />
            <Stack>
            <Box>
              <Typography component="span" fontWeight={600}>
                Customer Name:{" "}
              </Typography>
              {data.name1} {data.name2}
            </Box>
            <Box>
              <Typography component="span" fontWeight={900}>
                Contact Details
              </Typography>
            </Box>
            <Box>
              <Typography component="span" fontWeight={600}>
                Email:{" "}
              </Typography>
              {data.email}
            </Box>
            <Box>
              <Typography component="span" fontWeight={600}>
                Mobile No:{" "}
              </Typography>
              {data.mobile_no}
            </Box>
          </Stack>
        </Box>
        </Box>

        </StyledModalPaper>
    );
}

export default forwardRef((props, _ref) => <CustomerViewModal {...props} />);
