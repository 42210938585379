import { Paper, Typography } from "@mui/material";

export default function AdvancedSearch({children}) {
	return (
		<Paper component="fieldset" elevation={1} style={{
			padding: "1vmin 2vmin",
			display: "grid",
			gridTemplateColumns: "repeat(3, 1fr)",
			flexWrap: "wrap",
			gap: "2vmin",
			marginBottom: "1rem" ,
			justifyContent: "flex-start",
			alignItems: "center",
			border: "none"
		}}>
			<Typography style={{backgroundColor: "rgb(255, 255, 255)", paddingInline: "0.5rem"}} component={"legend"} variant="subtitle1">Search</Typography>
			{children}
		</Paper>
	)
}