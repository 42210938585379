import { forwardRef } from "react";
import { StyledModalPaper } from "../../styles/modal";
import {
  Box,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import ModalHeader from "../ModalHeader";
import Fieldset from "../../components/Fieldset";
import { Clear, Done } from "@mui/icons-material";

function AgentViewModal({ setModalOpen, data }) {
    return (
    <StyledModalPaper>
      <ModalHeader
        title="Agent details"
        setModalOpen={setModalOpen}
        type="view"
      />
      <Box
        sx={{
          display: "grid",
          width: "100%",
          gap: "1rem",
          "& > fieldset": {
            width: "fit-content",
            flexGrow: 1,
            display: "grid",
            gap: "1rem",
            justifyContent: "space-around",
            pb: 1,
          },
          "@media only screen and (max-width: 750px)": {
            gridTemplateColumns: "1fr",
          },
        }}

      >

    <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1}>
    <Chip  label={
        data.active_status
            ? "Status - Active"
            : "Status - Inactive"
        }
        color={data.active_status ? "success" : "error"}
    />  
  
</Stack>
     
    <Fieldset legend="Profile Image" style={{ paddingBottom: 16 }}>
    <img
      src={data.profile_image}
      style={{ maxWidth: "50%", width: "45%" }}
      srcSet={data.profile_image}
      alt="profile_image"
      loading="lazy"
    />
  </Fieldset>

<Stack>
<Box>
    <Typography component="span" fontWeight={600}>
    Agent Name :{" "}
    </Typography>
    {data.name}
</Box>
     <Box>
        <Typography component="span" fontWeight={600}>
        Email :{" "}
        </Typography>
        {data.email}
</Box>
<Box>
    <Typography component="span" fontWeight={600}>
    Mobile No :{" "}
    </Typography>
    {data.name}
</Box>
     <Box>
        <Typography component="span" fontWeight={600}>
        Address :{" "}
        </Typography>
        {data.address}
</Box>
<Box>
    <Typography component="span" fontWeight={600}>
    City :{" "}
    </Typography>
    {data.location}
</Box>
     <Box>
        <Typography component="span" fontWeight={600}>
        Area :{" "}
        </Typography>
        {data.area}
</Box>
<Box>
        <Typography component="span" fontWeight={600}>
        Pincode :{" "}
        </Typography>
        {data.pincode}
</Box>
<Box>
    <Typography component="span" fontWeight={600}>
    Country :{" "}
    </Typography>
    {data.country}
</Box>
     <Box>
        <Typography component="span" fontWeight={600}>
        State :{" "}
        </Typography>
        {data.state}
</Box><br/>
       
      <Box sx={{display: "flex"}}>
        <Fieldset legend="ID Proof Front Side" style={{ paddingBottom: 16 }}>
            <img
              src={data.main_image}
              style={{ maxWidth: "50%", width: "45%" }}
              srcSet={data.idfrontside}
              alt="idfrontside"
              loading="lazy"
            />
          </Fieldset>
          <Fieldset legend="ID Proof Back Side" style={{ paddingBottom: 16 }}>
            <img
              src={data.idbackside}
              style={{ maxWidth: "50%", width: "45%" }}
              srcSet={data.idbackside}
              alt="idbackside"
              loading="lazy"
            />
          </Fieldset>
      </Box>
  </Stack>

  
  </Box>

    </StyledModalPaper>
  );
}

export default forwardRef((props, _ref) => <AgentViewModal {...props} />);
