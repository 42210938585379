import { Button, CircularProgress } from "@mui/material";

export default function SubmitButton({showProgress, type="Submit" }) {
	return (
		<div style={{display: "grid", placeContent: "end"}}>
			<Button type="submit" disabled={showProgress} >
				{type} {showProgress && <>&nbsp; <CircularProgress color="success" variant="indeterminate" size="16px" /></>}
			</Button>
		</div>
	)
}