import { forwardRef, useState, useEffect, useContext } from "react";
import { StyledModalPaper } from "../../styles/modal";
import {
  Box,
  TextField,
  MenuItem,
  InputAdornment,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import { api } from "../../pages/global/apiConfig";
import ModalHeader from "../ModalHeader";
import SubmitButton from "../../components/FormButton";
import { CurrencyRupeeOutlined, PercentOutlined } from "@mui/icons-material";
import { AlertToast, alertState, noFetch } from "../../divine-constants";
import { arrayStringify } from "../../utils";
import { StyledTableHeadCell } from "../../components/Table";

function TourPackage({ setModalOpen, data, id, fetchData }) {
  console.log(id,"ID")
  console.log(data,"data")
    const [formData, setFormData] = useState({
    tour_id :id.tour_id,
    package_name: data ? data.package_name : "",
    from_location: data ? data.from_location : "",
    to_location: data ? data.to_location : "",
    no_of_guest: data ? data.no_guest : "",
    date_status: data ? data.date_status : false,
    from_date: data ? data.from_date : "",
    to_date: data ? data.to_date : "",
    category: data ? data.category : "",
    price: data ? data.price : "",
    discount: data ? data.discount_percentage : "",
    discount_price: data ? data.discount_price : "",
    highlight: data ? data.highlight : "",
    caption_list: [],
    description: data ? data.room_description : "",
    package_img: [],
    status: data ? data.room_active_status : false,
    pincode: data ? data.pincode : "",
    hotel_count: data ? data.hotel_count : 0,
    flight_count: data ? data.flight_count : 0,
    transport_count: data ? data.transport_count : 0,
    package_pack: data ? data.package_pack : "",
    package_guest: data ? data.package_guest : 0,
    place_plan_list: [],
  });
  const [captionlist, setCaptionList] = useState({
    caption_name: "",
    info_value: 0,
  });
  const [placePlan, setPlacePlan] = useState({
    place: "",
    day: 1,
  });
  const [progress, setProgress] = useState(false);
  const { setAlert } = useContext(AlertToast);
  const [selectArrays, setSelectArrays] = useState({
		locations: [],
		categories: []
	});
  useEffect(() => {
		Promise.all([
			api().get('sadmin/get_city_location'),
			api().get('sadmin/get_category?type=tour')
		]).then(results => {
			setSelectArrays({
				locations: results[0].data,
				categories: results[1].data
			});	
		}).catch(() => setAlert(noFetch));
	}, []);


  function handleText(event) {
    setFormData((current) => ({
      ...current,
      [event.target.name]: event.target.value,
    }));
  }

  function handleSingleSelect(event) {
		setFormData(current => ({...current, [event.target.name]: event.target.value}));
	}

  function handleImgs(event) {
    setFormData((current) => ({ ...current, package_img: event.target.files }));
  }
//  console.log(formData,"formdata")
  function handleFormSubmit(event) {
        setProgress(true);
    event.preventDefault();
    
    if(id.type === "edit"){
      let Data = {
        tour_id: formData.tour_id,
        package_id : data._id,
        subtitle:"null",
        package_name: formData.package_name ,
        from_location: formData.from_location ,
        to_location: formData.to_location ,
        no_of_guest: formData.no_of_guest ,
        date_status: formData.date_status ,
        from_date: formData.from_date ,
        to_date: formData.to_date ,
        category: formData.category ,
        price:formData.price ,
        discount_percentage: formData.discount ,
        discount_price: formData.discount_price ,
        highlight: formData.highlight ,
        description: formData.description ,
        package_img: Array.from(formData.package_img),
        active_status: formData.status ,
        pincode: formData.pincode ,
        hotel_count: formData.hotel_count ,
        flight_count: formData.flight_count, 
        transport_count: formData.transport_count ,
        package_pack: formData.package_pack ,
        package_guest: formData.package_guest ,
        caption_list: formData.caption_list,
        place_plan_list: formData.place_plan_list,
        
    }
    const finalData = new FormData();
    for (const key in Data) {
        if (Data.hasOwnProperty(key)) {
            const value = Data[key];
            finalData.append(key, key === "place_plan_list" || key === "caption_list" ? JSON.stringify(value) : value);
        }
      }
    	api().patch("sadmin/edit_tour_package_details",finalData).then(res => {
    		if(res.data.code === 201) {
    			setAlert(alertState({
    				message: "Room added successfully",
    				severity: "success",
    				show: true
    			}));
    			fetchData();
    			setModalOpen({type: "editNested" , to: "close"});
    		}
    	}).catch(err => {
    		if(err.status === 401) {
    			setAlert(alertState({
    				message: "You are not authorized to add a new room.",
    				severity: "warning",
    				show: true
    			}))
    		} else if (err.status === 403) {
    			setAlert(alertState({
    				message: "You do not have access to add a new room.",
    				severity: "warning",
    				show: true
    			}))
    		} else setAlert(alertState({
    			message: "Something went wrong. Please try again",
    			severity: "error",
    			show: true
    		}))
    	}).finally(() => setProgress(false));
    }
    else{
            let Data = {
            tour_id: formData.tour_id ,
            subtitle:"null",
            package_name: formData.package_name ,
            from_location: formData.from_location ,
            to_location: formData.to_location ,
            no_of_guest: formData.no_of_guest ,
            date_status: formData.date_status ,
            from_date: formData.from_date ,
            to_date: formData.to_date ,
            category: formData.category ,
            price:formData.price ,
            discount_percentage: formData.discount ,
            discount_price: formData.discount_price ,
            highlight: formData.highlight ,
            description: formData.description ,
            package_img: Array.from(formData.package_img),
            active_status: formData.status ,
            pincode: formData.pincode ,
            hotel_count: formData.hotel_count ,
            flight_count: formData.flight_count, 
            transport_count: formData.transport_count ,
            package_pack: formData.package_pack ,
            package_guest: formData.package_guest ,
            caption_list: formData.caption_list,
            place_plan_list: formData.place_plan_list,
            
        }
        const finalData = new FormData();
        for (const key in Data) {
            if (Data.hasOwnProperty(key)) {
                const value = Data[key];
                finalData.append(key, key === "place_plan_list" || key === "caption_list" ? JSON.stringify(value) : value);
            }
          }

      api().post("sadmin/add_tour_package_details", finalData).then(res => {
    		if(res.data.code === 201) {
    			setAlert(alertState({
    				message: "Package added successfully",
    				severity: "success",
    				show: true
    			}));
    			fetchData();
    			setModalOpen({type: data ? "edit" : "addNewNested", to: "close"});
    		}
    	}).catch(err => {
    		if(err.status === 401) {
    			setAlert(alertState({
    				message: "You are not authorized to add a new room.",
    				severity: "warning",
    				show: true
    			}))
    		} else if (err.status === 403) {
    			setAlert(alertState({
    				message: "You do not have access to add a new room.",
    				severity: "warning",
    				show: true
    			}))
    		} else setAlert(alertState({
    			message: "Something went wrong. Please try again",
    			severity: "error",
    			show: true
    		}))
    	}).finally(() => setProgress(false));
    }
  }

  return (
    <StyledModalPaper>
      <ModalHeader
        setModalOpen={setModalOpen}
        type={data ? "editNested" : "addNewNested"}
      />
      <Box
        component="form"
        className="list-form"
        onSubmit={handleFormSubmit}
        noValidate
      >
        <Box component="div" className="form-grid-container four-column-layout">
          <TextField
            label="Package Name"
            type="text"
            name="package_name"
            value={formData.package_name}
            onChange={handleText}
            placeholder="Package Name"
          />
          <TextField
            label="From Location"
            type="text"
            name="from_location"
            value={formData.from_location}
            onChange={handleText}
            placeholder="From Location"
          />
          <TextField
            label="To Location"
            type="text"
            name="to_location"
            value={formData.to_location}
            onChange={handleText}
            placeholder="To Location"
          />
          <TextField
            label="From"
            type="date"
            name="from_date"
            value={formData.from_date}
            onChange={handleText}
            placeholder="From Date"
          />
          <TextField
            label="To"
            type="date"
            name="to_date"
            value={formData.to_date}
            onChange={handleText}
            placeholder="To Date"
          />
          <TextField 
							select 
							label="Category"
							defaultValue={formData.category}
							value={formData.category}
							name="category"
							onChange={handleSingleSelect}
							>
								{selectArrays.categories.map(category => (
									<MenuItem value={category.category_name} key={category.id}>{category.category_name}</MenuItem>
								))}
						</TextField>

          <TextField
            required={false}
            label="Price"
            type="number"
            name="price"
            value={formData.price}
            onChange={handleText}
            placeholder="Enter Price"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CurrencyRupeeOutlined />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            required={false}
            label="Discount"
            type="number"
            name="discount"
            value={formData.discount}
            onChange={handleText}
            placeholder="Whole number percent"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PercentOutlined />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            required={false}
            label="Discount Price"
            type="number"
            name="discount_price"
            value={formData.discount_price}
            onChange={handleText}
            placeholder="Enter Discount Price"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CurrencyRupeeOutlined />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            required={false}
            label="Images"
            inputProps={{ multiple: true }}
            type="file"
            onChange={handleImgs}
            name="package_img"
          />
          <TextField
            label="Pincode"
            type="text"
            name="pincode"
            value={formData.pincode}
            onChange={handleText}
            placeholder="Enter pincode"
          />
          <TextField
            required={false}
            label="Hotel Count"
            type="number"
            name="hotel_count"
            value={formData.hotel_count}
            onChange={handleText}
            placeholder="Eg. 8"
          />
          <TextField
            required={false}
            label="Flight Count"
            type="number"
            name="flight_count"
            value={formData.flight_count}
            onChange={handleText}
            placeholder="Eg. 8"
          />
          <TextField
            required={false}
            label="Transport Count"
            type="number"
            name="transport_count"
            value={formData.transport_count}
            onChange={handleText}
            placeholder="Eg. 8"
          />

          <TextField
            select
            label="Package Pack"
            type="text"
            name="package_pack"
            value={formData.package_pack}
            onChange={handleText}
          >
            <MenuItem value="Single">Single</MenuItem>
            <MenuItem value="Group">Group</MenuItem>
            <MenuItem value="Couple">Couple</MenuItem>
          </TextField>
          <TextField
            required={false}
            label="Package Guest"
            type="number"
            name="package_guest"
            value={formData.package_guest}
            onChange={handleText}
            placeholder="Eg. 8"
          />
          <TextField
            required={false}
            label="no of guest"
            type="number"
            name="no_of_guest"
            value={formData.no_of_guest}
            onChange={handleText}
            placeholder="Eg. 8"
          />

          {/* <FormControl required={false} className="inline-form-control">
            <InputLabel shrink>Status</InputLabel>
            <FormControlLabel
              label={formData.status ? "Active" : "Inactive"}
              onChange={(e) =>
                setFormData((curr) => ({ ...curr, status: e.target.checked }))
              }
              control={<Switch checked={formData.status} />}
            />
          </FormControl> */}

          <TextField
            label="Description"
            placeholder="Enter Description..."
            className="big-text"
            name="description"
            value={formData.description}
            onChange={handleText}
            multiline
          />
          <TextField
            label="Highlight"
            placeholder="Enter highlight..."
            className="big-text"
            name="highlight"
            value={formData.highlight}
            onChange={handleText}
            multiline
          />
          
        </Box>
        <Box          
          className="form-grid-container four-column-layout"
        >
          <TextField
            label="caption Name"
            type="text"            
            value={captionlist.caption_name || ""}
            onChange={(e)=>setCaptionList({...captionlist,caption_name:e.target.value})}
            placeholder="caption Name"
          />{" "}
           <TextField
            label="Info Value" 
            type="number"            
            value={captionlist.info_value || 0}
            onChange={(e)=>setCaptionList({...captionlist,info_value:e.target.value})}
            placeholder="info_value"
          />         
          <TextField
            label="place"
            type="text"            
            value={placePlan.place || ""}
            onChange={(e)=>setPlacePlan({...placePlan,place:e.target.value})}
            placeholder="caption Name"
          />{" "}
           <TextField
            label="day" 
            type="number"            
            value={placePlan.day || 1}
            onChange={(e)=>setPlacePlan({...placePlan,day:e.target.value})}
            placeholder="info_value"
          />         
        </Box>
        <Box          
          className="form-grid-container two-column-layout"
          style={{padding: 15}}
        >
           <Box style={{display: "grid", placeContent: "center"}}>
        <Button  size="small" onClick={()=>{setFormData({...formData,caption_list:[...formData.caption_list,captionlist]});setCaptionList({})}} >Add new</Button>
        </Box>
        <Box style={{display: "grid", placeContent: "center"}}>
        <Button  size="small" onClick={()=>{setFormData({...formData, place_plan_list:[...formData.place_plan_list , placePlan]});setPlacePlan({})}} >Add new</Button>
        </Box>
        </Box>
       
        <Box          
          className="form-grid-container two-column-layout"
          style={{padding: 15}}
        >
           <TableContainer>
               <Table>
                   <TableHead>
                       <TableRow>
                       <StyledTableHeadCell>S.No</StyledTableHeadCell>
                       <StyledTableHeadCell>Caption Name</StyledTableHeadCell>
                       <StyledTableHeadCell>Info Value</StyledTableHeadCell>                       
                       </TableRow>
                   </TableHead>
                   <TableBody>
                      {formData.caption_list.map((e,i)=>{
                           return(
                            <TableRow key={i}>
                               <TableCell> {i+1}</TableCell>
                           <TableCell  style={{paddingBlock: 5}}>{e.caption_name} </TableCell>
                           <TableCell  style={{paddingBlock: 5}}>{e.info_value} </TableCell>
                           </TableRow>
                           )
                      })}
                   </TableBody>
               </Table>
               
               </TableContainer> 
           <TableContainer>
               <Table>
                   <TableHead>
                       <TableRow>
                       <StyledTableHeadCell>S.No</StyledTableHeadCell>
                       <StyledTableHeadCell>Place</StyledTableHeadCell>
                       <StyledTableHeadCell>Day</StyledTableHeadCell>                       
                       </TableRow>
                   </TableHead>
                   <TableBody>
                      {formData.place_plan_list.map((e,i)=>(
                           <TableRow key={i}>
                               <TableCell> {i+1}</TableCell>
                           <TableCell  style={{paddingBlock: 5}}>{e.place} </TableCell>
                           <TableCell  style={{paddingBlock: 5}}>{e.day} </TableCell>
                           </TableRow>
                      ))}
                   </TableBody>
               </Table>
               
               </TableContainer> 
            </Box>       

        <SubmitButton showProgress={progress} />
      </Box>
      
    </StyledModalPaper>
  );
}
export default forwardRef((props, _ref) => <TourPackage {...props} />);
