import "./App.css";
import "./styles/addnewForm.css";
import "./styles/list-form.css";
import { theme as customizedTheme } from "./theme";
import { useEffect, useMemo, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  CssBaseline,
  IconButton,
  Paper,
  Stack,
  ThemeProvider,
  Toolbar,
} from "@mui/material";
import {
  links,
  propertyLinks,
  Drawer,
  User,
  toolbarHeight,
  AlertToast,
  alertState,
  propertyStaffLinks,
} from "./divine-constants";
import Router from "./Router";
import SidebarAdmin from "./Drawer-Admin";
import SidebarPropertyOwner from "./Drawer-PropertyOwner";
import Header from "./Header";
import Title from "./pages/global/Title";
import { AdminPanelSettingsOutlined, Close, SettingsOutlined, SpaceDashboardOutlined,} from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import Container from '@mui/material/Container';
import useMediaQuery from '@mui/material/useMediaQuery';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';

function App() {
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [alert, setAlert] = useState({
    title: "",
    message: "",
    severity: "",
    show: false,
  });
  const initState = {
    username: localStorage.getItem("username"),
    loggedIn: Boolean(localStorage.getItem("token")?.split(".").length === 3),
    type: localStorage.getItem("userType"),
    name: localStorage.getItem("userCategory"),
  };
  const matches = useMediaQuery('(max-width:768px)');
  const [user, setUser] = useState(initState);
  const primary = {
    main:
      user.type === "SUPER_ADMIN" ||
        user.type === "FIELD_WORKER" ||
        user.type === "MANAGER" ||
        user.type === "TELE_COM"
        ? "#057306"
        : "#ce1124",
    light:
      user.type === "SUPER_ADMIN" ||
        user.type === "FIELD_WORKER" ||
        user.type === "MANAGER" ||
        user.type === "TELE_COM"
        ? "#0acf0a"
        : "#ed475d",
    dark:
      user.type === "SUPER_ADMIN" ||
        user.type === "FIELD_WORKER" ||
        user.type === "MANAGER" ||
        user.type === "TELE_COM"
        ? "#114d11"
        : "#ab030c",
  };
  const theme = useMemo(() => customizedTheme(primary), [user]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (alert.show) {
        setAlert({
          title: "",
          message: "",
          severity: "",
          show: false,
        });
      }
    }, 5000);
    return () => clearTimeout(timeout);
  }, [alert.show]);

  useEffect(() => {
    if (alert.show)
      setAlert({
        title: "",
        message: "",
        severity: "",
        show: false,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  //breadcrumb array for each page
  const [drawerWidth, setDrawerWidth] = useState(Drawer.Collapsed);

  const [currentRoutes, setCurrentRoutes] = useState([]);

  // When the browser loads, the code below sets the currentRoutes state based on the user type.
  // For eg. if the user logs in as super admin, the currentRoutes state is set to the entire links object.
  useEffect(() => {
    if (user.type === "PROPERTY_OWNER") {
      switch (localStorage.getItem("userCategory")) {
        case "HOTEL":
          setCurrentRoutes(propertyLinks["HOTEL"]);
          break;
        case "HOSTEL":
          setCurrentRoutes(propertyLinks["HOSTEL"]);
          break;
        case "HALL":
          setCurrentRoutes(propertyLinks["HALL"]);
          break;
        case "TOUR":
          setCurrentRoutes(propertyLinks["TOUR"]);
          break;
        case "CHAMB":
          setCurrentRoutes(propertyLinks["CHAMB"]);
          break;
        default:
          setCurrentRoutes([]);
          break;
      }
    } else if (user.type === "STAFF") {
      switch (localStorage.getItem("userCategory")) {
        case "HOTEL":
          setCurrentRoutes(propertyStaffLinks["HOTEL"]);
          break;
        case "HOSTEL":
          setCurrentRoutes(propertyStaffLinks["HOSTEL"]);
          break;
        case "HALL":
          setCurrentRoutes(propertyStaffLinks["HALL"]);
          break;
        case "TOUR":

          setCurrentRoutes(propertyStaffLinks["TOUR"]);
          break;
        case "CHAMB":

          setCurrentRoutes(propertyStaffLinks["CHAMB"]);
          break;
        default:
          setCurrentRoutes([]);
          break;
      }
    } else if (user.type === "FIELD_WORKER") {
      switch (localStorage.getItem("userCategory")) {
        case "MOSZAD":
          setCurrentRoutes(propertyLinks["FIELD_WORKER"]);
          break;
        default:
          setCurrentRoutes([]);
          break;
      }
    } else if (user.type === "TELE_COM") {
      switch (localStorage.getItem("userCategory")) {
        case "MOSZAD":
          setCurrentRoutes(propertyLinks.TELECOM);
          break;
        default:
          setCurrentRoutes([]);
          break;
      }
    } else {
      switch (user.type) {
        case "SUPER_ADMIN":
          setCurrentRoutes(links);
          break;
        case "MANAGER":
          setCurrentRoutes(links);
          break;
        case "ADMIN":
          setCurrentRoutes(links);
          break;

        default:
          setCurrentRoutes([]);
          break;
      }
    }
  }, [user.type, user.category]);

  const handleRefresh = () => {
    window.location.reload();
  };

  const getPropertyDashboard = () => {
    const dashboardPaths = {
      HOSTEL: "/hostel/dashboard",
      HOTEL: "/hotel/dashboard",
      HALL: "/hall/dashboard",
      TOUR: "/tours-and-travels/dashboard",
      CHAMB: "/moving-homes/dashboard",
    };
    return dashboardPaths[user.name] || "";
  };
  const getPropertyInfo = () => {
    const CompanyInfoPaths = {
      HOSTEL: "/hostel/company-info",
      HOTEL: "/hotel/company-info",
      HALL: "/hall/company-info",
      TOUR: "/tours-and-travels/company-info",
      CHAMB: "/moving-homes/company-info",
    };
    return CompanyInfoPaths[user.name] || "";
  };
  const getPropertyUser = () => {
    const CompanyUserPaths = {
      HOSTEL: "/hostel/user-management",
      HOTEL: "/hotel/user-management",
      HALL: "/hall/user-management",
      TOUR: "/tours-and-travels/user-management",
      CHAMB: "/moving-homes/user-management",
    };
    return CompanyUserPaths[user.name] || "";
  };
  const getPropertyRooms = () => {
    const PropertyRoomsPaths = {
      HOSTEL: "/hostel/rooms",
      HOTEL: "/hotel/rooms",
      HALL: "/hall/offline-booking",
      TOUR: "/tours-and-travels/tour-packages",
      CHAMB: "/moving-homes/packages",
    };
    return PropertyRoomsPaths[user.name] || "";
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className="App">
        <AlertToast.Provider value={{ alert, setAlert }}>
          <User.Provider value={{ user, setUser }}>
            {/* This css baseline adds the necessary styles, especially text styles, for the page */}
            <CssBaseline />

            {/* the header has positon of fixed so this toolbar offsets everything down */}
            <Toolbar />

            {/* Self explanatory */}
            {user.loggedIn &&
              (user.type === "SUPER_ADMIN" ||
                user.type === "ADMIN" ||
                user.type === "MANAGER" ||
                user.type === "TELE_COM" ? (
                <SidebarAdmin
                  links={currentRoutes}
                  drawerCollapse={drawerWidth}
                  setDrawerCollapse={setDrawerWidth}
                />
              ) : (
                <SidebarPropertyOwner
                  links={currentRoutes}
                  drawerCollapse={drawerWidth}
                  setDrawerCollapse={setDrawerWidth}
                />
              ))}

            {/* This is the overlay container for when the drawer (sidenav) is open in small screens */}
            <Box
              component="div"
              className="overlay"
              sx={{
                "@media only screen and (max-width: 750px)": {
                  position: "fixed",
                  height: "100vh",
                  zIndex: Drawer.zIndex,
                  width: drawerWidth === Drawer.Expanded ? "100vw" : 0,
                  transition: "left 300ms ease",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  top: toolbarHeight,
                  left: drawerWidth === Drawer.Expanded ? Drawer.Expanded : 0,
                },
              }}
            />

            {alert.show && (
              <Alert
                style={{
                  position: "absolute",
                  marginInline: "auto",
                  zIndex: 2000,
                  top: "1.5rem",
                  left: "50%",
                  transform: "translate(-50%, 0)",
                }}
                action={
                  <IconButton
                    size="small"
                    onClick={() => setAlert(alertState({}))}
                  >
                    <Close />
                  </IconButton>
                }
                severity={alert.severity}
              >
                <AlertTitle>{alert.title}</AlertTitle>
                {alert.message}
              </Alert>
            )}
            {/* Basically the main content of the application */}
            <Box
              component="main"
              style={
                user.loggedIn
                  ? {
                    marginLeft: drawerWidth,
                    padding: "4vmin",
                    transition: "margin-left 300ms ease", // animate the main content when drawer toggles
                    backgroundColor: "#F5F7FF", // color picked from the skydash admin website,
                  }
                  : {}
              }
              sx={{
                "@media only screen and (max-width: 750px)": {
                  marginLeft: "0 !important",
                },
              }}
            >
              {user.loggedIn && <Header setDrawerWidth={setDrawerWidth} />}
              <Box
                component={Paper}
                elevation={0}
                style={{ padding: user.loggedIn && "3vmin 4vmin" }}
              >
                {/* The title component is a common heading for the breadcrumbs of all components. This is made possible using the current location path. Beware that this is not there for login and 404 pages. */}
                <Title />
                <Router drawerWidth={drawerWidth} />
              </Box>
            </Box>
            {(user.type === "SUPER_ADMIN" || user.type === "MANAGER") && matches === true ? (
              <Stack maxWidth="sm" fixed style={{position :'sticky', bottom:'0',zIndex:'100'}}>
                <Box>
                  <BottomNavigation
                    sx={{ bgcolor: "aliceblue", maxWidth: 'sm'}}
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                  >
                    <BottomNavigationAction component={Link} to="/dashboard" label="Dashboard" icon={<SpaceDashboardOutlined />} />
                    <BottomNavigationAction component={Link} to="/settings" label="Settings" icon={<SettingsOutlined />} />
                    <BottomNavigationAction component={Link} to="/company-users" label="Users" icon={<AdminPanelSettingsOutlined />} />
                    <BottomNavigationAction onClick={handleRefresh} label="Refresh" icon={<RestoreIcon />} />
                  </BottomNavigation>
                </Box>
              </Stack>) : user.type === "PROPERTY_OWNER" && matches === true ? 

                  <Stack maxWidth="sm" fixed style={{position :'sticky', bottom:'0',zIndex:'100'}}>
                  <Box>
                    <BottomNavigation
                      sx={{ bgcolor: "aliceblue", maxWidth: 'sm' }}
                      showLabels
                      value={value}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                    >
                      <BottomNavigationAction component={Link} to={getPropertyDashboard()} label="Dashboard" icon={<SpaceDashboardOutlined />} />
                      <BottomNavigationAction component={Link} to={getPropertyInfo()} label="Info" icon={<InfoOutlinedIcon />} />
                      <BottomNavigationAction component={Link} to={getPropertyUser()} label="Users" icon={<AdminPanelSettingsOutlined />} />
                      <BottomNavigationAction onClick={handleRefresh} label="Refresh" icon={<RestoreIcon />} />
                    </BottomNavigation>
                  </Box>

                </Stack>:user.type === "STAFF" && matches === true ?
                <Stack maxWidth="sm" fixed style={{position :'sticky', bottom:'0',zIndex:'100'}}>
                 <Box>
                   <BottomNavigation
                     sx={{ bgcolor: "aliceblue", maxWidth: 'sm'}}
                     showLabels
                     value={value}
                     onChange={(event, newValue) => {
                       setValue(newValue);
                     }}
                   >
                     <BottomNavigationAction component={Link} to={getPropertyDashboard()} label="Dashboard" icon={<SpaceDashboardOutlined />} />
                     <BottomNavigationAction component={Link} to={getPropertyInfo()} label="Info" icon={<InfoOutlinedIcon />} />
                     <BottomNavigationAction component={Link} to={getPropertyRooms()} label={user.name === 'HOTEL' || user.name === 'HOSTEL' ? "Rooms": user.name === 'HALL'  ? 'Offline' :'Packages'} icon={<AppsOutlinedIcon />} />
                     <BottomNavigationAction onClick={handleRefresh} label="Refresh" icon={<RestoreIcon />} />
                   </BottomNavigation>
                 </Box>
              </Stack>: null}
          </User.Provider>
        </AlertToast.Provider>
      </Box>
    </ThemeProvider>
  );
}
export default App;
