import { useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import { Drawer as SideNav } from './divine-constants';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { Link } from 'react-router-dom';
 
export default function SidebarAdmin({links, drawerCollapse, setDrawerCollapse}) {
	const [openCollapse, setOpenCollapse] = useState(Array.from({length: 7}).fill(false));
	
	const handleSubMenuToggle = (parentId) => {
		if(parentId === undefined) return;
		setOpenCollapse(curr => curr.map((item, i) => i === parentId ? !item : false));
	}

	function handleMobileNav(link) {
		if((link.children?.length > 0) || window.innerWidth > 750) return;
		setDrawerCollapse(SideNav.Collapsed);
	}

	useEffect(() => {
		setOpenCollapse(
			Array.from({length: openCollapse.length}).fill(false)
		);
	}, [drawerCollapse])

	return (
		<Drawer sx={{
			"& .MuiDrawer-paper": {
			  	width: drawerCollapse,
				"@media only screen and (max-width: 750px)": {
					width: drawerCollapse === SideNav.Expanded ? SideNav.Expanded : 0
				}
			},
		  }}>
		  	<List className="sidebar">
			{links.map((link, i) => {
				return(
				<div key={i}>
					<Link to={link.children?.length === 0 && link.path} onClick={() => {handleMobileNav(link)}}>
						<ListItem>
							<ListItemButton onClick={() => handleSubMenuToggle(link.parentId)}>
								<ListItemIcon>{link.icon}</ListItemIcon>
								<ListItemText className='sidebar-link-text parent' primary={link.name} />
								{link.children && link.children.length > 0 && <ListItemIcon sx={{minWidth: 0}}>{openCollapse[link.parentId] ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>}
							</ListItemButton>
						</ListItem>
					</Link>
					{link.parentId !== undefined && (
						<Collapse in={openCollapse[link.parentId]}>
							<List disablePadding>
								{link.children.map((child, j) => (
									<Link key={j + i} to={link.path + child.path} onClick={() => handleMobileNav(child)}>
										<ListItem>
											<ListItemButton sx={{pl: 4}}>
												<ListItemIcon>{child.icon}</ListItemIcon>
												<ListItemText primary={child.name} />
											</ListItemButton>
										</ListItem>
									</Link>
								))}
							</List>
						</Collapse>
					)}
				</div>)
			})}
		  	</List>
		</Drawer>
	)
	
}