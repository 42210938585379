import { Fragment, useContext, useEffect, useReducer, useState } from "react";
import { TableContainer, Box, Table, TableHead, TableBody, TableRow, Button, Modal, TableCell, TextField, InputAdornment, LinearProgress, IconButton, Collapse, Checkbox, Stack, Divider, Pagination } from "@mui/material";
import { Add, ExpandLess, ExpandMore, Search } from "@mui/icons-material";
import { NestedTitle, StyledTableHeadCell, StyledTableHeader } from "../../../components/Table";
import { AddBank, EditItem, Redbin, ViewItem, Accadded } from "../../../components/Icons";
import HotelListModal from "../../../modals/hotels/HotelListModal";
import { api } from "../../global/apiConfig";
import HotelRoomsModal from "../../../modals/hotels/HotelRoomsModal";
import { AlertToast, noFetch, tableImageHeight, modalReducer } from "../../../divine-constants";
import DeleteModal from "../../../modals/DeleteModal";
import HotelRoomViewModal from "../../../modals/hotels/HotelRoomViewModal";
import HotelEditModal from "../../../modals/hotels/HotelEditModal";
import HotelListViewModal from "../../../modals/hotels/HotelListViewModal";
import HotelPaymentModal from "../../../modals/hotels/HotelPaymentModal";
import NumberDisplay from "../../../components/NumberDisplay";

export default function HotelList() {
	const { setAlert } = useContext(AlertToast);
	const [deleteModal, setDeleteModal] = useState(false);
	const [rows, setRows] = useState([]);
	const [filteredList, setFilteredList] = useState(rows);
	const [noResults, setNoResults] = useState(false);
	const [page, setPage] = useState(0);
	const [totalPage, setTotalPage] = useState()
	const [pageNumber, setPageNumber] = useState(1)
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [cardData, setCardData] = useState(0);
	const [modalOpen, setModalOpen] = useReducer(modalReducer, {
		addNew: false,
		addNewNested: false,
		edit: false,
		view: false,
		addBank: false,
		viewNested: false,
		editNested: false,
		success: false
	});
	const [progress, setProgress] = useState({ display: true, type: "regular" });
	const [clickedRow, setClickedRow] = useState();
	const [nestedClickedRow, setNestedClickedRow] = useState();
	const [Id, setId] = useState({})
	const [nestedRows, setNestedRows] = useState([]);
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;

	const CardsData = async () => {
		api().get('sadmin/get_property_card_count?property_type=hotel').then(res => {
			setCardData(res.data)
		}).catch(() => setAlert(noFetch));
	};

	const fetchData = () => api().get(`sadmin/get_hotel_list?page=${pageNumber}`).then(res => {
		if (res.status === 200) {
			setRows(res.data);
			if (res.data.length != 0) {
				setTotalPage(res.data[0].PageCount)
			}
			setProgress({ display: false, type: "" });
		}
	}).catch(() => setAlert(noFetch));

	useEffect(() => {
		fetchData();
		CardsData();
	}, [pageNumber]);

	useEffect(() => {
		setFilteredList(rows);
	}, [rows]);

	useEffect(() => {
		if (clickedRow) {
			setProgress({ display: true, type: "nested" });
			api().get('sadmin/get_hotel_room_list?hotel_id=' + clickedRow._id).then(res => {
				setNestedRows(res.data);
				setProgress({ display: false, type: "" })
			}).catch(() => setAlert(noFetch));
		}
		if (!clickedRow) setNestedRows([]);
	}, [clickedRow])

	const handlePageChange = (event, value) => {
		setPageNumber(value);
	};

	const handleChange1 = async (id, type) => {
		setFilteredList((prev) => prev.map(items=>{if (items._id == id && items.type === type){
			if (items.active_status == false) {
			return {...items,active_status : true}
			}else{
				return {...items,active_status : false}
			}
		} else{
			return items
		}}))
		api().patch('sadmin/active_property', { property_type: type, property_id: id }).then(res => {
			fetchData()
		}).catch(() => setAlert(noFetch));
	};
	
	const handleChange2 = async (id) => {
		setNestedRows(prev =>prev.map(items=>{if (items.id == id){
			if (items.room_active_status == false) {
			return {...items,room_active_status : true}
			}else{
				return {...items,room_active_status : false}
			}
		} else{
			return items
		}}))
		api().patch('sadmin/hotel_room_active_inactive', {room_id: id,type:"Active" }).then(res => {
		}).catch(() => setAlert(noFetch));
	};

	function handleTableSearch(event) {
		const query = event.target.value
		const next = rows.filter(item => {
			return Object
				.values(item)
				.map(v => typeof v === "string" ? v.toString() : "")
				.some(el => el.toLowerCase().includes(query));
		});
		if (next.length <= 0) setPage(0)
		setFilteredList(next);
		setNoResults(next.length <= 0);
	}

	return (
		<Box component="div">
			<Box className="card-container">
				<NumberDisplay color="text.primary" title="Total Hotels" value={cardData.property_count} />
				<NumberDisplay color="primary.main" title="Total Rooms" value={cardData.room_count} />
			</Box> <br></br>
			<StyledTableHeader>
				<Button onClick={() => setModalOpen({ type: "addNew", to: "open" })}><Add /> Add new</Button>
				<TextField
					label="Search in Table"
					onChange={handleTableSearch}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Search />
							</InputAdornment>
						)
					}}
					InputLabelProps={{ shrink: undefined }}
					required={false}
					helperText={noResults && "No results found"}
					FormHelperTextProps={{
						error: noResults
					}}
				/>
			</StyledTableHeader>
			{progress.display && progress.type === "regular" && <LinearProgress />}
			<TableContainer>
				<Table className="nested-table">
					<TableHead>
						<TableRow>
							<StyledTableHeadCell />
							<StyledTableHeadCell>S.No</StyledTableHeadCell>
							<StyledTableHeadCell>Name</StyledTableHeadCell>
							<StyledTableHeadCell>Image</StyledTableHeadCell>
							<StyledTableHeadCell>Category</StyledTableHeadCell>
							<StyledTableHeadCell>Mobile</StyledTableHeadCell>
							<StyledTableHeadCell>Email</StyledTableHeadCell>
							<StyledTableHeadCell>Total Rooms</StyledTableHeadCell>
							<StyledTableHeadCell>Active Status</StyledTableHeadCell>
							<StyledTableHeadCell>Action</StyledTableHeadCell>
						</TableRow>
					</TableHead>
					<TableBody >
						{rowsPerPage > 0
							&& filteredList.map((row, i) => (<Fragment key={row._id}>
								<TableRow >
									<TableCell><IconButton disabled={progress.display} onClick={() => setClickedRow(current => current?._id === row?._id ? null : row)}>{clickedRow?._id === row._id ? <ExpandLess /> : <ExpandMore />}</IconButton></TableCell>
									<TableCell>{pageNumber != "all" ? i + 1 + ((pageNumber - 1) * rowsPerPage) : i + 1}</TableCell>
									<TableCell>{row.name}</TableCell>
									<TableCell><img height={tableImageHeight} src={row.main_image} alt={row.main_image} /></TableCell>
									<TableCell>{row.category}</TableCell>
									<TableCell>{row.mobile_no}</TableCell>
									<TableCell>{row.email}</TableCell>
									<TableCell>{row.total_rooms}</TableCell>
									<TableCell><Checkbox
										onClick={() => handleChange1(row._id, row.type)}
										defaultChecked color="success"
										checked={row.active_status === true}
										inputProps={{ 'aria-label': 'controlled' }}
									/></TableCell>
									<TableCell>{row.account_details == 0 ? <AddBank onClick={() => { setNestedClickedRow(row); setModalOpen({ type: "addBank", to: "open" }); setId({ hotel_id: row._id }) }} /> : <Accadded />}
										<ViewItem onClick={() => { setNestedClickedRow(row); setModalOpen({ type: "view", to: "open" }) }} />
										<EditItem onClick={() => { setClickedRow(row); setModalOpen({ type: "edit", to: "open" }) }} />
										<Redbin onClick={() => { setClickedRow(row); setDeleteModal(true) }} />
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell colSpan={10}>
										<Collapse in={clickedRow?._id === row._id}>
											{progress.display && progress.type === "nested" && <LinearProgress />}
											<Box>
												<StyledTableHeader style={{ justifyContent: "flex-start" }}>
													<NestedTitle>{row.name} - Room List</NestedTitle>
													<Button variant="outlined" onClick={() => { setModalOpen({ type: "addNewNested", to: "open" }); setId({ hotel_id: row._id }) }}><Add /> Add new</Button>
												</StyledTableHeader>
												<Table>
													<TableHead>
														<TableRow>
															<StyledTableHeadCell>S.No</StyledTableHeadCell>
															<StyledTableHeadCell>Room Type</StyledTableHeadCell>
															<StyledTableHeadCell>Price</StyledTableHeadCell>
															<StyledTableHeadCell>Total Rooms</StyledTableHeadCell>
															<StyledTableHeadCell>Active Status</StyledTableHeadCell>
															<StyledTableHeadCell>Action</StyledTableHeadCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{nestedRows.map((nestedRow, nestInd) =>
															<TableRow key={nestedRow.id}>
																<TableCell>{nestInd + 1}</TableCell>
																<TableCell>{nestedRow.room_type}</TableCell>
																<TableCell>{nestedRow.price}</TableCell>
																<TableCell>{nestedRow.no_of_rooms}</TableCell>
																<TableCell><Checkbox
																	onClick={() => handleChange2(nestedRow.id)}
																	defaultChecked color="success"
																	checked={nestedRow.room_active_status === true}
																	inputProps={{ 'aria-label': 'controlled' }}
																/></TableCell>
																<TableCell>
																	<ViewItem onClick={() => { setNestedClickedRow(nestedRow); setModalOpen({ type: "viewNested", to: "open" }) }} />
																	<EditItem onClick={() => { setNestedClickedRow(nestedRow); setModalOpen({ type: "editNested", to: "open" }); setId({ hotel_id: row._id, type: "edit" }) }} />
																	<Redbin />
																</TableCell>
															</TableRow>
														)}
													</TableBody>
												</Table>
											</Box>
										</Collapse>
									</TableCell>
								</TableRow>
							</Fragment>
							))}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={4} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<br></br>
			<div sx={{ width: "100%" }}>
				<Stack divider={<Divider orientation="vertical" flexItem />}
					direction={{ xs: 'row', sm: 'row' }}
					spacing={{ xs: 2, sm: 2, md: 4 }} sx={{ width: "100%", justifyContent: "center", alignItems: 'center' }}>
					<Pagination
					siblingCount={0}
						count={totalPage}
						color="success"
						onChange={handlePageChange}
					/>
					<Button variant="contained" color="success" onClick={() => setPageNumber("all")}>Show All</Button>
				</Stack>
			</div>
			<Modal
				open={modalOpen.addNew}
				onClose={() => setModalOpen({ type: "addNew", to: "close" })}
				aria-labelledby="hotel list form"
				aria-describedby="insert new record"
			><HotelListModal setModalOpen={setModalOpen} modalOpen={modalOpen} fetchData={fetchData} /></Modal>

			<Modal
				open={modalOpen.addBank}
				onClose={() => setModalOpen({ type: "addBank", to: "close" })}
				aria-labelledby="Add Bank Account form"
				aria-describedby="insert new record"
			><HotelPaymentModal setModalOpen={setModalOpen} fetchData={fetchData} id={Id} /></Modal>

			<Modal
				open={modalOpen.edit}
				onClose={() => setModalOpen({ type: "edit", to: "close" })}
				aria-labelledby="hotel list form"
				aria-describedby="insert new record"
			><HotelEditModal setModalOpen={setModalOpen} modalOpen={modalOpen} fetchData={fetchData} data={clickedRow} /></Modal>

			<Modal
				open={modalOpen.editNested}
				onClose={() => setModalOpen({ type: "editNested", to: "close" })}
				aria-labelledby="hotel list form"
				aria-describedby="insert new record"
			><HotelRoomsModal fetchData={fetchData} setModalOpen={setModalOpen} data={nestedClickedRow} id={Id} /></Modal>
			<Modal
				open={modalOpen.addNewNested}
				onClose={() => setModalOpen({ type: "addNewNested", to: "close" })}
				aria-labelledby="hotel list form"
				aria-describedby="insert new record"
			><HotelRoomsModal fetchData={fetchData} setModalOpen={setModalOpen} id={Id} /></Modal>
			<Modal
				open={modalOpen.viewNested}
				onClose={() => setModalOpen({ type: "viewNested", to: "close" })}
				aria-labelledby="hotel list form"
				aria-describedby="insert new record"
			><HotelRoomViewModal setModalOpen={setModalOpen} data={nestedClickedRow} /></Modal>

			<Modal
				open={modalOpen.view}
				onClose={() => setModalOpen({ type: "view", to: "close" })}
				aria-labelledby="Hotel List"
				aria-describedby="insert new record"
			><HotelListViewModal setModalOpen={setModalOpen} data={nestedClickedRow} /></Modal>

			<DeleteModal dialogDisplay={deleteModal} setDialogDisplay={setDeleteModal} url={"sadmin/delete_hotel"} data={{ hotel_id: clickedRow?._id }} fetchData={fetchData} />
		</Box>
	)
}