import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { forwardRef, useState, useContext } from "react";
import { alertState, AlertToast } from "../divine-constants";
import { api } from "../pages/global/apiConfig";

function DeleteModal({url, setDialogDisplay, dialogDisplay, fetchData, data}) {
	const [progress, setProgress] = useState(false);
	
	const {setAlert} = useContext(AlertToast);
	function handleDelete() {
		setProgress(true);
		api().patch(url, data).then(res => {
			if(res.status === 200) {
				setDialogDisplay(false);
				setAlert(alertState({
					title: "",
					message: "Record deleted successfully",
					severity: "success",
					show: true	
				}))
				fetchData();
			}
		}).catch(() => {
			setAlert(alertState({
				title: "",
				message: "Unable to delete",
				severity: "error",
				show: true	
			}));
		}).finally(() => setProgress(false));
	}
	return <Dialog open={dialogDisplay} disableEnforceFocus keepMounted>
		<DialogTitle color="red">Warning !</DialogTitle>
		<DialogContent>
			<DialogContentText>
				Are you sure you want to delete this record ?<br />
				<br />
				<b>This action is not reversible.</b>
			</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button variant="text" disabled={progress} onClick={handleDelete} color="error"> {progress && <><CircularProgress sx={{float: "right"}} size="16px" />&nbsp;&nbsp;</>} Delete</Button>
			<Button variant="text" disabled={progress} onClick={() => setDialogDisplay(false)} color="warning">Cancel</Button>
		</DialogActions>
	</Dialog>
}

export default forwardRef((props, _ref) => <DeleteModal {...props} />)