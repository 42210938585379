import { forwardRef, useState, useEffect, useContext } from "react";
import { StyledModalPaper } from "../styles/modal";
import { Box, TextField, MenuItem, InputAdornment } from "@mui/material";
import ModalHeader from "./ModalHeader";
import SubmitButton from "../components/FormButton";
import { api } from "../pages/global/apiConfig";
import { AlertToast, noFetch, alertState } from "../divine-constants";
import { Percent } from "@mui/icons-material";

function OffersModal({setModalOpen, reqApi, type, fetchData}) {
	const {setAlert} = useContext(AlertToast);
	const [formData, setFormData] = useState({
		id: "",
		offer: ""
	});
	const [progress, setProgress] = useState(false);
	const [offers, setOffers] = useState([]);
	const getOffers = () => api().get(reqApi).then(res => {
		setOffers(res.data);
	}).catch(() => setAlert(noFetch));

	useEffect(() => {
		getOffers();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	function handleChange(event) {
		setFormData(current => ({...current, [event.target.name]: event.target.value}))
	}

	function handleFormSubmit(event) {
		event.preventDefault();
		setProgress(true);
		api().post("sadmin/add_offers", {
			id: formData.id,
			name: offers.find(el => el._id === formData.id).name,
			offer: formData.offer,
			type: type
		}).then(res => {
			if(res.data.code === 201){
				setModalOpen(false);
				setAlert(alertState({
					title: "",
					message: "Offer added successfully",
					severity: "success",
					show: true
				}));
				fetchData();
			}
		}).catch(err => {
			if(err.status === 401) {
				setAlert(alertState({
					title: "",
					message: "Unauthorized - Cannot add offer",
					severity: "warning",
					show: true
				}))
			} else setAlert(alertState({
				title: "",
				message: "Something went wrong. Please try again later",
				severity: "error",
				show: true
			}))
		}).finally(() => setProgress(false));
	}

	return (
		<StyledModalPaper>
			<ModalHeader setModalOpen={setModalOpen}/>
			<form onSubmit={handleFormSubmit} noValidate>
				<Box component="div" className="form-grid-container two-column-layout">
					<TextField
						label={type}
						select
						name="id"
						value={formData.id}
						onChange={handleChange}
					>
						{offers.map(offer => (
							<MenuItem value={offer._id} key={offer._id}>{offer.name}</MenuItem>
						))}
					</TextField>
					<TextField
						label="Offer"
						name="offer"
						type="number"
						InputProps={{endAdornment: <InputAdornment position="end"><Percent /></InputAdornment>}}
						value={formData.offer}
						onChange={handleChange}
					/>
				</Box>
				<SubmitButton showProgress={progress} />
			</form>
		</StyledModalPaper>
	)
}

export default forwardRef((props, _ref) => <OffersModal {...props} />)