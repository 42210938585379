import { TextField } from "@mui/material";
import { useState, useContext } from "react";
import { alertState, AlertToast } from "../../../divine-constants";
import { api } from "../../global/apiConfig";
import SubmitButton from "../../../components/FormButton";

export default function Agreements({data, setData}) {
	const {setAlert} = useContext(AlertToast);
	const [progress, setProgress] = useState(false);

	function handleSubmit(event) {
		setProgress(true);
		event.preventDefault();
		api().post("sadmin/setting_privacy_policy", {
			privacy: data.privacy_policy.trim(),
			terms: data.terms_condition.trim()
		}).then(res => {
			if(res.data.code === 201) {
				setAlert(alertState({
					title: "",
					message: "Guidelines added successfully",
					severity: "success",
					show: true
				}))
			}
		}).catch(() => {
			setAlert(alertState({
				title: "",
				message: "Something went wrong",
				severity: "error",
				show: true
			}))
		}).finally(() => setProgress(false));
	}
	function handleChange(event) {
		setData(curr => ({...curr, [event.target.name]: event.target.value}))
	}
	return (
		<form onSubmit={handleSubmit}>
			<div className="form-grid-container">
				<TextField 
					label="Privacy policy"
					placeholder="Enter your privacy policy"
					value={data.privacy_policy}
					type="text"
					onChange={handleChange}
					fullWidth
					name="privacy_policy"
					multiline
				/>
				<TextField 
					label="Terms & Conditions"
					placeholder="Enter your conditions"
					value={data.terms_condition}
					type="text"
					onChange={handleChange}
					fullWidth
					name="terms_condition"
					multiline
				/>
			</div>
			<SubmitButton showProgress={progress} type="Update" />
		</form>
	)
}