import { forwardRef, useState, useEffect, useContext } from "react";
import { StyledModalPaper } from "../../styles/modal";
import { Box, TextField, MenuItem, FormControl, InputLabel, FormControlLabel, Switch } from "@mui/material";
import ModalHeader from "../ModalHeader";
import { api } from "../../pages/global/apiConfig";
import SubmitButton from "../../components/FormButton";
import Fieldset from "../../components/Fieldset";
import { AlertToast, alertState, noFetch } from "../../divine-constants";

function ToursEditPartnersModal({setModalOpen, fetchData, data}) {
	const { setAlert } = useContext(AlertToast);
	const [selectArrays, setSelectArrays] = useState({
		locations: [],
		categories: []
	});
	const [progress, setProgress] = useState(false);
	const [areas, setAreas] = useState([]);
	const [formData, setFormData] = useState({
		id:data._id,
		name: data.name,
		date:data.date,
		iFrame: data.iframe,
		pincode: data.pincode,
		city: data.location,
		address: data.address,
		country: data.country,
		state: data.state,
		latitude: data.latitude,
		longitude: data.longitude,
		area: data.area,
		description: data.description,
		policy: data.poilcy,
		mainImg: "",
		otherImgs: [],
		status: data.active_status
		// telNum: "",
		// password: "",
		// username: "",
		// email: ""
	});

	useEffect(() => {
		Promise.all([
			api().get('sadmin/get_city_location'),
			api().get('sadmin/get_category?type=tour')
		]).then(results => {
			setSelectArrays({
				locations: results[0].data,
				categories: results[1].data
			});	
		}).catch(() => setAlert(noFetch));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if(selectArrays.locations.length > 0 && formData.city !== "") {
			api().get('sadmin/get_place_in_city?location_id=' + selectArrays.locations.filter(it => it.location_name === formData.city)[0].id).then(res => {
				setAreas(res.data);
			}).catch(() => setAlert(noFetch));
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData.city])

	function handleText(event) {
		setFormData(current => ({...current, [event.target.name]: event.target.value}));
	}

	function handleMainImg(event) {
		setFormData(current => ({...current, [event.target.name]: event.target.files[0]}));
	}

	function handleOtherImg(event) {
		setFormData(current => ({...current, [event.target.name]: event.target.files}));
	}

	function handleSingleSelect(event) {
		setFormData(current => ({...current, [event.target.name]: event.target.value}));
	}

	function handleFormSubmit(event) {
		setProgress(true);
		event.preventDefault();
		api().patch("sadmin/edit_tours_and_travels", {
			id:formData?.id,
			name: formData.name.trim(),
			category: formData.category,
			iframe: formData.iFrame,
			date: formData.date,
			pincode: formData.pincode,
			country: formData.country,
			state: formData.state,
			location: formData.city,
			area: formData.area,
			address: formData.address.trim(),
			latitude: formData.latitude,
			longitude: formData.longitude,
			subtitle:"null",
			description: formData.description.trim(),
			poilcy: formData.policy.trim(),
			main_image: formData.mainImg,
			other_image: Array.from(formData.otherImgs),
			active_status: formData.status,
			type: "tour",
			subtitle:"null"
			// user_name: formData.username.trim(),
			// email: formData.email,
			// mobile_no: Number(formData.telNum),
			// password: formData.password,
		}).then(res => {
			if(res.data.code === 201) {
				setAlert(alertState({
					message: "Tours And Travels / Partners Updated successfully",
					severity: "success",
					show: true
				}));
				setModalOpen({type: "edit", to: "close"});
				fetchData();
			}
		}).catch(err => {
			if(err.status === 401) {
				setAlert(alertState({
					message: "You are not authorized to add a new room.",
					severity: "warning",
					show: true
				}))
			} else if (err.status === 403) {
				setAlert(alertState({
					message: "You do not have access to add a new room.",
					severity: "warning",
					show: true
				}))
			} else setAlert(alertState({
				message: "Something went wrong. Please try again",
				severity: "error",
				show: true
			}))
		}).finally(() => setProgress(false));
	}


	return (
		<StyledModalPaper>
			<ModalHeader setModalOpen={setModalOpen} type="edit"/>
			<Box component="form" className="list-form" onSubmit={handleFormSubmit} noValidate>
				<Box>
					<Fieldset legend="Tours/Travel details" className="form-grid-container four-column-layout">
						<TextField 
							select 
							label="Category"
							defaultValue={formData.category}
							value={formData.category}
							name="category"
							onChange={handleSingleSelect}
							>
								{selectArrays.categories.map(category => (
									<MenuItem value={category.category_name} key={category.id}>{category.category_name}</MenuItem>
								))}
						</TextField>

						<TextField
							label="Name"
							type="text"
							name="name"
							value={formData.name}
							onChange={handleText}
							placeholder="Tour name"
						/>

						<TextField
							label="iFrame"
							type="text"
							name="iFrame"
							value={formData.iFrame}
							onChange={handleText}
							placeholder="iFrame link"
						/>

						<TextField
							label="Pincode"
							type="text"
							name="pincode"
							value={formData.pincode}
							onChange={handleText}
							placeholder="Eg. 600091"
						/>

						<TextField 
							select 
							label="Country"
							defaultValue={formData.country}
							value={formData.country}
							name="country"
							onChange={handleSingleSelect}
							>
								{[{name: "India", id: 1}].map((it) => (
									<MenuItem value={it.name} key={it.id}>{it.name}</MenuItem>
								))}
						</TextField>	

						<TextField 
							select 
							label="State"
							defaultValue={formData.state}
							value={formData.state}
							name="state"
							onChange={handleSingleSelect}
							>
								{[{name: "Tamil Nadu", id: 1}].map((it) => (
									<MenuItem value={it.name} key={it.id}>{it.name}</MenuItem>
								))}
						</TextField>	

						<TextField 
							select 
							label="City"
							defaultValue={formData.city}
							value={formData.city}
							name="city"
							onChange={handleSingleSelect}
							>
								{selectArrays.locations.map((it) => (
									<MenuItem value={it.location_name} key={it.id}>{it.location_name}</MenuItem>
								))}
						</TextField>	

						<TextField
							select
							label="Area"
							name="area"
							value={formData.area}
							onChange={handleSingleSelect}
						>
							{areas.map((area, i) => (
								<MenuItem value={area} key={i}>{area}</MenuItem>
							))}
						</TextField>

						<TextField
							required={false}
							label="Address"
							type="text"
							name="address"
							value={formData.address}
							onChange={handleText}
							placeholder="Enter address"
						/>
						
						<TextField
							label="Latitude"
							type="number"
							inputProps={{step: 0.00001}}
							name="latitude"
							value={formData.latitude}
							onChange={handleText}
							placeholder="Eg. 21.233511799948"
						/>

						<TextField
							label="Longitude"
							type="text"
							name="longitude"
							value={formData.longitude}
							onChange={handleText}
							placeholder="Eg. -110.640468316400"
						/>

						<TextField
							label="Main Image"
							type="file"
							name="mainImg"
							onChange={handleMainImg}
							placeholder="Input image file"
						/>

						<TextField
							required={false}
							label="Other Images"
							type="file"
							inputProps={{multiple: true}}
							onChange={handleOtherImg}
							name="otherImgs"
						/>
							
						{/* <FormControl className="inline-form-control" required={false}>
							<InputLabel shrink>Status</InputLabel>
							<FormControlLabel 
								label={formData.status ? "Active" : "Inactive"} 
								value={formData.status}
								onChange={e => setFormData(curr => ({...curr, status: e.target.checked}))} 
								checked={formData.status}
								control={<Switch/>} 
							/>
						</FormControl> */}

						<TextField 
							label="Description"
							placeholder="Enter Description..."
							className="big-text"
							name="description"
							value={formData.description}
							onChange={handleText}
							multiline
						/>

						<TextField 
							label="Policy"
							placeholder="Enter Policy..."
							className="big-text"
							name="policy"
							value={formData.policy}
							onChange={handleText}
							multiline
						/>
					</Fieldset>					
				</Box>

				<SubmitButton showProgress={progress} />

			</Box>
		</StyledModalPaper>
	)
}
export default forwardRef((props, _ref) => <ToursEditPartnersModal {...props} />)
