/**
 * 
 * @param {string} str 
 * @returns the string with its first letter in uppercase
 */
export function firstLetterUpper(str) {
    if(str.length <= 0) return "";
    return str.split(' ').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ');
}

/**
 * 
 * @param {string[]} arr 
 */
export function arrayStringify(arr) {
	return '[' + arr.map(m => '"' + m + '"') + ']';
}

/**
 * @param {any[]} items 
 * @param {string} prop 
 */
export function sumProp(items, prop) {
	return items.reduce((a, b) => a + b[prop], 0);
}

/**
  * @param {Object} object
  * @param {string} key
  * @return {any} value
 */
export function getParameterCaseInsensitive(object, key) {
	const asLowercase = key.toLowerCase();
	return object[Object.keys(object)
	  .find(k => k.toLowerCase() === asLowercase)
	];
  }

/**
 * 
 * @param {any[]} array 
 * @param {any} item 
 */
export function uniqueArray(array, item) {
	if(item !== undefined && item !== null)
	array.findIndex(value => value.id === item.id) === -1 && array.push(item);
	return array;
}

/**
 * 
 * @param {Location} location 
 * @returns the text converted to normal english
 */
export function hyphenToNormal(location) {
	let newTitle = location.pathname.split('/');
	newTitle.shift();
	if(newTitle[0] === '404') return [];
	return (newTitle.length <= 0 || newTitle[0].length <= 0) ? 
		["Dashboard"] : 
		newTitle.map(str => str.split('-').map(it => firstLetterUpper(it)).join(' '))
}









