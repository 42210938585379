import {
  Box,
  Button,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  ThemeProvider,
  CircularProgress,
} from "@mui/material";
import { useContext, useState } from "react";
import { KeyOutlined, PersonOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { api } from "./apiConfig";
import { AlertToast, alertState } from "../../divine-constants";
import { moszadTheme } from "./theme";

export default function NewPassword() {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(false);
  const [formData, setFormdata] = useState({
    email: "",
    password: "",
  });
  const [validations, setValidations] = useState({
    email: " ",
    password: " ",
  });
  const { setAlert } = useContext(AlertToast);

  function updateFormData(event) {
    setFormdata((current) => ({
      ...current,
      [event.target.name]: event.target.value,
    }));
    if (Object.values(validations).some((it) => it !== " ")) {
      setValidations((curr) => ({ ...curr, [event.target.name]: " " }));
    }
  }

  function validation() {
    let isValid = true;
    if (formData.email.trim() === "") {
      isValid = false;
      setValidations((curr) => ({ ...curr, email: "Email is required." }));
    } else setValidations((curr) => ({ ...curr, email: " " }));

    if (formData.password.trim() === "") {
      isValid = false;
      setValidations((curr) => ({
        ...curr,
        password: "Password is required.",
      }));
    } else setValidations((curr) => ({ ...curr, password: " " }));
    return isValid;
  }

  async function onSubmit(event) {
    event.preventDefault();
    setProgress(true);
    if (!validation()) {
      setProgress(false);
      return;
    }
  
    await api()
      .post("sadmin/sadmin_password_reset", {
        email: formData.email.trim(),
        password: formData.password.trim(),
      })
      .then((res) => {
        if (res.status == "201") {
          setAlert(
            alertState({
              message: "Password Reset Successfully",
              severity: "success",
              show: true,
            })
          );
          window.location.replace("/Moszad-login");
          localStorage.clear();
        }
      })
      .catch((err) => {
        setAlert(
          alertState({
            message: "Invalid credentials",
            severity: "error",
            show: true,
          })
        );
      })
      .finally(() => setProgress(false));
  }

  return (
    <ThemeProvider theme={moszadTheme}>
      <Box
        component="div"
        style={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          top: 0,
          display: "grid",
          overflow: "auto",
          placeItems: "center",
          zIndex: 1201,
          backgroundColor: "rgb(245, 247, 255)",
        }}
      >
        <Paper
          style={{
            width: "40%",
            minWidth: "275px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginInline: "auto",
            padding: "1rem 2rem 1.5rem",
          }}
          component="form"
          elevation={2}
          onSubmit={onSubmit}
          noValidate
        >
          <h2
            style={{
              fontFamily: "Kalnia",
              width: "50%",
              marginInline: "auto",
              minWidth: 150,
              display: "block",
              color: "#057306",
              textAlign: "center",
            }}
          >
            Moszad
          </h2>
          <Typography
            variant="h4"
            style={{ marginBlockEnd: "1rem", opacity: "0.5" }}
          >
            Reset Your Password!
          </Typography>
          <TextField
            value={formData.email}
            error={Boolean(validations.email.trim())}
            name="email"
            type="text"
            placeholder="Email"
            label="Email"
            color="success"
            onChange={updateFormData}
            helperText={validations.email}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutlined />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            value={formData.password}
            error={Boolean(validations.password.trim())}
            name="password"
            type="password"
            placeholder="Password"
            label="Password"
            color="success"
            onChange={updateFormData}
            helperText={validations.password}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <KeyOutlined />
                </InputAdornment>
              ),
            }}
          />

          <Box
            component="div"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginBottom: "1rem",
            }}
          ></Box>
          <Button
            type="submit"
            disabled={progress}
            style={{
              backgroundColor: "#057306",
              display: "block",
              width: "fit-content",
              placeContent: "end",
              marginLeft: "auto",
            }}
          >
            {"Continue"}{" "}
            {progress && (
              <>
                &nbsp;{" "}
                <CircularProgress
                  color="info"
                  variant="indeterminate"
                  size="16px"
                />
              </>
            )}
          </Button>
          {/* <SubmitButton type="Sign In" showProgress={progress} /> */}
        </Paper>
      </Box>
    </ThemeProvider>
  );
}
