import { forwardRef, useState, useContext } from "react";
import { StyledModalPaper } from "../../styles/modal";
import { Box, TextField } from "@mui/material";
import ModalHeader from "../ModalHeader";
import SubmitButton from "../../components/FormButton";
import { api } from "../../pages/global/apiConfig";
import { alertState, AlertToast } from "../../divine-constants";

function HostelCategoryModal({setModalOpen, fetchData , data, type}) {
	const [formData, setFormData] = useState({
		categoryName:data?data.category_name:"",
		image: undefined
	});
	const [progress, setProgress] = useState(false);
	
	const {setAlert} = useContext(AlertToast);

	function handleCategory(event) {
		setFormData({categoryName: event.target.value})
	}
	function handleImage(event) {
		setFormData(current => ({...current, image: event.target.files[0]}))
	}
		function handleFormSubmit(event) {
		event.preventDefault();
		setProgress(true);
		if(formData.categoryName.trim() === "") return;
		let URL = "sadmin/add_category";

		if(type.type === "edit"){
						URL = "sadmin/edit_category"
			api().patch(URL, {
				category_name: formData.categoryName,
				type: type.category_type,
				cat_id:type.id,
                image:"null"
			}).then(res => {
                				if(res.data.code === 201) {
										setModalOpen(false);
					setAlert(alertState({
						title: "",
						message: "Hotel category added successfully",
						severity: "success",
						show: true
					}));
					fetchData();
				}
			}).catch(err => {
				if(err.status === 401) setAlert(alertState({
						title: "",
						message: "Unauthorized - Cannot add record",
						severity: "warning",
						show: true
					}))
				else setAlert(alertState({
					title: "",
					message: "Something went wrong. Please try again later",
					severity: "error",
					show: true
				}))
			}).finally(() => setProgress(false));
			type.type = "";
			return;
		}
		api().post(URL, {
			category_name: formData.categoryName,
			type: "hostel"
		}).then(res => {
			if(res.data.code === 201) {
				
				setModalOpen(false);
				setAlert(alertState({
					title: "",
					message: "Hostel category added successfully",
					severity: "success",
					show: true
				}));
				fetchData();
			}
		}).catch(err => {
			if(err.status === 401) setAlert(alertState({
					title: "",
					message: "Unauthorized - Cannot add record",
					severity: "warning",
					show: true
				}))
			else setAlert(alertState({
				title: "",
				message: "Something went wrong. Please try again later",
				severity: "error",
				show: true
			}))
		}).finally(() => setProgress(false));
	}
	return (
		<StyledModalPaper>
			<ModalHeader setModalOpen={setModalOpen} edit={type.type}/>
			<Box component="form" onSubmit={handleFormSubmit} noValidate>
				<Box component="div" className="form-grid-container ">
					<TextField
						label="Category Name"
						type="text"
						value={formData.categoryName}
						placeholder="Enter category name"
						onChange={handleCategory}
					/>
					
				</Box>
				<SubmitButton showProgress={progress} />
			</Box>
		</StyledModalPaper>
	)
}

export default forwardRef((props, _ref) => <HostelCategoryModal {...props} />)
