import { Fragment, useContext, useEffect, useReducer, useState } from "react";
import { TableContainer, Box, Table, TableHead, TableBody, TableRow, Button, Modal, TableCell, TablePagination, TextField, InputAdornment, LinearProgress, IconButton, Collapse } from "@mui/material";
import { Add, ExpandLess, ExpandMore, Search } from "@mui/icons-material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { Accadded, AddBank, EditItem, Redbin, ViewItem } from "../../components/Icons";
import { NestedTitle, StyledTableHeadCell, StyledTableHeader } from "../../components/Table";
import { AlertToast, modalReducer, noFetch, tableImageHeight } from "../../divine-constants";
import HotelListModal from "../../modals/hotels/HotelListModal";
import { api } from "../global/apiConfig";


export default function PropertyListChamb() {
	const {setAlert} = useContext(AlertToast);
	const [rows, setRows] = useState([]);
	const [filteredList, setFilteredList] = useState(rows);
	const [deleteModal, setDeleteModal] = useState(false);
	const [noResults, setNoResults] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const [modalOpen, setModalOpen] = useReducer(modalReducer, {
		addNew: false,
		addNewNested: false,
		edit: false,
		view: false,
		addBank:false,
		viewNested: false,
		editNested: false,
		success : false
	});
	const [progress, setProgress] = useState({display: true, type: "regular"});
	const [clickedRow, setClickedRow] = useState(); 
	const [nestedClickedRow, setNestedClickedRow] = useState();
	const [Id,setId] = useState({})
	const [nestedRows, setNestedRows] = useState([]);
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;
	
	const fetchData = () => api().get("property/main_property_list").then(res => {
		if(res.status === 200) {
			setRows(res.data);
			setProgress({display: false, type: ""});
		}
	}).catch(() => setAlert(noFetch));

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		setFilteredList(rows);
	}, [rows]);

	useEffect(() => {
		if(clickedRow) {
			setProgress({display: true, type: "nested"});
			api().get('property/get_hotel_check_availability?property_id=' + clickedRow._id).then(res => {
				console.log(res.data,"P")
				setNestedRows(res.data[0].room_data);
				setProgress({display: false, type: ""})
			}).catch(() => setAlert(noFetch));
		}
		if(!clickedRow) setNestedRows([]);
	}, [clickedRow])
   
	function handlePageChange(_, newPage) {
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(parseInt(event.target.value), 10);
		setPage(0);
	}
	
	function handleTableSearch(event) {
		const query = event.target.value
		const next = rows.filter(item => {
			return Object
			.values(item)
			.map(v => typeof v === "string" ? v.toString() : "")
			.some(el => el.toLowerCase().includes(query));
		});
		if(next.length <= 0) setPage(0)
		setFilteredList(next);
		setNoResults(next.length <= 0);
	}
	return (
		<Box component="div">
			
			{progress.display && progress.type === "regular" && <LinearProgress />}
			<TableContainer>
				<Table className="nested-table">
					<TableHead>
						<TableRow>
							<StyledTableHeadCell/>
							<StyledTableHeadCell>S.No</StyledTableHeadCell>
							<StyledTableHeadCell>Name</StyledTableHeadCell>
							<StyledTableHeadCell>Image</StyledTableHeadCell>
							<StyledTableHeadCell>Category</StyledTableHeadCell>
							<StyledTableHeadCell>Mobile</StyledTableHeadCell>
							<StyledTableHeadCell>Email</StyledTableHeadCell>
						</TableRow>
					</TableHead>

					<TableBody >
						{(rowsPerPage > 0 
							? filteredList.slice(page * rowsPerPage, (page + 1) * rowsPerPage) 
							: filteredList).map((row, i) => (<Fragment key={row._id}>
							<TableRow >
								<TableCell><IconButton disabled={progress.display} onClick={() => setClickedRow(current => current?._id === row?._id ? null : row)}>{clickedRow?._id === row._id ? <ExpandLess /> : <ExpandMore />}</IconButton></TableCell>
								<TableCell>{i + 1 + page*rowsPerPage}</TableCell>
								<TableCell style={{textAlign:"left"}}>{row.name}</TableCell>
								<TableCell><img height={tableImageHeight} src={row.main_image} alt={row.main_image} /></TableCell>
								<TableCell>{row.category}</TableCell>
								<TableCell>{row.mobile_no}</TableCell>
								<TableCell>{row.email}</TableCell>
								
							</TableRow>
							<TableRow>
								<TableCell colSpan={10}>
									<Collapse in={clickedRow?._id === row._id}>
										{progress.display && progress.type === "nested" && <LinearProgress />}
										<Box>
											<StyledTableHeader style={{justifyContent: "flex-start"}}>
												<NestedTitle>{row.name} - Availabe Rooms</NestedTitle>
												{/* <Button variant="outlined" onClick={() => {setModalOpen({type: "addNewNested", to: "open"});setId({hotel_id:row._id})}}><Add /> Add new</Button> */}
											</StyledTableHeader>
											<Table>
												<TableHead>
													<TableRow>
														<StyledTableHeadCell>S.No</StyledTableHeadCell>
														<StyledTableHeadCell>Room Type</StyledTableHeadCell>
														<StyledTableHeadCell>Total Rooms</StyledTableHeadCell>
														<StyledTableHeadCell>Booked Rooms</StyledTableHeadCell>
														<StyledTableHeadCell>Available Rooms</StyledTableHeadCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{nestedRows.map((nestedRow, nestInd) => 
														<TableRow key={nestedRow.id}>
															<TableCell>{nestInd + 1}</TableCell>
															<TableCell>{nestedRow.room_type}</TableCell>
															<TableCell>{nestedRow.total_rooms}</TableCell>
															<TableCell>{nestedRow.booked_rooms}</TableCell>
															<TableCell>{nestedRow.availble_room}</TableCell>
															
														</TableRow>
													)}
												</TableBody>
											</Table>
										</Box>
									</Collapse>
								</TableCell>
							</TableRow>
							</Fragment>
						))}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={4} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[20, 40, {label: "All", value: -1}]}
				colSpan={100}
				count={filteredList.length}
				component="div"
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
			/>
			<Modal 
				open={modalOpen.addNew} 
				onClose={() => setModalOpen({type: "addNew", to: "close"})} 
				aria-labelledby="hotel list form" 
				aria-describedby="insert new record"
			><HotelListModal setModalOpen={setModalOpen} modalOpen={modalOpen} fetchData={fetchData} /></Modal>


			{/* <Modal 
				open={modalOpen.addBank} 
				onClose={() => setModalOpen({type: "addBank", to: "close"})} 
				aria-labelledby="Add Bank Account form" 
				aria-describedby="insert new record"
			><HotelPaymentModal setModalOpen={setModalOpen} fetchData={fetchData} id={Id} /></Modal>

			<Modal 
				open={modalOpen.edit} 
				onClose={() => setModalOpen({type: "edit", to: "close"})} 
				aria-labelledby="hotel list form" 
				aria-describedby="insert new record"
			><HotelEditModal setModalOpen={setModalOpen} modalOpen={modalOpen} fetchData={fetchData} data={clickedRow} /></Modal>
			
			<Modal 
				open={modalOpen.editNested } 
				onClose={() => setModalOpen({type: "editNested", to: "close"})} 
				aria-labelledby="hotel list form" 
				aria-describedby="insert new record"
			><HotelRoomsModal fetchData={fetchData} setModalOpen={setModalOpen} data={nestedClickedRow} id={Id}/></Modal>
			<Modal 
				open={ modalOpen.addNewNested} 
				onClose={() => setModalOpen({type: "addNewNested", to: "close"})} 
				aria-labelledby="hotel list form" 
				aria-describedby="insert new record"
			><HotelRoomsModal fetchData={fetchData} setModalOpen={setModalOpen}  id={Id}/></Modal>
			<Modal 
				open={modalOpen.viewNested} 
				onClose={() => setModalOpen({type: "viewNested", to: "close"})} 
				aria-labelledby="hotel list form" 
				aria-describedby="insert new record"
			><HotelRoomViewModal setModalOpen={setModalOpen} data={nestedClickedRow} /></Modal>

			<Modal 
				open={modalOpen.view} 
				onClose={() => setModalOpen({type: "view", to: "close"})} 
				aria-labelledby="Hotel List" 
				aria-describedby="insert new record"
			><HotelListViewModal setModalOpen={setModalOpen} data={nestedClickedRow} /></Modal>

			<DeleteModal dialogDisplay={deleteModal} setDialogDisplay={setDeleteModal} url={"sadmin/delete_hotel"} data={{hotel_id: clickedRow?._id}} fetchData={fetchData} /> */}
		</Box>
	)
}


