import { useEffect, useState, Fragment, useContext } from "react";
import { TableContainer, Box, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, TextField, InputAdornment, LinearProgress, MenuItem, Collapse, IconButton, AlertTitle, Divider, Stack, Button, Pagination } from "@mui/material";
import { Search, ExpandMore, ExpandLess } from "@mui/icons-material";
import { StyledTableHeadCell, StyledTableHeader } from "../../../components/Table";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { NestedTitle } from "../../../components/Table";
import AdvancedSearch from "../../../components/AdvancedSearch";
import { api } from "../../global/apiConfig";
import NumberDisplay from "../../../components/NumberDisplay";
import { sumProp } from "../../../utils";
import { AlertToast, noFetch } from "../../../divine-constants";

export default function HotelChechAvailability() {
	const { setAlert } = useContext(AlertToast);
	const [rows, setRows] = useState([]);
	const [filteredList, setFilteredList] = useState(rows);
	const [noResults, setNoResults] = useState(false);
	const [page, setPage] = useState(0);
	const [totalPage, setTotalPage] = useState()
	const [pageNumber, setPageNumber] = useState(1)
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const [progress, setProgress] = useState(true);
	const [clickedRow, setClickedRow] = useState();
	const [advancedSearch, setAdvancedSearch] = useState({
		start: "", end: "", hotelName: "",
	});
	const [cardData, setCardData] = useState({});

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;
	const fetchData = (startDate, endDate, name) => {
		const url = `sadmin/get_hotel_booking_available_data?start_date=${startDate}&end_date=${endDate}&property_id=${name}`;
		api().get(url).then(res => {
			if (res.status === 200) {
				if (res.data.length != 0) {
					setTotalPage(res.data.PageCount)
				}
				setRows(res.data.result);
				setCardData(res.data);
				setProgress(false);
			}
		}).catch(() => setAlert(noFetch));
	}

	const fetchInitialData = (startDate, endDate, name) => {
		const url = `sadmin/get_hotel_booking_available_data?start_date=${startDate}&end_date=${endDate}&property_id=${name}&page=${pageNumber}`;
		api().get(url).then(res => {
			if (res.status === 200) {
				if (res.data.length != 0) {
					setTotalPage(res.data.PageCount)
				}
				setRows(res.data.result);
				setCardData(res.data);
				setProgress(false);
			}
		}).catch(() => setAlert(noFetch));
	}

	// useEffect(() => {
	// 	fetchData(advancedSearch.start,advancedSearch.end,advancedSearch.hotelName);
	// }, [advancedSearch.start,advancedSearch.end,advancedSearch.hotelName]);

	useEffect(() => {
		fetchInitialData(advancedSearch.start, advancedSearch.end, advancedSearch.hotelName);
	}, [pageNumber]);

	useEffect(() => {
		setFilteredList(rows);
		setPage(0);
	}, [rows]);

	const handlePageChange = (event, value) => {
		setPageNumber(value);
	}

	function handleAdvancedSearch(event) {
		const query = event.target.value.toLowerCase();
		if (event.target.name == "hotelName" && query === "select") {
			setAdvancedSearch(curr => ({ ...curr, [event.target.name]: "" }));
			fetchData("", "", "");
			return
		} else if (event.target.name == "hotelName" && query != "select") {
			setAdvancedSearch(curr => ({ ...curr, [event.target.name]: event.target.value }))
			fetchData(advancedSearch.start, advancedSearch.end, query);

		} else if (event.target.name == "start" && query != "select" && advancedSearch.hotelName != "select") {
			setAdvancedSearch(curr => ({ ...curr, [event.target.name]: event.target.value }))
			fetchData(query, advancedSearch.end, advancedSearch.hotelName);

		} else if (event.target.name == "end" && query != "select" && advancedSearch.hotelName != "select") {
			setAdvancedSearch(curr => ({ ...curr, [event.target.name]: event.target.value }))
			fetchData(advancedSearch.start, query, advancedSearch.hotelName);
		}
		const next = rows.filter(item => {
			return Object.values(item)
				.map(v => {
					const typeValue = typeof v
					if (typeValue === 'string') {
						return v.toString()
					}
				})
				.some(el => {
					const typeValue = typeof el
					if (typeValue === 'string') {
						return el.toLowerCase().includes(query)
					}
				});
		});
		if (next.length <= 0) setPage(0)
		setFilteredList(next);
		setNoResults(next.length <= 0);
	}

	return (
		<Box component="div">
			<Box className="card-container" style={{ display: "flex", gap: "2vmin", width: "100%", justifyContent: "flex-start" }}>
				<NumberDisplay color="primary.main" title="Total Hotels" value={cardData.total_hotels} />
				<NumberDisplay color="text.main" title="Total Rooms" value={cardData.room_count} />
				<NumberDisplay color="warning.light" title="Booked Rooms" value={cardData.booked} />
				<NumberDisplay color="success.light" title="Available Rooms" value={cardData.available} />
			</Box>
			<br />
			<AdvancedSearch>
				<TextField
					required={false}
					label="Start Date"
					type="date"
					name="start"
					value={advancedSearch.start}
					onChange={handleAdvancedSearch}
					helperText=""
				/>
				<TextField
					required={false}
					label="End Date"
					type="date"
					name="end"
					value={advancedSearch.end}
					onChange={handleAdvancedSearch}
					helperText=""
				/>
				<TextField
					select
					required={false}
					name="hotelName"
					value={advancedSearch.hotelName || ''}
					onChange={handleAdvancedSearch}
					label="Hotel Name"
					helperText=""
				>
					<MenuItem value={"select"}>Select</MenuItem>
					{rows.map((item, ind) => <MenuItem key={ind} value={item.hotel_id}>{item.hotel_name}</MenuItem>)}
				</TextField>
			</AdvancedSearch>
			{progress && <LinearProgress />}
			<TableContainer>
				<Table className="nested-table">
					<TableHead>
						<TableRow>
							<StyledTableHeadCell />
							<StyledTableHeadCell>S.No</StyledTableHeadCell>
							<StyledTableHeadCell>Hotel Name</StyledTableHeadCell>
							<StyledTableHeadCell>Total Rooms</StyledTableHeadCell>
							<StyledTableHeadCell>Booked Rooms</StyledTableHeadCell>
							<StyledTableHeadCell>Available Rooms</StyledTableHeadCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rowsPerPage > 0
							&& filteredList.map((row, ind) => (<Fragment key={row.hotel_id}>
								<TableRow>
									<TableCell><IconButton disabled={progress.display} onClick={() => setClickedRow(current => current?.hotel_id === row?.hotel_id ? null : row)}>{clickedRow?.hotel_id === row.hotel_id ? <ExpandLess /> : <ExpandMore />}</IconButton></TableCell>
									<TableCell>{pageNumber != "all" ? ind + 1 + ((pageNumber - 1) * rowsPerPage) : ind + 1}</TableCell>
									<TableCell>{row.hotel_name}</TableCell>
									<TableCell>{row.total_rooms}</TableCell>
									<TableCell>{row.booked_rooms}</TableCell>
									<TableCell>{sumProp(row.room_data, 'availble_room')}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell colSpan={10}>
										<Collapse in={clickedRow?.hotel_id === row.hotel_id}>
											{progress.display && progress.type === "nested" && <LinearProgress />}
											<NestedTitle>{row.hotel_name} - Room List</NestedTitle>
											<Box>
												<Table>
													<TableHead>
														<TableRow>
															<StyledTableHeadCell>Room Type</StyledTableHeadCell>
															<StyledTableHeadCell>Total Rooms</StyledTableHeadCell>
															<StyledTableHeadCell>Booked Rooms</StyledTableHeadCell>
															<StyledTableHeadCell>Available Rooms</StyledTableHeadCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{row.room_data.map((nestedRow, nestInd) =>
															<TableRow key={nestInd}>
																<TableCell style={{ paddingBlock: 5 }}>{nestedRow.room_type}</TableCell>
																<TableCell>{nestedRow.total_rooms}</TableCell>
																<TableCell>{nestedRow.booked_rooms}</TableCell>
																<TableCell>{nestedRow.availble_room}</TableCell>
															</TableRow>
														)}
													</TableBody>
												</Table>
											</Box>
										</Collapse>
									</TableCell>
								</TableRow>
							</Fragment>))}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={4} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<br></br>
			<div sx={{ width: "100%" }}>
				<Stack divider={<Divider orientation="vertical" flexItem />}
					direction={{ xs: 'row', sm: 'row' }}
					spacing={{ xs: 1, sm: 2, md: 4 }} sx={{ width: "100%", justifyContent: "center", alignItems: 'center' }}>
					<Pagination
						count={totalPage}
						siblingCount={0}
						color="success"
						onChange={handlePageChange}
					/>
					<Button variant="contained" color="success" onClick={() => setPageNumber("all")}>Show All</Button>
				</Stack>
			</div>
		</Box>
	)
}