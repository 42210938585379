import { forwardRef } from "react";
import { StyledModalPaper } from "../../styles/modal";
import {
  Box,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import ModalHeader from "../ModalHeader";
import Fieldset from "../../components/Fieldset";
import { Clear, Done } from "@mui/icons-material";

function ViewUserModel({ setModalOpen, data }) {
    return (
    <StyledModalPaper>
      <ModalHeader
        title="Agent details"
        setModalOpen={setModalOpen}
        type="view"
      />
      <Box
        sx={{
          display: "grid",
          width: "100%",
          gap: "1rem",
          "& > fieldset": {
            width: "fit-content",
            flexGrow: 1,
            display: "grid",
            gap: "1rem",
            justifyContent: "space-around",
            pb: 1,
          },
          "@media only screen and (max-width: 750px)": {
            gridTemplateColumns: "1fr",
          },
        }}

      >

    <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1}>
    <Chip  label={
        data.status
            ? "Status - Active"
            : "Status - Inactive"
        }
        color={data.status ? "success" : "error"}
    />  </Stack>
  
<Box>
    <Typography component="span" fontWeight={600}>
    Agent Name :{" "}
    </Typography>
    {data.name}
</Box>
     <Box>
        <Typography component="span" fontWeight={600}>
        Email :{" "}
        </Typography>
        {data.email}
</Box>
<Box>
    <Typography component="span" fontWeight={600}>
    Mobile No :{" "}
    </Typography>
    {data.name}
</Box>
<Box>
    <Typography component="span" fontWeight={600}>
    Role :{" "}
    </Typography>
    {data.role}
</Box>
  </Box>

    </StyledModalPaper>
  );
}

export default forwardRef((props, _ref) => <ViewUserModel {...props} />);
