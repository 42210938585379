import { forwardRef, useState, useContext } from "react";
import { StyledModalPaper } from "../styles/modal";
import { MenuItem, TextField, InputAdornment } from "@mui/material";
import { PercentOutlined } from "@mui/icons-material";
import { api } from "../pages/global/apiConfig";
import { AlertToast, alertState } from "../divine-constants";
import SubmitButton from "../components/FormButton";
import ModalHeader from "./ModalHeader";

function GSTModal({setModalOpen, fetchData}) {
	const [formData, setFormData] = useState({
		type: "",
		min: "",
		max: "",
		gst: ""
	});
	const {setAlert} = useContext(AlertToast);
	const [progress, setProgress] = useState(false);
	function handleSubmit(event) {
		setProgress(true);
		event.preventDefault();
		api().post("sadmin/add_gst", {
			type: formData.type,
			min_value: formData.type === "hotel" ? formData.min : '',
			max_value: formData.type === "hotel" ? formData.min : '',
			gst_percentage: formData.gst
		}).then(res => {
			if(res.data.code === 201) {
				setAlert(alertState({
					title: "",
					message: "GST record added successfully",
					severity: "success",
					show: true
				}));
				fetchData();
				setModalOpen({type: "addNew", to: "close"});
			}
		}).catch(() => {
			setAlert(alertState({
				title: "",
				message: "Something went wrong",
				severity: "error",
				show: true
			}))
		}).finally(() => setProgress(false));
	}
	function handleChange(event) {
		setFormData(curr => ({...curr, [event.target.name]: event.target.value}))
	}
	return (
		<StyledModalPaper>
			<ModalHeader setModalOpen={setModalOpen} />
			<form onSubmit={handleSubmit}>
				<div className="form-grid-container two-column-layout">
					<TextField 
						select
						label="User type"
						type="text"
						name="type"
						value={formData.type}
						onChange={handleChange}
					>
					{["hotel", "hostel", "hall", "tour"].map((el, i) => (
						<MenuItem key={i} value={el}>{el}</MenuItem>
					))}
					</TextField>
					<TextField
						name="min"
						type="number"
						label="Min value"
						disabled={formData.type !== "hotel"}
						placeholder="Minimum GST value"
						FormHelperTextProps={{
							error: formData.type === "hotel"
						}}
						helperText={formData.type === "hotel" ? " " : "Only applicable for type - Hotel"}
						value={formData.min}
						onChange={handleChange}
					/>
					<TextField
						name="max"
						type="number"
						label="Max value"
						disabled={formData.type !== "hotel"}
						placeholder="Maximum GST value"
						FormHelperTextProps={{
							error: formData.type === "hotel"
						}}
						helperText={formData.type === "hotel" ? " " : "Only applicable for type - Hotel"}
						value={formData.max}
						onChange={handleChange}
					/>
					<TextField
						name="gst"
						type="number"
						label="GST value"
						value={formData.gst}
						placeholder="GST percentage"
						InputProps={{
							endAdornment: <InputAdornment position="end"><PercentOutlined /></InputAdornment>
						}}
						onChange={handleChange}
					/>
				</div>
				<SubmitButton showProgress={progress} type="Update" />
			</form>
		</StyledModalPaper>
	)
}

export default forwardRef((props, _ref) => <GSTModal {...props} />)