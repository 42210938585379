import { Collapse, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Drawer as SideNav, User } from './divine-constants';
import { Link } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';

export default function SidebarPropertyOwner({links, drawerCollapse, setDrawerCollapse}) {
	const { user} = useContext(User);
	const [dropDown, setDropDown] = useState(false);
	const [active, setActive] = useState(0);
	useEffect(() => {
		if(drawerCollapse === SideNav.Collapsed) setDropDown(false);
	}, [drawerCollapse])
	console.log(links,user,"link")
	return (
		<Drawer sx={{
			"& .MuiDrawer-paper": {
			  	width: drawerCollapse,
				"@media only screen and (max-width: 750px)": {
					width: drawerCollapse === SideNav.Expanded ? SideNav.Expanded : 0
				},
			},
		  }}>
		  	<List className="sidebar">
			{links.slice(0, links.length - 1).map((link, i) => (
			  <Link to={link.path} key={i} onClick={() => {setDrawerCollapse(SideNav.Collapsed); setActive(i)}} className={active === i ? "active" : ""}>
					<ListItem>
						<ListItemButton>
							<ListItemIcon>{link.icon}</ListItemIcon>
							<ListItemText className='sidebar-link-text parent' primary={link.name} />
						</ListItemButton>
					</ListItem>
				</Link>
			))}
				<ListItem>
					<ListItemButton onClick={() => setDropDown(c => !c)}>
						<ListItemIcon>{links.at(-1)?.icon}</ListItemIcon>
						<ListItemText className='sidebar-link-text parent' primary={links.at(-1)?.name} />
						<ListItemIcon sx={{minWidth: 0}}>{dropDown ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
					</ListItemButton>
				</ListItem>
				<Collapse in={dropDown}>
					<List disablePadding>
						{ links.at(-1)?.children.map((link, i) =>
							<Link to={link.path} key={i} onClick={() => setDrawerCollapse(SideNav.Collapsed)}>
								<ListItem>
									<ListItemButton sx={{pl: 4}}>
										<ListItemIcon>{link.icon}</ListItemIcon>
										<ListItemText primary={link.name} />
									</ListItemButton>
								</ListItem>
							</Link>
						)}
					</List>
				</Collapse>
		  	</List>
		</Drawer>
	)
}