import { useEffect, useReducer, useState } from "react";
import { TableContainer, Box, Table, TableHead, TableBody, TableRow, Button, Modal, TableCell, TablePagination, TextField, InputAdornment, LinearProgress } from "@mui/material";
import { Add, Search } from "@mui/icons-material";
import { StyledTableHeadCell, StyledTableHeader } from "../../components/Table";
import { EditItem, Redbin, ViewItem } from "../../components/Icons";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { api } from "../global/apiConfig";
import HotelRoomsModal from "../../modals/hotels/HotelRoomsModal";
import { modalReducer, tableImageHeight } from "../../divine-constants";
import HotelRoomViewModal from "../../modals/hotels/HotelRoomViewModal";

export default function Rooms() {
	const [progress, setProgress] = useState(true);
    const [nestedClickedRow, setNestedClickedRow] = useState();
	const [modalOpen, setModalOpen] = useReducer(modalReducer, {
	
		viewNested: false,
	
	});
	const [rows, setRows] = useState([]);
	const [filteredList, setFilteredList] = useState(rows);	
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;
    const id = localStorage.getItem("UserId");
	console.log(id,"id")
	const fetchData = () => api().get(`property/get_hotel_room_list?hotel_id=${id}` ).then(res => {
		if(res.status === 200) {
			setRows(res.data);	
			setProgress(false)	
		}
	})
	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => setFilteredList(rows), [rows]);


	function handlePageChange(event, newPage) {	
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(parseInt(event.target.value), 10);
		setPage(0);
	}

	return (
		<Box component="div">
			{progress && <LinearProgress />}
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<StyledTableHeadCell>S.No</StyledTableHeadCell>
							<StyledTableHeadCell>Room Type</StyledTableHeadCell>
							<StyledTableHeadCell>Image</StyledTableHeadCell>
							<StyledTableHeadCell>Price </StyledTableHeadCell>
							<StyledTableHeadCell>Total rooms</StyledTableHeadCell>
							<StyledTableHeadCell>Action</StyledTableHeadCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0 
							? filteredList.slice(page * rowsPerPage, (page + 1) * rowsPerPage) 
							: filteredList).map((row, i) => (
							<TableRow key={row.id}>
								<TableCell>{i + 1}</TableCell>
								<TableCell>{row.room_type}</TableCell>
	
								<TableCell><img height={tableImageHeight} src={row.room_image[0]} alt={row.room_image[0]} /></TableCell>
								<TableCell>{row.price}</TableCell>
								<TableCell>{row.no_of_rooms}</TableCell>
								<TableCell>
								<ViewItem onClick={() => {setNestedClickedRow(row); setModalOpen({type: "viewNested", to: "open"})}}/>
								</TableCell>
							</TableRow>
						))}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={4} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 20, {label: "All", value: -1}]}
				colSpan={100}
				count={filteredList.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
			/>
			 

      <Modal 
				open={modalOpen.viewNested} 
				onClose={() => setModalOpen({type: "viewNested", to: "close"})} 
				aria-labelledby="hotel list form" 
				aria-describedby="insert new record"
			><HotelRoomViewModal setModalOpen={setModalOpen} data={nestedClickedRow} /></Modal>
		</Box>
	)
}