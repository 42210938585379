import { Fragment, useContext, useEffect, useReducer, useState } from "react";
import { TableContainer, Box, Table, TableHead, TableBody, TableRow, Button, Modal, TableCell, TablePagination, TextField, InputAdornment, LinearProgress, IconButton, Collapse } from "@mui/material";
import { Add, ExpandLess, ExpandMore, Search } from "@mui/icons-material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { Accadded, AddBank, EditItem, Redbin, ViewItem } from "../../components/Icons";
import { NestedTitle, StyledTableHeadCell, StyledTableHeader } from "../../components/Table";
import { AlertToast, modalReducer, noFetch, tableImageHeight } from "../../divine-constants";
import HotelListModal from "../../modals/hotels/HotelListModal";
import { api } from "../global/apiConfig";
import AddPropertyUserModel from "../../modals/Property User/AddPropertyUserModel";
import DeleteModal from "../../modals/DeleteModal";
import EditPropertyUserModel from "../../modals/Property User/EditPropertyUserModel";


export default function PropertyListChamb() {
	const {setAlert} = useContext(AlertToast);
	const [rows, setRows] = useState([]);
	const [filteredList, setFilteredList] = useState(rows);
	const [deleteModal, setDeleteModal] = useState(false);
	const [noResults, setNoResults] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const [modalOpen, setModalOpen] = useReducer(modalReducer, {
		addNew: false,
		addNewNested: false,
		edit: false,
		view: false,
		addBank:false,
		viewNested: false,
		editNested: false,
		success : false
	});
	const [progress, setProgress] = useState({display: true, type: "regular"});
	const [clickedRow, setClickedRow] = useState(); 
	const [nestedClickedRow, setNestedClickedRow] = useState();
	const [Id,setId] = useState({})
	const [nestedRows, setNestedRows] = useState([]);
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;
	
	const fetchData = () => api().get("property/main_property_list").then(res => {
		if(res.status === 200) {
			setRows(res.data);
			setProgress({display: false, type: ""});
		}
	}).catch(() => setAlert(noFetch));

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		setFilteredList(rows);
	}, [rows]);

	useEffect(() => {
		if(clickedRow) {
			setProgress({display: true, type: "nested"});
			api().get('property/get_property_user?property_id=' + clickedRow._id).then(res => {
				console.log(res.data,"P")
				setNestedRows(res.data);
				setProgress({display: false, type: ""})
			}).catch(() => setAlert(noFetch));
		}
		if(!clickedRow) setNestedRows([]);
	}, [clickedRow])
   
	function handlePageChange(_, newPage) {
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(parseInt(event.target.value), 10);
		setPage(0);
	}
	
	function handleTableSearch(event) {
		const query = event.target.value
		const next = rows.filter(item => {
			return Object
			.values(item)
			.map(v => typeof v === "string" ? v.toString() : "")
			.some(el => el.toLowerCase().includes(query));
		});
		if(next.length <= 0) setPage(0)
		setFilteredList(next);
		setNoResults(next.length <= 0);
	}
	return (
		<Box component="div">
			{progress.display && progress.type === "regular" && <LinearProgress />}
			<TableContainer>
				<Table className="nested-table">
					<TableHead>
						<TableRow>
							<StyledTableHeadCell/>
							<StyledTableHeadCell>S.No</StyledTableHeadCell>
							<StyledTableHeadCell>Name</StyledTableHeadCell>
							<StyledTableHeadCell>Image</StyledTableHeadCell>
							<StyledTableHeadCell>Location</StyledTableHeadCell>
							<StyledTableHeadCell>Mobile</StyledTableHeadCell>
							<StyledTableHeadCell>Email</StyledTableHeadCell>
						</TableRow>
					</TableHead>

					<TableBody >
						{(rowsPerPage > 0 
							? filteredList.slice(page * rowsPerPage, (page + 1) * rowsPerPage) 
							: filteredList).map((row, i) => (<Fragment key={row._id}>
							<TableRow >
								<TableCell><IconButton disabled={progress.display} onClick={() => setClickedRow(current => current?._id === row?._id ? null : row)}>{clickedRow?._id === row._id ? <ExpandLess /> : <ExpandMore />}</IconButton></TableCell>
								<TableCell>{i + 1 + page*rowsPerPage}</TableCell>
								<TableCell>{row.name}</TableCell>
								<TableCell><img height={tableImageHeight} src={row.main_image} alt={row.main_image} /></TableCell>
								<TableCell>{row.location}</TableCell>
								<TableCell>{row.mobile_no}</TableCell>
								<TableCell>{row.email}</TableCell>
								
							</TableRow>
							<TableRow>
								<TableCell colSpan={10}>
									<Collapse in={clickedRow?._id === row._id}>
										{progress.display && progress.type === "nested" && <LinearProgress />}
										<Box>
											<StyledTableHeader style={{justifyContent: "flex-start"}}>
												<NestedTitle>{row.name} - Active Staff's</NestedTitle>
												<Button variant="outlined" onClick={() => {setModalOpen({type: "addNewNested", to: "open"});setId({tour_id:row._id})}}><Add /> Add Staff</Button>
											</StyledTableHeader>
											<Table>
												<TableHead>
													<TableRow>
														<StyledTableHeadCell>S.No</StyledTableHeadCell>
														<StyledTableHeadCell>Name</StyledTableHeadCell>
														<StyledTableHeadCell>Role</StyledTableHeadCell>
														<StyledTableHeadCell>Email</StyledTableHeadCell>
														<StyledTableHeadCell>Contact</StyledTableHeadCell>
														<StyledTableHeadCell>Access ID</StyledTableHeadCell>
														<StyledTableHeadCell>Action</StyledTableHeadCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{nestedRows.map((nestedRow, nestInd) => 
														<TableRow key={nestedRow.id}>
															<TableCell>{nestInd + 1}</TableCell>
															<TableCell>{nestedRow.name}</TableCell>
															<TableCell>{nestedRow.role}</TableCell>
															<TableCell>{nestedRow.email}</TableCell>
															<TableCell>{nestedRow.mobile_no}</TableCell>
															<TableCell>{nestedRow.access_id}</TableCell>
                              <TableCell>
                              <EditItem onClick={() => {setNestedClickedRow(nestedRow); setModalOpen({type: "editNested", to: "open"});setId({user_id:row._id,type:"edit"})}} />
                                  <Redbin
                                    onClick={() => {
                                      setNestedClickedRow(nestedRow);
                                      setDeleteModal(true);
                                    }}
                                  />
                                </TableCell>
															
														</TableRow>
													)}
												</TableBody>
											</Table>
										</Box>
									</Collapse>
								</TableCell>
							</TableRow>
							</Fragment>
						))}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={4} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[20, 40, {label: "All", value: -1}]}
				colSpan={100}
				count={filteredList.length}
				component="div"
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
			/>

			<Modal 
				open={ modalOpen.addNewNested} 
				onClose={() => setModalOpen({type: "addNewNested", to: "close"})} 
				aria-labelledby="hotel list form" 
				aria-describedby="insert new record"
			><AddPropertyUserModel fetchData={fetchData} setModalOpen={setModalOpen}  id={Id.tour_id}/></Modal>

<Modal 
				open={modalOpen.editNested } 
				onClose={() => setModalOpen({type: "editNested", to: "close"})} 
				aria-labelledby="hostel list form" 
				aria-describedby="insert new record"
			><EditPropertyUserModel fetchData={fetchData} setModalOpen={setModalOpen} data={nestedClickedRow} id={Id}/></Modal>

			<DeleteModal dialogDisplay={deleteModal} setDialogDisplay={setDeleteModal} url={"property/delete_property_user"} data={{user_id: nestedClickedRow?._id}} fetchData={fetchData} />

		</Box>
	)
}


