import { Paper, styled } from "@mui/material"

export const StyledModalPaper = styled(Paper)(({theme}) => ({
	position: "absolute",
	top: "50%",
	left: "50%",
	display: "flex",
	flexDirection: "column",
	transform: "translate(-50%, -50%)",
	maxHeight: "90vh",
	maxWidth: "95vmax",
	width: "80%",
	minWidth: "250px",
	overflowY: "auto",
	paddingInline: "1rem",
	paddingBottom: "1rem",
}))