import { useContext, useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../../../components/TabPanel";
import RefundPolicy from "./RefundPolicy";
import GstTab from "./GstTab";
import AboutUs from "./AboutUsTab";
import ContactTab from "./ContactTab";
import Agreements from "./Agreements";
import { api } from "../../global/apiConfig";
import { AlertToast, noFetch } from "../../../divine-constants";

export default function Settings() {
	const [page, setPage] = useState(0);
	const {setAlert} = useContext(AlertToast);
	const [data, setData] = useState({
		"id": "",
        "website": "",
        "mobile": "",
        "email": "",
        "map": "",
        "about_us": "",
        "privacy_policy": "",
        "terms_condition": "",
        "refund_cancellation": ""
	})
	function handlePageChange(e, next) {
		setPage(next);
	}
	const fetchData = () => api().get("sadmin/get_setting_data").then(res => {
		if(res.status === 200)
			setData(res.data[0]);
	}).catch(() => setAlert(noFetch));
	useEffect(() => {
		fetchData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<Tabs value={page} onChange={handlePageChange} aria-label="settings page tabs">
				<Tab label="Contact details" />
				<Tab label="About Us" />
				<Tab label="Agreements" />
				<Tab label="Refund Policy" />
				<Tab label="GST" />
			</Tabs>
			<TabPanel value={page} index={0} children={<ContactTab data={data} setData={setData} />} />
			<TabPanel value={page} index={1} children={<AboutUs data={data} setData={setData} />} />
			<TabPanel value={page} index={2} children={<Agreements data={data} setData={setData} />} />
			<TabPanel value={page} index={3} children={<RefundPolicy data={data} setData={setData} />} />
			<TabPanel value={page} index={4} children={<GstTab />} />
		</>
	)
}