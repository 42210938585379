import { Box, Button, FormControl, FormControlLabel, InputAdornment, Paper, TextField, Typography, Checkbox, Link, ThemeProvider, CircularProgress } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { KeyOutlined, PersonOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { api } from "./apiConfig";
import { AlertToast, User, alertState } from "../../divine-constants";
import { moszadTheme } from "./theme";
export default function MoszadLogin({drawerWidth}) {
	// eslint-disable-next-line no-unused-vars
	const {user, setUser} = useContext(User);
	const navigate = useNavigate();
	const [progress, setProgress] = useState(false);
	const [formData, setFormdata] = useState({
		email: "",
		password: "",
		remember: false
	});
	const [validations, setValidations] = useState({
		email: " ",
		password: " ",
	})

	const {setAlert} = useContext(AlertToast);

	function updateFormData(event) {
		setFormdata(current => ({...current, [event.target.name]: event.target.value}))
		if(Object.values(validations).some(it => it !== " ")) {
			setValidations(curr => ({...curr, [event.target.name]: " "}));
		}
	}

	useEffect(() => {
		if(user.loggedIn) return navigate("/");
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	function validation() {
		let isValid = true;
		if (formData.email.trim() === "") {
			isValid = false;
			setValidations(curr => ({...curr, email: "Email is required."}))
		} else setValidations(curr => ({...curr, email: " "}))
		
		if (formData.password.trim() === "") {
			isValid = false;
			setValidations(curr => ({...curr, password: "Password is required."}))
		} else setValidations(curr => ({...curr, password: " "}))
		return isValid;
	}

	async function onSubmit(event) {
		event.preventDefault();
		setProgress(true);
		if(!validation()) {
			setProgress(false);
			return;
		};
		await api().post("sadmin/super_admin_login", {
			email: formData.email.trim(),
			password: formData.password.trim()
		}).then(res => {
			if(res.data.message === "Login Successful") {
				setAlert(alertState({
					message: "Successfully logged in",
					severity: "success",
					show: true
				}))
				localStorage.setItem("token", res.data.data);
				localStorage.setItem("UserId", res.data.user_id);
				localStorage.setItem("username", formData.email);
				localStorage.setItem("userType", res.data.role.toUpperCase());
				localStorage.setItem("userCategory", res.data.property_name.toUpperCase());
				setUser({
					username: formData.email,
					type: res.data.role.toUpperCase(),
					name:res.data.property_name.toUpperCase(),
					loggedIn: true
				});			
				// navigate("/",{ state: { token : res.data.data} });
				window.location.replace("/")
			}
		}).catch(err => {
			setAlert(alertState({
				message: "Invalid credentials",
				severity: "error",
				show: true
			}));
		}).finally(() => setProgress(false));
	}

	  
 	return (
		<ThemeProvider theme={moszadTheme}>
			<Box component="div" style={{
				width: "100vw",
				height: "100vh",
				position: "fixed",
				top: 0,
				display: "grid",
				overflow: "auto",
				marginLeft: user.loggedIn && `calc(-8vmin - ${drawerWidth}px)`,
				placeItems: "center",
				zIndex: 1201,
				backgroundColor: "rgb(245, 247, 255)"
			}}>
				<Paper style={{
					width: "40%",
					minWidth: "275px",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					marginInline: "auto",
					padding: "1rem 2rem 1.5rem",
				}} component="form" elevation={2} onSubmit={onSubmit} noValidate>

					<h2 style ={{fontFamily : 'Kalnia',width: "50%",
							marginInline: "auto",
							minWidth: 150,
							display: "block",
							color :"#057306",
							}}>Moszad M-REP</h2>
					{/* <img 
						src="/logo-primary.png" 
						alt="logo" 
						style={{
							width: "50%",
							marginInline: "auto",
							minWidth: 150,
							display: "block"
						}} 
					/> */}
					<Typography variant="h4" style={{marginBlockEnd: "1rem", opacity: "0.5"}}>Login to continue.</Typography>
					<TextField 
						value={formData.email}
						error={Boolean(validations.email.trim())}
						name="email"
						type="text"
						placeholder="Email"
						label="Email"
						color = "success"
						onChange={updateFormData}
						helperText={validations.email}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<PersonOutlined />
								</InputAdornment>
							)
						}}
					/>
					<TextField
						value={formData.password}
						error={Boolean(validations.password.trim())}
						name="password"
						type="password"
						placeholder="Password"
						label="Password"
						color = "success"
						onChange={updateFormData}
						helperText={validations.password}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<KeyOutlined />
								</InputAdornment>
							)
						}}
					/>
					<Box component="div" style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						flexWrap: "wrap",
						marginBottom: "1rem"
					}}>
						<FormControl>
							<FormControlLabel name="remember" control={<Checkbox color = "success" checked={formData.remember} onChange={e => setFormdata(current => ({...current, remember: e.target.checked}))}/>} label="Remember me" />
						</FormControl>
						<span style={{cursor: "pointer", color:"black"}} onClick={() => {navigate("/Moszad-forgot-password")}}>Forgot password ?</span>

						{/* <Link style={{cursor: "pointer", color:"black"}}>Forgot password ? </Link> */}
					</Box>
					<Button type="submit" disabled={progress} style={{backgroundColor : "#057306", display: "block", width:"fit-content",placeContent: "end",marginLeft:"auto"}} >
				{"Sign In"} {progress && <>&nbsp; <CircularProgress color="info" variant="indeterminate" size="16px" /></>}
			</Button>

					{/* <SubmitButton type="Sign In" showProgress={progress} /> */}
				</Paper>
			</Box>
		</ThemeProvider>
	)
}