import { forwardRef, useState, useEffect, useContext } from "react";
import { StyledModalPaper } from "../../styles/modal";
import {
  Box,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  ListItemText,
  Switch,
  InputLabel,
  InputAdornment,
  FormControl,
} from "@mui/material";
import ModalHeader from "../ModalHeader";
import SubmitButton from "../../components/FormButton";
import { noFetch, AlertToast, alertState } from "../../divine-constants";
import { CurrencyRupee, PercentOutlined } from "@mui/icons-material";
import { api } from "../../pages/global/apiConfig";
import Fieldset from "../../components/Fieldset";
import { arrayStringify } from "../../utils";

function AddPropertyUserModel({ setModalOpen, fetchData,id}) {
    const { setAlert } = useContext(AlertToast);
    const [areas, setAreas] = useState([]);
    const [progress, setProgress] = useState(false);
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      mobile_no: "",
      role: "",
      property_id: id,
      property_name: localStorage.getItem('userCategory').toLowerCase(),
      status: false,
    });
  
   console.log(id,"f") 
    function handleText(event) {
      setFormData((current) => ({
        ...current,
        [event.target.name]: event.target.value,
      }));
    }
  
    function handleFormSubmit(event) {
          event.preventDefault();
  
      setProgress(true);
      api()
        .post("property/add_property_user", {
          name: formData.name.trim(),
          email: formData.email.trim(),
          mobile_no: formData.mobile_no,
          role: formData.role,
          property_id : formData.property_id,
          property_name : formData.property_name,
          status: true,
        })
        .then((res) => {
          if (res.data.code === 201) {
                      setAlert(
              alertState({
                message: "Company User added successfully",
                severity: "success",
                show: true,
              })
            );
            setModalOpen({ type: "addNewNested", to: "close" });
            fetchData();
          }
        })
        .catch((err) => {
          if (err.status === 401) {
            setAlert(
              alertState({
                message: "You are not authorized to add a new Agent.",
                severity: "warning",
                show: true,
              })
            );
          } else if (err.status === 403) {
            setAlert(
              alertState({
                message: "You do not have access to add a new agent.",
                severity: "warning",
                show: true,
              })
            );
          } else
            setAlert(
              alertState({
                message: "Something went wrong. Please try again",
                severity: "error",
                show: true,
              })
            );
        })
        .finally(() => setProgress(false));
    }
  
    return (
      <StyledModalPaper>
        <ModalHeader setModalOpen={setModalOpen} type="addNewNested" />
        <Box
          component="form"
          className="list-form"
          onSubmit={handleFormSubmit}
          noValidate
        >
          <Box>
            <Fieldset className="form-grid-container four-column-layout">
            <TextField
                label="Name"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleText}
                placeholder="Enter Name"
              />
              <TextField
                label="Email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleText}
                placeholder="Enter Email"
              />
  
              <TextField
                label="Mobile Number"
                type="text"
                name="mobile_no"
                value={formData.mobile_no}
                onChange={handleText}
                placeholder="+91"
              />
  
            <TextField 
            select 
            label="USer Type"
            defaultValue={formData.role}
            value={formData.role}
            name="user_type"
            onChange={(e)=>{setFormData({...formData,role: e.target.value})}}
            >
                <MenuItem value="staff">Staff</MenuItem>
        </TextField>
 
              <FormControl className="inline-form-control" required={false}>
                <InputLabel shrink>Status</InputLabel>
                <FormControlLabel
                  label={formData.status ? "Active" : "Inactive"}
                  value={formData.status}
                  onChange={(e) =>
                    setFormData((curr) => ({ ...curr, status: e.target.checked }))
                  }
                  checked={formData.status}
                  control={<Switch />}
                />{" "}
              </FormControl>
            </Fieldset>
          </Box>
          <SubmitButton showProgress={progress} />
        </Box>
      </StyledModalPaper>
    );
  }
  export default forwardRef((props, _ref) => <AddPropertyUserModel {...props} />);
  