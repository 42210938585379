import { useEffect, useState } from "react";
import { Box, TextField, MenuItem, Checkbox, ListItemText, FormControlLabel, Switch, FormControl, InputLabel, Card, CardActionArea, CardContent, CardHeader, Typography, Grid, List, ListItem, ListItemAvatar } from "@mui/material";
import styles from "./Companyinfo.module.css"
import { Property_URL } from "../global/apiConfig";

export default function CompanyInfo() {
	const [formData, setFormData] = useState({});

  useEffect(() => {    
    let token = localStorage.getItem("token");
    let property_id = localStorage.getItem("UserId");
    async function Callfunction() {
      let response = await fetch(
        `${Property_URL}property/main_property_list?property_type=tour`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let res = await response.json();      
      setFormData(res[0]); 
    }
    Callfunction();
  }, []);

  return(

    <> 
    <Box className={styles.CardStyle} >
    <Card className={styles.CardDataStyle} >
          <CardActionArea>
           <CardHeader   title={ <Typography variant="h3" display="block">Tour Details</Typography>}></CardHeader>
        <CardContent sx={{ paddingTop:0}} >
        <Typography variant="button" display="block" fontWeight={600} gutterBottom>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: 1,
            m: 1,
  
          }}
        >
         <Box>Name</Box>       
         <Box>{formData?.name}</Box>
        </Box>
        
        </Typography>
        <Typography variant="button" display="block" fontWeight={600} gutterBottom>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: 1,
            m: 1,
  
          }}
        >
         
         <Box> Email </Box>       
         <Box>{formData?.email}</Box>
        </Box>
        
        </Typography>
        <Typography variant="button" display="block" fontWeight={600} gutterBottom >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: 1,
            m: 1,
  
          }}
        >
         <Box> Mobile No </Box>       
         <Box>{formData?.mobile_no}</Box>
        </Box>     
        </Typography>    
        </CardContent>
        </CardActionArea>
       
      </Card>    
    
    <Card className={styles.CardDataStyle} >
    <CardActionArea>
           <CardHeader   title={ <Typography variant="h3" display="block">Address</Typography>}></CardHeader>
        <CardContent sx={{ paddingTop:0}} >
        <Typography variant="button" display="block" fontWeight={600} gutterBottom>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: 1,
            m: 1,
  
          }}
        >
         <Box> Address </Box>       
         <Box>{formData?.address}</Box>
        </Box> 
        
        </Typography>
        <Typography variant="button" display="block" fontWeight={600} gutterBottom>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: 1,
            m: 1,
  
          }}
        >
         <Box>  Area  </Box>       
         <Box>{formData?.area}</Box>
        </Box> 
       
        </Typography>
        <Typography variant="button" display="block" fontWeight={600} gutterBottom>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: 1,
            m: 1,
  
          }}
        >
         <Box>  State  </Box>       
         <Box>{formData?.state} - {formData?.pincode}</Box>
        </Box> 
      
        </Typography>
        <Typography variant="button" display="block" fontWeight={600} gutterBottom >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: 1,
            m: 1,
  
          }}
        >
         <Box>  Country  </Box>       
         <Box>{formData?.country}</Box>
        </Box> 
       
        
        </Typography>    
        </CardContent>
        </CardActionArea>
      </Card>    
    </Box>  
        <Box sx={{ paddingTop:1}}>
    <Card  >
          <CardActionArea>
           <CardHeader  title={<Typography variant="h3" display="block">Main Image</Typography>}></CardHeader>
        <CardContent sx={{ paddingTop:0}}>
        <img
            src={formData?.main_image}
            style={{ maxWidth: "35%", width: "45%" }}
            srcSet={formData?.main_image}
            alt="main_Image"
            loading="lazy"
          />
        </CardContent>
        </CardActionArea>
      </Card>  
      </Box> 
    <Box sx={{ paddingTop:1}}>
    <Card  >
          <CardActionArea>
           <CardHeader  title={<Typography variant="h3" display="block">Other Image</Typography>}></CardHeader>
        <CardContent style={{ paddingTop:0, paddingBottom: 16, display: "flex", flexWrap: "wrap", gap: 4 }}>
        {formData?.other_image && formData?.other_image.map((image, ind) => (
            <img
              key={ind}
              src={image}
              style={{ maxWidth: "25%", width: "35%"  }}
              srcSet={image}
              alt={image}
              loading="lazy"
            />
          ))}
        </CardContent>
        </CardActionArea>
      </Card>  
      </Box> 
    <Box sx={{ paddingTop:1}}>
    <Card  >
          <CardActionArea>
           <CardHeader  title={<Typography variant="h3" display="block">Map View</Typography>}></CardHeader>
        <CardContent sx={{ paddingTop:0}}>
        <Box component="span">
             <iframe src={formData?.iframe}></iframe>
            </Box>
        {/* <Box
              component="div"            
              dangerouslySetInnerHTML={{ __html: formData?.iframe }}
            ></Box> */}
        </CardContent>
        </CardActionArea>
      </Card>  
      </Box> 
    <Box sx={{ paddingTop:1}}>
    <Card  >
          <CardActionArea>
           <CardHeader  title={<Typography variant="h3" display="block">Description</Typography>}></CardHeader>
        <CardContent variant="body2" sx={{ paddingTop:0}}>
          {formData?.description}
        </CardContent>
        </CardActionArea>
      </Card>  
      </Box> 
    <Box sx={{ paddingTop:1}}>
    <Card  >
          <CardActionArea>
           <CardHeader  title={<Typography variant="h3" display="block">Policy</Typography>}></CardHeader>
        <CardContent variant="body2" sx={{ paddingTop:0}}>
        {formData?.poilcy}
        </CardContent>
        </CardActionArea>
      </Card>  
      </Box> 
    </>
    )
	
}
