import { TextField } from "@mui/material";
import { useContext, useState } from "react";
import SubmitButton from "../../../components/FormButton";
import { api } from "../../global/apiConfig";
import { AlertToast, alertState } from "../../../divine-constants";

export default function ContactTab({data, setData}) {
	const {setAlert} = useContext(AlertToast);
	const [progress, setProgress] = useState(false);
	function handleSubmit(event) {
		setProgress(true);
		event.preventDefault();
		api().post("sadmin/contact_details_moszad", {
			website: data.website.trim(),
			mobile: data.mobile,
			email: data.email.trim(),
			map: data.map.trim()
		}).then(res => {
			if(res.data.code === 201) {
				setAlert(alertState({
					title: "",
					message: "Contact details added successfully",
					severity: "success",
					show: true
				}))
			}
		}).catch(() => {
			setAlert(alertState({
				title: "",
				message: "Something went wrong",
				severity: "error",
				show: true
			}))
		}).finally(() => setProgress(false));
	}

	function handleChange(event) {
		setData(current => ({...current, [event.target.name]: event.target.value}))
	}

	return (
		<form onSubmit={handleSubmit} noValidate>
			<div className="form-grid-container two-column-layout">
				<TextField
					label="Website"
					placeholder="eg. www.example.com"
					name="website"
					value={data.website}
					onChange={handleChange}
					type="text"
				/>
				<TextField 
					label="Mobile No."
					placeholder="eg. +91 1234567890"
					name="mobile"
					value={data.mobile}
					onChange={handleChange}
					type="tel"
				/>
				<TextField 
					label="Email"
					placeholder="eg. example@gmail.com"
					name="email"
					value={data.email}
					onChange={handleChange}
					type="email"
				/>
				<TextField 
					label="Map"
					placeholder="Enter iframe"
					name="map"
					value={data.map}
					onChange={handleChange}
					type="text"
				/>
			</div>
			<SubmitButton showProgress={progress} type="Update" />
		</form>
	)
}