import { forwardRef, useState, useContext } from "react";
import { StyledModalPaper } from "../styles/modal";
import { MenuItem, FormControl, TextField, FormControlLabel, Switch, Box } from "@mui/material";
import { api } from "../pages/global/apiConfig";
import ModalHeader from "./ModalHeader";
import SubmitButton from "../components/FormButton";
import { imageTypes, AlertToast, alertState } from "../divine-constants";

function AmenitiesModal({setModalOpen, fetchData}) {
	const amenitiesOptions = [
		{displayName: "Hotel", value: "hotel"},
		{displayName: "Hostel", value: "hostel"},
		{displayName: "Halls", value: "halls"},
		{displayName: "Tours & Travels", value: "toursAndTravels"},
		{displayName: "Moving Homes", value: "movingHomes"},
	];
	const [formData, setFormData] = useState({
		amenities_name: "",
		type: "",
		image: undefined,
		highlight_status: false
	});

	const [progress, setProgress] = useState(false);
	
	const {setAlert} = useContext(AlertToast);

	const [validations, setValidations] = useState({
		amenities_name: " ",
		type: " ",
		image: " "
	});

	function handleHighlight() {
		setFormData(current => {
			return {...current, highlight_status: !current.highlight}
		})
	}

	function handleAmenities(event) {
		setFormData(current => {
			return {...current, amenities_name: event.target.value}
		});
		setValidations(curr => ({...curr, amenities_name: ""}));
	}

	function handleType(event) {
		setFormData(current => {
			return {...current, type: event.target.value}
		});
		setValidations(curr => ({...curr, type: ""}));
	}

	function handleImage(event) {
		setFormData(current => {
			return {...current, image: event.target.files[0]}
		});
		setValidations(curr => ({...curr, image: ""}));
	}

	function validate() {
		setProgress(true);
		let isValid = true;
		if(formData.amenities_name === "") {
			isValid = false;
			setValidations(curr => ({...curr, amenities_name: "Name is required"}))
		}
		if(formData.type === "") {
			isValid = false;
			setValidations(curr => ({...curr, type: "Type is required"}))
		}
		if(formData.image === undefined || formData.image === null) {
			isValid = false;
			setValidations(curr => ({...curr, image: "Image is required"}))
		} else if(!imageTypes.includes(formData.image?.type)) {
			isValid = false;
			setValidations(curr => ({...curr, image: "Invalid image file type"}))
		}
		setProgress(false);
		return isValid;
	}

	function handleFormSubmit(event) {
		event.preventDefault();
		if(!validate()) return;
		setProgress(true);
		api().post("sadmin/add_amenities", {
			amenities_name: formData.amenities_name.trim(),
			type: formData.type,
			image: formData.image,
			highlight_status: formData.highlight_status
		}).then(res => {
			if(res.data.code === 201){
				
				setModalOpen(false);
				setAlert(alertState({
					title: "",
					message: "Amenity added successfully",
					severity: "success",
					show: true
				}));
				fetchData();
			}
		}).catch(err => {
			if(err.status === 401) {
				setAlert(alertState({
					title: "",
					message: "Unauthorized - Cannot add record",
					severity: "warning",
					show: true
				}))
			} else setAlert(alertState({
				title: "",
				message: "Something went wrong. Please try again later",
				severity: "error",
				show: true
			}))
		}).finally(() => setProgress(false));
	}

	return (
	<>
		{/* <Snackbar color="green" open autoHideDuration={5000} message="action" action={
			<Fragment><IconButton><CloseOutlined color="success" fontSize="small" /></IconButton></Fragment>
		}>
		</Snackbar> */}
		<StyledModalPaper>
			<ModalHeader setModalOpen={setModalOpen} type="addNew" />
			<Box component="form" noValidate onSubmit={handleFormSubmit}>
				<Box component="div" className="form-grid-container two-column-layout">
					<TextField
						type="text"
						label="Amenities"
						className="amenities"
						placeholder="Enter Amenities"
						value={formData.amenities_name}
						helperText={validations.amenities_name}
						error={Boolean(validations.amenities_name.trim())}
						onChange={handleAmenities}
					/>
					<TextField
						select
						label="Type"
						className="type"
						onChange={handleType}
						value={formData.type}
						helperText={validations.type}
						error={Boolean(validations.type.trim())}
					>
						{amenitiesOptions.map((option, index) => (
							<MenuItem key={index} value={option.value}>{option.displayName}</MenuItem>
						))}
					</TextField>
					<TextField
						type="file"
						label="Image"
						onChange={handleImage}
						helperText={validations.image}
						error={Boolean(validations.image.trim())}
					/>
					<FormControl>
						<FormControlLabel label="Highlight" control={
							<Switch color="primary" checked={formData.highlight_status} onChange={handleHighlight}/>}
						/>
					</FormControl>
				</Box>
				<SubmitButton showProgress={progress} />
			</Box>
		</StyledModalPaper>
	</>
	)
}

//Caveat with strict mode
export default forwardRef((props, _ref) => <AmenitiesModal {...props}/>);