import { forwardRef, useState, useEffect, useContext } from "react";
import { StyledModalPaper } from "../../styles/modal";
import { Box, TextField, MenuItem, ListItemText, Checkbox, FormControl, InputLabel, FormControlLabel, Switch } from "@mui/material";
import ModalHeader from "../ModalHeader";
import { api } from "../../pages/global/apiConfig";
import SubmitButton from "../../components/FormButton";
import Fieldset from "../../components/Fieldset";
import { AlertToast, alertState, noFetch } from "../../divine-constants";

function HostelEditModal({setModalOpen, fetchData, data}) {
    	const { setAlert } = useContext(AlertToast);
	const [selectArrays, setSelectArrays] = useState({
		locations: [],
		amenities: [],
		categories: []
	});
	const [progress, setProgress] = useState(false);
	const [areas, setAreas] = useState([]);
	const [formData, setFormData] = useState({
        id :data._id,
		name: data.name,
		category: data.category,
		city: data.location,
		country: data.country,
		state: data.state,
		amenities: [],
		iFrame: data.iFrame,
		gender: data.gender,
		address: data.address,
		latitude: data.latitude,
		longitude: data.longitude,
		area: data.area,
		description: data.hostel_description,
		policy: data.hostel_poilcy,
		pincode: data.pincode,
		mainImg: "",
		otherImgs:"",
		status: data.status,
		telNum: data.telNum
		// password: data.,
		// username: data.,
		// email:data.
	});

	useEffect(() => {
		Promise.all([
			api().get('sadmin/get_city_location'),
			api().get('sadmin/get_amenities'),
			api().get('sadmin/get_category?type=hostel')
		]).then(results => {
			setSelectArrays({
				locations: results[0].data,
				amenities: results[1].data,
				categories: results[2].data
			});	
		}).catch(() => setAlert(noFetch));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if(selectArrays.locations.length > 0 && formData.city !== "") {
			api().get('sadmin/get_place_in_city?location_id=' + selectArrays.locations.filter(it => it.location_name === formData.city)[0].id).then(res => {
				setAreas(res.data);
			}).catch(() => setAlert(noFetch));
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData.city])

	function handleText(event) {
		setFormData(current => ({...current, [event.target.name]: event.target.value}));
	}

	function handleMainImg(event) {
		setFormData(current => ({...current, [event.target.name]: event.target.files[0]}));
	}

	function handleOtherImg(event) {
		setFormData(current => ({...current, [event.target.name]: event.target.files}));
	}

	function handleSingleSelect(event) {
		setFormData(current => ({...current, [event.target.name]: event.target.value}));
	}
	
	function handleMultiSelect(event) {
		const {
			target: {value}, 
		} = event;
		setFormData(current => ({...current, amenities: value}))
	}

	function handleFormSubmit(event) {
        		setProgress(true);
		event.preventDefault();
		api().patch("sadmin/edit_hostel", {
            id:formData.id,
			name: formData.name.trim(),
			category: formData.category,
			country: formData.country,
			state: formData.state,
			location: formData.city,
			area: formData.area,
			address: formData.address.trim(),
			gender: formData.gender,
			subtitle: "",
			hostel_description: formData.description.trim(),
			hostel_poilcy: formData.policy.trim(),
			hostel_amentities: '[' + formData.amenities.map(it => '"' + it + '"').join(',') + ']',
			pincode: formData.pincode,
			main_image: formData.mainImg,
			other_image: Array.from(formData.otherImgs),
			active_status: formData.status,
			type: "hostel",
            subtitle:"null"
			// user_name: formData.username.trim(),
			// email: formData.email,
			// mobile_no: Number(formData.telNum),
			// password: formData.password,
		}).then(res => {
			if(res.data.code === 201) {
				setAlert(alertState({
					message: "Hostel Edited successfully",
					severity: "success",
					show: true
				}));
				setModalOpen({type: "edit", to: "close"});
				fetchData();
			}
		}).catch(err => {
			if(err.status === 401) {
				setAlert(alertState({
					message: "You are not authorized to add a new room.",
					severity: "warning",
					show: true
				}))
			} else if (err.status === 403) {
				setAlert(alertState({
					message: "You do not have access to add a new room.",
					severity: "warning",
					show: true
				}))
			} else setAlert(alertState({
				message: "Something went wrong. Please try again",
				severity: "error",
				show: true
			}))
		}).finally(() => setProgress(false));
	}


	return (
		<StyledModalPaper>
			<ModalHeader setModalOpen={setModalOpen} type="edit"/>
			<Box component="form" className="list-form" onSubmit={handleFormSubmit} noValidate>
				<Box>
					<Fieldset legend="Hostel details" className="form-grid-container four-column-layout">
						<TextField 
							select 
							label="Category"
							defaultValue={formData.category}
							value={formData.category}
							name="category"
							onChange={handleSingleSelect}
							>
								{selectArrays.categories.map(category => (
									<MenuItem value={category.category_name} key={category.id}>{category.category_name}</MenuItem>
								))}
						</TextField>

						<TextField
							label="Name"
							type="text"
							name="name"
							value={formData.name}
							onChange={handleText}
							placeholder="Hostel name"
						/>

						<TextField
							required={false}
							label="Embedded iFrame"
							type="text"
							name="iFrame"
							value={formData.iFrame}
							onChange={handleText}
							placeholder="Enter iFrame link"
						/>
												
						<TextField 
							select 
							label="Gender"
							defaultValue={formData.gender}
							value={formData.gender}
							name="gender"
							onChange={handleSingleSelect}
							>
								{["Male", "Female"].map((gender, i) => (
									<MenuItem value={gender} key={i}>{gender}</MenuItem>
								))}
						</TextField>

						<TextField 
							select 
							label="Country"
							defaultValue={formData.country}
							value={formData.country}
							name="country"
							onChange={handleSingleSelect}
							>
								{[{name: "India", id: 1}].map((it) => (
									<MenuItem value={it.name} key={it.id}>{it.name}</MenuItem>
								))}
						</TextField>	

						<TextField 
							select 
							label="State"
							defaultValue={formData.state}
							value={formData.state}
							name="state"
							onChange={handleSingleSelect}
							>
								{[{name: "Tamil Nadu", id: 1}].map((it) => (
									<MenuItem value={it.name} key={it.id}>{it.name}</MenuItem>
								))}
						</TextField>	

						<TextField 
							select 
							label="City"
							defaultValue={formData.city}
							value={formData.city}
							name="city"
							onChange={handleSingleSelect}
							>
								{selectArrays.locations.map((it) => (
									<MenuItem value={it.location_name} key={it.id}>{it.location_name}</MenuItem>
								))}
						</TextField>	

						<TextField
							select
							label="Area"
							name="area"
							value={formData.area}
							onChange={handleSingleSelect}
						>
							{areas.map((area, i) => (
								<MenuItem value={area} key={i}>{area}</MenuItem>
							))}
						</TextField>

						<TextField
							required={false}
							label="Address"
							type="text"
							name="address"
							value={formData.address}
							onChange={handleText}
							placeholder="Enter address"
						/>
						
						<TextField
							label="Latitude"
							type="number"
							inputProps={{step: 0.00001}}
							name="latitude"
							value={formData.latitude}
							onChange={handleText}
							placeholder="Eg. 21.233511799948"
						/>

						<TextField
							label="Longitude"
							type="text"
							name="longitude"
							value={formData.longitude}
							onChange={handleText}
							placeholder="Eg. -110.640468316400"
						/>

						<TextField
							label="Amenities"
							select
							SelectProps={{multiple: true, renderValue: selected => selected.map(s => selectArrays.amenities.find(v => v.id === s).amenities_name) + ', '}}
							value={formData.amenities}
							onChange={handleMultiSelect}
							name="amenities"
							placeholder="Select Amenities"
						>
							{selectArrays.amenities.map(option => (
								<MenuItem									
									key={option.id}
									value={option.id}
								>
									<Checkbox checked={formData.amenities.indexOf(option.id) > -1} />
									<ListItemText>{option.amenities_name}</ListItemText>
								</MenuItem>
							))}
						</TextField>

						<TextField 
							label="Pincode"
							type="text"
							name="pincode"
							value={formData.pincode}
							onChange={handleText}
							placeholder="Enter pincode"
						/>

						<TextField
							label="Main Image"
							type="file"
							name="mainImg"
							onChange={handleMainImg}
							placeholder="Input image file"
						/>

						<TextField
							required={false}
							label="Other Images"
							type="file"
							inputProps={{multiple: true}}
							onChange={handleOtherImg}
							name="otherImgs"
						/>
							
						{/* <FormControl className="inline-form-control" required={false}>
							<InputLabel shrink>Status</InputLabel>
							<FormControlLabel 
								label={formData.status ? "Active" : "Inactive"} 
								value={formData.status}
								onChange={e => setFormData(curr => ({...curr, status: e.target.checked}))} 
								checked={formData.status}
								control={<Switch/>} 
							/>
						</FormControl> */}

						<TextField 
							label="Description"
							placeholder="Enter Description..."
							className="big-text"
							name="description"
							value={formData.description}
							onChange={handleText}
							multiline
						/>

						<TextField 
							label="Policy"
							placeholder="Enter Policy..."
							className="big-text"
							name="policy"
							value={formData.policy}
							onChange={handleText}
							multiline
						/>
					</Fieldset>

					{/* <Fieldset legend="User details" className="form-grid-container four-column-layout">
						<TextField
							label="Mobile"
							type="tel"
							inputMode="tel"
							name="telNum"
							onChange={handleText}
							value={formData.telNum}
							placeholder="Enter mobile number"
						/>

						<TextField
							label="Password"
							type="password"
							name="password"
							onChange={handleText}
							value={formData.password}
							placeholder="Enter password"
						/>
						
						<TextField
							label="Username"
							type="text"
							name="username"
							value={formData.username}
							onChange={handleText}
							placeholder="Enter username"
						/>

						<TextField
							label="Email"
							type="email"
							name="email"
							value={formData.email}
							onChange={handleText}
							placeholder="Enter email"
						/>
					</Fieldset> */}
				</Box>

				<SubmitButton showProgress={progress} />

			</Box>
		</StyledModalPaper>
	)
}
export default forwardRef((props, _ref) => <HostelEditModal {...props} />)
