import { Card, CardContent, CardHeader, Typography } from "@mui/material";

export default function NumberDisplay({title, value, color, subheader}) {
	return (
		<Card sx={{borderBottom: 5, borderColor: color, display: "flex", flexDirection: "column", flexBasis: "100%"}}>
			<CardHeader title={<Typography variant="h4">{title}</Typography>} subheader={subheader} />
			<CardContent sx={{pt: 0, mt: "auto"}}>
				<Typography color={color} variant="h2">{value}</Typography>
			</CardContent>
		</Card>
	)
}