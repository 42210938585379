import { forwardRef, useState, useContext } from "react";
import { StyledModalPaper } from "../styles/modal";
import { Box, FormControl, FormControlLabel, InputLabel, MenuItem, Switch, TextField } from "@mui/material";
import ModalHeader from "./ModalHeader";
import SubmitButton from "../components/FormButton";
import { api } from "../pages/global/apiConfig";
import { AlertToast, ShowAlert, alertState } from "../divine-constants";
import { Label } from "@mui/icons-material";

function BannersModal({setModalOpen, fetchData}) {
	const [formData, setFormData] = useState({
		name: "",
		type: "",
		webImage: "",
		mobileImage: "",
		value :"",
		status: false
	});
	const [progress, setProgress] = useState(false);
	
	const {setAlert} = useContext(AlertToast);

	function handleFormSubmit(event) {
		setProgress(true);
		event.preventDefault();
		api().post("sadmin/add_banners", {
			banner_name: formData.name,
			mobile_image: formData.mobileImage,
			website_image: formData.webImage,
			type: formData.type,
			value : formData.value,
			status: formData.status
		}).then(res => {
			if(res.data.code === 201){
				
				setModalOpen(false);
				setAlert(alertState({
					title: "",
					message: "Banner added successfully",
					severity: "success",
					show: true
				}));
				fetchData();
			}
		}).catch(err => {
			if(err.status === 401) {
				setAlert(alertState({
					title: "",
					message: "Unauthorized - Cannot add record",
					severity: "warning",
					show: true
				}))
			} else setAlert(alertState({
				title: "",
				message: "Something went wrong. Please try again later",
				severity: "error",
				show: true
			}))
		}).finally(() => setProgress(false));
	}


	function handleImage(event) {
		setFormData(current => {
			return {...current, [event.target.name]: event.target.files[0]}
		})
	}

	function handleText(event) {
		setFormData(curr => ({...curr, [event.target.name]: event.target.value}));
	}

	return (
		<StyledModalPaper>
			<ModalHeader setModalOpen={setModalOpen}/>
			<Box component="form" onSubmit={handleFormSubmit} noValidate>
				<Box component="div" className="form-grid-container two-column-layout">
					<TextField
						label="Name"
						type="text"
						name="name"
						value={formData.name}
						onChange={handleText}
					/>

					<TextField
						select
						label="Type"
						value={formData.type}
						onChange={handleText}
						name="type"
					>
						<MenuItem value="banner">Banner</MenuItem>
						<MenuItem value="offer">Offer</MenuItem>
					</TextField>

					{formData.type=="offer"?<TextField
						label="Value"
						type="text"
						name="value"
						value={formData.value}
						onChange={handleText} 
					/>:null}

					<TextField
						label="Website Image"
						type="file"
						name="webImage"
						onChange={handleImage}
					/>

					<TextField
						label="Mobile Image"
						type="file"
						name="mobileImage"
						onChange={handleImage}
					/>

					<FormControl required={false}>
						<InputLabel shrink>Status</InputLabel>
						<FormControlLabel label={formData.status ? "Active" : "Inactive"} onChange={e => setFormData(curr => ({...curr, status: e.target.checked}))} control={<Switch checked={formData.status} />} />
					</FormControl>
				</Box>
				<SubmitButton showProgress={progress} />
			</Box>
		</StyledModalPaper>
	)
}

export default forwardRef((props, _ref) => <BannersModal {...props} />)