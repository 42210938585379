import axios from "axios";

// export const domain = "http://127.0.0.1:8000/";
// export const Property_URL ="http://127.0.0.1:8000/";
// export const domain = "http://51.20.156.85:8000/";
// export const Property_URL ="http://51.20.156.85:8000/";
export const production = "https://admin.moszadhotels.com/";
export const Property_URL ="https://admin.moszadhotels.com/";
axios.defaults.baseURL = production;
axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
axios.defaults.headers.common["Accept"] = "application/json";
// axios.defaults.headers.common["Authorization"] = "Bearer "+ localStorage.getItem("token");

axios.defaults.timeout = 30000;

const instance =  axios.create({
	headers: {
		"Content-Type": "multipart/form-data",
		"common": {
			Authorization: "Bearer " + localStorage.getItem("token")
		}
	}
});

instance.interceptors.request.use(config => {
	return config;
}, error => Promise.reject(error));
instance.interceptors.response.use(config => config, error => {
	if(error.code === "ECONNABORTED" && error.message.includes("timeout")) {
		console.error("Request Timed out !")
		return Promise.reject(error);
	}
});

export const api = () => instance;