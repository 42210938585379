import { useContext, useState } from "react";
import { AppBar, Toolbar, Menu, MenuItem, IconButton, Box, Paper, Avatar, Badge } from "@mui/material";
import { Logout, Add, Notifications, Person, MenuOutlined } from "@mui/icons-material";
import { Drawer, User } from "./divine-constants";
import { useNavigate } from "react-router-dom";

export default function Header({setDrawerWidth}) {
	const [adminAnchorEl, setAdminAnchorEl] = useState(null);
	const openAdminAnchor = Boolean(adminAnchorEl);
	const closeAdminMenu = () => {setAdminAnchorEl(null)}
	const openAdminMenu = event => {setAdminAnchorEl(event.currentTarget)}
  const navigate = useNavigate();
  const { user, setUser } = useContext(User);
  function handleDrawerMenu() {
    setDrawerWidth(current => current === Drawer.Collapsed ? Drawer.Expanded : Drawer.Collapsed);
  }
let usertype =  user.type ? user.type.split('_').join(' ') : null;

console.log(user,"user")
  const handleSignOut = () => {
    localStorage.clear();
    setUser({
      loggedIn: false,
      username: undefined,
      type: undefined,
      name:undefined
    })
    closeAdminMenu();
    if(user.type === "SUPER_ADMIN")
      return navigate("/Moszad-login", {replace: true});
    else if(user.type === "PROPERTY_OWNER" || user.type === "STAFF")
      return navigate("Property-login", {replace: true})
  }
  
	return (
		<AppBar color="primary" component="header" position="fixed" style={{zIndex: Drawer.zIndex + 1}}>
      <Toolbar>
        <IconButton sx={{color: "white", padding: "8px"}} size="large" style={{marginInline: 8}} onClick={handleDrawerMenu}><MenuOutlined /></IconButton>
        {/* <img style={{height: "48px"}} alt="Moszad logo"/> */}       
        {(user.type === "SUPER_ADMIN") || user.type === "MANAGER" || user.type === "TELE_COM" || user.type === "FIELD_WORKER" ?
          <h2 style ={{fontFamily : 'Kalnia'}}>Moszad M-REP</h2> :user.type === "FORGOT" ? null: <h2 style ={{fontFamily : 'Kalnia'}}>Moszad M-OS</h2>}
        
        <Box component="div" style={{marginLeft: "auto"}}>
          <div className="user">
            <Paper>
              <Menu variant="menu" keepMounted anchorEl={adminAnchorEl} onClose={closeAdminMenu} open={openAdminAnchor}>
                <MenuItem onClick={closeAdminMenu} style={{display: "flex", paddingBlock: 8}}>
                  <div className="profile-image">
                    <Avatar sx={{mr: 2}}/>
                  </div>
                  <div className="profile-description">
                    <span className='name'>Moszad({usertype})</span><br />
                    <span className='email'>{user.username}</span>
                  </div>
                </MenuItem>
                <MenuItem style={{paddingBlock: 8}} onClick={handleSignOut}><Logout sx={{mr: 2}}/> Sign Out</MenuItem>
              </Menu>
            </Paper>
            <IconButton sx={{color: "white"}} size="large" onClick={openAdminMenu}><Person /></IconButton>
            <IconButton sx={{color: "white"}} size="large">
              <Badge badgeContent={10} color="warning">
                <Notifications/>
              </Badge>
            </IconButton>
          </div>
        </Box>
      </Toolbar>
    </AppBar>
	)
}