import {Box, MenuItem, Stack, Tab, Tabs, TextField,} from "@mui/material";
import TabPanel from "../../../components/TabPanel";
import { useContext, useEffect, useState } from "react";
import HotelCharts from "./HotelCharts";
import HostelCharts from "./HostelCharts";
import HallCharts from "./HallCharts";
import TourCharts from "./TourCharts";
import ChambCharts from "./ChambCharts";
import { PieChart} from "@mui/x-charts/PieChart";
import { LineChart } from "@mui/x-charts/LineChart";
import { BarChart } from "@mui/x-charts/BarChart";
import { api } from "../../global/apiConfig";
import { AlertToast, noFetch } from "../../../divine-constants";
import AdvancedSearch from "../../../components/AdvancedSearch";
import Fieldset from "../../../components/Fieldset";
import { WidthFull } from "@mui/icons-material";
import PullToRefresh from 'react-simple-pull-to-refresh';

export default function Dashboard() {
  const [page, setPage] = useState(0);
  const { setAlert } = useContext(AlertToast);
  const [advancedSearch, setAdvancedSearch] = useState("weekly");
  const [arrays, setArrays] = useState([[], [], [], [], [], [], []]);

  const fetchData = async () => {
    const url = `sadmin/combined_dashboard?interval=${advancedSearch}`;
    try {
      const res = await api().get(url);
      if (res.status === 200) {
        setArrays([res.data.map((item) => item.booking_count),
        res.data.map((item) => item.cancellation_count),
        res.data.map((item) => item.total_amount),
        res.data.map((item) => item.booking_percentage),
        res.data.map((item) => item.cancellation_percentage),
        res.data.reduce((sum, item) => sum + item.booking_percentage, 0),
        res.data.reduce((sum, item) => sum + item.cancellation_percentage, 0),])
      }
    } catch (error) {
      setAlert(noFetch);
    }
  };
  useEffect(() => {
    fetchData();
  }, [advancedSearch]);

  function handlePageChange(e, next) {
    setPage(next);
  }

  const booking = [
    { id: 0, value: arrays[3][0], label: 'Hotel %' },
    { id: 1, value: arrays[3][1], label: 'Hostel %' },
    { id: 2, value: arrays[3][2], label: 'Hall %' },
    { id: 3, value: arrays[3][3], label: 'Tours & Travels %' },
    { id: 4, value: arrays[3][4], label: 'Distinct Chamb %' },
  ]
  const cancellation = [
    { id: 0, value: arrays[4][0], label: 'Hotel %' },
    { id: 1, value: arrays[4][1], label: 'Hostel %' },
    { id: 2, value: arrays[4][2], label: 'Hall %' },
    { id: 3, value: arrays[4][3], label: 'Tours & Travels %' },
    { id: 4, value: arrays[4][4], label: 'Distinct Chamb %' },
  ]
  return (
    <Box component="div">
      <AdvancedSearch>
        <TextField
          select
          required={false}
          name="name"
          value={advancedSearch}
          onChange={(e) => {
            setAdvancedSearch(e.target.value);
          }}
          label="Filter"
          helperText=""
        >
          <MenuItem value="weekly">Weekly</MenuItem>
          <MenuItem value="monthly">Monthly</MenuItem>
          <MenuItem value="yearly">Yearly</MenuItem>
        </TextField>
      </AdvancedSearch>

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Fieldset legend="Bookings Percentage" style={{width:"100%"}}>{<PieChart
            series={[
              {
                data: arrays[5] === 0 ? [{label: "No Bookings data found!"}] : booking ,
                highlightScope: { faded: 'global', highlighted: 'item' },
                outerRadius: 70,
                cx:75,
                faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
              },
            ]}
            slotProps={{ 
              legend: arrays[5]=== 0 ? {
                direction: 'column',
                position: { vertical: 'middle', horizontal: 'middle' },
                padding: 0,
                labelStyle: {
                  fontSize: 15,
                },
                itemMarkWidth: 15,
                itemMarkHeight: 15,
                markGap: 3,
              } :{
                direction: 'column',
                position: { vertical: 'middle', horizontal: 'right' },
                padding: 0,
                labelStyle: {
                  fontSize: 12,
                },
                itemMarkWidth: 15,
                itemMarkHeight: 15,
                markGap: 3,
              },
            }}
            height={200}
           />}
           </Fieldset>
            
        <Fieldset legend="Cancellations Percentage" style={{width:"100%"}}>{<PieChart
            series={[
              {
                data: arrays[6] === 0 ? [{label: "No Cancellations data found!"}] : cancellation,
                highlightScope: { faded: 'global', highlighted: 'item' },
                outerRadius: 70,
                cx:75,
                faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
              },
            ]}
            slotProps={{ 
              legend: arrays[6]=== 0 ? {
                direction: 'column',
                position: { vertical: 'middle', horizontal: 'middle' },
                padding: 0,
                labelStyle: {
                  fontSize: 15,
                },
                itemMarkWidth: 15,
                itemMarkHeight: 15,
                markGap: 3,
              } :{
                direction: 'column',
                position: { vertical: 'middle', horizontal: 'right' },
                padding: 0,
                labelStyle: {
                  fontSize: 12,
                },
                itemMarkWidth: 15,
                itemMarkHeight: 15,
                markGap: 3,
              },
            }}
            height={200}/>}</Fieldset>
      </Stack>

      <Fieldset legend="Bookings & Cancellations">{arrays[0].length && arrays[1].length && <BarChart
        height={300}
        series={[
          { data: arrays[0], label: 'Bookings', id: 'pvId', },
          { data: arrays[1], label: 'Cancellations', id: 'uvId' },
        ]}
        xAxis={[{ data: ["Hotel", "Hostel", "Hall", "Tours & Travels", "Distinct Chamb"], scaleType: 'band' }]}
      />}</Fieldset>

        <Fieldset legend="Revenue">{arrays[2].length && <LineChart
        xAxis={[{ scaleType: 'point', data: ["Hotel", "Hostel", "Hall", "Tours & Travels", "Distinct Chamb"] }]}
        sx={{
          '.MuiLineElement-root': {
            display: 'none',
          }
        }}
        series={[
          {
            data: arrays[2],
            area: true,
            label: "Revenue"
          },
        ]}
        height={300}
      />}</Fieldset>

      <Tabs
        value={page}
        onChange={handlePageChange}
        aria-label="Dashboard page tabs"
        varient="fullWidth"
      >
        <Tab label="Hotel" />
        <Tab label="Hostel" />
        <Tab label="Hall" />
        <Tab label="Tours & Travels" />
        <Tab label="Distinct Chamb" />
      </Tabs>
      <TabPanel value={page} index={0} children={<HotelCharts />} />
      <TabPanel value={page} index={1} children={<HostelCharts />} />
      <TabPanel value={page} index={2} children={<HallCharts />} />
      <TabPanel value={page} index={3} children={<TourCharts />} />
      <TabPanel value={page} index={4} children={<ChambCharts />} />
    </Box>
  );
}
