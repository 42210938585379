import { forwardRef, useState, useContext, useEffect } from "react";
import { StyledModalPaper } from "../styles/modal";
import { AlertToast, noFetch } from "../divine-constants";
import { Box, TextField, MenuItem } from "@mui/material";
import { api } from "../pages/global/apiConfig";
import { alertState } from "../divine-constants";
import ModalHeader from "./ModalHeader";
import SubmitButton from "../components/FormButton";

function RecommendationsModal({setModalOpen, label, reqApi, resApi, fetchData}) {
	const {setAlert} = useContext(AlertToast);
	const [progress, setProgress] = useState(false);
	const [id, setId] = useState("");

	const [recommendations, setRecommendations] = useState([]);
	const getRecommendations = () => api().get(reqApi).then(res => {
		setRecommendations(res.data);
	}).catch(() => setAlert(noFetch));

	useEffect(() => {
		getRecommendations();
	}, []);


	function handleChange(event) {
		setId(event.target.value)
	}

	function handleFormSubmit(event) {
		event.preventDefault();
		setProgress(true);
		api().post(resApi, {
			id: id,
			name: recommendations.find(el => el._id === id).name
		}).then(res => {
			if(res.data.code === 201){
				setModalOpen(false);
				setAlert(alertState({
					title: "",
					message: "Recommendation added successfully",
					severity: "success",
					show: true
				}));
				fetchData();
			}
		}).catch(err => {
			if(err.status === 401) {
				setAlert(alertState({
					title: "",
					message: "Unauthorized - Cannot add recommendation",
					severity: "warning",
					show: true
				}))
			} else setAlert(alertState({
				title: "",
				message: "Something went wrong. Please try again later",
				severity: "error",
				show: true
			}))
		}).finally(() => setProgress(false));
	}

	return (
		<StyledModalPaper>
			<ModalHeader setModalOpen={setModalOpen} title={label}/>
			<form onSubmit={handleFormSubmit} noValidate>
				<Box component="div" className="form-grid-container">
					<TextField
						label={label.split(' ')[1] + " name"}
						select
						name="recommendation"
						value={id}
						onChange={handleChange}
					>
						{recommendations.map(recommendation => (
							<MenuItem value={recommendation._id} key={recommendation._id} >{recommendation.name}</MenuItem>
						))}
					</TextField>
				</Box>
				<SubmitButton showProgress={progress} />
			</form>
		</StyledModalPaper>
	)
}

export default forwardRef((props, _ref) => <RecommendationsModal {...props} />)