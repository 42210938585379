import { useState, useContext, useEffect, useReducer } from "react";
import { TableContainer, Box, Table, TableHead, TableBody, TableRow, Button, Modal, TableCell, TablePagination, TextField, InputAdornment, LinearProgress } from "@mui/material";
import { api } from "../../global/apiConfig";
import { Add, Search } from "@mui/icons-material";
import { AlertToast, modalReducer, noFetch, tableImageHeight } from "../../../divine-constants";
import { StyledTableHeadCell, StyledTableHeader } from "../../../components/Table";
import { AddBank, EditItem, Redbin, ViewItem } from "../../../components/Icons";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import PaymentListViewModal from "../../../modals/PaymentModal/PaymentListViewModal";
import PaymentListEditModal from "../../../modals/PaymentModal/HostelPaymentEditModal";


export default function Hostelpayment() {
	const {setAlert} = useContext(AlertToast);
	const [rows, setRows] = useState([]);
	const [filteredList, setFilteredList] = useState(rows);
	const [deleteModal, setDeleteModal] = useState(false);
	const [clickedRow, setClickedRow] = useState();
	const [nestedClickedRow, setNestedClickedRow] = useState();
	const [noResults, setNoResults] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const [modalOpen, setModalOpen] = useReducer(modalReducer, {
		addNew: false,
		addNewNested: false,
		edit: false,
		view: false,
		addBank:false,

	});
	const [progress, setProgress] = useState(false);
	const [Id,setId] = useState({})
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;

	const fetchData = () => api().get("sadmin/get_hostel_account_details").then(res => {
		if(res.status === 200) {
			setRows(res.data);
			setProgress(false);
		}
	}).catch(() => setAlert(noFetch));

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		setFilteredList(rows);
	}, [rows]);

	function handlePageChange(event, newPage) {
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(parseInt(event.target.value), 10);
		setPage(0);
	}
	
	function handleTableSearch(event) {
		const query = event.target.value
		const next = rows.filter(item => {
			return Object
			.values(item)
			.map(v => v.toString())
			.some(el => el.toLowerCase().includes(query));
		});
		if(next.length <= 0) setPage(0)
		setFilteredList(next);
		setNoResults(next.length <= 0);
	}
	return (
		<Box component="div">
			<StyledTableHeader>
				<TextField 
					label="Search in Table"
					onChange={handleTableSearch}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Search />
							</InputAdornment>
						)
					}}
					InputLabelProps={{shrink: undefined}}
					required={false}
					helperText={noResults && "No results found"}
					FormHelperTextProps={{
						error: noResults
					}}
				/>

			</StyledTableHeader>
			{progress && <LinearProgress />}
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<StyledTableHeadCell>S.No</StyledTableHeadCell>
							<StyledTableHeadCell>Hostel Name</StyledTableHeadCell>
							<StyledTableHeadCell>Accountant Name</StyledTableHeadCell>
							<StyledTableHeadCell>Bank Name</StyledTableHeadCell>
							<StyledTableHeadCell>Account Number</StyledTableHeadCell>
							<StyledTableHeadCell>IFSC Code</StyledTableHeadCell>
							<StyledTableHeadCell>Action</StyledTableHeadCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0 
							? filteredList.slice(page * rowsPerPage, (page + 1) * rowsPerPage) 
							: filteredList).map((row, i) => (
							<TableRow key={i}>
								<TableCell>{i + 1 + page*rowsPerPage}</TableCell>
								<TableCell>{row.hostel_name}</TableCell>
								<TableCell>{row.name}</TableCell>
								<TableCell>{row.bank_name}</TableCell>
								<TableCell>{row.account_number}</TableCell>
								<TableCell>{row.ifsc}</TableCell>
								<TableCell>

								<ViewItem onClick={() => {setNestedClickedRow(row); setModalOpen({type: "view", to: "open"})}}/>
								<EditItem onClick={() => {setClickedRow(row); setModalOpen({type: "edit", to: "open"})}} />
								
								</TableCell>
							</TableRow>
						))}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={4} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[20, 40, {label: "All", value: -1}]}
				colSpan={100}
				count={filteredList.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
			/>
			

          <Modal 
				open={modalOpen.edit} 
				onClose={() => setModalOpen({type: "edit", to: "close"})} 
				aria-labelledby="hall" 
				aria-describedby="insert new record"
			><PaymentListEditModal setModalOpen={setModalOpen} modalOpen={modalOpen} fetchData={fetchData} data={clickedRow} /></Modal>

			<Modal 
				open={modalOpen.view} 
				onClose={() => setModalOpen({type: "view", to: "close"})} 
				aria-labelledby="hall" 
				aria-describedby="insert new record"
			><PaymentListViewModal setModalOpen={setModalOpen} data={nestedClickedRow} /></Modal>

			{/* <DeleteModal dialogDisplay={deleteModal} setDialogDisplay={setDeleteModal} url={"sadmin/delete_hall"} data={{hall_id: clickedRow?._id}} fetchData={fetchData} /> */}
		</Box>
	)
}