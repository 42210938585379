import { TableContainer, Box, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, TextField, InputAdornment, LinearProgress, Modal } from "@mui/material";
import { EditItem, ViewItem } from "../../components/Icons";
import { StyledTableHeadCell, StyledTableHeader } from "../../components/Table";
import { useEffect, useState } from "react";
import { AddBox } from "@mui/icons-material";
import { TablePaginationActions } from "../../components/Table-pagination";
import { noFetch } from "../../divine-constants";
import { api } from "../global/apiConfig";

export default function HostelScheduledVisit() {
const [rows, setRows] = useState([]); 
const [filteredList, setFilteredList] = useState(rows);
const [rowsPerPage, setRowsPerPage] = useState(5);
const [page, setPage] = useState(0);
const [progress, setProgress] = useState(true);
const [editModalOpen, setEditModalOpen] = useState(false);
const [clickedRow, setClickedRow] = useState();
const [viewModalOpen, setViewModalOpen] = useState(false);

const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;

const id = localStorage.getItem("UserId");
	const fetchData = () => api().get(`property/get_schedule_visit?property_id=${id}&&type=hostel`).then(res => {
		if(res.status === 200) {
			setRows(res.data);
			setProgress(false);	
		}
	})
	useEffect(() => {
		fetchData();
	}, []);

useEffect(() => {
	setFilteredList(rows);
	setPage(0);
}, [rows]);	

function handlePageChange(event, newPage) {	
	setPage(newPage);
}

function handleChangeRowsPerPage(event) {
	setRowsPerPage(parseInt(event.target.value), 10);
	setPage(0);
}
	return (
		<Box component="div">
			{progress && <LinearProgress />}
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<StyledTableHeadCell>S.No</StyledTableHeadCell>
							<StyledTableHeadCell>User Name</StyledTableHeadCell>
							<StyledTableHeadCell>Property Name</StyledTableHeadCell>
							<StyledTableHeadCell>Date</StyledTableHeadCell>
							<StyledTableHeadCell>Time</StyledTableHeadCell>
							<StyledTableHeadCell>Action</StyledTableHeadCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0 
							? filteredList.slice(page * rowsPerPage, (page + 1) * rowsPerPage) 
							: filteredList).map((row, i) => {
								console.log("filteredList",row)
								return(
							<TableRow key={i}>
								<TableCell>{i + 1 + page*rowsPerPage}</TableCell>
								<TableCell>{row.user_name}</TableCell>
								<TableCell>{row.property_name}</TableCell>
								<TableCell>{row.date}</TableCell>
								<TableCell>{row.time}</TableCell>
								<TableCell>
									<ViewItem onClick={() => {setClickedRow(row); setViewModalOpen(true)}} />
									<EditItem onClick={() => {setClickedRow(row); setEditModalOpen(true)}} />
								</TableCell>
							</TableRow>
						)})}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={4} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[5, 10, {label: "All", value: -1}]}
				colSpan={100}
				count={filteredList.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
			/>
			{/* <Modal 
				open={viewModalOpen} 
				onClose={() => setViewModalOpen(false)} 
				aria-labelledby="hostel room list form" 
				aria-describedby="insert new hostel room"
			><ScheduledVisitViewModal data={clickedRow} setModalOpen={setViewModalOpen} /></Modal>
			<Modal 
				open={editModalOpen} 
				onClose={() => setEditModalOpen(false)} 
				aria-labelledby="hostel room list form" 
				aria-describedby="insert new hostel room"
			><ScheduledVisitViewModal data={clickedRow} setModalOpen={setEditModalOpen} type = "edit" /></Modal> */}
		</Box>
	)
	
}