import {
    Box,
    Button,
    InputAdornment,
    Paper,
    TextField,
    Typography,
    ThemeProvider,
    CircularProgress,
  } from "@mui/material";
  import { useContext, useState } from "react";
  import { PersonOutlined } from "@mui/icons-material";
  import { useNavigate } from "react-router-dom";
  import { api } from "./apiConfig";
  import {
    AlertToast,
    alertState,
  } from "../../divine-constants";
  import { moszadTheme } from "./theme";
  import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
  
  export default function MoszadForgotPassword() {
    const navigate = useNavigate();
    const [progress, setProgress] = useState(false);
    const [otp, setOtp] = useState(false);
    const [formData, setFormdata] = useState({
      email: "",
      otp: "",
      remember: false,
    });
    const [validations, setValidations] = useState({
      email: " ",
      otp : " "
    });
  
    const { setAlert } = useContext(AlertToast);
  
    function updateFormData(event) {
      setFormdata((current) => ({
        ...current,
        [event.target.name]: event.target.value,
      }));
      if (Object.values(validations).some((it) => it !== " ")) {
        setValidations((curr) => ({ ...curr, [event.target.name]: " " }));
      }
    }
  
    function validation() {
      let isValid = true;
      if (formData.email.trim() === "") {
        isValid = false;
        setValidations((curr) => ({ ...curr, email: "Email is required." }));
      } else setValidations((curr) => ({ ...curr, email: " " }));
  
      if (otp === true ? formData.otp.trim() === "" : undefined) {
        isValid = false;
        setValidations((curr) => ({ ...curr, otp: "Please Enter Otp." }));
      } else setValidations((curr) => ({ ...curr, otp: " " }));
      return isValid;
    }
  
    async function onSubmit(event) {
      event.preventDefault();
      setProgress(true);
      if (!validation()) {
        setProgress(false);
        return;
      }
  
      if (otp === false) {
        await api()
          .post("sadmin/super_admin_otp_send", {
            email: formData.email.trim(),
          })
          .then((res) => {
            if (res.status == "201") {
              setOtp(true);
              setAlert(
                alertState({
                  message: "Otp Sent Successfully",
                  severity: "success",
                  show: true,
                })
              );
            }
          })
          .catch((err) => {
            setAlert(
              alertState({
                message: "Invalid credentials",
                severity: "error",
                show: true,
              })
            );
          })
          .finally(() => setProgress(false));
      } else {

          await api()
          .post("sadmin/sadmin_verify_otp", {
            email: formData.email.trim(),
            otp : formData.otp.trim()
          })
          .then((res) => {
            if (res.status == "201") {
              setOtp(true);
              setAlert(
                alertState({
                  message: " Otp verified Successfully",
                  severity: "success",
                  show: true,
                })
              );
              localStorage.setItem("token",res.data.token);
              localStorage.setItem("userType", 'FORGOT');
        window.location.replace("/Moszad-newpassword")
     }})
          .catch((err) => {
            setAlert(
              alertState({
                message: "Invalid Otp",
                severity: "error",
                show: true,
              })
            );
          })
          .finally(() => setProgress(false));
      }
    }
  
    return (
      <ThemeProvider theme={moszadTheme}>
        <Box
          component="div"
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            display: "grid",
            overflow: "auto",
            placeItems: "center",
            zIndex: 1201,
            backgroundColor: "rgb(245, 247, 255)",
          }}
        >
          <Paper
            style={{
              width: "40%",
              minWidth: "275px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginInline: "auto",
              padding: "1rem 2rem 1.5rem",
            }}
            component="form"
            elevation={2}
            onSubmit={onSubmit}
            noValidate
          >
            <h2
              style={{
                fontFamily: "Kalnia",
                width: "50%",
                marginInline: "auto",
                minWidth: 150,
                display: "block",
                color: "#057306",
                textAlign: "center",
              }}
            >
              Moszad
            </h2>
            <Typography
              variant="h4"
              style={{ marginBlockEnd: "1rem", opacity: "0.5" }}
            >
              Lost your password? Please enter your email address. You will receive OTP to create a new password via email.
            </Typography>
            <TextField
              value={formData.email}
              error={Boolean(validations.email.trim())}
              name="email"
              type="text"
              placeholder="Email"
              label="Email"
              color="success"
              onChange={updateFormData}
              helperText={validations.email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonOutlined />
                  </InputAdornment>
                ),
              }}
            />
            {otp === true && (
              <TextField
                value={formData.otp}
                error={Boolean(validations.otp.trim())}
                name="otp"
                type="text"
                placeholder="Enter Otp"
                label="OTP"
                color="success"
                onChange={updateFormData}
                helperText={validations.otp}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TextsmsOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
              />
            )}    
            <Box
              component="div"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
                marginBottom: "1rem",
              }}
            ></Box>
            <Button
              type="submit"
              disabled={progress}
              style={{
                backgroundColor: "#057306",
                display: "block",
                width: "fit-content",
                placeContent: "end",
                marginLeft: "auto",
              }}
            >
              {"Continue"}{" "}
              {progress && (
                <>
                  &nbsp;{" "}
                  <CircularProgress
                    color="info"
                    variant="indeterminate"
                    size="16px"
                  />
                </>
              )}
            </Button>
            {/* <SubmitButton type="Sign In" showProgress={progress} /> */}
          </Paper>
        </Box>
      </ThemeProvider>
    );
  }
  