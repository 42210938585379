import { forwardRef } from "react";
import { StyledModalPaper } from "../../styles/modal";
import {
  Box,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import ModalHeader from "../ModalHeader";
import Fieldset from "../../components/Fieldset";
import { Clear, Done } from "@mui/icons-material";

function PackageViewModal({ setModalOpen, data }) {
    return (
   
    <StyledModalPaper>
      <ModalHeader
        title="Chamb"
        setModalOpen={setModalOpen}
        type="viewNested"
      />
      <Box
        sx={{
          display: "grid",
          width: "100%",
          gap: "1rem",
          "& > fieldset": {
            width: "fit-content",
            flexGrow: 1,
            display: "grid",
            gap: "1rem",
            justifyContent: "space-around",
            pb: 1,
          },
          "@media only screen and (max-width: 750px)": {
            gridTemplateColumns: "1fr",
          },
        }}
        className="two-column-layout"
      >
        <Box>
          <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1}>
            <Chip label={data.category} />
            <Chip label={data.discount_price + " Discount Price"}  color={"success"}/>
            {/* <Chip label={data.room_type} color={data.room_type.toLowerCase() === "ac" ? "success" : "error"} /> */}
            <Chip
              label={
                data.active_status
                  ? "Status - Active"
                  : "Status - Inactive"
              }
              color={data.active_status ? "success" : "error"}
            />
            {/* <Chip icon={data.couple_friendly ? <Done /> : <Clear /> } label={"Couple Friendly"} color={data.couple_friendly ? "success" : "error"} /> */}
            <Chip color="success" label={data.discount_percentage + "% off"} />
          </Stack>
          <br />
          <Box>
          <Typography component="span" fontWeight={900} fontSize={20}>
                Starting Price:{" "}
              </Typography>
            <h2 style={{ display: "inline" }}>&#8377; {data.price}</h2>
            &nbsp;(w/o discount) <br />
          </Box>
          <br />
          <Stack>
            <Box>
              <Typography component="span" fontWeight={600}>
                Package Name:{" "}
              </Typography>
              {data.package_name}
            </Box>          
          </Stack>
        </Box>
      
      </Box>
      <Fieldset legend="Description">{data.description}</Fieldset>
 
      <Fieldset
        legend="Images"
        style={{ paddingBottom: 16, display: "flex", flexWrap: "wrap", gap: 4 }}
      >
        {data.package_image.map((image, ind) => (
          <img
            key={ind}
            src={image}
            style={{ maxWidth: "35%", width: "45%" }}
            srcSet={image}
            alt={image}
            loading="lazy"
          />
        ))}
      </Fieldset>
   
    </StyledModalPaper>
  );
}

export default forwardRef((props, _ref) => <PackageViewModal {...props} />);
