import { useContext, useEffect, useReducer, useState } from "react";
import { TableContainer, Box, Table, TableHead, TableBody, TableRow, Modal, TableCell, TablePagination, TextField, InputAdornment, LinearProgress } from "@mui/material";
import { Search, SearchOutlined } from "@mui/icons-material";
import { StyledTableHeadCell, StyledTableHeader } from "../../components/Table";
import { TablePaginationActions } from "../../components/Table-pagination"; 
import {ViewItem } from "../../components/Icons";
import { AlertToast, modalReducer, noFetch } from "../../divine-constants";
import { api } from "../global/apiConfig";
import CustomerViewModal from "../../modals/CustomerViewModal";
import NumberDisplay from "../../components/NumberDisplay";

export default function Customers() {
	const {setAlert} = useContext(AlertToast);
	const [nestedClickedRow, setNestedClickedRow] = useState();
	const [rows, setRows] = useState([]);
	const [filteredList, setFilteredList] = useState(rows);
	const [noResults, setNoResults] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [modalOpen, setModalOpen] = useReducer(modalReducer, {
		addNew: false,
		addNewNested: false,
		edit: false,
		view: false,
		addBank:false,
		viewNested: false,
		editNested: false,
		success : false
	});
	const [progress, setProgress] = useState(true);
	const [clickedRow, setClickedRow] = useState();
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;

	const fetchData = () => api().get("sadmin/get_customers_details").then(res => {
		if(res.status === 200) {
			setRows(res.data);
			setProgress(false);
		}
	}).catch(() => setAlert(noFetch));
		
	useEffect(() => {
		fetchData();
	}, [])

	useEffect(() => {
		setFilteredList(rows);
		setPage(0);
	}, [rows])

	function handlePageChange(event, newPage) {
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(parseInt(event.target.value), 10);
		setPage(0);
	}

	function handleTableSearch(event) {
		const query = event.target.value
		const next = rows.filter(item => {
			return Object
			.values(item)
			.map(v => typeof v === "string" ? v.toString() : "")
			.some(el => el.toLowerCase().includes(query));
		});
		if(next.length <= 0) setPage(0)
		setFilteredList(next);
		setNoResults(next.length <= 0);
	}
	return (
		<Box component="div">
			<Box className="card-container">
				<NumberDisplay color="text.primary" title="Coustomer's Count" value={rows.length} />
			</Box> <br></br>
		<StyledTableHeader>
				<TextField 
					label="Search in Table"
					onChange={handleTableSearch}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Search />
							</InputAdornment>
						)
					}}
					InputLabelProps={{shrink: undefined}}
					required={false}
					helperText={noResults && "No results found"}
					FormHelperTextProps={{
						error: noResults
					}}
				/>
			</StyledTableHeader>
			{progress && <LinearProgress />}
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<StyledTableHeadCell>S.No</StyledTableHeadCell>
							<StyledTableHeadCell>Name</StyledTableHeadCell>
							<StyledTableHeadCell>Mobile No</StyledTableHeadCell>
							<StyledTableHeadCell>Email</StyledTableHeadCell>
							<StyledTableHeadCell>Bookings</StyledTableHeadCell>
							<StyledTableHeadCell>Cancellations</StyledTableHeadCell>
							<StyledTableHeadCell>Action</StyledTableHeadCell>
						</TableRow>
					</TableHead>
					<TableBody>
            {(rowsPerPage > 0
              ? filteredList.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              : filteredList
            ).map((row, i) => (
              <TableRow key={i}>
                <TableCell>{i + 1 + page * rowsPerPage}</TableCell>
                <TableCell>{row.name1} {row.name2}</TableCell>
                <TableCell>{row.mobile_no}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.booking}</TableCell>
                <TableCell>{row.cancellation}</TableCell>
				<TableCell>
					<ViewItem onClick={() => {setNestedClickedRow(row); setModalOpen({type: "view", to: "open"})}}/>
                 </TableCell>

                
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 20, {label: "All", value: -1}]}
				colSpan={100}
				count={filteredList.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
			/>
			<Modal 
				open={modalOpen.view} 
				onClose={() => setModalOpen({type: "view", to: "close"})} 
				aria-labelledby="Customer Details" 
				aria-describedby="insert new record"
			><CustomerViewModal setModalOpen={setModalOpen} data={nestedClickedRow} /></Modal>
		</Box>
	)
}