import { Tab, Tabs } from "@mui/material"
import { useState } from "react";
import TabPanel from "../../../../components/TabPanel";
import HotelOffer from "./HotelOffer";
import HostelOffer from "./HostelOffer";
import HallOffer from "./HallOffer";
import ToursOffer from "./ToursOffer";
import ChambOffer from "./ChambOffer";

export default function Recommendations() {
	const [page, setPage] = useState(0);
	function handlePageChange(e, next) {
		setPage(next);
	}
	return (
		<>
			<Tabs value={page} onChange={handlePageChange}>
				<Tab label="Hotels"/>
				<Tab label="Hostels"/>
				<Tab label="Halls"/>
				<Tab label="Tours"/>
				{/* <Tab label="Distinct Chamb"/> */}
			</Tabs>
			<TabPanel index={0} value={page} children={<HotelOffer />}/>
			<TabPanel index={1} value={page} children={<HostelOffer />}/>
			<TabPanel index={2} value={page} children={<HallOffer />}/>
			<TabPanel index={3} value={page} children={<ToursOffer />}/>
			{/* <TabPanel index={4} value={page} children={<ChambOffer />} /> */}
		</>
	)
}