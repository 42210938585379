import { forwardRef } from "react";
import { StyledModalPaper } from "../../styles/modal";
import { Box, Chip, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from "@mui/material";
import ModalHeader from "../ModalHeader";
import Fieldset from "../../components/Fieldset";
import { Clear, Done } from "@mui/icons-material";

function HotelRoomViewModal({setModalOpen, data}) {
	return (
		<StyledModalPaper>
			<ModalHeader title="Room details" setModalOpen={setModalOpen} type="viewNested"/>
			<Box sx={{
				display: "grid",
				width: "100%",
				gap: "1rem",
				"& > fieldset": {
					width: "fit-content",
					flexGrow: 1,
					display: "grid",
					gap: "1rem",
					justifyContent: "space-around",
					pb: 1
				},
				"@media only screen and (max-width: 750px)": {
					gridTemplateColumns: "1fr"
				}
			}} className="two-column-layout">
				<Box>
					<Stack direction="row" useFlexGap flexWrap="wrap" spacing={1}>
						<Chip label={data.category} color={"success"} />
						<Chip label={data.room_type} color={data.room_type.toLowerCase() === "ac" ? "success" : "success"} />
						<Chip label={data.room_active_status ? "Status - Active" : "Status - Inactive"} color={data.room_active_status ? "success" : "error"} />
						<Chip icon={data.couple_friendly ? <Done /> : <Clear /> } label={"Couple Friendly"} color={data.couple_friendly ? "success" : "error"} />
						<Chip color="success" label={data.discount_percentage + "% off"} />
					</Stack>
					<br />
					<Box>
						<h2 style={{display: "inline"}}>&#8377; {data.price}</h2>&nbsp;(w/o discount) <br/>
					</Box>
					<br />
					<Stack>
						<Box>
							<Typography component="span" fontWeight={600}>Size: </Typography>
							{data.size}
						</Box>
						<Box>
							<Typography component="span" fontWeight={600}>Adults: </Typography>
							{data.no_of_adult}
						</Box>
						<Box>
							<Typography component="span" fontWeight={600}>Children: </Typography>
							{data.no_of_child}
						</Box>
						<Box>
							<Typography component="span" fontWeight={600}>Total rooms: </Typography>
							{data.no_of_rooms}
						</Box>
					</Stack>
				</Box>
				<Fieldset legend="Amenities" style={{width: "100%", justifyContent: "flex-start", display: "block"}}>
					<List sx={{maxHeight: 200, overflowY: "auto"}}>
						{data.room_amentities.map((amenity, ind) => 
						<ListItem key={ind} sx={{pb: 2}}>
							<ListItemAvatar>
								<img src={amenity.image} alt={amenity.image} height={30} />
							</ListItemAvatar>
							&nbsp;
							<ListItemText>
								{amenity.amenities_name}
							</ListItemText>
						</ListItem>
						)}
					</List>
				</Fieldset>
			</Box>
			<Fieldset legend="Description">
				{data.room_description}
			</Fieldset>

		<Fieldset
        legend="Images"
        style={{ paddingBottom: 16, display: "flex", flexWrap: "wrap", gap: 4 }}
      >
        {data.room_image.map((image, ind) => (
          <img
            key={ind}
            src={image}
            style={{ maxWidth: "35%", width: "45%" }}
            srcSet={image}
            alt={image}
            loading="lazy"
          />
        ))}
        </Fieldset>
		</StyledModalPaper>
	)
}
export default forwardRef((props, _ref) => <HotelRoomViewModal {...props} />)
