import { useState, useEffect, useContext, useReducer } from "react";
import { Table, Box, TableContainer, TableHead, TableRow, TableCell, TableBody, Button, TablePagination, Modal, TextField, InputAdornment, LinearProgress } from "@mui/material";
import { AddOutlined, SearchOutlined } from "@mui/icons-material";
import { StyledTableHeadCell, StyledTableHeader } from "../../components/Table";
import { TablePaginationActions } from "../../components/Table-pagination";
import { EditItem, Redbin } from "../../components/Icons";
import AmenitiesModal from "../../modals/AmenitiesModal";
import { AlertToast, noFetch, tableImageHeight } from "../../divine-constants";
import { api } from "../global/apiConfig";
import { modalReducer } from "../../divine-constants";
import DeleteModal from "../../modals/DeleteModal";
import AmenitiesEditModal from "../../modals/AmenitiesEditModal";

export default function Amenities() {
  const {setAlert} = useContext(AlertToast);
  const [rows, setRows] = useState([]);
  const [filteredList, setFilteredList] = useState(rows);
  const [noResults, setNoResults] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalOpen, setModalOpen] = useReducer(modalReducer, {
		addNew: false,
    edit: false
	});
  const [progress, setProgress] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
	const [clickedRow, setClickedRow] = useState();
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;

  const fetchData = () => 
    api().get("sadmin/get_amenities").then(res => {
      if(res.status !== 200) return;
      setRows(res.data);
      setProgress(false);
    }).catch(() => setAlert(noFetch));
 
  useEffect(() => {
    fetchData()
  }, [])
  useEffect(() => {
    setFilteredList(rows);
    setPage(0);
  }, [rows])

  function handleOpenModal() {
    setModalOpen(true);
  }

  function handleCloseModal() {
    setModalOpen(false);
  }

  function handlePageChange(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value), 10);
    setPage(0);
  }

  function handleTableSearch(event) {
    const query = event.target.value.toLowerCase();
    const next = rows.filter((item) => {
      return Object.values(item)
        .map((v) => v.toString())
        .some((el) => el.toLowerCase().includes(query));
    });
    console.log(next,"next")

    if (next.length <= 0) setPage(0);
    setFilteredList(next);
    setNoResults(next.length <= 0);
  }

  return (
    <Box component="div">
      <StyledTableHeader className="table-header">
      <Button onClick={() => setModalOpen({type: "addNew", to: "open"})}>
          <AddOutlined /> Add new
        </Button>
        <TextField
          label="Search in Table"
          onChange={handleTableSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{shrink: undefined}}
					required={false}
          helperText={noResults && "No results found"}
          FormHelperTextProps={{
            error: noResults,
          }}
        />
        
      </StyledTableHeader>
      {progress && <LinearProgress />}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableHeadCell>S.No</StyledTableHeadCell>
              <StyledTableHeadCell>Amenities</StyledTableHeadCell>
              <StyledTableHeadCell>Image</StyledTableHeadCell>
              <StyledTableHeadCell>Type</StyledTableHeadCell>
              <StyledTableHeadCell>Action</StyledTableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredList.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              : filteredList
            ).map((row, i) => (
              <TableRow key={i}>
                <TableCell>{i + 1 + page*rowsPerPage}</TableCell>
                <TableCell>{row.amenities_name}</TableCell>
                <TableCell><img height={tableImageHeight} src={row.image} alt={row.image}/></TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>
                  <EditItem onClick={() => {setClickedRow(row); setModalOpen({type: "edit", to: "open"})}} />
                  <Redbin onClick={() => {setDeleteModal(true); setClickedRow(row)}} />
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, { label: "All", value: -1 }]}
        colSpan={100}
        count={filteredList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
      <Modal
        open={modalOpen.addNew}
        onClose={handleCloseModal}
        aria-labelledby="amenities-form"
        aria-describedby="insert new record"
      >
        <AmenitiesModal setModalOpen={setModalOpen} fetchData={fetchData} />
      </Modal>
      <Modal
        open={modalOpen.edit}
        onClose={handleCloseModal}
        aria-labelledby="amenities-form"
        aria-describedby="insert new record"
      >
        <AmenitiesEditModal setModalOpen={setModalOpen} fetchData={fetchData} data={clickedRow} />
      </Modal>
			<DeleteModal dialogDisplay={deleteModal} setDialogDisplay={setDeleteModal} url={"sadmin/delete_amenities?amenities_id=" + clickedRow?.id} fetchData={fetchData} />
    </Box>
  );
}
