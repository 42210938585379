import { useEffect, useState } from "react";
import { TableContainer, Box, Table, TableHead, TableBody, TableRow, Button, Modal, TableCell, TablePagination, TextField, InputAdornment, LinearProgress } from "@mui/material";
import { Add, Search } from "@mui/icons-material";
import { StyledTableHeadCell, StyledTableHeader } from "../../components/Table";
import { TablePaginationActions } from "../../components/Table-pagination"; 
import { EditItem, Redbin } from "../../components/Icons";
import LocationsModal from "../../modals/LocationsModal";
import { tableImageHeight } from "../../divine-constants";
import { api } from "../global/apiConfig";
import { Navigate } from "react-router-dom";
import DeleteModal from "../../modals/DeleteModal";

export default function Locations() {
	const [rows, setRows] = useState([]);
	const [filteredList, setFilteredList] = useState(rows);
	const [noResults, setNoResults] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [modalOpen, setModalOpen] = useState(false);
	const [progress, setProgress] = useState(true);
	const [deleteModal, setDeleteModal] = useState(false);
	const [clickedRow, setClickedRow] = useState();
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;

	const fetchData = () => api().get("sadmin/get_location").then(res => {
			if(res.status === 200) {
				setRows(res.data);
				setProgress(false);
			}
		}).catch(() => <Navigate to="/login" replace />);
	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		setFilteredList(rows);
		setPage(0);
	}, [rows])

	function handleOpenModal() {
		setModalOpen(true);
	}

	function handleCloseModal() {
		setModalOpen(false);
	}

	function handlePageChange(_, newPage) {
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(parseInt(event.target.value), 10);
		setPage(0);
	}

	function handleTableSearch(event) {
		const query = event.target.value
		const next = rows.filter(item => {
			return Object
			.values(item)
			.map(v => v.toString())
			.some(el => el.toLowerCase().includes(query));
		});
		if(next.length <= 0) setPage(0)
		setFilteredList(next);
		setNoResults(next.length <= 0);
	}

	return (
		<Box component="div">
			<StyledTableHeader>
			<Button onClick={handleOpenModal}><Add /> Add new</Button>
				<TextField 
					label="Search in Table"
					onChange={handleTableSearch}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Search />
							</InputAdornment>
						)
					}}
					InputLabelProps={{shrink: undefined}}
					required={false}
					helperText={noResults && "No results found"}
					FormHelperTextProps={{
						error: noResults
					}}
				/>
			</StyledTableHeader>
			{progress && <LinearProgress />}
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<StyledTableHeadCell>S.No</StyledTableHeadCell>
							<StyledTableHeadCell>Image</StyledTableHeadCell>
							<StyledTableHeadCell>Location</StyledTableHeadCell>
							<StyledTableHeadCell>Action</StyledTableHeadCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0 
							? filteredList.slice(page * rowsPerPage, (page + 1) * rowsPerPage) 
							: filteredList).map((row, i) => (
							<TableRow key={row.id}>
								<TableCell>{i + 1 + page*rowsPerPage}</TableCell>
								<TableCell><img height={tableImageHeight} src={row.image} alt={row.image}/></TableCell>
								<TableCell>{row.location_name}</TableCell>
								<TableCell>
									<Redbin onClick={() => {setDeleteModal(true); setClickedRow(row)}} />
								</TableCell>
							</TableRow>
						))}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={4} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[5, 10, {label: "All", value: -1}]}
				colSpan={100}
				count={filteredList.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
			/>
			<Modal 
				open={modalOpen} 
				onClose={handleCloseModal} 
				aria-labelledby="locations-form" 
				aria-describedby="insert new record"
			><LocationsModal fetchData={fetchData} setModalOpen={setModalOpen}/></Modal>
			<DeleteModal dialogDisplay={deleteModal} setDialogDisplay={setDeleteModal} url={"sadmin/delete_location?location_id=" + clickedRow?.id} fetchData={fetchData} />
		</Box>
	)
}