import { forwardRef, useState, useEffect, useContext } from "react";
import { StyledModalPaper } from "../../styles/modal";
import { Box, TextField, MenuItem, Checkbox, ListItemText, FormControlLabel, Switch, InputAdornment, FormControl, InputLabel } from "@mui/material";
import { api } from "../../pages/global/apiConfig";
import ModalHeader from "../ModalHeader";
import SubmitButton from "../../components/FormButton";
import { CurrencyRupeeOutlined, PercentOutlined } from "@mui/icons-material";
import { AlertToast, alertState } from "../../divine-constants";
import { arrayStringify } from "../../utils";

function HostelRoomsModal({setModalOpen, data, id, fetchData}) {
		const [formData, setFormData] = useState({
		type: data ? data.room_type.toLowerCase() : "",
		price: data ? data.price : "",
		discount: data ? data.discount_percentage : "",
		roomCount: data ? data.no_of_rooms : "",
		size: data ? data.size.match(/\d+/)[0]: "",
		guests: data ? data.guests:"",
		amenities: [],
		images: [],
		description:  data ? data.room_description : "",
		status:data ? data.room_active_status : false,
		advance:data ? data.advance : "",
		advanceInfo:data ? data.advance: "",
		couple_friendly:data ? data.couple_friendly : false,

	});
console.log(formData,"formData")
	const [progress, setProgress] = useState(false);
	const {setAlert} = useContext(AlertToast);
	const [amenities, setAmenitites] = useState([]);
    	useEffect(() => {
		api().get('sadmin/get_amenities')
		.then(results => {
			
			setAmenitites(results.data);
		}).catch(() => {
			setAlert(alertState({
				message: "An error occured. Please try again",
				severity: "error",
				show: true
			}))
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function handleText(event) {
		setFormData(current => ({...current, [event.target.name]: event.target.value}));
	}

	function handleImgs(event) {
		setFormData(current => ({...current, images: event.target.files}));
	}

	function handleMultiSelect(event) {
		const {
			target: {value}, 
		} = event
		setFormData(current => ({...current, amenities: value}))
	}

	function handleFormSubmit(event) {
		setProgress(true);
		event.preventDefault();
		if(id.type === "edit"){
			api().patch("sadmin/edit_hostel_room", {
				hostel_id: id.hostel_id,
				hostel_room_id:data.id,
				room_type: formData.type,
				price: formData.price,
				discount_percentage: formData.discount,
				discounted_price: 0,
				no_of_rooms: formData.roomCount,
				size: formData.size,
				no_guest: formData.guests,
				room_amentities: arrayStringify(formData.amenities),
				room_image: Array.from(formData.images),
				room_active_status: formData.status,
				room_description: formData.description.trim(),
				room_ac_status: formData.type === "AC",
				advance_amount: formData.advance,
				advance_amount_info: formData.advanceInfo,
				couple_friendly: formData.couple_friendly
			}).then(res => {
				if(res.data.code === 201) {
					setAlert(alertState({
						message: "Room added successfully",
						severity: "success",
						show: true
					}));
					fetchData();
					setModalOpen({type: "editNested" , to: "close"});
				}
			}).catch(err => {
				if(err.status === 401) {
					setAlert(alertState({
						message: "You are not authorized to add a new room.",
						severity: "warning",
						show: true
					}))
				} else if (err.status === 403) {
					setAlert(alertState({
						message: "You do not have access to add a new room.",
						severity: "warning",
						show: true
					}))
				} else setAlert(alertState({
					message: "Something went wrong. Please try again",
					severity: "error",
					show: true
				}))
			}).finally(() => setProgress(false));
		}else{
			api().post("sadmin/add_hostel_room", {
				hostel_id: id.hostel_id,
				room_type: formData.type,
				price: formData.price,
				discount_percentage: formData.discount,
				discounted_price: 0,
				no_of_rooms: formData.roomCount,
				size: formData.size,
				no_guest: formData.guests,
				room_amentities: arrayStringify(formData.amenities),
				room_image: Array.from(formData.images),
				room_active_status: formData.status,
				room_description: formData.description.trim(),
				room_ac_status: formData.type === "AC",
				advance_amount: formData.advance,
				advance_amount_info: formData.advanceInfo,
				couple_friendly: formData.couple_friendly
			}).then(res => {
				if(res.data.code === 201) {
					setAlert(alertState({
						message: "Room added successfully",
						severity: "success",
						show: true
					}));
					fetchData();
					setModalOpen({type: data ? "edit" : "addNewNested", to: "close"});
				}
			}).catch(err => {
				if(err.status === 401) {
					setAlert(alertState({
						message: "You are not authorized to add a new room.",
						severity: "warning",
						show: true
					}))
				} else if (err.status === 403) {
					setAlert(alertState({
						message: "You do not have access to add a new room.",
						severity: "warning",
						show: true
					}))
				} else setAlert(alertState({
					message: "Something went wrong. Please try again",
					severity: "error",
					show: true
				}))
			}).finally(() => setProgress(false));
		}
		
	}

	return (
		<StyledModalPaper>
			<ModalHeader setModalOpen={setModalOpen} type={data ? "editNested" : "addNewNested"}/>
			<Box component="form" className="list-form" onSubmit={handleFormSubmit} noValidate>
				<Box component="div" className="form-grid-container four-column-layout">

					<TextField
						select
						label="Room type"
						type="text"
						name="type"
						value={formData.type}
						onChange={handleText}
					>
						<MenuItem value="3_sharing">3 sharing</MenuItem>
						<MenuItem value="2_sharing">2 sharing</MenuItem>
						<MenuItem value="4_sharing">4 sharing</MenuItem>
						<MenuItem value="6_sharing">6 sharing</MenuItem>
					</TextField>

					<TextField
						required={false}
						label="No. of rooms"
						type="number"
						name="roomCount"
						value={formData.roomCount}
						onChange={handleText}
						placeholder="Eg. 8"
					/>

					<TextField
						required={false}
						label="Size (sq. ft)"
						type="number"
						name="size"
						value={formData.size}
						onChange={handleText}
						placeholder="Eg. 602.5"
						InputProps={{endAdornment: <InputAdornment position="end">sq. ft</InputAdornment>}}
					/>

					<TextField
						required={false}
						label="Price"
						type="number"
						name="price"
						value={formData.price}
						onChange={handleText}
						placeholder="Enter Price"
						InputProps={{startAdornment: <InputAdornment position="start"><CurrencyRupeeOutlined /></InputAdornment>}}
					/>

					<TextField
						required={false}
						label="Discount"
						type="number"
						name="discount"
						value={formData.discount}
						onChange={handleText}
						placeholder="Whole number percent"
						InputProps={{endAdornment: <InputAdornment position="end"><PercentOutlined /></InputAdornment>}}
					/>

					<TextField
						required={false}
						label="Advance Payment"
						type="number"
						name="advance"
						inputProps={{min: 0}}
						value={formData.advance}
						onChange={handleText}
						placeholder="2000"
						InputProps={{startAdornment: <InputAdornment position="start"><CurrencyRupeeOutlined /></InputAdornment>}}
					/>

<TextField
						label="Amenities"
						select
						SelectProps={{multiple: true, renderValue: selected => selected.map(s => amenities.find(v => v.id === s)?.amenities_name)+ ', '}}
						value={formData.amenities}
						onChange={handleMultiSelect}
						name="amenities"
						placeholder="Select Amenities"
					>
						{amenities.map(option => (
							<MenuItem									
								key={option.id}
								value={option.id}
								sx={{paddingBlock: 0}}
							>
								<Checkbox checked={formData.amenities.indexOf(option.id) > -1} />
								<ListItemText>{option.amenities_name}</ListItemText>
							</MenuItem>
						))}
					</TextField>

					<TextField
						required={false}
						label="Images"
						inputProps={{multiple: true}}
						type="file"
						onChange={handleImgs}
						name="images"
					/>

					<TextField
						label="Total guests"
						inputProps={{min: 0}}
						type="number"
						name="guests"
						value={formData.guests}
						onChange={handleText}
					/>

					{/* <FormControl required={false} className="inline-form-control">
						<InputLabel shrink>Status</InputLabel>
						<FormControlLabel label={formData.status ? "Active" : "Inactive"} onChange={e => setFormData(curr => ({...curr, status: e.target.checked}))} control={<Switch checked={formData.status} />} />
					</FormControl> */}

					<FormControl required={false} className="inline-form-control">
						<InputLabel shrink>Couple Friendly</InputLabel>
						<FormControlLabel label={formData.couple_friendly ? "Yes" : "No"} onChange={e => setFormData(curr => ({...curr, couple_friendly: e.target.checked}))} control={<Switch checked={formData.couple_friendly} />} />
					</FormControl>

					<TextField 
						label="Description" 
						placeholder="Enter Description..."
						className="big-text"
						name="description"
						value={formData.description}
						onChange={handleText}
						multiline
					/>
					<TextField 
						label="Advance Payment Info"
						placeholder="Enter advance payments information..."
						className="big-text"
						name="advanceInfo"
						value={formData.advanceInfo}
						onChange={handleText}
						multiline
					/>
				</Box>
				<SubmitButton showProgress={progress} />
			</Box>
		</StyledModalPaper>
	)
}
export default forwardRef((props, _ref) => <HostelRoomsModal {...props} />)
