import { Box, MenuItem, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { BarChart } from '@mui/x-charts/BarChart';
import { AlertToast, noFetch } from "../../../divine-constants";
import { api } from "../../global/apiConfig";
import AdvancedSearch from "../../../components/AdvancedSearch";
import NumberDisplay from "../../../components/NumberDisplay";


export default function PropertyOwnerDashboard() {
  const { setAlert } = useContext(AlertToast);
  const [advancedSearch, setAdvancedSearch] = useState("weekly");
  const [arrays, setArrays] = useState([[],[],[],[],[],[]]);
  const [arrLen,setArrLen] = useState()
  
  const property_id = localStorage.getItem('UserId')
  const type = localStorage.getItem('userCategory').toLocaleLowerCase()

  const fetchData = async () => {
    const url = `property/property_combined_dashboard?interval=${advancedSearch}&property_id=${property_id}&type=${type}`;
    try {
      const res = await api().get(url);
      if (res.status === 200) {
        setArrLen(res.data[0].length)
        setArrays([res.data[0].map((item) => item.property_name) ,
        res.data[0].map((item) => item.booking_count),
        res.data[0].map((item) => item.cancellation_count),
        res.data[0].reduce((sum, item) => sum + item.total_amount, 0),
        res.data[0].reduce((sum, item) => sum + item.booking_percentage, 0),
        res.data[0].reduce((sum,item) => sum + item.cancellation_percentage, 0),
      ])
      }
    } catch (error) {
      setAlert(noFetch);
    }
  };
  useEffect(() => {
    fetchData();
  }, [advancedSearch]);

const bookingPercentage = arrays[4]/arrLen
const cancellationPercentage = arrays[5]/arrLen

return(
<box component="div">
    <AdvancedSearch>
        <TextField
          select
          required={false}
          name="name"
          value={advancedSearch}
          onChange={(e) => {
            setAdvancedSearch(e.target.value);
          }}
          label="Filter"
          helperText=""
        >
          <MenuItem value="weekly">Weekly</MenuItem>
          <MenuItem value="monthly">Monthly</MenuItem>
          <MenuItem value="yearly">Yearly</MenuItem>
        </TextField>
      </AdvancedSearch>
      
    <Box className="card-container">
       {<NumberDisplay color="text.primary" title="Total Amount" value={`₹ ${arrays[3]}`} />}
        <NumberDisplay color="primary.main" title="Bookings Percentage" value={`${bookingPercentage.toString().slice(0,5)} %`} />
        <NumberDisplay color="error.main" title="Cancellations Percentage" value={`${cancellationPercentage.toString().slice(0,5)} %`} />
      </Box>
      <br></br><br></br>

      {arrays[0].length && arrays[1].length && arrays[2].length && <BarChart
          height={300}
          series={[
            { data: arrays[1], label: 'Booking', id: 'pvId' },
            { data: arrays[2], label: 'Cancellation', id: 'uvId' },
          ]}
          xAxis={[{ data: arrays[0], scaleType: 'band' }]}
        />}
      
</box>
);
}