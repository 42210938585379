import { Route } from "react-router-dom";

import Dashboard from "./pages/moszad/DashBoard/Dashboard";
import Amenities from "./pages/moszad/Amenities";
import Locations from "./pages/moszad/Locations";
import Banners from "./pages/moszad/Banners";
import Customers from "./pages/moszad/Customers";

import HotelCategory from "./pages/moszad/hotels/HotelCategory";
import HotelList from "./pages/moszad/hotels/HotelList";
import HotelBookings from "./pages/moszad/hotels/HotelBookings";
import HotelCheckAvailability from "./pages/moszad/hotels/HotelCheckAvailability";
import {default as WeeklyPaymentsHotel} from "./pages/moszad/hotels/WeeklyPayments";

import HostelCategory from "./pages/moszad/hostels/HostelCategory";
import HostelList from "./pages/moszad/hostels/HostelList";
import HostelBookings from "./pages/moszad/hostels/HostelBookings";
import HostelCheckAvailability from "./pages/moszad/hostels/HostelCheckAvailability";
import HostelSCheduledVisit from "./pages/moszad/hostels/HostelScheduledVisit";
import {default as WeeklyPaymentsHostel} from "./pages/moszad/hostels/WeeklyPayments";

import HallCategory from "./pages/moszad/halls/HallCategory";
import HallList from "./pages/moszad/halls/HallList";
import HallBookings from "./pages/moszad/halls/HallBookings";
import HallEnquiry from "./pages/moszad/halls/HallEnquiry";
import {default as WeeklyPaymentsHall} from "./pages/moszad/halls/WeeklyPayments";

import ToursCategory from "./pages/moszad/tours and travels/ToursCategory";
import ToursPartners from "./pages/moszad/tours and travels/ToursPartners";
import ToursBookings from "./pages/moszad/tours and travels/ToursBookings";
import ToursEnquiry from "./pages/moszad/tours and travels/ToursEnquiry";
import {default as WeeklyPaymentsTour} from "./pages/moszad/tours and travels/WeeklyPayments";

import DistinctChambCategory from "./pages/moszad/distinct chamb/DistinctChambCategory";
import DistinctChambPartners from "./pages/moszad/distinct chamb/DistinctChambPartners";
import DistinctChambEnquiry from "./pages/moszad/distinct chamb/DistinctChambEnquiry";
import DistinctChambBookings from "./pages/moszad/distinct chamb/DistinctChambBookings";
import {default as WeeklyPaymentsChamb} from "./pages/moszad/distinct chamb/WeeklyPayments";

import Settings from "./pages/moszad/settings/Settings";
import Recommendations from "./pages/moszad/home screen/recommendations/Recommendations";
import Offers from "./pages/moszad/home screen/offers/Offers";
import Agent from "./pages/moszad/Agent";

import PaymentList from "./pages/moszad/payment/PaymentList";
import CompanyUser from "./pages/moszad/Company User/CompanyUser";

export const routes = [
	<Route path="/dashboard" element={<Dashboard />} />,
	<Route path="/amenities" element={<Amenities />} />,
	<Route path="/locations" element={<Locations />} />,
	<Route path="/banners-and-offers" element={<Banners />} />,
	<Route path="/customers" element={<Customers />} />,
	<Route path="/agent" element={<Agent />} />,

	<Route path="/hotels">
		<Route path="category" element={<HotelCategory />} />
		<Route path="list" element={<HotelList />} />
		<Route path="bookings" element={<HotelBookings />} />
		<Route path="check-availability" element={<HotelCheckAvailability />} />
		<Route path="weekly-payments" element={<WeeklyPaymentsHotel />} />
	</Route>,

	<Route path="/hostels">
		<Route path="category" element={<HostelCategory />} />
		<Route path="list" element={<HostelList />} />
		<Route path="bookings" element={<HostelBookings />} />
		<Route path="scheduled-visit" element={<HostelSCheduledVisit />} />
		<Route path="check-availability" element={<HostelCheckAvailability />} />
		<Route path="weekly-payments" element={<WeeklyPaymentsHostel />} />
	</Route>,

	<Route path="/halls">
		<Route path="category" element={<HallCategory />} />
		<Route path="list" element={<HallList />} />
		<Route path="bookings" element={<HallBookings />} />
		<Route path="enquiry" element={<HallEnquiry />} />
		<Route path="weekly-payments" element={<WeeklyPaymentsHall />} />
	</Route>,

	<Route path="/tours-and-travels">
		<Route path="category" element={<ToursCategory />} />
		<Route path="partners" element={<ToursPartners />} />
		<Route path="bookings" element={<ToursBookings />} />
		<Route path="enquiry" element={<ToursEnquiry />} />
		<Route path="weekly-payments" element={<WeeklyPaymentsTour />} />
	</Route>,

	<Route path="/distinct-chamb">
		<Route path="category" element={<DistinctChambCategory />} />
		<Route path="partners" element={<DistinctChambPartners />} />
		<Route path="bookings" element={<DistinctChambBookings />} />
		<Route path="enquiry" element={<DistinctChambEnquiry />} />
		<Route path="weekly-payments" element={<WeeklyPaymentsChamb />} />
	</Route>,

	<Route path="/payments" element={<PaymentList />}></Route>,
	<Route path="/company-users" element={<CompanyUser />}></Route>,
	<Route path="/settings" element={<Settings />} />,
	<Route path="/home-screen">
		<Route path="recommendations" element={<Recommendations />} />
		<Route path="offers" element={<Offers />} />
	</Route>
]
