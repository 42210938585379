import { TextField } from "@mui/material";
import { useState, useContext } from "react";
import { alertState, AlertToast } from "../../../divine-constants";
import { api } from "../../global/apiConfig";
import SubmitButton from "../../../components/FormButton";

export default function AboutUs({data, setData}) {
	const {setAlert} = useContext(AlertToast);
	const [progress, setProgress] = useState(false);

	function handleSubmit(event) {
		setProgress(true);
		event.preventDefault();
		api().post("sadmin/setting_about_us", {
			about: data.about_us.trim()
		}).then(res => {
			if(res.data.code === 201) {
				
				setAlert(alertState({
					title: "",
					message: "About details added successfully",
					severity: "success",
					show: true
				}))
			}
		}).catch(() => {
			
			setAlert(alertState({
				title: "",
				message: "Something went wrong",
				severity: "error",
				show: true
			}))
		}).finally(() => setProgress(false));
	}
	function handleChange(event) {
		setData(curr => ({...curr, about_us: event.target.value}))
	}
	return (
		<form onSubmit={handleSubmit}>
			<div>
				<TextField 
					label="About Us"
					placeholder="Enter about us"
					type="text"
					name="about_us"
					onChange={handleChange}
					value={data.about_us}
					fullWidth
					multiline
				/>
			</div>
			<SubmitButton showProgress={progress} type="Update" />
		</form>
	)
}