import { Box, Paper, TableCell, Typography, styled } from "@mui/material";


export const StyledTableHeadCell = styled(TableCell)(({theme}) => ({
	fontWeight: 600,
	fontSize: theme.typography.body1.fontSize,
}));

export const StyledTableHeader = styled(Box)(() => ({
	width: "100%",
	marginBottom: "1rem",
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	textAlign: "right",
	"@media only screen and (max-width: 500px)": {
		flexDirection: "column",
		gap: "1rem"
	}
}));

export const NestedTitle = styled(Typography)(({theme}) => ({
	marginInline: 16,
	color: theme.palette.primary.main,
	fontSize: theme.typography.h3.fontSize,
	textAlign: "left"
}))

export const StyledTableWrapper = styled(Paper)(() => ({
	padding: "3vmin 5vmin 0"
}));