import { useContext, useEffect, useReducer, useState } from "react";
import { Box, TextField, MenuItem, Checkbox, ListItemText, FormControlLabel, Switch, FormControl, InputLabel, Card, CardActionArea, CardContent, CardHeader, Typography, Grid, List, ListItem, ListItemAvatar, LinearProgress, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, TablePagination, Modal, Button } from "@mui/material";
import { Property_URL, api } from "../../pages/global/apiConfig";
import styles from "./CompanyChambInfo.module.css"
import PaymentsModal from "../../modals/PaymentsModal";
import { AlertToast, modalReducer, noFetch } from "../../divine-constants";
import NumberDisplay from "../../components/NumberDisplay";
import AdvancedSearch from "../../components/AdvancedSearch";
import { StyledTableHeadCell, StyledTableHeader } from "../../components/Table";
import { ViewItem } from "../../components/Icons";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";

export function AddbankAccountChamb() {
    const [formData, setFormData] = useState([]);

  
    useEffect(() => {    
      let token = localStorage.getItem("token");
      let property_id = localStorage.getItem("UserId");
      async function Callfunction() {
        let response = await fetch(
         // `https://admin.moszadhotels.com/property/get_hotel_account_details?property_id=${property_id}`,
       `${Property_URL}property/get_chamb_account_details?property_id=${property_id}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        let res = await response.json();      
        setFormData(res);
       
      }
      Callfunction();
    }, []);
   
       return (
		<Box >
		<Grid container spacing={3} justifyContent="start">
		{formData?.map((data,index)=>{
			return(
				<Grid item xs={12} md={6} >
					<Card  >
				<CardActionArea>
				 <CardHeader   title={ <Typography variant="h3" display="block">{`${data.hotel_name} Acccount Details`}</Typography>}></CardHeader>
			  <CardContent sx={{ paddingTop:0}} >
			  <Typography variant="button" display="block" fontWeight={600} gutterBottom>
			  <Box
				sx={{
				  display: 'flex',
				  justifyContent: 'space-between',
				  p: 1,
				  m: 1,
		
				}}
			  >
			   <Box>Name</Box>     
			   <Box>{data?.name}</Box>
			  </Box>
			  
			  </Typography>
			  <Typography variant="button" display="block" fontWeight={600} gutterBottom>
			  <Box
				sx={{
				  display: 'flex',
				  justifyContent: 'space-between',
				  p: 1,
				  m: 1,
		
				}}
			  >
			   <Box>  Property Type </Box>    
			   <Box>{data?.property_type}</Box>
			  </Box>
			
			  </Typography>
			  <Typography variant="button" display="block" fontWeight={600} gutterBottom>
			  <Box
				sx={{
				  display: 'flex',
				  justifyContent: 'space-between',
				  p: 1,
				  m: 1,
		
				}}
			  >
			   <Box> Account Number </Box> 
			   <Box>{data?.account_number}</Box>
			  </Box>
			  
			  </Typography>
			  <Typography variant="button" display="block" fontWeight={600} gutterBottom >
			  <Box
				sx={{
				  display: 'flex',
				  justifyContent: 'space-between',
				  p: 1,
				  m: 1,
		
				}}
			  >
			   <Box> Bank Name </Box>    
			   <Box>{data?.bank_name}</Box>
			  </Box>     
			  </Typography>
	 
			  <Typography variant="button" display="block" fontWeight={600} gutterBottom >
			  <Box
				sx={{
				  display: 'flex',
				  justifyContent: 'space-between',
				  p: 1,
				  m: 1,
		
				}}
			  >
			   <Box> IFSC Code </Box>      
			   <Box>{data?.ifsc}</Box>
			  </Box>     
			  </Typography>
	 
			  <Typography variant="button" display="block" fontWeight={600} gutterBottom >
			  <Box
				sx={{
				  display: 'flex',
				  justifyContent: 'space-between',
				  p: 1,
				  m: 1,
		
				}}
			  >
			   <Box> Bank Code </Box>     
			   <Box>{data?.bank_code}</Box>
			  </Box>     
			  </Typography>
			  </CardContent>
			  </CardActionArea>
			 
			</Card>
				</Grid> 
			)
		})}
		</Grid>     
      </Box>
       )
}

export function WeeklyPaymentsChamb() {
  const {setAlert} = useContext(AlertToast);
	const [rows, setRows] = useState([]);
	const [filteredList, setFilteredList] = useState(rows);
	const [noResults, setNoResults] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [progress, setProgress] = useState(true);
	const [modalOpen, setModalOpen] = useReducer(modalReducer, {view: false});
	const [clickedRow, setClickedRow] = useState();
	const [advancedSearch, setAdvancedSearch] = useState({
		start: "", end: "", chambName: ""
	});
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;
	const [cardData, setCardData] = useState({});
	
	const id = localStorage.getItem("UserId");
	const fetchData = () => api().get(`property/chamb_weekly_payment?property_id=${id}` ).then(res => {
		if(res.status === 200) {
			setRows(res.data.booking_date)
			setCardData(res.data)
			// setRows(res.data.filter(d => d.payment_status !== "unable"));
			setProgress(false);
		}
	}).catch(() => setAlert(noFetch));

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		setFilteredList(rows);
		setPage(0);
	}, [rows]);


	function handlePageChange(_, newPage) {
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(parseInt(event.target.value), 10);
		setPage(0);
	}

	function handleAdvancedSearch(event) {	
		const query = event.target.value.toLowerCase();

		const next = rows.filter(item => {
			return Object.values(item)
				.map(v => {
					const typeValue = typeof v
					if (typeValue === 'string') {
						return v.toString()
					}
				})
				.some(el =>{ 
					const typeValue = typeof el
										if (typeValue === 'string') {
					return el.toLowerCase().includes(query)}
				});
		});
		if (next.length <= 0) setPage(0)
		setFilteredList(next);
		setNoResults(next.length <= 0);
		
		setAdvancedSearch(curr => ({...curr, [event.target.name]: event.target.value}))
	}
	
	
	return (
		<Box component="div">
			<Box className="card-container" style={{display: "flex", gap: "2vmin", width: "100%", justifyContent: "flex-start"}}>
				<NumberDisplay color="primary.main" title="Total Amount" value={cardData.total_amount_sum} />
				<NumberDisplay color="success.main" title="Paid Amount" value={cardData.paid_amount} />
				<NumberDisplay color="error.main" title="Unpaid Amount" value={cardData.unpaid_amount} />
			</Box>
			<br />
			<AdvancedSearch>
				<TextField 
					required={false}
					label="Start Date"
					type="date" 
					name="start"
					value={advancedSearch.start}
					onChange={handleAdvancedSearch}
					helperText=""
				/>
				<TextField 
					required={false}
					label="End Date"
					type="date" 
					name="end"
					value={advancedSearch.end}
					onChange={handleAdvancedSearch}
					helperText=""
				/>
				<TextField 
					select
					required={false}
					name="chambName"
					value={advancedSearch.hotelName}
					onChange={handleAdvancedSearch}
					label="Chamb Name"
					helperText=""
				>
				</TextField>
			</AdvancedSearch>
			<StyledTableHeader>
				{/* <TextField 
					label="Search in Table"
					onChange={handleTableSearch}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Search />
							</InputAdornment>
						)
					}}
					InputLabelProps={{shrink: undefined}}
					required={false}
					helperText={noResults && "No results found"}
					FormHelperTextProps={{
						error: noResults
					}}
				/> */}
			</StyledTableHeader>
			{progress && <LinearProgress />}
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<StyledTableHeadCell>S.No</StyledTableHeadCell>
							<StyledTableHeadCell>Property Name</StyledTableHeadCell>
							<StyledTableHeadCell>Weekly Amount</StyledTableHeadCell>
							<StyledTableHeadCell>Amount To Pay</StyledTableHeadCell>
							<StyledTableHeadCell>Start Date</StyledTableHeadCell>
							<StyledTableHeadCell>End Date</StyledTableHeadCell>
							<StyledTableHeadCell>Payment Status</StyledTableHeadCell>
							<StyledTableHeadCell>View</StyledTableHeadCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0 
							? filteredList.slice(page * rowsPerPage, (page + 1) * rowsPerPage) 
							: filteredList).map((row, i) => 
							<TableRow key={i}>
								<TableCell>{i + 1 + page*rowsPerPage}</TableCell>
								<TableCell>{row.property_name}</TableCell>
								<TableCell>{row.weekly_amount}</TableCell>
								<TableCell>{row.payable_amount}</TableCell>
								<TableCell>{row.start_date}</TableCell>
								<TableCell>{row.end_date}</TableCell>
								<TableCell>{row.payment_status == "unpaid" ? <Button color="warning">Unpaid</Button> : <Button color="success">Paid</Button>}</TableCell>
								<TableCell>
									<ViewItem onClick={() => {setClickedRow(row); setModalOpen({type: "view", to: "open"})}} />
								</TableCell>
							</TableRow>
						)}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={4} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 20, {label: "All", value: -1}]}
				SelectProps={{padding: "normal"}}
				colSpan={100}
				component={"div"}
				count={filteredList.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
			/>
			<Modal 
				open={modalOpen.view} 
				onClose={() => setModalOpen({type: "view", to: "close"})} 
				aria-labelledby="hotel category form" 
				aria-describedby="insert new record"
			><PaymentsModal data={clickedRow?.booking_data} setModalOpen={setModalOpen}/></Modal>
		</Box>
	)
    

}