import { forwardRef, useState, useEffect, useContext } from "react";
import { StyledModalPaper } from "../../styles/modal";
import { Box, TextField, MenuItem, FormControl, FormControlLabel, Checkbox, ListItemText, Switch, InputLabel, InputAdornment } from "@mui/material";
import ModalHeader from "../ModalHeader";
import SubmitButton from "../../components/FormButton";
import { noFetch, AlertToast, alertState } from "../../divine-constants";
import { CurrencyRupee, PercentOutlined } from "@mui/icons-material";
import { api } from "../../pages/global/apiConfig";
import Fieldset from "../../components/Fieldset";
import { arrayStringify } from "../../utils";

function PaymentListEditModal({setModalOpen, fetchData, data, id}) {
		const {setAlert} = useContext(AlertToast);
	const [areas, setAreas] = useState([]);
	const [progress, setProgress] = useState(false);
	const [formData, setFormData] = useState({
		id : data._id,
		name: data.name,
		property_type: data.property_type,
		property_id : data.property_id,
		account_number: data.account_number,
		bank_name: data.bank_name,
		ifsc: data.ifsc,
		bank_code: data.bank_code,
		status:  data.status,
		
	});
	function handleText(event) {
		setFormData(current => ({...current, [event.target.name]: event.target.value}));
	}

	function handleMainImg(event) {
		setFormData(current => ({...current, [event.target.name]: event.target.files[0]}));
	}

	function handleOtherImg(event) {
		setFormData(current => ({...current, [event.target.name]: event.target.files}));
	}

	function handleSingleSelect(event) {
		setFormData(current => ({...current, [event.target.name]: event.target.value}));
	}

	function handleFormSubmit(event) {
		event.preventDefault();
		setProgress(true);
		api().patch("sadmin/edit_hotel_account", {
			property_id:formData.property_id,
			account_id:data.id,
			name: formData.name.trim(),
			property_type: formData.property_type,
			account_number: formData.account_number,
			bank_name: formData.bank_name,
			bank_code: formData.bank_code,
			ifsc: formData.ifsc,
			active_status: formData.status,	
			
		}).then(res => {
			if(res.data.code === 200) {
								setAlert(alertState({
					message: "Account Details Edited successfully",
					severity: "success",
					show: true
				}));
				setModalOpen({type: "edit", to: "close"});
				fetchData();
			}
					}).catch(err => {
			if(err.status === 401) {
				setAlert(alertState({
					message: "You are not authorized to add a new hall.",
					severity: "warning",
					show: true
				}))
			} else if (err.status === 403) {
				setAlert(alertState({
					message: "You do not have access to add a new hall.",
					severity: "warning",
					show: true
				}))
			} else 
				setAlert(alertState({
					message: "Something went wrong. Please try again",
					severity: "error",
					show: true
				}))

		}).finally(() => setProgress(false));
	}


	return (
		<StyledModalPaper>
			<ModalHeader setModalOpen={setModalOpen} type="edit"/>
			<Box component="form" className="list-form" onSubmit={handleFormSubmit} noValidate>
				<Box>
					<Fieldset legend="Account Details" className="form-grid-container four-column-layout">
					<TextField
							label="Name"
							type="text"
							name="name"
							value={formData.name}
							onChange={handleText}
							placeholder="Enter Name"
						/>

						<TextField 
							select 
							label="Property Type"
							defaultValue={formData.property_type}
							value={formData.property_type}
							name="property_type"
							onChange={(e)=>{setFormData({...formData,property_type: e.target.value})}}
							>
								<MenuItem value="hotel">Hotel</MenuItem>
								<MenuItem value="hostel">hostel</MenuItem>
								<MenuItem value="hall">hall</MenuItem>
								<MenuItem value="tour">tour</MenuItem>
								<MenuItem value="chamb">chamb</MenuItem>
						</TextField>

						<TextField
							label="Bank Name"
							type="text"
							name="bank_name"
							value={formData.bank_name}
							onChange={handleText}
							placeholder="Enter Bank Name"
						/>

						<TextField
							required={false}
							label="Account Number"
							type="text"
							name="account_number"
							value={formData.account_number}
							onChange={handleText}
							placeholder="Enter Account Number"
						/>
						<TextField
							label="IFSC Code"
							type="text"
							name="ifsc"
							value={formData.ifsc}
							onChange={handleText}
							placeholder="Enter IFSC Code"
						/>

						<TextField
							label="Bank Code"
							type="text"
							name="bank_code"
							value={formData.bank_code}
							onChange={handleText}
							placeholder="Enter Bank Code"
						/>

						{/* <FormControl className="inline-form-control" required={false}>
						<InputLabel shrink>Status</InputLabel>
						<FormControlLabel 
							label={formData.status ? "Active" : "Inactive"} 
							onChange={e => setFormData(curr => ({...curr, status: e.target.checked}))} 
							checked={formData.status}
							control={<Switch/>} 
						/>
					</FormControl> */}
						
					</Fieldset>					
				</Box>
				<SubmitButton showProgress={progress} />
			</Box>
		</StyledModalPaper>
	)
}
export default forwardRef((props, _ref) => <PaymentListEditModal {...props} />)
