import { forwardRef, useState, useEffect, useContext } from "react";
import { StyledModalPaper } from "../../styles/modal";
import { Box, TextField, MenuItem, FormControl, FormControlLabel, Checkbox, ListItemText, Switch, InputLabel, InputAdornment } from "@mui/material";
import ModalHeader from "../ModalHeader";
import SubmitButton from "../../components/FormButton";
import { noFetch, AlertToast, alertState } from "../../divine-constants";
import { CurrencyRupee, PercentOutlined } from "@mui/icons-material";
import { api } from "../../pages/global/apiConfig";
import Fieldset from "../../components/Fieldset";
import { arrayStringify } from "../../utils";

function HallListModal({setModalOpen, fetchData}) {
	const {setAlert} = useContext(AlertToast);
	const [areas, setAreas] = useState([]);
	const [progress, setProgress] = useState(false);
	const [formData, setFormData] = useState({
		name: "",
		categories: [],
		country: "",
		state: "",
		city: "",
		area: "",
		pincode: "",
		latitude: "",
		longitude: "",
		iFrame: "",
		price: "",
		discount: "",
		advance: "",
		advancePaymentInfo: "",
		minCapacity: "",
		maxCapacity: "",
		address: "",
		description: "",
		policy: "",
		gender: "",
		mainImg: "",
		otherImgs: [],
		amenities: [],
		status: false,
		telNum: "",
		username: "",
		email: "",
		password: "",
	});

	const [selectArrays, setSelectArrays] = useState({
		cities: [],
		amenities: [],
		categories: []
	});

	useEffect(() => {
		Promise.all([
			api().get('sadmin/get_city_location'),
			api().get('sadmin/get_amenities'),
			api().get('sadmin/get_category?type=hall')
		]).then(results => {
			setSelectArrays({
				cities: results[0].data,
				amenities: results[1].data,
				categories: results[2].data
			});	
		}).catch(() => setAlert(noFetch));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if(selectArrays.cities.length > 0 && formData.city !== "") {
			api().get('sadmin/get_place_in_city?location_id=' + selectArrays.cities.filter(it => it.location_name === formData.city)[0].id).then(res => {
				setAreas(res.data);
			}).catch(() => setAlert(noFetch));
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData.city])

	function handleText(event) {
		setFormData(current => ({...current, [event.target.name]: event.target.value}));
	}

	function handleMainImg(event) {
		setFormData(current => ({...current, [event.target.name]: event.target.files[0]}));
	}

	function handleOtherImg(event) {
		setFormData(current => ({...current, [event.target.name]: event.target.files}));
	}

	function handleSingleSelect(event) {
		setFormData(current => ({...current, [event.target.name]: event.target.value}));
	}

	function handleFormSubmit(event) {
		event.preventDefault();
		setProgress(true);
		api().post("sadmin/add_hall", {
			name: formData.name.trim(),
			category: arrayStringify(formData.category),
			country: formData.country,
			state: formData.state,
			location: formData.city,
			subtitle: "",
			staring_price: formData.price,
			discount_percentage: formData.discount,
			advance_payment: formData.advance,
			advance_payment_info: formData.advancePaymentInfo,
			minimum_capacity: formData.minCapacity,
			maximum_capacity: formData.maxCapacity,
			address: formData.address,
			iframe:formData.iFrame,
			latitude: formData.latitude,
			longitude: formData.longitude,
			hall_description: formData.description.trim(),
			hall_poilcy: formData.policy.trim(),
			hall_amentities: arrayStringify(formData.amenities),
			pincode: formData.pincode,
			main_image: formData.mainImg,
			other_image: Array.from(formData.otherImgs),
			active_status: formData.status,
			type: "hall",
			user_name: formData.username.trim(),
			password: formData.password.trim(),
			email: formData.email.trim(),
			mobile_no: formData.telNum,
			role: "super_admin",
			property_name: "hall",
			area: formData.area
		}).then(res => {
			if(res.data.code === 201) {
					setAlert(alertState({
					message: "Hall added successfully",
					severity: "success",
					show: true
				}));
				setModalOpen({type: "addNew", to: "close"});
				fetchData();
			}
		}).catch(err => {
			if(err.status === 401) {
				setAlert(alertState({
					message: "You are not authorized to add a new hall.",
					severity: "warning",
					show: true
				}))
			} else if (err.status === 403) {
				setAlert(alertState({
					message: "You do not have access to add a new hall.",
					severity: "warning",
					show: true
				}))
			} else 
				setAlert(alertState({
					message: "Something went wrong. Please try again",
					severity: "error",
					show: true
				}))

		}).finally(() => setProgress(false));
	}

	return (
		<StyledModalPaper>
			<ModalHeader setModalOpen={setModalOpen} type="addNew"/>
			<Box component="form" className="list-form" onSubmit={handleFormSubmit} noValidate>
				<Box>
					<Fieldset legend="Hall details" className="form-grid-container four-column-layout">
						<TextField
							label="Name"
							type="text"
							name="name"
							onChange={handleText}
							value={formData.name}
							placeholder="Enter hall name"
						/>

						<TextField 
							select 
							SelectProps={{multiple: true, renderValue: selected => selected.map(s => selectArrays.categories.find(v => v.id === s).category_name) + ', '}}
							label="Categories"
							defaultValue={formData.categories}
							value={formData.categories}
							name="categories"
							onChange={handleSingleSelect}
							>
								{selectArrays.categories.map(category => (
									<MenuItem									
										key={category.id}
										value={category.id}
									>
										<Checkbox checked={formData.categories.indexOf(category.id) > -1} />
										<ListItemText>{category.category_name}</ListItemText>
									</MenuItem>
								))}
						</TextField>

						<TextField
							label="Amenities"
							select
							SelectProps={{multiple: true, renderValue: selected => selected.map(s => selectArrays.amenities.find(v => v.id === s).amenities_name) + ', '}}
							value={formData.amenities}
							onChange={handleSingleSelect}
							name="amenities"
							placeholder="Select Amenities"
						>
							{selectArrays.amenities.map(amenity => (
								<MenuItem									
									key={amenity.id}
									value={amenity.id}
								>
									<Checkbox checked={formData.amenities.indexOf(amenity.id) > -1} />
									<ListItemText>{amenity.amenities_name}</ListItemText>
								</MenuItem>
							))}
						</TextField>

						<TextField 
							select
							label="Country"
							defaultValue={formData.country}
							value={formData.country}
							name="country"
							onChange={handleSingleSelect}
							>
								{["India"].map((country, i) => (
									<MenuItem value={country} key={i}>{country}</MenuItem>
								))}
						</TextField>
						<TextField 
							select
							label="State"
							defaultValue={formData.state}
							value={formData.state}
							name="state"
							onChange={handleSingleSelect}
							>
								{["Tamil Nadu"].map((state, i) => (
									<MenuItem value={state} key={i}>{state}</MenuItem>
								))}
						</TextField>
						<TextField 
							select
							label="City"
							defaultValue={formData.city}
							value={formData.city}
							name="city"
							onChange={handleSingleSelect}
							>
								{selectArrays.cities.map(city => (
									<MenuItem value={city.location_name} key={city.id}>{city.location_name}</MenuItem>
								))}
						</TextField>

						<TextField
							select
							label="Area"
							name="area"
							value={formData.area}
							onChange={handleSingleSelect}
						>
							{areas.map((area, i) => (
								<MenuItem value={area} key={i}>{area}</MenuItem>
							))}
						</TextField>

						<TextField
							label="Pincode"
							type="text"
							name="pincode"
							value={formData.pincode}
							onChange={handleText}
							placeholder="Eg. 600091"
						/>	

						<TextField
							label="Latitude"
							type="number"
							inputProps={{step: 0.00001}}
							InputProps={{endAdornment: <InputAdornment position="end">North</InputAdornment>}}
							name="latitude"
							value={formData.latitude}
							onChange={handleText}
							placeholder="Eg. 21.233511799948"
						/>

						<TextField
							label="Longitude"
							type="text"
							name="longitude"
							InputProps={{endAdornment: <InputAdornment position="end">East</InputAdornment>}}
							value={formData.longitude}
							onChange={handleText}
							placeholder="Eg. -110.640468316400"
						/>	

						<TextField
							required={false}
							label="Embedded iFrame"
							type="text"
							name="iFrame"
							onChange={handleText}
							value={formData.iFrame}
							placeholder="Enter iFrame link"
						/>

						<TextField
							required={false}
							label="Address"
							type="text"
							name="address"
							onChange={handleText}
							value={formData.address}
							placeholder="Enter address"
						/>
						
						<TextField
							required={false}
							label="Price"
							type="number"
							name="price"
							InputProps={{startAdornment: <InputAdornment position="start"><CurrencyRupee /></InputAdornment>}}
							onChange={handleText}
							value={formData.price}
							placeholder="Enter price"
						/>
						
						<TextField
							required={false}
							label="Discount percentage"
							type="number"
							name="discount"
							InputProps={{
								endAdornment: <InputAdornment position="end"><PercentOutlined/></InputAdornment>
							}}
							onChange={handleText}
							value={formData.discount}
							placeholder="Enter discount %"
						/>
						
						<TextField
							required={false}
							label="Advance payment"
							type="number"
							name="advance"
							onChange={handleText}
							value={formData.advance}
							placeholder="Enter advance payment"
						/>
						
						<TextField
							required={false}
							label="Advance payment info"
							type="text"
							name="advancePaymentInfo"
							onChange={handleText}
							value={formData.advancePaymentInfo}
							placeholder="Enter advance payment info"
						/>
						
						<TextField
							required={false}
							label="Minimum capacity"
							type="number"
							inputProps={{min: 0}}
							name="minCapacity"
							onChange={handleText}
							value={formData.minCapacity}
							placeholder="Enter minimum capacity"
						/>
						
						<TextField
							required={false}
							label="Maximum capacity"
							type="number"
							inputProps={{min: 0}}
							name="maxCapacity"
							onChange={handleText}
							value={formData.maxCapacity}
							placeholder="Enter maximum capacity"
						/>

						<TextField
							label="Main Image"
							type="file"
							name="mainImg"
							onChange={handleMainImg}
							placeholder="Input image file"
						/>

						<TextField
							required={false}
							label="Other Images"
							type="file"
							inputProps={{multiple: true}}
							onChange={handleOtherImg}
							name="otherImgs"
						/>

						<FormControl className="inline-form-control" required={false}>
							<InputLabel shrink>Status</InputLabel>
							<FormControlLabel 
								label={formData.status ? "Active" : "Inactive"} 
								value={formData.status}
								onChange={e => setFormData(curr => ({...curr, status: e.target.checked}))} 
								checked={formData.status}
								control={<Switch/>} 
							/>
						</FormControl>

						<TextField 
							label="Description"
							placeholder="Enter Description..."
							className="big-text"
							name="description"
							onChange={handleText}
							value={formData.description}
							multiline
						/>

						<TextField 
							label="Policy"
							placeholder="Enter Policy..."
							className="big-text"
							name="policy"
							onChange={handleText}
							value={formData.policy}
							multiline
						/>
					</Fieldset>

					<Fieldset legend="User Details" className="form-grid-container four-column-layout">
						<TextField
							label="Mobile"
							type="tel"
							inputMode="tel"
							name="telNum"
							value={formData.telNum}
							onChange={handleText}
							placeholder="Enter mobile number"
						/>

						<TextField
							label="Username"
							type="text"
							name="username"
							value={formData.username}
							onChange={handleText}
							placeholder="Enter username"
						/>

						<TextField
							label="Email"
							type="email"
							name="email"
							value={formData.email}
							onChange={handleText}
							placeholder="Enter email"
						/>

						<TextField
							label="Password"
							type="password"
							name="password"
							value={formData.password}
							onChange={handleText}
							placeholder="Enter password"
						/>
					</Fieldset>
				</Box>
				<SubmitButton showProgress={progress} />
			</Box>
		</StyledModalPaper>
	)
}
export default forwardRef((props, _ref) => <HallListModal {...props} />)
