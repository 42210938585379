import { Fragment, useEffect, useState, useReducer } from "react";
import { TableContainer, Box, Table, TableHead, TableBody, TableRow, Button, Modal, TableCell, TablePagination, TextField, InputAdornment, LinearProgress, Collapse, IconButton } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Add, Search } from "@mui/icons-material";
import { Navigate } from "react-router-dom";
import { api } from "../../global/apiConfig";
import { StyledTableHeadCell, StyledTableHeader } from "../../../components/Table";
import { Accadded, AddBank, EditItem, Redbin, ViewItem } from "../../../components/Icons";
import { NestedTitle } from "../../../components/Table";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import HostelListModal from "../../../modals/hostels/HostelListModal";
import HostelRoomsModal from "../../../modals/hostels/HostelRoomsModal";
import DeleteModal from "../../../modals/DeleteModal";
import { tableImageHeight, modalReducer } from "../../../divine-constants";
import HostelRoomViewModal from "../../../modals/hostels/HostelRoomViewModal";
import HostelEditModal from "../../../modals/hostels/HostelEditModal";
import HostelListViewModal from "../../../modals/hostels/HostelListViewModal";
import HostelPaymentModal from "../../../modals/hostels/HostelPaymentModal";

export default function CompanyUserHostelList() {
	const [rows, setRows] = useState([]);
	const [filteredList, setFilteredList] = useState(rows);
	const [noResults, setNoResults] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const [modalOpen, setModalOpen] = useReducer(modalReducer, {
		addNew: false,
		addNewNested: false,
		view: false,
		edit: false,
		viewNested: false,
		editNested: false,
		addBank:false,

	});
	const [progress, setProgress] = useState({display: true, type: "regular"});
	const [clickedRow, setClickedRow] = useState(); 
	const [nestedClickedRow, setNestedClickedRow] = useState();
	const [Id,setId] = useState({})
	const [nestedRows, setNestedRows] = useState([]);
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;

	const id = localStorage.getItem('UserId')
	const fetchData = () => api().get("sadmin/get_suser_hostel_list?user_id="+id).then(res => {
		if(res.status === 200) {
			setRows(res.data);
			setProgress({display: false, type: ""});
		}
	}).catch(() => <Navigate to="/login" replace />);

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		setFilteredList(rows);
	}, [rows]);

	useEffect(() => {
		if(clickedRow) {
			setNestedRows([]);
			setProgress({display: true, type: "nested"});
			api().get('sadmin/get_hostel_room_list?hostel_id=' + clickedRow._id).then(res => {
				setNestedRows(res.data);
				setProgress({display: false, type: ""})
			})
		}
		if(!clickedRow) setNestedRows([]);
	}, [clickedRow])

	function handlePageChange(event, newPage) {
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(parseInt(event.target.value), 10);
		setPage(0);
	}
	
	function handleTableSearch(event) {
		const query = event.target.value
		const next = rows.filter(item => {
			return Object
			.values(item)
			.map(v => v.toString())
			.some(el => el.toLowerCase().includes(query));
		});
		if(next.length <= 0) setPage(0)
		setFilteredList(next);
		setNoResults(next.length <= 0);
	}
	return (
		<Box component="div">
			<StyledTableHeader>
				{/* <TextField 
					label="Search in Table"
					onChange={handleTableSearch}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Search />
							</InputAdornment>
						)
					}}
					InputLabelProps={{shrink: undefined}}
					required={false}
					helperText={noResults && "No results found"}
					FormHelperTextProps={{
						error: noResults
					}}
				/> */}
				<Button onClick={() => setModalOpen({type: "addNew", to: "open"})}><Add /> Add new</Button>
			</StyledTableHeader>
			{progress.display && progress.type === "regular" && <LinearProgress />}
			<TableContainer>
				<Table className="nested-table">
					<TableHead>
						<TableRow>
							<StyledTableHeadCell />
							<StyledTableHeadCell>S.No</StyledTableHeadCell>
							<StyledTableHeadCell>Name</StyledTableHeadCell>
							<StyledTableHeadCell>Image</StyledTableHeadCell>
							<StyledTableHeadCell>Category</StyledTableHeadCell>
							<StyledTableHeadCell>Mobile</StyledTableHeadCell>
							<StyledTableHeadCell>Email</StyledTableHeadCell>
							<StyledTableHeadCell>Total Rooms</StyledTableHeadCell>
							<StyledTableHeadCell>Action</StyledTableHeadCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0 
							? filteredList.slice(page * rowsPerPage, (page + 1) * rowsPerPage) 
							: filteredList).map((row, ind) => (<Fragment key={ind}>
							<TableRow>
								<TableCell><IconButton disabled={progress.display} onClick={() => setClickedRow(current => current?._id === row?._id ? null : row)}>{clickedRow?._id === row._id ? <ExpandLess /> : <ExpandMore />}</IconButton></TableCell>
								<TableCell>{ind + 1 + page*rowsPerPage}</TableCell>
								<TableCell>{row.name}</TableCell>
								<TableCell><img height={tableImageHeight} src={row.main_image} alt={row.main_image} /></TableCell>
								<TableCell>{row.category}</TableCell>
								<TableCell>{row.mobile_no}</TableCell>
								<TableCell>{row.email}</TableCell>
								<TableCell>{row.total_rooms}</TableCell>

								<TableCell>{row.account_details == 0 ?<AddBank onClick={() => {setNestedClickedRow(row); setModalOpen({type: "addBank", to: "open"});setId({hostel_id:row._id})}}/> :<Accadded/>}				

								<ViewItem onClick={() => {setNestedClickedRow(row); setModalOpen({type: "view", to: "open"})}}/>
								<EditItem onClick={() => {setClickedRow(row); setModalOpen({type: "edit", to: "open"})}} />
									<Redbin onClick={() => {setClickedRow(row); setDeleteModal(true)}} />
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell colSpan={9}>
									<Collapse in={clickedRow?._id === row._id}>
										{progress.display && progress.type === "nested" && <LinearProgress />}
										<Box>
											<StyledTableHeader style={{justifyContent: "flex-start"}}>
												<NestedTitle>{row.name} - Room List</NestedTitle>
												<Button variant="outlined" onClick={() => {setNestedClickedRow(row); setModalOpen({type: "addNewNested", to: "open"});setId({hostel_id:row._id})}}><Add /> Add new</Button>
											</StyledTableHeader>
											<Table>
												<TableHead>
													<TableRow>
														<StyledTableHeadCell>S.No</StyledTableHeadCell>
														<StyledTableHeadCell>Room Type</StyledTableHeadCell>
														<StyledTableHeadCell>Price</StyledTableHeadCell>
														<StyledTableHeadCell>Total Rooms</StyledTableHeadCell>
														<StyledTableHeadCell>Action</StyledTableHeadCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{nestedRows.map((nestedRow, nestInd) => 
														<TableRow key={nestedRow.id}>
															<TableCell>{nestInd + 1}</TableCell>
															<TableCell>{nestedRow.room_type}</TableCell>
															<TableCell>{nestedRow.price}</TableCell>
															<TableCell>{nestedRow.no_of_rooms}</TableCell>
															<TableCell>
															<ViewItem onClick={() => {setNestedClickedRow(nestedRow); setModalOpen({type: "viewNested", to: "open"})}}/>
															<EditItem onClick={() => {setNestedClickedRow(nestedRow); setModalOpen({type: "editNested", to: "open"});setId({hostel_id:row._id,type:"edit"})}} />
																<Redbin />
															</TableCell>
														</TableRow>
													)}
												</TableBody>
											</Table>
										</Box>
									</Collapse>
								</TableCell>
							</TableRow>
							</Fragment>
						))}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={4} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[20, 40, {label: "All", value: -1}]}
				colSpan={100}
				count={filteredList.length}
				component="div"
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
			/>
			<Modal 
				open={modalOpen.addNew} 
				onClose={() => setModalOpen({type: "addNew", to: "close"})} 
				aria-labelledby="hostel list form" 
				aria-describedby="insert new record"
			><HostelListModal fetchData={fetchData} setModalOpen={setModalOpen} type="addNew" modalOpen={modalOpen}/></Modal>
	        
			<Modal 
				open={modalOpen.addBank} 
				onClose={() => setModalOpen({type: "addBank", to: "close"})} 
				aria-labelledby="Add Bank Account form" 
				aria-describedby="insert new record"
			><HostelPaymentModal setModalOpen={setModalOpen} fetchData={fetchData} id={Id} /></Modal>

			
			<Modal 
				open={modalOpen.edit} 
				onClose={() => setModalOpen({type: "edit", to: "close"})} 
				aria-labelledby="hostel list form" 
				aria-describedby="insert new record"
			><HostelEditModal setModalOpen={setModalOpen} modalOpen={modalOpen} fetchData={fetchData} data={clickedRow} /></Modal>
			<Modal 
				open={modalOpen.editNested } 
				onClose={() => setModalOpen({type: "editNested", to: "close"})} 
				aria-labelledby="hostel list form" 
				aria-describedby="insert new record"
			><HostelRoomsModal fetchData={fetchData} setModalOpen={setModalOpen} data={nestedClickedRow} id={Id}/></Modal>
			<Modal 
				open={modalOpen.addNewNested} 
				onClose={() => setModalOpen({type: "addNewNested", to: "close"})} 
				aria-labelledby="hostel room list form" 
				aria-describedby="insert new hostel room"
			><HostelRoomsModal fetchData={fetchData} setModalOpen={setModalOpen}  id={Id} /></Modal>

		<Modal 
			open={modalOpen.viewNested} 
			onClose={() => setModalOpen({type: "viewNested", to: "close"})} 
			aria-labelledby="hostel list form" 
			aria-describedby="insert new record"
		><HostelRoomViewModal setModalOpen={setModalOpen} data={nestedClickedRow} /></Modal>

	<Modal 
		open={modalOpen.view} 
		onClose={() => setModalOpen({type: "view", to: "close"})} 
		aria-labelledby="Hotel List" 
		aria-describedby="insert new record"
	><HostelListViewModal setModalOpen={setModalOpen} data={nestedClickedRow} /></Modal>

			<DeleteModal dialogDisplay={deleteModal} setDialogDisplay={setDeleteModal} url={"sadmin/delete_hostel"} data={{hostel_id: clickedRow?._id}} fetchData={fetchData} />
		</Box>
	)
}