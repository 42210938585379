import { useContext, useEffect, useState, useReducer } from "react";
import { TableContainer, Box, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, TextField, InputAdornment, LinearProgress, MenuItem, Modal, Button } from "@mui/material";
import { Search } from "@mui/icons-material";
import { StyledTableHeadCell, StyledTableHeader } from "../../../components/Table";
import { ViewItem } from "../../../components/Icons";
import { AlertToast, noFetch } from "../../../divine-constants";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import AdvancedSearch from "../../../components/AdvancedSearch";
import { api } from "../../global/apiConfig";
import NumberDisplay from "../../../components/NumberDisplay";
import PaymentsModal from "../../../modals/PaymentsModal";
import { modalReducer } from "../../../divine-constants";

export default function WeeklyPayments() {
	const {setAlert} = useContext(AlertToast);
	const [rows, setRows] = useState([]);
	const [filteredList, setFilteredList] = useState(rows);
	const [noResults, setNoResults] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [progress, setProgress] = useState(true);
	const [modalOpen, setModalOpen] = useReducer(modalReducer, {view: false});
	const [clickedRow, setClickedRow] = useState();
	const [advancedSearch, setAdvancedSearch] = useState({
		start: "", end: "", chambName: ""
	});
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;
	const [cardData, setCardData] = useState({});

	const fetchData = () => api().get('sadmin/chamb_weekly_payment?type=chamb').then(res => {
		if(res.status === 200) {
			setRows(res.data.booking_date)
			setCardData(res.data)
			// setRows(res.data.filter(d => d.payment_status !== "unable"));
			setProgress(false);
		}
	}).catch(() => setAlert(noFetch));

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		setFilteredList(rows);
		setPage(0);
	}, [rows]);


	function handlePageChange(_, newPage) {
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(parseInt(event.target.value), 10);
		setPage(0);
	}

	function handleAdvancedSearch(event) {	
		const query = event.target.value.toLowerCase();
		if(query === "select"){
			setAdvancedSearch(curr => ({...curr, [event.target.name]: event.target.value}));
			fetchData();
			return
		}
		const next = rows.filter(item => {
			return Object.values(item)
				.map(v => {
					const typeValue = typeof v
					if (typeValue === 'string') {
						return v.toString()
					}
				})
				.some(el =>{ 
					const typeValue = typeof el
										if (typeValue === 'string') {
					return el.toLowerCase().includes(query)}
				});
		});
		if (next.length <= 0) setPage(0)
		setFilteredList(next);
		setNoResults(next.length <= 0);
		
		setAdvancedSearch(curr => ({...curr, [event.target.name]: event.target.value}))
	}
	
	
	return (
		<Box component="div">
			<Box className="card-container" style={{display: "flex", gap: "2vmin", width: "100%", justifyContent: "flex-start"}}>
				<NumberDisplay color="primary.main" title="Total Amount" value={cardData.total_amount_sum} />
				<NumberDisplay color="success.main" title="Paid Amount" value={cardData.paid_amount} />
				<NumberDisplay color="error.main" title="Unpaid Amount" value={cardData.unpaid_amount} />
			</Box>
			<br />
			<AdvancedSearch>
				<TextField 
					required={false}
					label="Start Date"
					type="date" 
					name="start"
					value={advancedSearch.start}
					onChange={handleAdvancedSearch}
					helperText=""
				/>
				<TextField 
					required={false}
					label="End Date"
					type="date" 
					name="end"
					value={advancedSearch.end}
					onChange={handleAdvancedSearch}
					helperText=""
				/>
				<TextField 
					select
					required={false}
					name="chambName"
					value={advancedSearch.hotelName}
					onChange={handleAdvancedSearch}
					label="Chamb Name"
					helperText=""
				>
					<MenuItem value={"select"}>Select</MenuItem>
					{rows.map((item, ind) => <MenuItem key={ind} value={item.property_name}>{item.property_name}</MenuItem>)}
				</TextField>
			</AdvancedSearch>
			<StyledTableHeader>
				{/* <TextField 
					label="Search in Table"
					onChange={handleTableSearch}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Search />
							</InputAdornment>
						)
					}}
					InputLabelProps={{shrink: undefined}}
					required={false}
					helperText={noResults && "No results found"}
					FormHelperTextProps={{
						error: noResults
					}}
				/> */}
			</StyledTableHeader>
			{progress && <LinearProgress />}
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<StyledTableHeadCell>S.No</StyledTableHeadCell>
							<StyledTableHeadCell>Property Name</StyledTableHeadCell>
							<StyledTableHeadCell>Weekly Amount</StyledTableHeadCell>
							<StyledTableHeadCell>Amount To Pay</StyledTableHeadCell>
							<StyledTableHeadCell>Start Date</StyledTableHeadCell>
							<StyledTableHeadCell>End Date</StyledTableHeadCell>
							<StyledTableHeadCell>Payment Status</StyledTableHeadCell>
							<StyledTableHeadCell>View</StyledTableHeadCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0 
							? filteredList.slice(page * rowsPerPage, (page + 1) * rowsPerPage) 
							: filteredList).map((row, i) => 
							<TableRow key={i}>
								<TableCell>{i + 1 + page*rowsPerPage}</TableCell>
								<TableCell>{row.property_name}</TableCell>
								<TableCell>{row.weekly_amount}</TableCell>
								<TableCell>{row.payable_amount}</TableCell>
								<TableCell>{row.start_date}</TableCell>
								<TableCell>{row.end_date}</TableCell>
								<TableCell>{row.payment_status == "unpaid" ? <Button color="warning">Unpaid</Button> : <Button color="success">Paid</Button>}</TableCell>
								<TableCell>
									<ViewItem onClick={() => {setClickedRow(row); setModalOpen({type: "view", to: "open"})}} />
								</TableCell>
							</TableRow>
						)}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={4} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 20, {label: "All", value: -1}]}
				SelectProps={{padding: "normal"}}
				colSpan={100}
				component={"div"}
				count={filteredList.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
			/>
			<Modal 
				open={modalOpen.view} 
				onClose={() => setModalOpen({type: "view", to: "close"})} 
				aria-labelledby="hotel category form" 
				aria-describedby="insert new record"
			><PaymentsModal data={clickedRow?.booking_data} setModalOpen={setModalOpen}/></Modal>
		</Box>
	)
}