import { useEffect, useState } from "react";
import { TableContainer, Box, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, TextField, InputAdornment, LinearProgress, MenuItem, Modal, Button } from "@mui/material";
import { Search } from "@mui/icons-material";
import { StyledTableHeadCell, StyledTableHeader } from "../../components/Table";
import { ViewItem } from "../../components/Icons";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import AdvancedSearch from "../../components/AdvancedSearch";
import { api } from "../global/apiConfig";
import NumberDisplay from "../../components/NumberDisplay";
import HotelBookingsModal from "../../modals/BookingsModal";

export default function OfflineTicket() {
	const d = new Date();
    let date = `${d.getDate() < 10 ? "0" : ""}${d.getDate().toString()}`;
    let month = `${d.getMonth() + 1 < 10 ? "0" : ""}${(d.getMonth() + 1).toString()}`;
    let year = d.getFullYear().toString();

	const [rows, setRows] = useState([]);
	const [filteredList, setFilteredList] = useState(rows);
	const [noResults, setNoResults] = useState(false);
	const [modal, setModal] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [progress, setProgress] = useState(true);
	const [clickedRow, setClickedRow] = useState();
	const [advancedSearch, setAdvancedSearch] = useState({
		start: `${year}-${month}-${date}`, end: "",
	});
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;

	const id = localStorage.getItem("UserId");
	const fetchData = () => api().get(`property/chamb_offline_booking?property_id=${id}&&start_date=${advancedSearch.start}` ).then(res => {
		if(res.status === 200) {
			setRows(res.data[0].package_data);	
			setProgress(false);	
		}
	})
	useEffect(() => {
		fetchData(advancedSearch.start);
	}, [advancedSearch.start]);

	useEffect(() => {
		setFilteredList(rows);
		setPage(0);
	}, [rows]);


	function handlePageChange(_, newPage) {
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(parseInt(event.target.value), 10);
		setPage(0);
	}

	function handleAdvancedSearch(event) {
		setAdvancedSearch(curr => ({...curr, [event.target.name]: event.target.value}))
	}
	
	function handleTableSearch(event) {
		const query = event.target.value
		const next = rows.filter(item => {
			return Object
			.values(item)
			.map(v => v.toString())
			.some(el => el.toLowerCase().includes(query));
		});
		if(next.length <= 0) setPage(0)
		setFilteredList(next);
		setNoResults(next.length <= 0);
	}
	return (
		<Box component="div">			
			<AdvancedSearch>
				<TextField 
					required={false}
					label="Date"
					type="date" 
					name="start"
					value={advancedSearch.start}
					onChange={handleAdvancedSearch}
					helperText=""
					inputProps={{
						min: `${year}-${month}-${date}`
					}}
				/>
				
			</AdvancedSearch>
			<StyledTableHeader>
				{/* <TextField 
					label="Search in Table"
					onChange={handleTableSearch}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Search />
							</InputAdornment>
						)
					}}
					InputLabelProps={{shrink: undefined}}
					required={false}
					helperText={noResults && "No results found"}
					FormHelperTextProps={{
						error: noResults
					}}
				/> */}
			</StyledTableHeader>
			{progress && <LinearProgress />}
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<StyledTableHeadCell>S.No</StyledTableHeadCell>
							<StyledTableHeadCell>Name</StyledTableHeadCell>							
							<StyledTableHeadCell>Price</StyledTableHeadCell>
							<StyledTableHeadCell>Discount Amount</StyledTableHeadCell>
							<StyledTableHeadCell>Action</StyledTableHeadCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0 
							? filteredList.slice(page * rowsPerPage, (page + 1) * rowsPerPage) 
							: filteredList).map((row, i) => 
							<TableRow key={i}>
								<TableCell>{i + 1 + page*rowsPerPage}</TableCell>
								<TableCell>{row.name}</TableCell>
								<TableCell>{row.price}</TableCell>
								<TableCell>{row.discount_percentage}</TableCell>
								<TableCell>{<Button color="primary">Book</Button>}</TableCell>
							</TableRow>
						)}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={4} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 20, {label: "All", value: -1}]}
				SelectProps={{padding: "normal"}}
				colSpan={100}
				component={"div"}
				count={filteredList.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
			/>
		</Box>
	)
	}