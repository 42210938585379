import { forwardRef, useContext, useEffect, useState } from "react";
import { StyledModalPaper } from "../styles/modal";
import { Box, MenuItem, TextField } from "@mui/material";
import ModalHeader from "./ModalHeader";
import SubmitButton from "../components/FormButton";
import { api } from "../pages/global/apiConfig";
import { AlertToast, alertState, noFetch } from "../divine-constants";

function LocationsModal({setModalOpen, fetchData}) {
	const [formData, setFormData] = useState({
		location: "",
		image: undefined,
	});
	const [progress, setProgress] = useState(false);
	const [locations, setLocations] = useState([]);
	
	const {setAlert} = useContext(AlertToast);

	const getLocations = () => api().get('sadmin/get_city_location').then(res => {
		setLocations(res.data);
	}).catch(() => setAlert(noFetch));

	useEffect(() => {
		getLocations();
	}, []);

	function handleFormSubmit(event) {
		setProgress(true);
		event.preventDefault();
		api().post("sadmin/add_location", {
			location_name: formData.location.trim(),
			image: formData.image
		}).then(res => {
			if(res.data.code === 201){
				setModalOpen(false);
				setAlert(alertState({
					title: "",
					message: "Location added successfully",
					severity: "success",
					show: true
				}));
				fetchData();
			}
		}).catch(err => {
			if(err.status === 401) {
				setAlert(alertState({
					title: "",
					message: "Unauthorized - Cannot add record",
					severity: "warning",
					show: true
				}))
			} else setAlert(alertState({
				title: "",
				message: "Something went wrong. Please try again later",
				severity: "error",
				show: true
			}))
		}).finally(() => setProgress(false));
	}
	function handleImage(event) {
		setFormData(current => {
			return {...current, image: event.target.files[0]}
		})
	}

	function handleLocation(event) {
		setFormData(current => {
			return {...current, location: event.target.value}
		})
	}

	return <>
		<StyledModalPaper>
			<ModalHeader setModalOpen={setModalOpen}/>
			<Box component="form" className="locations-form" onSubmit={handleFormSubmit} noValidate>
				<Box component="div" className="form-grid-container two-column-layout">
					<TextField 
						select
						label="Location"
						value={formData.location}
						onChange={handleLocation}
					>
						{locations.map(location => 
							<MenuItem value={location.location_name} key={location.id}>{location.location_name}</MenuItem>)}
					</TextField>
					<TextField
						type="file"
						label="Image"
						onChange={handleImage}
					/>
				</Box>
				<SubmitButton showProgress={progress} />
			</Box>
		</StyledModalPaper>
	</>
}

export default forwardRef((props, _ref) => <LocationsModal {...props} />)