import { useContext, useEffect, useState } from "react";
import { TableContainer, Box, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, TextField, InputAdornment, LinearProgress, MenuItem, Modal, Stack, Typography, Switch, Checkbox } from "@mui/material";
import { Search } from "@mui/icons-material";
import { StyledTableHeadCell, StyledTableHeader } from "../../components/Table";
import { ViewItem } from "../../components/Icons";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import AdvancedSearch from "../../components/AdvancedSearch";
import { api } from "../global/apiConfig";
import NumberDisplay from "../../components/NumberDisplay";
import HotelBookingsModal from "../../modals/BookingsModal";
import corp from "../Logo/corp-logo.png";
import ta from "../Logo/travel-logo.png";
import { AlertToast, noFetch } from "../../divine-constants";
import { green, pink } from "@mui/material/colors";


export default function Bookings() {
	const [rows, setRows] = useState([]);
	const { setAlert } = useContext(AlertToast);
	const [filteredList, setFilteredList] = useState(rows);
	const [modal, setModal] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [progress, setProgress] = useState(true);
	const [clickedRow, setClickedRow] = useState();
	const [advancedSearch, setAdvancedSearch] = useState({
		start: "", end: "", hotelName: ""
	});
	const [cardData, setCardData] = useState({});
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;

	const id = localStorage.getItem("UserId");
	const fetchData = () => api().get(`property/get_hotel_booking_details?property_id=${id}&&start_date=${advancedSearch.start}&&end_date=${advancedSearch.end}` ).then(res => {
		if(res.status === 200) {
			setRows(res.data.data);	
			setCardData(res.data)
			console.log(res.data,"property/get_hotel_booking_details?")	
			setProgress(false);	
		}
	})

	useEffect(() => {
		fetchData(advancedSearch.start, advancedSearch.end);
	}, [advancedSearch.start, advancedSearch.end]);

	useEffect(() => {
		setFilteredList(rows);
		setPage(0);
	}, [rows]);


	function handlePageChange(_, newPage) {
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(parseInt(event.target.value), 10);
		setPage(0);
	}

	function handleAdvancedSearch(event) {
		setAdvancedSearch(curr => ({...curr, [event.target.name]: event.target.value}))
	}
	
	const handleChange1 = async (id) => {
		setFilteredList(prev => prev.map(items=>{if (items._id == id){
			return {...items,user_checkout_status : "inhouse"}
		}else {
			return items
		}}))
		api().patch('property/property_user_checkout_status', { type: 'hotel', checkout: 'inhouse', booking_id:id }).then(res => {
			fetchData()
		}).catch(() => setAlert(noFetch));
	};
	const handleChange2 = async (id) => {
		setFilteredList(prev => prev.map(items=>{if (items._id == id){
			return {...items,user_checkout_status : "completed"}
		}else {
			return items
		}}))
		api().patch('property/property_user_checkout_status', { type: 'hotel', checkout: 'completed', booking_id:id }).then(res => {
			fetchData()
		}).catch(() => setAlert(noFetch));
	};
	return (
		<Box component="div">
			<Box className="card-container">
				<NumberDisplay color="text.primary" title="Total Bookings" value={cardData.booking_count} />
				<NumberDisplay color="primary.main" title="Total Amount" value={cardData.total_amounts} />
				<NumberDisplay color="error.main" title="Cancellations" value={cardData.cancellation_count} />
				<NumberDisplay color="success.main" title="Confirmations" value={cardData.confirmation_count} />
			</Box>
			<br />
			<AdvancedSearch>
				<TextField 
					required={false}
					label="Start Date"
					type="date" 
					name="start"
					value={advancedSearch.start}
					onChange={handleAdvancedSearch}
					helperText=""
				/>
				<TextField 
					required={false}
					label="End Date"
					type="date" 
					name="end"
					value={advancedSearch.end}
					onChange={handleAdvancedSearch}
					helperText=""
				/>
			</AdvancedSearch>

			{progress && <LinearProgress />}
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<StyledTableHeadCell>S.No</StyledTableHeadCell>
							<StyledTableHeadCell>User name</StyledTableHeadCell>
							<StyledTableHeadCell>User type</StyledTableHeadCell>
							<StyledTableHeadCell>Total rooms</StyledTableHeadCell>
							<StyledTableHeadCell>Total Amount</StyledTableHeadCell>
							<StyledTableHeadCell>Booking Status</StyledTableHeadCell>
							<StyledTableHeadCell>Checkout Status</StyledTableHeadCell>
							<StyledTableHeadCell>View</StyledTableHeadCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0 
							? filteredList.slice(page * rowsPerPage, (page + 1) * rowsPerPage) 
							: filteredList).map((row, i) => (
							<TableRow key={row.order_id}>
								<TableCell><Stack>
											{row.user_type === "super_admin" || row.user_type === "manager" ? (
												<img src={corp} style={{ width: "40px", height: "20px" }} />
											) : row.user_type === 'agent' ? (
												<img src={ta} style={{ width: "40px", height: "20px" }} />
											) : null}
											<Typography>{i + 1 + page * rowsPerPage}</Typography>
										</Stack></TableCell>
								<TableCell>{row.user_name}</TableCell>
								<TableCell>{row.property_name}</TableCell>
								<TableCell>{row.total_rooms}</TableCell>
								<TableCell>{row.total_amount}</TableCell>
								<TableCell>{row.booking_status}</TableCell>
								<TableCell>
								<Checkbox
										onClick={() => handleChange1(row._id,)}
										sx={{
											color: green[800],
											'&.Mui-checked': {color: green[600],},
										}}
										disabled={row.user_checkout_status==='inhouse' || row.user_checkout_status==='completed'}
										checked={row.user_checkout_status === 'inhouse' || row.user_checkout_status === 'completed'?true:false}
										inputProps={{ 'aria-label': 'controlled' }}
									/>Inhouse
								<Checkbox
										onClick={() => handleChange2(row._id)}
										disabled={row.user_checkout_status==='completed'}
										sx={{
											color: pink[800],
											'&.Mui-checked': {color: pink[600],},
										}}
										checked={row.user_checkout_status === 'completed'?true:false}
										inputProps={{ 'aria-label': 'controlled' }}
									/>Completed
								</TableCell>
								<TableCell>
									<ViewItem onClick={() => {setClickedRow(row);setModal(true)}} />
								</TableCell>
							</TableRow>
						))}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={4} />
							</TableRow>
						)}
					</TableBody>
				</Table> 
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 20, {label: "All", value: -1}]}
				colSpan={100}
				count={filteredList.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
			/>
			<Modal 
				open={modal} 
				onClose={() => setModal(false)} 
				aria-labelledby="hotel bookings view" 
			><HotelBookingsModal booking={clickedRow} setModalOpen={setModal}/></Modal>
		</Box>
	)
}