import { StyledTableHeader, StyledTableHeadCell } from "../../../../components/Table";
import { useState, useEffect, useContext } from "react";
import { Add, Search } from "@mui/icons-material";
import { Redbin } from "../../../../components/Icons";
import { api } from "../../../global/apiConfig";
import { TableContainer, TextField, Button, InputAdornment, Table, TableBody, TableHead, TableRow, TableCell, TablePagination, Modal, LinearProgress } from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import OffersModal from "../../../../modals/Offers";
import { AlertToast, noFetch } from "../../../../divine-constants";

export default function HotelRec() {	
	const {setAlert} = useContext(AlertToast);
	const [rows, setRows] = useState([]);
	const [filteredList, setFilteredList] = useState(rows);
	const [noResults, setNoResults] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(2);
	const [modalOpen, setModalOpen] = useState(false);
	const [progress, setProgress] = useState(false);
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;

	const fetchData = async () => {
		setProgress(true);
		return await api().get("sadmin/get_property_offers?type=tour").then(res => {
			if(res.status !== 200) return;
			setRows(res.data);
		}).catch(() => setAlert(noFetch)).finally(() => setProgress(false));
	}
	
	useEffect(() => {
		fetchData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setFilteredList(rows);
		setPage(0);
	}, [rows]);

	function handleOpenModal() {
		setModalOpen(true);
	}

	function handleCloseModal() {
		setModalOpen(false);
	}

	function handlePageChange(_, newPage) {	
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(parseInt(event.target.value), 10);
		setPage(0);
	}
	
	function handleTableSearch(event) {
		const query = event.target.value
		const next = rows.filter(item => {
			return Object
			.values(item)
			.map(v => v.toString())
			.some(el => el.toLowerCase().includes(query.toLowerCase()));
		});
		if(next.length <= 0) setPage(0)
		setFilteredList(next);
		setNoResults(next.length <= 0);
	}
	return (
		<>
			<StyledTableHeader>
			<Button onClick={handleOpenModal}><Add /> Add new</Button>
				<TextField 
					label="Search in Table"
					onChange={handleTableSearch}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Search />
							</InputAdornment>
						)
					}}
					InputLabelProps={{shrink: undefined}}
					required={false}
					helperText={noResults && "No results found"}
					FormHelperTextProps={{
						error: noResults
					}}
				/>
			</StyledTableHeader>
			{progress && <LinearProgress />}
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<StyledTableHeadCell>S.No</StyledTableHeadCell>
							<StyledTableHeadCell>Tour Name</StyledTableHeadCell>
							<StyledTableHeadCell>Offer</StyledTableHeadCell>
							<StyledTableHeadCell>Action</StyledTableHeadCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0 
							? filteredList.slice(page * rowsPerPage, (page + 1) * rowsPerPage) 
							: filteredList).map((row, i) => (
							<TableRow key={row.property_id}>
								<TableCell>{i + 1 + page*rowsPerPage}</TableCell>
								<TableCell>{row.name}</TableCell>
								<TableCell>{row.offer}</TableCell>
								<TableCell><Redbin /></TableCell>
							</TableRow>
						))}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={4} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[2, 4, {label: "All", value: -1}]}
				colSpan={100}
				count={filteredList.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
			/>
			<Modal 
				open={modalOpen} 
				onClose={handleCloseModal} 
				aria-labelledby="tour offers form" 
				aria-describedby="insert new record"
			><OffersModal setModalOpen={setModalOpen} reqApi="sadmin/get_tour_and_travel" type="tour" fetchData={fetchData} /></Modal>
		</>
	)
}