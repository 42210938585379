import Fieldset from "../components/Fieldset";
import { StyledModalPaper } from "../styles/modal";
import { Box, Divider, Paper } from "@mui/material";
import ModalHeader from "./ModalHeader";
import { Fragment, forwardRef } from "react";
import { firstLetterUpper } from "../utils";

function PaymentsModal({setModalOpen, data}) {
	const paymentKeys = ["discount_value", "amount", "tax", "total_amount", "payment_type", "rayzorpay_payment_id", "rayzorpay_order_id"];
	const bookingKeys = ["booking_status", "no_of_guest", "booking_date", "start_date", "end_date"];
	const modifyKeyProp = str => firstLetterUpper(str.replace(/_/g, ' '))

	return (	
		<StyledModalPaper>
			<ModalHeader setModalOpen={setModalOpen} title="Booking data" />
			<Box display="grid" gridTemplateColumns="repeat(3, 1fr)" sx={{
				"@media screen and (max-width: 1150px)": {
					gridTemplateColumns: "1fr 1fr"
				},
				"@media screen and (max-width: 750px)": {
					gridTemplateColumns: "1fr"
				},
				"@media screen and (min-width: 1500px)": {
					gridTemplateColumns: "repeat(4, 1fr)"
				}
			}}>
				{data.map((item, index) => 
					<Box component={Paper} key={index} padding={1} margin={1} elevation={2}>
						<Fieldset legend="Payment details">
							{paymentKeys.map(key => <Fragment key={key}>
								<Box paddingY={1} display={"flex"} justifyContent={"space-between"}>
									<span style={{display: "block", flexGrow: '0.5', fontWeight: '600'}}>
										{key.includes("id") ? firstLetterUpper(modifyKeyProp(key).split(/ /)[1]) + ' id' : modifyKeyProp(key)}
									</span><span>{item[key]}</span>
								</Box>
								<Divider  />
							</Fragment>
							)}
						</Fieldset>
						<Fieldset legend="Booking details">
							{bookingKeys.map(key => <Fragment key={key}>
								<Box paddingY={1} display={"flex"} justifyContent={"space-between"}>
									<span style={{display: "block", flexGrow: '0.5', fontWeight: '600'}}>
										{key.includes("id") ? firstLetterUpper(modifyKeyProp(key).split(/ /)[1]) + ' id' : modifyKeyProp(key)}
									</span><span>{item[key]}</span>
								</Box>
								<Divider  />
							</Fragment>
							)}
						</Fieldset>
					</Box>
				)}
			</Box>
		</StyledModalPaper>
	)
}

export default forwardRef((props, _ref) => <PaymentsModal {...props} />)