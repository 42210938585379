import { AssuredWorkloadOutlined, DeleteOutlined, EditOutlined, RemoveRedEyeOutlined} from "@mui/icons-material";
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import { IconButton, Tooltip } from "@mui/material";

export function Redbin({onClick, disabled}) {
	return <Tooltip title="Delete"><IconButton color="error" disabled={disabled} onClick={onClick}><DeleteOutlined /></IconButton></Tooltip>
}
export function EditItem({onClick, disabled=false}) {
	return <Tooltip title="Edit"><IconButton color="primary" disabled={disabled} onClick={onClick}><EditOutlined /></IconButton></Tooltip>
}
export function AddBank({onClick, disabled=false}) {
	return <Tooltip title="Add Bank"><IconButton color="primary" disabled={disabled} onClick={onClick}><AccountBalanceOutlinedIcon /></IconButton></Tooltip>
}
export function ViewItem({onClick, disabled=false}) {
	return <Tooltip title="View"><IconButton color="warning" disabled={disabled} onClick={onClick}><RemoveRedEyeOutlined /></IconButton></Tooltip>
}
export function Accadded({onClick, disabled=false}) {
	return <Tooltip title="Account Added"><IconButton color="success" disabled={disabled} onClick={onClick}><AssuredWorkloadOutlined /></IconButton></Tooltip>
}
