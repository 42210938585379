import { useState, useReducer, useContext, useEffect, Fragment } from "react";
import { TableContainer, Box, Table, TableHead, TableBody, TableRow, Button, Modal, TableCell, TablePagination, TextField, InputAdornment, LinearProgress, Collapse, IconButton, Chip } from "@mui/material";
import { Add, Search, ExpandLess, ExpandMore } from "@mui/icons-material";
import { NestedTitle } from "../../../components/Table";
import { StyledTableHeadCell, StyledTableHeader } from "../../../components/Table";
import { AddBank, EditItem, Redbin, ViewItem } from "../../../components/Icons";
import { modalReducer, AlertToast, noFetch, tableImageHeight } from "../../../divine-constants";
import { api } from "../../global/apiConfig";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import ChambPartnersModal from "../../../modals/distinct chamb/ChambPartnersModal";
import DeleteModal from "../../../modals/DeleteModal";
import ChambEditPartnersModal from "../../../modals/distinct chamb/ChambEditPartnersModal";
import ChambPackageModal from "../../../modals/distinct chamb/ChambPackageModal";
import ChambViewModal from "../../../modals/distinct chamb/ChambViewModal";
import DistinctChambViewModal from "../../../modals/distinct chamb/DistinctChambViewModal";
import DistinctChambPaymentModal from "../../../modals/distinct chamb/DistinctChambPaymentModal";

export default function CompanyUserDistinctChambPartners() {
	
	const {setAlert} = useContext(AlertToast);
	const [rows, setRows] = useState([]);
	const [nestedRows, setNestedRows] = useState([]);
	const [filteredList, setFilteredList] = useState(rows);
	const [deleteModal, setDeleteModal] = useState(false);
	const [noResults, setNoResults] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const [modalOpen, setModalOpen] = useReducer(modalReducer, {
		addNew: false,
		addBank: false,
		edit: false,
		viewNested: false,
		addNewNested: false,
		editNested: false,
		view: false
	});
	const [Id,setId] = useState({})
	const [progress, setProgress] = useState({display: true, type: "regular"});
	const [clickedRow, setClickedRow] = useState(); 
	const [nestedClickedRow, setNestedClickedRow] = useState(); 
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;

	function handlePageChange(event, newPage) {
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(parseInt(event.target.value), 10);
		setPage(0);
	}
	const id = localStorage.getItem('UserId');
	const fetchData = () => api().get("sadmin/get_suser_distinct_chamb?user_id="+id).then(res => {
		if(res.status === 200) {
			setRows(res.data);
			setProgress({display: false, type: "regular"});
		}
	}).catch(() => setAlert(noFetch));

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		setFilteredList(rows);
	}, [rows]);

	useEffect(() => {
		if(clickedRow) {
			setNestedRows([]);
			setProgress({display: true, type: "nested"});
			api().get('sadmin/get_chamb_property?chamb_id=' + clickedRow._id).then(res => {
				setNestedRows(res.data);
				setProgress({display: false, type: ""})
			})
		}
		if(!clickedRow) setNestedRows([]);
	}, [clickedRow])
		function handleTableSearch(event) {
		const query = event.target.value
		const next = rows.filter(item => {
			return Object
			.values(item)
			.map(v => v.toString())
			.some(el => el.toLowerCase().includes(query));
		});
		if(next.length <= 0) setPage(0)
		setFilteredList(next);
		setNoResults(next.length <= 0);
	}
	return (
		<Box component="div">
			<StyledTableHeader>
				{/* <TextField 
					label="Search in Table"
					onChange={handleTableSearch}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Search />
							</InputAdornment>
						)
					}}
					InputLabelProps={{shrink: undefined}}
					required={false}
					helperText={noResults && "No results found"}
					FormHelperTextProps={{
						error: noResults
					}}
				/> */}
				<Button onClick={() => setModalOpen({type: "addNew", to: "open"})}><Add /> Add new</Button>
			</StyledTableHeader>
			{progress.display && progress.type === "regular" && <LinearProgress />}
			<TableContainer>
				<Table className="nested-table">
					<TableHead>
						<TableRow>
							<StyledTableHeadCell />
							<StyledTableHeadCell>S.No</StyledTableHeadCell>
							<StyledTableHeadCell>Name</StyledTableHeadCell>
							<StyledTableHeadCell>Image</StyledTableHeadCell>
							<StyledTableHeadCell>Category</StyledTableHeadCell>
							<StyledTableHeadCell>Mobile</StyledTableHeadCell>
							<StyledTableHeadCell>Email</StyledTableHeadCell>
							<StyledTableHeadCell>Action</StyledTableHeadCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{(rowsPerPage > 0 
							? filteredList.slice(page * rowsPerPage, (page + 1) * rowsPerPage) 
							: filteredList).map((row, i) => (<Fragment key={i}>
							<TableRow key={i}>
								<TableCell><IconButton disabled={progress.display} onClick={() => setClickedRow(current => current?._id === row?._id ? null : row)}>{clickedRow?._id === row._id ? <ExpandLess /> : <ExpandMore />}</IconButton></TableCell>
								<TableCell>{i + 1 + page*rowsPerPage}</TableCell>
								<TableCell>{row.name}</TableCell>
								<TableCell><img height={tableImageHeight} src={row.main_image} alt={row.main_image}/></TableCell>
								<TableCell>{row.category}</TableCell>
								<TableCell>{row.mobile}</TableCell>
								<TableCell>{row.email}</TableCell>
								<TableCell>
								<AddBank onClick={() => {setNestedClickedRow(row); setModalOpen({type: "addBank", to: "open"});setId({chamb_id:row._id})}}/>
								<ViewItem onClick={() => {setNestedClickedRow(row); setModalOpen({type: "view", to: "open"})}}/>
								<EditItem onClick={() => {setClickedRow(row); setModalOpen({type: "edit", to: "open"})}} />
									<Redbin onClick={() => {setClickedRow(row); setDeleteModal(true)}} />
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell colSpan={9}>
									<Collapse in={clickedRow?._id === row._id}>
										{progress.display && progress.type === "nested" && <LinearProgress />}
										<Box>
											<StyledTableHeader style={{justifyContent: "flex-start"}}>
												<NestedTitle>{row.name} - partners</NestedTitle>
												<Button variant="outlined" onClick={() => {setNestedClickedRow(row); setModalOpen({type: "addNewNested", to: "open"});setId({Chamb_id:row?._id})}}><Add /> Add new</Button>
											</StyledTableHeader>
											<Table>
												<TableHead>
													<TableRow>
														<StyledTableHeadCell>S.No</StyledTableHeadCell>
														<StyledTableHeadCell>Name</StyledTableHeadCell>
														<StyledTableHeadCell>Price</StyledTableHeadCell>														
														<StyledTableHeadCell>Status</StyledTableHeadCell>
														<StyledTableHeadCell>Action</StyledTableHeadCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{nestedRows.map((nestedRow, nestInd) => 
														<TableRow key={nestInd}>
															<TableCell>{++nestInd}</TableCell>
															<TableCell>{nestedRow.name}</TableCell>
															<TableCell>{nestedRow.price}</TableCell>														
															<TableCell><Chip label={nestedRow.active_status ? "Active" : "Inactive"} color={nestedRow.active_status ? "success" : "error"} /></TableCell>
															<TableCell>
															<ViewItem onClick={() => {setNestedClickedRow(nestedRow); setModalOpen({type: "viewNested", to: "open"})}}/>
																<EditItem onClick={() => {setNestedClickedRow(nestedRow); setModalOpen({type: "editNested", to: "open"});setId({Chamb_id:row._id,type:"edit"})}} />
																<Redbin />
															</TableCell>
														</TableRow>
													)}
												</TableBody>
											</Table>
										</Box>
									</Collapse>
								</TableCell>
							</TableRow>
							</Fragment>
						))}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={4} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[20, 40, {label: "All", value: -1}]}
				colSpan={100}
				count={filteredList.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
			/>
			<Modal 
				open={modalOpen.addNew} 
				onClose={() => setModalOpen({type: "addNew", to: "close"})} 
				aria-labelledby="tours list form" 
				aria-describedby="insert new record"
			><ChambPartnersModal setModalOpen={setModalOpen} fetchData={fetchData}/></Modal>
			
			<Modal 
				open={modalOpen.addBank} 
				onClose={() => setModalOpen({type: "addBank", to: "close"})} 
				aria-labelledby="Add Bank Account form" 
				aria-describedby="insert new record"
			><DistinctChambPaymentModal setModalOpen={setModalOpen} fetchData={fetchData} id={Id} /></Modal>

			
			<Modal 
				open={modalOpen.edit} 
				onClose={() => setModalOpen({type: "edit", to: "close"})} 
				aria-labelledby="Tour / Packages form" 
				aria-describedby="insert new record"
			><ChambEditPartnersModal setModalOpen={setModalOpen} modalOpen={modalOpen} fetchData={fetchData} data={clickedRow} /></Modal>
			<Modal 
				open={ modalOpen.addNewNested} 
				onClose={() => setModalOpen({type: "addNewNested", to: "close"})} 
				aria-labelledby="Tour / Packages form" 
				aria-describedby="insert new record"
			><ChambPackageModal fetchData={fetchData} setModalOpen={setModalOpen}  id={Id}/></Modal>
			
			<Modal 
				open={modalOpen.editNested } 
				onClose={() => setModalOpen({type: "editNested", to: "close"})} 
				aria-labelledby="Tour / Packages form" 
				aria-describedby="insert new record"
			><ChambPackageModal fetchData={fetchData} setModalOpen={setModalOpen} data={nestedClickedRow} id={Id}/></Modal>
			
			<Modal 
				open={modalOpen.viewNested} 
				onClose={() => setModalOpen({type: "viewNested", to: "close"})} 
				aria-labelledby="Tour / Packages" 
				aria-describedby="insert new record"
			><ChambViewModal setModalOpen={setModalOpen} data={nestedClickedRow} /></Modal>
			<Modal 
				open={modalOpen.view} 
				onClose={() => setModalOpen({type: "view", to: "close"})} 
				aria-labelledby="Tour / Packages" 
				aria-describedby="insert new record"
			><DistinctChambViewModal setModalOpen={setModalOpen} data={nestedClickedRow} /></Modal>
			
			<DeleteModal dialogDisplay={deleteModal} setDialogDisplay={setDeleteModal} url={"sadmin/delete_distinct_chamb"} data={{chamb_id: clickedRow?._id}} fetchData={fetchData} />
		</Box>
	)
}