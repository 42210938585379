import { forwardRef, useContext, useEffect, useState } from "react";
import { StyledModalPaper } from "../../styles/modal";
import { Box, TextField, MenuItem, Checkbox, ListItemText, FormControl, InputLabel, FormControlLabel, Switch } from "@mui/material";
import { api } from "../../pages/global/apiConfig";
import ModalHeader from "../ModalHeader";
import SubmitButton from "../../components/FormButton";
import { AlertToast, alertState, noFetch } from "../../divine-constants";
import Fieldset from "../../components/Fieldset";

function AgentPaymentModal({setModalOpen, fetchData,data,id}) {
	const { setAlert } = useContext(AlertToast);
	const [progress, setProgress] = useState(false);
	const [formData,  setFormData] = useState({
		name: "",
		property_type: "",
		property_id : "",
		account_number: "",
		bank_name: "",
		ifsc: "",
		bank_code: "",
		status: false,
		
	});
	function handleText(event) {
		setFormData(current => ({...current, [event.target.name]: event.target.value}));
	}

	function handleSingleSelect(event) {
		setFormData(current => ({...current, [event.target.name]: event.target.value}));
	}

	function handleFormSubmit(event) {
		event.preventDefault();
		api().post("sadmin/add_agent_account_details", {
			agent_id : id.agent_id,
			name: formData.name.trim(),
			property_type: formData.property_type,
			property_id : id.agent_id,
			account_number: formData.account_number,
			ifsc: formData.ifsc,
			bank_code: formData.bank_code,
			bank_name: formData.bank_name,			
			active_status: formData.status,
		}).then(res => {
			if(res.data.code === 201) {
				setAlert(alertState({
					message: "Bank Account Details Successfully",
					severity: "success",
					show: true
				}));
				fetchData();
				setModalOpen({type: "addBank", to: "close"});
			}
		}).catch(err =>  {
			if (err.status === 401) {
			  setAlert(
				alertState({
				  message: "You are not authorized to add a new Agent.",
				  severity: "warning",
				  show: true,
				})
			  );
			} else if (err.status === 403) {
			  setAlert(
				alertState({
				  message: "You do not have access to add a new agent.",
				  severity: "warning",
				  show: true,
				})
			  );
			} else
			  setAlert(
				alertState({
				  message: "Something went wrong. Please try again",
				  severity: "error",
				  show: true,
				})
			  );
		  })
		  .finally(() => setProgress(false));
	}

	return (
		<StyledModalPaper>
			<ModalHeader setModalOpen={setModalOpen} type="addBank"/>
			<Box component="form" className="list-form" onSubmit={handleFormSubmit} noValidate>
				<Box component="div">
					<Fieldset legend="property Account Details" className="form-grid-container four-column-layout">

						<TextField
							label="Name"
							type="text"
							name="name"
							value={formData.name}
							onChange={handleText}
							placeholder="Enter Name"
						/>

						<TextField 
							select 
							label="Property Type"
							defaultValue={formData.property_type}
							value={formData.property_type}
							name="property_type"
							onChange={(e)=>{setFormData({...formData,property_type: e.target.value})}}
							>
								<MenuItem value="hotel">hotel</MenuItem>
								<MenuItem value="hostel">hostel</MenuItem>
								<MenuItem value="hall">hall</MenuItem>
								<MenuItem value="tour">tour</MenuItem>
								<MenuItem value="chamb">chamb</MenuItem>
								<MenuItem value="agent">agent</MenuItem>
						</TextField>

						<TextField
							label="Bank Name"
							type="text"
							name="bank_name"
							value={formData.bank_name}
							onChange={handleText}
							placeholder="Enter Bank Name"
						/>

						<TextField
							required={false}
							label="Account Number"
							type="text"
							name="account_number"
							value={formData.account_number}
							onChange={handleText}
							placeholder="Enter Account Number"
						/>
						<TextField
							label="IFSC Code"
							type="text"
							name="ifsc"
							value={formData.ifsc}
							onChange={handleText}
							placeholder="Enter IFSC Code"
						/>

						<TextField
							label="Bank Code"
							type="text"
							name="bank_code"
							value={formData.bank_code}
							onChange={handleText}
							placeholder="Enter Bank Code"
						/>

						<FormControl className="inline-form-control" required={false}>
						<InputLabel shrink>Status</InputLabel>
						<FormControlLabel 
							label={formData.status ? "Active" : "Inactive"} 
							onChange={e => setFormData(curr => ({...curr, status: e.target.checked}))} 
							checked={formData.status}
							control={<Switch/>} 
						/>
					</FormControl>					
					</Fieldset>
				</Box>
				<SubmitButton/>

			</Box>
		</StyledModalPaper>
	)
}
export default forwardRef((props, _ref) => <AgentPaymentModal {...props} />)
