import { forwardRef } from "react";
import { StyledModalPaper } from "../styles/modal";
import { Typography, Box } from "@mui/material";
import ModalHeader from "./ModalHeader";
import Fieldset from "../components/Fieldset";

function BookingsModal({setModalOpen, booking}) {

	return (
		<StyledModalPaper>
			{/* <ModalHeader title={<>{booking.property_type} <Typography color="text.light" component="span">({booking.property_name})</Typography></>} setModalOpen={setModalOpen}/> */}
			<Box sx={{
				display: "flex",
				flexWrap: "wrap",
				width: "100%",
				gap: "1rem",
				"& > fieldset": {
					width: "fit-content",
					flexGrow: 1,
					display: "grid",
					gridTemplateColumns: "repeat(2, 1fr)",
					gap: "1rem",
					justifyContent: "space-around",
					pb: 1,
					"@media only screen and (max-width: 500px)": {
						gridTemplateColumns: "1fr"
					}
				}
			}}>
				<Fieldset legend="Booking details">
					<Box>
						<Typography component="span" color="text.secondary">Booking date</Typography><br />
						<Typography component="span" color="text.primary">{booking.booking_date}</Typography>
					</Box>
					<Box>
						<Typography component="span" color="text.secondary">Status</Typography><br />
						<Typography component="span" color="text.primary">{booking.booking_status}</Typography>
					</Box>
					<Box>
						<Typography component="span" color="text.secondary">Start date</Typography><br />
						<Typography component="span" color="text.primary">{booking.start_date}</Typography>
					</Box>
					<Box>
						<Typography component="span" color="text.secondary">End date</Typography><br />
						<Typography component="span" color="text.primary">{booking.end_date}</Typography>
					</Box>
					<Box>
						<Typography component="span" color="text.secondary">Guests</Typography><br />
						<Typography component="span" color="text.primary">{booking.no_of_guest}</Typography>
					</Box>
				</Fieldset>
				<Fieldset className="payment-details" legend="Payment details">
					<Box>
						<Typography component="span" color="text.secondary">Type</Typography><br />
						<Typography component="span" color="text.primary">{booking.payment_type}</Typography>
					</Box>
					<Box>
						<Typography component="span" color="text.secondary">Tax</Typography><br />
						<Typography component="span" color="text.primary">{booking.tax}</Typography>
					</Box>
					<Box>
						<Typography component="span" color="text.secondary">Total Amount</Typography><br />
						<Typography component="span" color="text.primary">{booking.total_amount}</Typography>
					</Box>
					<Box>
						<Typography component="span" color="text.secondary">Discount</Typography><br />
						<Typography component="span" color="text.primary">{booking.discount_value} %</Typography>
					</Box>
					<Box>
						<Typography component="span" color="text.secondary">Untaxed Amount</Typography><br />
						<Typography component="span" color="text.primary">{booking.amount} %</Typography>
					</Box>
				</Fieldset>
			</Box>
		</StyledModalPaper>
	)
}

export default forwardRef((props, _ref) => <BookingsModal {...props} />)
