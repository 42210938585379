import {useState } from "react";
import {InputAdornment, Tab, TextField } from "@mui/material";

import Tabs from "@mui/material/Tabs";
import TabPanel from "../../../components/TabPanel";

import Hotelpayment from "./Hotel";
import Hostelpayment from "./Hostel";
import Hallpayment from "./Hall";
import Tourpayment from "./Tour";
import Chambpayment from "./Chamb";
import Agentpayment from "./Agent";
import { Search } from "@mui/icons-material";
import { StyledTableHeader } from "../../../components/Table";

export default function PaymentList() {
	const [page, setPage] = useState(0);
	const [rows, setRows] = useState([]);
	const [filteredList, setFilteredList] = useState(rows);
	const [noResults, setNoResults] = useState(false);
	
	function handlePageChange(_, newPage) {
		setPage(newPage);
	}

	return (
		<>		
		<Tabs value={page} onChange={handlePageChange} aria-label="settings page tabs">
				<Tab label="Hotel" />
				<Tab label="Hostel " />
				<Tab label="Hall " />
				<Tab label="Tours And Travel " />
				<Tab label="Distinct Chamb " />
				<Tab label="Agent " />
			</Tabs>
			<TabPanel value={page} index={0} children={<Hotelpayment />} />
			<TabPanel value={page} index={1} children={<Hostelpayment />} />
			<TabPanel value={page} index={2} children={<Hallpayment />} />
			<TabPanel value={page} index={3} children={<Tourpayment />} />
			<TabPanel value={page} index={4} children={<Chambpayment />} />
			<TabPanel value={page} index={5} children={<Agentpayment />} />
		</>
	)
}