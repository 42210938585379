import { useContext, Fragment } from "react";
import { User } from "./divine-constants";
import { Navigate, Route, Routes } from "react-router-dom";

import {default as HostelCompanyInfo} from "./pages/hostel/CompanyInfo";
import PropertyListHostel from "./pages/hostel/PropertyList";
import {default as HostelRooms} from "./pages/hostel/Rooms";
import {default as HostelBookings} from "./pages/hostel/Bookings";
import {default as HostelAvailability} from "./pages/hostel/Availability";
import {default as HostelOfflineTicket} from "./pages/hostel/OfflineTicket";
import {default as HostelScheduledVisit} from "./pages/hostel/HostelScheduledVisit";
import {AddbankAccountHostel, WeeklyPaymentsHostel} from "./pages/hostel/Payments";

import {default as HotelCompanyInfo} from "./pages/hotel/CompanyInfo";
import PropertyListHotel from "./pages/hotel/PropertyList";
import PropertyUserHostel from "./pages/hostel/PropertyUser"
import PropertyUserHotel from "./pages/hotel/PropertyUser"
import PropertyUserHall from "./pages/hall/PropertyUser"
import PropertyUserChamb from "./pages/chamb/PropertyUser"
import PropertyUserTour from "./pages/tour/PropertyUser"
import {default as HotelRooms} from "./pages/hotel/Rooms";
import {default as HotelBookings} from "./pages/hotel/Bookings";
import {default as HotelAvailability} from "./pages/hotel/Availability";
import {default as HotelOfflineTicket} from "./pages/hotel/OfflineTicket";
import {AddbankAccountHotel, WeeklyPaymentsHotel} from "./pages/hotel/Payments";

import {default as HallCompanyInfo} from "./pages/hall/CompanyInfo";
import PropertyListHall from "./pages/hall/PropertyList";
import {default as HallBookings} from "./pages/hall/Bookings";
import {default as HallOfflineTicket} from "./pages/hall/OfflineTicket";
import {default as HallEnquiry} from "./pages/hall/HallEnquiry";
import {AddbankAccountHall, WeeklyPaymentsHall} from "./pages/hall/Payments";

import {default as TourCompanyInfo} from "./pages/tour/CompanyInfo";
import PropertyListTour from "./pages/tour/PropertyList";
import {default as TourPackages} from "./pages/tour/TourPackages";
import {default as TourBookings} from "./pages/tour/Bookings";
import {default as TourOfflineTicket} from "./pages/tour/OfflineTicket";
import {AddbankAccountTour, WeeklyPaymentsTour} from "./pages/tour/Payments";

import {default as ChambCaravan} from "./pages/chamb/Caravan";
import PropertyListChamb from "./pages/chamb/PropertyList";
import {default as Packages} from "./pages/chamb/Packages"
import {default as ChambEnquiry} from "./pages/chamb/Enquiry";
import {default as ChambBookings} from "./pages/chamb/Bookings";
import {default as ChambOfflineTicket} from "./pages/chamb/OfflineTicket";
import {AddbankAccountChamb, WeeklyPaymentsChamb} from "./pages/chamb/Payments";

import PageNotFound from "./pages/global/404";
import { routes } from "./router-admin";
import MoszadLogin from "./pages/global/Moszad-Login";
import PropertyLogin from "./pages/global/Property-Login";

import CompanyUserHotelList from "./pages/moszad/Company User/CompanyUserHotelList";
import CompanyUserHostelList from "./pages/moszad/Company User/CompanyUserHostelList";
import CompanyUserHallList from "./pages/moszad/Company User/CompanyUserHallList";
import CompanyUserToursPartners from "./pages/moszad/Company User/CompanyUserToursPartners";
import CompanyUserDistinctChambPartners from "./pages/moszad/Company User/CompanyUserDistinctChambPartners";

import HotelCheckAvailability from "./pages/moszad/hotels/HotelCheckAvailability";
import {default as THotelBookings} from "./pages/moszad/hotels/HotelBookings";
import {default as THostelBookings} from "./pages/moszad/hostels/HostelBookings";
import HostelChechAvailability from "./pages/moszad/hostels/HostelCheckAvailability";
import {default as THallBookings} from "./pages/moszad/halls/HallBookings";
import {default as TTourBookings} from "./pages/moszad/tours and travels/ToursBookings";
import {default as TChambBookings} from "./pages/moszad/distinct chamb/DistinctChambBookings";
import DeactivateUser from "./pages/global/Deactivate-user";
import Dashboard from "./pages/moszad/DashBoard/Dashboard";
import ParticularPropertyDashboard from "./pages/moszad/DashBoard/ParticularPropertyDashboard";
import PropertyOwnerDashboard from "./pages/moszad/DashBoard/PropertyOwnerDashboard";
import MoszadForgotPassword from "./pages/global/Moszad-ForgotPassword";
import NewPassword from "./pages/global/newpassword";

export default function Router({drawerWidth}) {
	const {user} = useContext(User); 
	return (
		<Routes> 
			<Route path="/Moszad-login" element={<MoszadLogin drawerWidth={drawerWidth} />} />
			<Route path="/Moszad-forgot-password" element={<MoszadForgotPassword drawerWidth={drawerWidth} />} />
			<Route path="/Moszad-newpassword" element={<NewPassword drawerWidth={drawerWidth} />} />
			<Route path="/Property-login" element={<PropertyLogin drawerWidth={drawerWidth} />} />
			<Route path="/Deactivate-User" element={<DeactivateUser drawerWidth={drawerWidth} />} />


			{!user.loggedIn && <Route path="*" element={<Navigate to="/Moszad-login" replace />} />}
			{(user.type === "SUPER_ADMIN" || user.type === "ADMIN" || user.type === "MANAGER") && <Route path="/"  element={<Navigate to="/dashboard" replace />} />}
			{(user.type ==="PROPERTY_OWNER" || user.type === "STAFF") && user.name === "HOSTEL" && <Route path="/"  element={<Navigate to="/hostel/company-info" replace />} />}
			{(user.type ==="PROPERTY_OWNER" || user.type === "STAFF") && user.name ==="HOTEL" && <Route path="/"  element={<Navigate to="/hotel/company-info" replace />} />}
			{(user.type ==="PROPERTY_OWNER" || user.type === "STAFF") && user.name === "HALL" && <Route path="/"  element={<Navigate to="/hall/company-info" replace />} />}
			{(user.type ==="PROPERTY_OWNER" || user.type === "STAFF") && user.name === "TOUR" && <Route path="/"  element={<Navigate to="/tours-and-travels/company-info" replace />} />}
			{(user.type ==="PROPERTY_OWNER" || user.type === "STAFF") && user.name === "CHAMB" && <Route path="/"  element={<Navigate to="/moving-homes/caravan" replace />} />}
			{user.type ==="FIELD_WORKER" && user.name === "MOSZAD" && <Route path="/"  element={<Navigate to="/field-worker/hotel" replace />} />}
			{user.type ==="MANAGER" && user.name === "MOSZAD" && <Route path="/"  element={<Navigate to="/manager/hotel" replace />} />}
			{user.type ==="TELE_COM" && user.name === "MOSZAD" && <Route path="/"  element={<Navigate to="/booking-demand/tele-support/dashboard" replace />} />}

			{(user.type === "SUPER_ADMIN" || user.type === "MANAGER" || user.type === "ADMIN") && routes.map((route, ind) => <Fragment key={ind}>{route}</Fragment>)}

			{(user.type ==="PROPERTY_OWNER"|| user.type === "STAFF") && user.name ==="HOTEL"  && <Route path="hotel">
					<Route path="" index element={user.type ==="STAFF" ? <ParticularPropertyDashboard /> : <Navigate to = "/hotel/dashboard" replace/>} />
					<Route path="dashboard" element={user.type ==="STAFF" ? <ParticularPropertyDashboard /> : <PropertyOwnerDashboard/>} />
					<Route path="company-info" element={user.type ==="STAFF" ? <HotelCompanyInfo /> : <PropertyListHotel/>} />
					<Route path="rooms" element={user.type ==="STAFF" ?<HotelRooms />:<></>} />
					<Route path="bookings" element={<HotelBookings />} />
					<Route path="offline-booking" element={<HotelOfflineTicket />} />
					<Route path="check-availability" element={<HotelAvailability />} />
					<Route path="user-management" element={<PropertyUserHotel />} />
					<Route path="add-bank-account" element={<AddbankAccountHotel />} />
					<Route path="weekly-payments" element={<WeeklyPaymentsHotel />} />
				</Route>
			}
			{(user.type ==="PROPERTY_OWNER" || user.type === "STAFF") && user.name === "HOSTEL" && <Route path="hostel">
					<Route path="" index element={user.type ==="STAFF" ? <ParticularPropertyDashboard /> : <Navigate to = "/hostel/dashboard"/>} />
					<Route path="dashboard" index element={user.type ==="STAFF" ? <ParticularPropertyDashboard /> : <PropertyOwnerDashboard/>} />
					<Route path="company-info" element={user.type ==="STAFF" ? <HostelCompanyInfo /> : <PropertyListHostel/>} />
					<Route path="rooms" element={user.type ==="STAFF" ?<HostelRooms />:<></>} />
					<Route path="bookings" element={<HostelBookings />} />
					<Route path="scheduled-visits" element={<HostelScheduledVisit />} />
					<Route path="offline-booking" element={<HostelOfflineTicket />} />
					<Route path="check-availability" element={<HostelAvailability />} />
					<Route path="user-management" element={<PropertyUserHostel />} />
					<Route path="add-bank-account" element={<AddbankAccountHostel />} />
					<Route path="weekly-payments" element={<WeeklyPaymentsHostel />} />
				</Route>
			}
			{(user.type ==="PROPERTY_OWNER" || user.type === "STAFF") && user.name === "HALL" && <Route path="hall">
					<Route path="" index element={user.type === "STAFF" ? <ParticularPropertyDashboard />:<Navigate to ="/hall/dashboard" />} />
					<Route path="dashboard" element={user.type === "STAFF" ? <ParticularPropertyDashboard />:<PropertyOwnerDashboard />} />
					<Route path="company-info" element={user.type === "STAFF" ? <HallCompanyInfo />:<PropertyListHall />} />
					<Route path="bookings" element={<HallBookings />} />
					<Route path="offline-booking" element={<HallOfflineTicket />} />
					<Route path="enquiry" element={<HallEnquiry />} />
					<Route path="user-management" element={<PropertyUserHall />} />
					<Route path="add-bank-account" element={<AddbankAccountHall />} />
					<Route path="weekly-payments" element={<WeeklyPaymentsHall />} />
				</Route>
			}
			{(user.type ==="PROPERTY_OWNER" || user.type === "STAFF") && user.name === "TOUR" && <Route path="tours-and-travels">
					<Route path="" index element={user.type === "STAFF"?<ParticularPropertyDashboard />:<PropertyOwnerDashboard />} />
					<Route path="dashboard" element={user.type === "STAFF"?<ParticularPropertyDashboard />:<PropertyOwnerDashboard />} />
					<Route path="company-info" element={user.type === "STAFF"?<TourCompanyInfo />:<PropertyListTour />} />
					<Route path="tour-packages" element={<TourPackages />} />
					<Route path="bookings" element={<TourBookings />} />
					<Route path="offline-booking" element={<TourOfflineTicket />} />
					<Route path="scheduled-visit" element={<></>} />
					<Route path="user-management" element={<PropertyUserTour   />} />
					<Route path="add-bank-account" element={<AddbankAccountTour />} />
					<Route path="weekly-payments" element={<WeeklyPaymentsTour />} />
				</Route>
			}
			{(user.type ==="PROPERTY_OWNER" || user.type === "STAFF") && user.name === "CHAMB" && <Route path="moving-homes">
					<Route path="" index element={user.type === "STAFF"?<ParticularPropertyDashboard />:<PropertyOwnerDashboard />} />
					<Route path="dashboard" element={user.type === "STAFF"?<ParticularPropertyDashboard />:<PropertyOwnerDashboard />} />
					<Route path="caravan" element={user.type === "STAFF"?<ChambCaravan />:<PropertyListChamb />} />
					<Route path="packages" element={<Packages/>} />
					<Route path="enquiry" element={<ChambEnquiry />} />
					<Route path="bookings" element={<ChambBookings />} />
					<Route path="offline-booking" element={<ChambOfflineTicket />} />
					<Route path="user-management" element={<PropertyUserChamb/>} />
					<Route path="add-bank-account" element={<AddbankAccountChamb />} />
					<Route path="weekly-payments" element={<WeeklyPaymentsChamb />} />
				</Route>
			}
			{user.type === "FIELD_WORKER" && user.name ==="MOSZAD"  && <Route path="field-worker">
					<Route path="" index element={<CompanyUserHotelList />} />
					<Route path="hotel" element={<CompanyUserHotelList/>} />
					<Route path="hostel" element={<CompanyUserHostelList/>} />
					<Route path="hall" element={<CompanyUserHallList/>} />
					<Route path="tours-and-travels" element={<CompanyUserToursPartners/>} />
					<Route path="distinct-chamb" element={<CompanyUserDistinctChambPartners/>} />
					</Route>
			}
			{/* {user.type === "MANAGER" && user.name ==="MOSZAD"  && <Route path="manager">
					<Route path="" index element={<Navigate to="/dashboard" replace />} />
					<Route path="dashboard" element={<Dashboard/>} />
					<Route path="hotel" element={<CompanyUserHotelList/>} />
					<Route path="hostel" element={<CompanyUserHostelList/>} />
					<Route path="hall" element={<CompanyUserHallList/>} />
					<Route path="tours-and-travels" element={<CompanyUserToursPartners/>} />
					<Route path="distinct-chamb" element={<CompanyUserDistinctChambPartners/>} />
					</Route>
			} */}
			{user.type === "TELE_COM" && user.name ==="MOSZAD"  && <Route path="booking-demand/tele-support">
					<Route path="booking-demand/tele-support/dashboard" index element={<Navigate to="/dashboard" />} />
					<Route path="dashboard" element={<Dashboard/>} />
					<Route path="hotel-bookings" element={<THotelBookings/>} />
					<Route path="hotel-check-availability" element={<HotelCheckAvailability/>} />

					<Route path="hostel-bookings" element={<THostelBookings/>} />
					<Route path="hostel-check-availability" element={<HostelChechAvailability/>} />

					<Route path="hall-bookings" element={<THallBookings/>} />
					<Route path="hall-check-availability" element={<HostelChechAvailability/>} />

					<Route path="tours-&-travels-bookings" element={<TTourBookings/>} />
					<Route path="tours-&-travels-check-availability" element={<HostelChechAvailability/>} />

					<Route path="distinct-chamb-bookings" element={<TChambBookings/>} />
					<Route path="distinct-chamb-check-availability" element={<HostelChechAvailability/>} />			
					</Route>
			}
			{user.loggedIn && <Route path="/404" element={<PageNotFound />} />}
			{user.loggedIn && <Route path="*" element={<Navigate to="/404" replace />} />}
		</Routes>
	)
}