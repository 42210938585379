import { Box, Divider, MenuItem, Stack, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react"; 
import { BarChart } from '@mui/x-charts/BarChart';
import { AlertToast, noFetch } from "../../../divine-constants";
import { api } from "../../global/apiConfig";
import NumberDisplay from "../../../components/NumberDisplay";
import AdvancedSearch from "../../../components/AdvancedSearch";

export default function ParticularPropertyDashboard() {
  const { setAlert } = useContext(AlertToast);
  const [advancedSearch, setAdvancedSearch] = useState("weekly");
  const [arrays, setArrays] = useState([[],[],[],[],[],[]]);
  const [arrLen,setArrLen] = useState()
  const [inhouse,setInhouse] = useState()

  const property_id = localStorage.getItem('UserId')
  const type = localStorage.getItem('userCategory').toLocaleLowerCase()
  const fetchData2 = async () => {
    const url2 = `property/property_dashboard_checkout_status`;
    try {
      const res = await api().get(url2);
      if (res.status === 200) {
        console.log(inhouse,"Inhouse data")
        setInhouse(res.data)
      }
    } catch (error) {
      setAlert(noFetch);
    }
  };
  const fetchData1 = async () => {
    const url1 = `property/property_particular_dashboard?interval=${advancedSearch}&property_id=${property_id}&type=${type}`;
    try {
      const res = await api().get(url1);
      if (res.status === 200) {
        setArrLen(res.data.length)
        setArrays([advancedSearch === "yearly" ? res.data.map((item) => item.unit.slice(0,3)) : res.data.map((item) => item.unit) ,
        res.data.map((item) => item.bookings),
        res.data.map((item) => item.cancellations),
        res.data.reduce((sum, item) => sum + item.total_amount, 0),
        res.data.reduce((sum, item) => sum + item.booking_percentage, 0),
        res.data.reduce((sum,item) => sum + item.cancellation_percentage, 0),
      ])
      }
    } catch (error) {
      setAlert(noFetch);
    }
  };

  useEffect(() => {
    fetchData2();
    fetchData1();
  }, [advancedSearch]);

const bookingPercentage = arrays[4]/arrLen
const cancellationPercentage = arrays[5]/arrLen

return(
<box component="div">
  <Stack direction={{ xs: 'column', sm: 'row', }}
  spacing={{ xs: 1, sm: 2, md: 4 }}
  divider={<Divider orientation="vertical" flexItem />}>
  <Box>
  <AdvancedSearch>
        <TextField 
        sx={{width: '175px'}}
          select
          required={false}
          name="name"
          value={advancedSearch}
          onChange={(e) => {
            setAdvancedSearch(e.target.value);
          }}
          label="Filter"
          helperText=""
        >
          <MenuItem value="weekly">Weekly</MenuItem>
          <MenuItem value="monthly">Monthly</MenuItem>
          <MenuItem value="yearly">Yearly</MenuItem>
        </TextField>
      </AdvancedSearch>
  </Box>
      <Box className="card-container">
        <NumberDisplay color="#0aa342" title="Inhouse Count" value={inhouse.inhouse_count} />
        <NumberDisplay color="primary.main" title="Completed Count" value={inhouse.completed_count} />
      </Box>
  </Stack>      
    <Box className="card-container">
        <NumberDisplay color="text.primary" title="Total Amount" value={`₹ ${arrays[3]}`} />
        <NumberDisplay color="#02B2AF" title="Bookings Percentage" value={`${bookingPercentage.toString().slice(0,5)} %`} />
        <NumberDisplay color="#2E96FF" title="Cancellations Percentage" value={`${cancellationPercentage.toString().slice(0,5)} %`} />
      </Box>
      <br></br><br></br>

      {arrays[0].length && arrays[1].length && arrays[2].length && <BarChart
          height={300}
          series={[
            { data: arrays[1], label: 'Booking', id: 'pvId' },
            { data: arrays[2], label: 'Cancellation', id: 'uvId' },
          ]}
          xAxis={[{ data: arrays[0], scaleType: 'band' }]}
        />}
      
</box>
);
}