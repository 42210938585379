import { forwardRef, useContext, useEffect, useState } from "react";
import { StyledModalPaper } from "../../styles/modal";
import { Box, TextField, MenuItem, Checkbox, ListItemText, FormControl, InputLabel, FormControlLabel, Switch } from "@mui/material";
import { api } from "../../pages/global/apiConfig";
import ModalHeader from "../ModalHeader";
import SubmitButton from "../../components/FormButton";
import { AlertToast, alertState, noFetch } from "../../divine-constants";
import Fieldset from "../../components/Fieldset";

function HotelEditModal({setModalOpen, fetchData, data}){
	const { setAlert } = useContext(AlertToast);
	const [selectArrays, setselectArrays] = useState({
		cities: [],
		categories: [],
		amenities: []
	})
	const [areas, setAreas] = useState([]);
	const [formData, setFormData] = useState({
		id:data._id,
		name: data.name,
		subtitle: data.subtitle,
		iFrame: data.iframe,
		address: data.address,
		country: data.country,
		state: data.state,
		city: data.location,
		area: data.address,
		category: data.category,
		pincode: data.pincode,
		latitude: data.latitude,
		longitude: data.longitude,
		amenities: [],
		mainImg: "",
		otherImgs: [],
		status: data.active_status,
		description: data.hotel_description,
		policy: data.hotel_poilcy
	});
 	useEffect(() => {
		Promise.all([
			api().get('sadmin/get_city_location'),
			api().get('sadmin/get_amenities'),
			api().get('sadmin/get_category?type=hotel')
		]).then(results => {
			setselectArrays({
				cities: results[0].data,
				categories: results[2].data,
				amenities: results[1].data
			});
		}).catch(() => setAlert(noFetch));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if(selectArrays.cities.length > 0 && formData.city !== "") {
			api().get('sadmin/get_place_in_city?location_id=' + selectArrays.cities.filter(it => it.location_name === formData.city)[0].id).then(res => {
				setAreas(res.data);
			}).catch(() => setAlert(noFetch));
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData.city])

	function handleText(event) {
		setFormData(current => ({...current, [event.target.name]: event.target.value}));
	}

	function handleMainImg(event) {
		setFormData(current => ({...current, [event.target.name]: event.target.files[0]}));
	}

	function handleOtherImg(event) {
		setFormData(current => ({...current, [event.target.name]: event.target.files}));
	}

	function handleSingleSelect(event) {
		console.log("event name",event.target.name )
		console.log("event value",event.target.value )
		setFormData(current => ({...current, [event.target.name]: event.target.value}));
	}

	function handleMultiSelect(event) {
		const {
			target: {value}, 
		} = event
		setFormData(current => ({...current, amenities: value}))
	}


	function handleFormSubmit(event) {
				event.preventDefault();
		api().patch("sadmin/edit_hotel", {
			id:formData.id,
			name: formData.name.trim(),
			category: formData.category,
			country: formData.country,
			state: formData.state,
			iframe: formData.iFrame,
			location: formData.city,
			subtitle: formData.subtitle.trim(),
			area: formData.area,
			address: formData.address.trim(),
			hotel_description: formData.description.trim(),
			hotel_poilcy: formData.policy.trim(),
			longitude: formData.longitude,
			latitude: formData.latitude,
			hotel_amentities: '[' + formData.amenities.map(it => '"' + it + '"').join(',') + ']',
			pincode: "600091",
			main_image: formData.mainImg,
			other_image: Array.from(formData.otherImgs),
			active_status: formData.status,
			type: "hotel",
			subtitle:"null"

			// user_name: formData.username,
			// email: formData.email,
			// mobile_no: Number(formData.telNum),
			// password: formData.password
		}).then(res => {
						if(res.data.code === 201) {
				setAlert(alertState({
					message: "Hotel Edited successfully",
					severity: "success",
					show: true
				}));
				fetchData();
				setModalOpen({type: "edit", to: "close"});
			}
		}).catch(err => console.log(err))}

	return (
		<StyledModalPaper>
			<ModalHeader setModalOpen={setModalOpen} type="edit"/>
			<Box component="form" className="list-form" onSubmit={handleFormSubmit} noValidate>
				<Box component="div">
					<Fieldset legend="Hotel details" className="form-grid-container four-column-layout">
						<TextField 
							select 
							label="Category"
							defaultValue={formData.category}
							value={formData.category}
							name="category"
							onChange={handleSingleSelect}
							>
								{selectArrays.categories.map((category, i) => (
									<MenuItem value={category.category_name} key={category.id}>{category.category_name}</MenuItem>
								))}
						</TextField>

						<TextField
							label="Name"
							type="text"
							name="name"
							value={formData.name}
							onChange={handleText}
							placeholder="Hotel / Resort name"
						/>

						<TextField
							required={false}
							label="Embedded iFrame"
							type="text"
							name="iFrame"
							value={formData.iFrame}
							onChange={handleText}
							placeholder="Enter iFrame link"
						/>

						<TextField 
							select 
							label="Country"
							defaultValue={formData.country}
							value={formData.country}
							name="country"
							onChange={handleSingleSelect}
							>
								{[{name: "India", id: 1}].map((it) => (
									<MenuItem value={it.name} key={it.id}>{it.name}</MenuItem>
								))}
						</TextField>	

						<TextField 
							select 
							label="State"
							defaultValue={formData.state}
							value={formData.state}
							name="state"
							onChange={handleSingleSelect}
							>
								{[{name: "Tamil Nadu", id: 1}].map((it) => (
									<MenuItem value={it.name} key={it.id}>{it.name}</MenuItem>
								))}
						</TextField>	

						<TextField 
							select 
							label="City"
							defaultValue={formData.city}
							value={formData.city}
							name="city"
							onChange={handleSingleSelect}
							>
								{selectArrays.cities.map((it) => (
									<MenuItem value={it.location_name} key={it.id}>{it.location_name}</MenuItem>
								))}
						</TextField>	

						<TextField
							select
							label="Area"
							name="area"
							defaultValue={formData.area}
							value={formData.area}
							onChange={handleSingleSelect}
						>
							{areas.map((area, i) => (
								<MenuItem value={area} key={i}>{area}</MenuItem>
							))}
						</TextField>

						<TextField
							label="Address"
							type="text"
							name="address"
							value={formData.address}
							onChange={handleText}
							placeholder="Enter address"
						/>

						<TextField
							label="Amenities"
							select
							SelectProps={{multiple: true, renderValue: selected => selected.map(s => selectArrays.amenities.find(v => v.id === s)?.amenities_name) + ', '}}
							value={formData.amenities}
							onChange={handleMultiSelect}
							name="amenities"
							placeholder="Select Amenities"
						>
							{selectArrays.amenities.map(option => (
								<MenuItem									
									key={option.id}
									value={option.id}
								>
									<Checkbox checked={formData.amenities.indexOf(option.id) > -1} />
									<ListItemText>{option?.amenities_name}</ListItemText>
								</MenuItem>
							))}
						</TextField>

						<TextField 
							label="Pincode"
							type="text"
							name="pincode"
							value={formData.pincode}
							onChange={handleText}
							placeholder="Enter pincode"
						/>

						<TextField
							label="Latitude"
							type="text"
							name="latitude"
							value={formData.latitude}
							onChange={handleText}
							placeholder="4.24123234"
						/>

						<TextField
							label="Longitude"
							type="text"
							name="longitude"
							value={formData.longitude}
							onChange={handleText}
							placeholder="4.24123234"
						/>

						<TextField
							label="Main Image"
							type="file"
							name="mainImg"
							onChange={handleMainImg}
							placeholder="Input image file"
						/>

						<TextField
							required={false}
							label="Other Images"
							type="file"
							inputProps={{multiple: true}}
							onChange={handleOtherImg}
							name="otherImgs"
						/>
							
						{/* <FormControl className="inline-form-control" required={false}>
							<InputLabel shrink>Status</InputLabel>
							<FormControlLabel 
								label={formData.status ? "Active" : "Inactive"} 
								onChange={e => setFormData(curr => ({...curr, status: e.target.checked}))} 
								checked={formData.status}
								control={<Switch/>} 
							/>
						</FormControl> */}

						<TextField 
							label="Description"
							placeholder="Enter Description..."
							className="big-text"
							name="description"
							value={formData.description}
							onChange={handleText}
							multiline
						/>

						<TextField 
							label="Policy"
							placeholder="Enter Policy..."
							className="big-text"
							name="policy"
							value={formData.policy}
							onChange={handleText}
							multiline
						/>
					</Fieldset>
				</Box>
				<SubmitButton />

			</Box>
		</StyledModalPaper>
	)
}
export default forwardRef((props, _ref) => <HotelEditModal {...props} />)
