import { forwardRef } from "react";
import { StyledModalPaper } from "../../styles/modal";
import {
  Box,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import ModalHeader from "../ModalHeader";
import Fieldset from "../../components/Fieldset";
import { Clear, Done } from "@mui/icons-material";

function PaymentListViewModal({ setModalOpen, data }) {
    return (
    <StyledModalPaper>
      <ModalHeader
        title="Account Details"
        setModalOpen={setModalOpen}
        type="view"
      />
      <Box
        sx={{
          display: "grid",
          width: "100%",
          gap: "1rem",
          "& > fieldset": {
            width: "fit-content",
            flexGrow: 1,
            display: "grid",
            gap: "1rem",
            justifyContent: "space-around",
            pb: 1,
          },
          "@media only screen and (max-width: 750px)": {
            gridTemplateColumns: "1fr",
          },
        }}
        className="two-column-layout"
      >
        <Box>
          <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1}>

            {/* <Chip label={data.room_type} color={data.room_type.toLowerCase() === "ac" ? "success" : "error"} /> */}
            <Chip
              label={
                data.status
                  ? "Status - Active"
                  : "Status - Inactive"
              }
              color={data.status ? "success" : "error"}
            />
          </Stack>
          <br />
          <Stack>
          <Box>
              <Typography component="span" fontWeight={900}>
                Contact Details
              </Typography>
            </Box>
            <Box>
              <Typography component="span" fontWeight={600}>
                Name:{" "}
              </Typography>
              {data.name}
            </Box>
           
            <Box>
              <Typography component="span" fontWeight={600}>
              Property Type:{" "}
              </Typography>
              {data.property_type}
            </Box>

            <Box>
              <Typography component="span" fontWeight={600}>
                Account Number:{" "}
              </Typography>
              {data.account_number}
            </Box>

            <Box>
              <Typography component="span" fontWeight={600}>
                Bank Name:{" "}
              </Typography>
              {data.bank_name}
            </Box>
            <Box>
              <Typography component="span" fontWeight={600}>
                IFSC Code:{" "}
              </Typography>
              {data.ifsc}
            </Box>
            <Box>
              <Typography component="span" fontWeight={600}>
                Bank Code:{" "}
              </Typography>
              {data.bank_code}
            </Box>

          </Stack>
        </Box>
       </Box>
    </StyledModalPaper>
  );
}

export default forwardRef((props, _ref) => <PaymentListViewModal {...props} />);
